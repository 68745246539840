import React from "react";

export default function FirstStep() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '0px', padding: '0px', width: '100%', backgroundColor: 'white', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 10px 3px 0px', width: '80vw' }}>
                <button
                    onClick={() => {
                        const div = document.getElementById('videoControl');
                        if (div.requestFullscreen)
                            div.requestFullscreen();
                        else if (div.webkitRequestFullscreen)
                            div.webkitRequestFullscreen();
                        else if (div.msRequestFullScreen)
                            div.msRequestFullScreen();
                    }
                    }
                    style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
                >
                    Pełen ekran
                </button>
            </div>
            <video id='videoControl' controls autoPlay style={{ width: '80vw' }}>
                <source src='/open/3_pierwsze_arkusze.mp4' type="video/mp4" />
                Video control not supported by browser
            </video>

        </div>
    )
};