import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ContactMailIcon from '@material-ui/icons/ContactMail';

class Contact extends Component {
  render() {
    return (

      <Paper style={{ minHeight: '750px' }}>
        <div
          style={{
            background: "linear-gradient(to right, #0b6471, #9BC0C5)",
            display: "flex",
            height: "55px",
            width: "100%",
            backgroundColor: "#0b6471",
            color: "white",
            borderRadius: "5px",
            paddingTop: "0px",
            paddingLeft: "0px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "80px", marginTop: "5px" }}>
            <ContactMailIcon
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "42px",
              }}
            />
          </div>
          <div>
            <Typography variant="h5" style={{ color: "white" }}>
              KONTAKT
           </Typography>
          </div>
        </div>
        <div style={{ paddingTop: '20px', paddingLeft: '10px' }}>
          <Typography>
            Kontakt z COplus odbywa się poprzez Dział Obsługi Klienta pod adresem e-mail: <a href='mailto:pomoc@coplus.pl'>pomoc@coplus.pl</a>
          </Typography>
        </div>
      </Paper>

    );
  }
}



export default Contact;