const tasks = [
  
    {
      "Kod procedury": "100.01",
      "Nazwa procedury": "Znieczulenie ogólne dotchawicze z monitorowaniem rozszerzonym (do 2 godzin)",
      "Data do": "15.11.2020",
      "Status": "Do wysłania",
      "statuscode": 1,
      "Opcje": "Wyślij"
    },
    {
      "Kod procedury": "100.02",
      "Nazwa procedury": "Znieczulenie ogólne dotchawicze z monitorowaniem rozszerzonym (od 2 do 4 godzin)",
      "Data do": "15.11.2020",
      "Status": "W opracowaniu",
      "statuscode": 2,
      "Opcje": ""
    },
    {
      "Kod procedury": "100.21",
      "Nazwa procedury": "Znieczulenie podpajęczynówkowe (do 2 godzin)",
      "Data do": "30.10.2020",
      "Status": "W opracowaniu (4 dni po terminie)",
      "statuscode": 3,
      "Opcje": ""
    },
    {
      "Kod procedury": "100.43",
      "Nazwa procedury": "Znieczulenie nasiękowe",
      "Data do": "30.10.2020",
      "Status": "Zakończone - do weryfikacji",
      "statuscode": 4,
      "Opcje": "Akceptuj"
    }
   
];

const consultants = [
  {
    "name": "Tatiana",
    "lastname": "Kochańska",
    "position": "otolaryngolog",
    "email": "t.kochanska@........pl"
  },
  {
    "name": "Mateusz",
    "lastname": "Kotla",
    "position": "laryngolog",
    "email": "m.kotla@........pl"
  },
  {
    "name": "Janina",
    "lastname": "Kowalska",
    "position": "anestezjolog",
    "email": "j.kowalska@........pl"
  },
  {
    "name": "Krzysztof",
    "lastname": "Nowakowski",
    "position": "chirurg",
    "email": "k.nowakowski@........pl"
  },
  {
    "name": "Artur",
    "lastname": "Skorupa",
    "position": "anestezjolog",
    "email": "a.skorupa@........pl"
  },
  {
    "name": "Zuzanna",
    "lastname": "Ślusarz",
    "position": "neurolog",
    "email": "z.slusarz@........pl"
  },
  {
    "name": "Edward",
    "lastname": "Sławiński",
    "position": "ortopeda",
    "email": "e.slawisnki@........pl"
  },
  {
    "name": "Marzena",
    "lastname": "Tańska",
    "position": "pulmonolog",
    "email": "m.tanska@........pl"
  }
];

const yardsList = [
  { yardName: "Blok operacyjny", yardID: 1 },
  { yardName: "Pracownia tomografii komputerowej", yardID: 2 },
  { yardName: "Oddział anestezjologii", yardID: 3 }
];

const proceduresList = [
  {
    procedureID: 21.841,
    procedureName: "21.841 - Plastyka przegrody nosowej",
    yardName: "Blok operacyjny"
  },
  {
    procedureID: 13.49,
    procedureName: "13.49 - Rozdrobnienie soczewki i aspiracja zaćmy - inne",
    yardName: "Blok operacyjny"
  },
  {
    procedureID: "06.4",
    procedureName: "06.4 - Całkowita resekcja tarczycy",
    yardName: "Blok operacyjny"
  },
  {
    procedureID: 28.2,
    procedureName: "28.2 - Wycięcie migdałków podniebiennych",
    yardName: "Blok operacyjny"
  },
  {
    procedureID: 80.26,
    procedureName: "80.26 - Artroskopia - kolano",
    yardName: "Blok operacyjny"
  },
  {
    procedureID: 81.513,
    procedureName:
      "81.513 - Całkowita pierwotna wymiana stawu biodrowego z użyciem trzpienia przynasadowego",
    yardName: "Blok operacyjny"
  }
];

const hrres = [
  {
    Lp: "IDpr4",
    "Grupa pracownicza": "Instrumentariuszka",
    "Jednostka miary": "godzina",
    "Nazwa procedury": "",
    "Koszt jednostkowy": "60",
    "Komórka organizacyjna": "Blok operacyjny"
  },
  {
    Lp: "IDpr6",
    "Grupa pracownicza": "Lekarz chirurg",
    "Jednostka miary": "godzina",
    "Nazwa procedury": "",
    "Koszt jednostkowy": "120",
    "Komórka organizacyjna": "Oddział chirurgii ogólnej"
  },
  {
    Lp: "IDpr3",
    "Grupa pracownicza": "Lekarz chirurg ortopeda",
    "Jednostka miary": "godzina",
    "Nazwa procedury": "",
    "Koszt jednostkowy": "180",
    "Komórka organizacyjna": "Oddział chirurgii urazowo-ortopedycznej"
  },
  {
    Lp: "IDpr2",
    "Grupa pracownicza": "Instrumenatriuszka",
    "Jednostka miary": "procedura",
    "Nazwa procedury": '13.49 "zaćma"',
    "Koszt jednostkowy": "100",
    "Komórka organizacyjna": "Oddział okulistyczny"
  },
  {
    Lp: "IDpr1",
    "Grupa pracownicza": "Lekarz chirurg okulista",
    "Jednostka miary": "procedura",
    "Nazwa procedury": '13.49 "zaćma"',
    "Koszt jednostkowy": "400",
    "Komórka organizacyjna": "Oddział okulistyczny"
  },
  {
    Lp: "IDpr5",
    "Grupa pracownicza": "Lekarz",
    "Jednostka miary": "godzina",
    "Nazwa procedury": "",
    "Koszt jednostkowy": "105",
    "Komórka organizacyjna": "Oddział otorynolaryngologiczny"
  }
];
const hrheaders = [
  {
    name: "Komórka organizacyjna",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Grupa pracownicza",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Ilość zużyta na 1 procedurę",
    type: "number",
    edit: "true",
    format: ""
  },
  {
    name: "Liczba pracowników",
    type: "number",
    edit: "true",
    format: ""
  },
  {
    name: "Koszt jednostkowy",
    type: "number",
    edit: "false",
    format: "currency"
  },
  {
    name: "Koszt całkowity",
    type: "number",
    edit: "false",
    format: "currency"
  }
];

const matheaders = [
  {
    name: "Indeks",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Lek, materiał, wyrób",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Typ",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Pojemność / rozmiar",
    type: "text",
    edit: "false",
    format: ""
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: ""
  },

  {
    name: "Ilość zużyta na 1 procedurę",
    type: "number",
    edit: "true"
  },
  {
    name: "Koszt jednostkowy",
    type: "number",
    edit: "false",
    format: "currency"
  },

  {
    name: "Koszt całkowity",
    type: "number",
    edit: "false",
    format: "currency"
  }
];

var materials = [];
for (var i = 1; i <= 100; i++) {
  let key1 = "Indeks";
  let key2 = "Lek, materiał, wyrób";
  let key3 = "Typ";
  let key4 = "Pojemność / rozmiar";
  let key5 = "Jednostka miary";
  let key6 = "Koszt jednostkowy";
  var obj = {};
  obj[key1] = `ID ${i}`;
  obj[key2] = `Materiał ${i}`;
  obj[key3] = `M`;
  obj[key4] = `100`;
  obj[key5] = `ml`;
  obj[key6] = 10;
  materials.push(obj);
}

materials = [
  {
    Indeks: "ID1",
    "Lek, materiał, wyrób": "Adrenalina",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "0,1% amp",
    "Koszt jednostkowy": "2,45"
  },
  {
    Indeks: "ID2",
    "Lek, materiał, wyrób": "Alcaine",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "5mg/ml",
    "Koszt jednostkowy": "23"
  },
  {
    Indeks: "ID3",
    "Lek, materiał, wyrób": "Aqua pro iniectione",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "25ml amp.  ",
    "Koszt jednostkowy": "0,34"
  },
  {
    Indeks: "ID4",
    "Lek, materiał, wyrób": "Bandaż dziany",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "r 5-15",
    "Koszt jednostkowy": "2,6"
  },
  {
    Indeks: "ID5",
    "Lek, materiał, wyrób": "BSS - roztwór d/irygacji wewnątrzgałkowej",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "480ml+20ml/500ml",
    "Koszt jednostkowy": "22"
  },
  {
    Indeks: "ID6",
    "Lek, materiał, wyrób": "Cewnik do podawania tlenu przez nos",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1,2"
  },
  {
    Indeks: "ID7",
    "Lek, materiał, wyrób": "Czepek chirurgiczny",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "3,42"
  },
  {
    Indeks: "ID8",
    "Lek, materiał, wyrób": "Czyścik do koagulacji",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "3,3"
  },
  {
    Indeks: "ID9",
    "Lek, materiał, wyrób": "Dexamethason 0,1%",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "1 mg/ml",
    "Koszt jednostkowy": "12"
  },
  {
    Indeks: "ID10",
    "Lek, materiał, wyrób": "Difadol",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "0,1%/5ml",
    "Koszt jednostkowy": "8,62"
  },
  {
    Indeks: "ID11",
    "Lek, materiał, wyrób": "Diuramid",
    Typ: "M",
    "Jednostka miary": "mg",
    "Pojemność / rozmiar": "250 mg",
    "Koszt jednostkowy": "0,8"
  },
  {
    Indeks: "ID12",
    "Lek, materiał, wyrób": "Dormicum",
    Typ: "M",
    "Jednostka miary": "mg",
    "Pojemność / rozmiar": "7,5 mg",
    "Koszt jednostkowy": "1,3"
  },
  {
    Indeks: "ID13",
    "Lek, materiał, wyrób": "Dren do pompy dobowy",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID14",
    "Lek, materiał, wyrób": "Dren do ssaka",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "4,08"
  },
  {
    Indeks: "ID15",
    "Lek, materiał, wyrób": "Dren perforowany",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1,5"
  },
  {
    Indeks: "ID16",
    "Lek, materiał, wyrób": "Elektroda bierna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "3,9"
  },
  {
    Indeks: "ID17",
    "Lek, materiał, wyrób": "Elektroda czynna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "7,4"
  },
  {
    Indeks: "ID18",
    "Lek, materiał, wyrób": "Elektroda do EKG",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "0,22"
  },
  {
    Indeks: "ID19",
    "Lek, materiał, wyrób":
      "Elektroda naklejana do neuromonitoringu chirurgicznego",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "900"
  },
  {
    Indeks: "ID20",
    "Lek, materiał, wyrób": "Elektroda widelcowa odbiorcza",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "300"
  },
  {
    Indeks: "ID21",
    "Lek, materiał, wyrób": "Eyefill H.D.",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "2% / 2,5ml",
    "Koszt jednostkowy": "27"
  },
  {
    Indeks: "ID22",
    "Lek, materiał, wyrób": "Fartuch chirurgiczny wzmocniony",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "XL",
    "Koszt jednostkowy": "8,07"
  },
  {
    Indeks: "ID23",
    "Lek, materiał, wyrób": "Folia operacyjna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "35 x 40 cm",
    "Koszt jednostkowy": "20"
  },
  {
    Indeks: "ID24",
    "Lek, materiał, wyrób": "Igła",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "1,2",
    "Koszt jednostkowy": "0,08"
  },
  {
    Indeks: "ID25",
    "Lek, materiał, wyrób": "Igła 1-razowa (iniekcyjna)",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "0,5 x25mm",
    "Koszt jednostkowy": "0,06"
  },
  {
    Indeks: "ID26",
    "Lek, materiał, wyrób": "Igła 1-razowa (iniekcyjna)",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "1,2 x40mm",
    "Koszt jednostkowy": "0,06"
  },
  {
    Indeks: "ID27",
    "Lek, materiał, wyrób": "Igła punkcyjna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "22G x 0,7 x 75mm",
    "Koszt jednostkowy": "6,83"
  },
  {
    Indeks: "ID28",
    "Lek, materiał, wyrób": "Injector aplikator",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "27,41"
  },
  {
    Indeks: "ID29",
    "Lek, materiał, wyrób": "Kalipoz prolongatum",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "750mg (391mg K+)",
    "Koszt jednostkowy": "0,1257"
  },
  {
    Indeks: "ID30",
    "Lek, materiał, wyrób": "Kaniula z portem",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "0,8/0,9x25mm niebieska ",
    "Koszt jednostkowy": "2,46"
  },
  {
    Indeks: "ID31",
    "Lek, materiał, wyrób": "Lignocaina",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2% amp",
    "Koszt jednostkowy": "3,62"
  },
  {
    Indeks: "ID32",
    "Lek, materiał, wyrób": "Mannitol 20%",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "200 mg/ml",
    "Koszt jednostkowy": "4,182"
  },
  {
    Indeks: "ID33",
    "Lek, materiał, wyrób": "Maska chirurgiczna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1"
  },
  {
    Indeks: "ID34",
    "Lek, materiał, wyrób": "Maxitrol",
    Typ: "M",
    "Jednostka miary": "mg",
    "Pojemność / rozmiar": "(1mg+3500 j.m.+6000 j.m.)/g",
    "Koszt jednostkowy": "25"
  },
  {
    Indeks: "ID35",
    "Lek, materiał, wyrób": "Monafox",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "0,5% (5mg/ml)",
    "Koszt jednostkowy": "17,9"
  },
  {
    Indeks: "ID36",
    "Lek, materiał, wyrób": "Natrium chloratum 0,9%",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "3000ml",
    "Koszt jednostkowy": "14"
  },
  {
    Indeks: "ID37",
    "Lek, materiał, wyrób": "Natrium chloratum 0,9%",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "9 mg/ml inj./fl.100ml",
    "Koszt jednostkowy": "1,4"
  },
  {
    Indeks: "ID38",
    "Lek, materiał, wyrób": "Neosynephrine",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "10 %/ 10 ml",
    "Koszt jednostkowy": "21,99"
  },
  {
    Indeks: "ID39",
    "Lek, materiał, wyrób": "Nylon PA (Okul.)",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "10/0",
    "Koszt jednostkowy": "22"
  },
  {
    Indeks: "ID40",
    "Lek, materiał, wyrób": "Octenisept",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "52,8"
  },
  {
    Indeks: "ID41",
    "Lek, materiał, wyrób": "Opaska elastyczna z zapinką",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "5 x 15 cm",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID42",
    "Lek, materiał, wyrób": "Opaska podgipsowa",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "15 x 3 cm",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID43",
    "Lek, materiał, wyrób": "Opatrunek Tegaderm",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "17"
  },
  {
    Indeks: "ID44",
    "Lek, materiał, wyrób": "Osłona na stolik",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "8"
  },
  {
    Indeks: "ID45",
    "Lek, materiał, wyrób": "Ostrze do skalpela jednorazowe",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "nr 10",
    "Koszt jednostkowy": "13"
  },
  {
    Indeks: "ID46",
    "Lek, materiał, wyrób": "Ostrze do skalpela jednorazowe",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "nr 11",
    "Koszt jednostkowy": "13"
  },
  {
    Indeks: "ID47",
    "Lek, materiał, wyrób": "Ostrze do skalpela jednorazowe",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "nr 15",
    "Koszt jednostkowy": "13"
  },
  {
    Indeks: "ID48",
    "Lek, materiał, wyrób": "Ostrze do skalpela jednorazowe",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "nr 23",
    "Koszt jednostkowy": "13"
  },
  {
    Indeks: "ID49",
    "Lek, materiał, wyrób": "Oxycort maść",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "3%+1% / 10g",
    "Koszt jednostkowy": "17,52"
  },
  {
    Indeks: "ID50",
    "Lek, materiał, wyrób": "Pakiet opatr. seton wyjałow",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "1x1",
    "Koszt jednostkowy": "1,67"
  },
  {
    Indeks: "ID51",
    "Lek, materiał, wyrób": "Pakiet opatr. seton wyjałow",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "1x2",
    "Koszt jednostkowy": "2,37"
  },
  {
    Indeks: "ID52",
    "Lek, materiał, wyrób": "Pakiet opatrunkowy",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "46"
  },
  {
    Indeks: "ID53",
    "Lek, materiał, wyrób": "Pakiet opatrunkowy kompres gazow",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "10 x 10 cm",
    "Koszt jednostkowy": "5,92"
  },
  {
    Indeks: "ID54",
    "Lek, materiał, wyrób": "Pakiet opatrunkowy kompres gazow",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "18x18cm",
    "Koszt jednostkowy": "1,34"
  },
  {
    Indeks: "ID55",
    "Lek, materiał, wyrób": "Pakiet opatrunkowy kompres jałowy",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "10"
  },
  {
    Indeks: "ID56",
    "Lek, materiał, wyrób": "Pakiet opatrunkowy serwet",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "6"
  },
  {
    Indeks: "ID57",
    "Lek, materiał, wyrób": "Pęseta igłowa",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "518"
  },
  {
    Indeks: "ID58",
    "Lek, materiał, wyrób": "PGA z igłą",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2/0",
    "Koszt jednostkowy": "5"
  },
  {
    Indeks: "ID59",
    "Lek, materiał, wyrób": "Pierścień dotorebkowy okulistyczny",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "250"
  },
  {
    Indeks: "ID60",
    "Lek, materiał, wyrób": "Plaster",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2,5cmx 9,14m",
    "Koszt jednostkowy": "1,52"
  },
  {
    Indeks: "ID61",
    "Lek, materiał, wyrób": "Plaster",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "80x58 mm",
    "Koszt jednostkowy": "0,5"
  },
  {
    Indeks: "ID62",
    "Lek, materiał, wyrób": "Płyn do dezynfekcji pola operacyjnego",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "9,05"
  },
  {
    Indeks: "ID63",
    "Lek, materiał, wyrób": "Podwiązka Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2/0",
    "Koszt jednostkowy": "14"
  },
  {
    Indeks: "ID64",
    "Lek, materiał, wyrób": "Podwiązka Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "3/0",
    "Koszt jednostkowy": "14"
  },
  {
    Indeks: "ID65",
    "Lek, materiał, wyrób": "Podwiązka Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "4/0",
    "Koszt jednostkowy": "20"
  },
  {
    Indeks: "ID66",
    "Lek, materiał, wyrób": "Pojemnik do długotrwałego odsysania",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "400ml",
    "Koszt jednostkowy": "2,42"
  },
  {
    Indeks: "ID67",
    "Lek, materiał, wyrób": "Pojemnik do długotrwałego odsysania ",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "400ml",
    "Koszt jednostkowy": "3"
  },
  {
    Indeks: "ID68",
    "Lek, materiał, wyrób": "Pudełko magnetyczne na zużyte igły",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1,46"
  },
  {
    Indeks: "ID69",
    "Lek, materiał, wyrób": "Rękawice bezpudrowe chirurgiczne sterylne",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "3"
  },
  {
    Indeks: "ID70",
    "Lek, materiał, wyrób": "Rękawice diagnostyczne",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1"
  },
  {
    Indeks: "ID71",
    "Lek, materiał, wyrób": "Rozwórki tęczówkowe",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "300"
  },
  {
    Indeks: "ID72",
    "Lek, materiał, wyrób": "Serweta  operacyjna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "200 cm x 150 cm",
    "Koszt jednostkowy": "15"
  },
  {
    Indeks: "ID73",
    "Lek, materiał, wyrób": "Serweta - ściereczka sterylna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1,25"
  },
  {
    Indeks: "ID74",
    "Lek, materiał, wyrób": "Serweta – zestaw do operacji stawu biodrowego",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "70,4"
  },
  {
    Indeks: "ID75",
    "Lek, materiał, wyrób": "Serweta operacyjna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "200 cm x 150 cm",
    "Koszt jednostkowy": "15"
  },
  {
    Indeks: "ID76",
    "Lek, materiał, wyrób": "Serweta operacyjna",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "75 cm x 90 cm",
    "Koszt jednostkowy": "7"
  },
  {
    Indeks: "ID77",
    "Lek, materiał, wyrób": "Serweta operacyjna sterylna z laminatu",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "100 cm x 150 cm",
    "Koszt jednostkowy": "5,29"
  },
  {
    Indeks: "ID78",
    "Lek, materiał, wyrób": "Serweta operacyjna sterylna z laminatu",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "75 cm x 75 cm",
    "Koszt jednostkowy": "2,42"
  },
  {
    Indeks: "ID79",
    "Lek, materiał, wyrób": "Serweta operacyjna z taśmą lepną",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "15 x 10 cm ",
    "Koszt jednostkowy": "7"
  },
  {
    Indeks: "ID80",
    "Lek, materiał, wyrób": "Serweta pokrowiec na przewody ",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID81",
    "Lek, materiał, wyrób": "Serweta- zestaw do zabiegów artroskopii",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "55"
  },
  {
    Indeks: "ID82",
    "Lek, materiał, wyrób": "Serweta- zestaw do zabiegów laryngologicznych",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "230x260cm z wyc. U",
    "Koszt jednostkowy": "65,4"
  },
  {
    Indeks: "ID83",
    "Lek, materiał, wyrób": "Soczewka wewnątrzgałkowa zwijana z aplikatorem",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "300"
  },
  {
    Indeks: "ID84",
    "Lek, materiał, wyrób": "Solutio Detreomycini",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "0,5% / 10,0",
    "Koszt jednostkowy": "1,1"
  },
  {
    Indeks: "ID85",
    "Lek, materiał, wyrób": "Solutio hydrochloridi 0,5%",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "0,5% / 10,0",
    "Koszt jednostkowy": "1,8"
  },
  {
    Indeks: "ID86",
    "Lek, materiał, wyrób": "Solutio Povidone-Iodine 5% (Betadini)",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "5% / 10,0",
    "Koszt jednostkowy": "1,5"
  },
  {
    Indeks: "ID87",
    "Lek, materiał, wyrób": "Stapler skórny",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "4,5"
  },
  {
    Indeks: "ID88",
    "Lek, materiał, wyrób": "Strój chirurgiczny dla pacjenta",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "3,43"
  },
  {
    Indeks: "ID89",
    "Lek, materiał, wyrób": "Strzykawka",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2 ml",
    "Koszt jednostkowy": "0,15"
  },
  {
    Indeks: "ID90",
    "Lek, materiał, wyrób": "Strzykawka",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "20 ml",
    "Koszt jednostkowy": "0,26"
  },
  {
    Indeks: "ID91",
    "Lek, materiał, wyrób": "Strzykawka",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "3 ml",
    "Koszt jednostkowy": "0,56"
  },
  {
    Indeks: "ID92",
    "Lek, materiał, wyrób": "Strzykawka",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "5 ml",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID93",
    "Lek, materiał, wyrób": "Strzykawka jednorazowa trzyczęściowa",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "100ml",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID94",
    "Lek, materiał, wyrób": "Szczotki do mycia chirurgicznego",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1,04"
  },
  {
    Indeks: "ID95",
    "Lek, materiał, wyrób": "Szew Nylon",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2/0 z igłą 35 mm",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID96",
    "Lek, materiał, wyrób": "Szew Nylon",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "4/0 z igłą 16",
    "Koszt jednostkowy": "2"
  },
  {
    Indeks: "ID97",
    "Lek, materiał, wyrób": "Szew Polyester",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "1 z igłą 35",
    "Koszt jednostkowy": "6"
  },
  {
    Indeks: "ID98",
    "Lek, materiał, wyrób": "Szew Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "0 z igłą 40 mm",
    "Koszt jednostkowy": "9,54"
  },
  {
    Indeks: "ID99",
    "Lek, materiał, wyrób": "Szew Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "1 z igłą 40",
    "Koszt jednostkowy": "7,45"
  },
  {
    Indeks: "ID100",
    "Lek, materiał, wyrób": "Szew Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "2/0 z igłą 26",
    "Koszt jednostkowy": "6"
  },
  {
    Indeks: "ID101",
    "Lek, materiał, wyrób": "Szew Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "3/0 z igłą 26",
    "Koszt jednostkowy": "6"
  },
  {
    Indeks: "ID102",
    "Lek, materiał, wyrób": "Szew Polysorb",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "4-0 igła 20",
    "Koszt jednostkowy": "9,5"
  },
  {
    Indeks: "ID103",
    "Lek, materiał, wyrób": "Ściereczki sterylne",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "1,25"
  },
  {
    Indeks: "ID104",
    "Lek, materiał, wyrób": "Tampon donosowy",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "100x20x15mm ",
    "Koszt jednostkowy": "20,82"
  },
  {
    Indeks: "ID105",
    "Lek, materiał, wyrób": "Taśma samoprzylepna z włókniny",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "0,33"
  },
  {
    Indeks: "ID106",
    "Lek, materiał, wyrób": "Tropicamidum",
    Typ: "M",
    "Jednostka miary": "ml",
    "Pojemność / rozmiar": "1%(10mg/ml)",
    "Koszt jednostkowy": "10"
  },
  {
    Indeks: "ID107",
    "Lek, materiał, wyrób": "Wata",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "2,51"
  },
  {
    Indeks: "ID108",
    "Lek, materiał, wyrób": "Zestaw do płukania powierzchni stawowych",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "160"
  },
  {
    Indeks: "ID109",
    "Lek, materiał, wyrób":
      "Zestaw do zabiegu fakoemulsyfikacji (operacja zaćmy)",
    Typ: "M",
    "Jednostka miary": "szt",
    "Pojemność / rozmiar": "",
    "Koszt jednostkowy": "329,4"
  }
];

var matassignmentsSource = [];
for (i = 1; i <= 1; i++) {
  let key1 = "Indeks materiału";
  let key2 = "Ilość zużyta na 1 procedurę";
  obj = {};
  obj[key1] = `ID ${i}`;
  obj[key2] = i;
  matassignmentsSource.push(obj);
}

var hrassignmentsSource = [];
for (i = 1; i <= 1; i++) {
  let key1 = "Indeks pracownika";
  let key2 = "Ilość zużyta na 1 procedurę";
  let key3 = "Liczba pracowników";
  obj = {};
  obj[key1] = `${i}`;
  obj[key2] = i;
  obj[key3] = i;
  hrassignmentsSource.push(obj);
}

const procedureinfo = {
  length: "2020-02-02 00:00",
  date: "2020-10-15",
  described_by: "-",
  consulted_with: "-"
};

export {
  hrres,
  hrheaders,
  materials,
  matheaders,
  matassignmentsSource,
  hrassignmentsSource,
  yardsList,
  proceduresList,
  procedureinfo,
  consultants,
  tasks
};
