import React, { Suspense } from "react";
import ReactPlayer from "react-player/file";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { StarRounded } from "@material-ui/icons";
//const ReactPlayer = React.lazy(() => import("react-player/file"));

export default function UserManual001() {
  let a = 1;
  const matchesWidth = useMediaQuery("(min-width:600px)");
  return (
    //<Suspense fallback={<div>Wczytywanie...</div>}>
    <div class="article">
      <br />
      <br />
      
      <div
        style={{
          borderRadius: "5px",
          backgroundColor: "rgb(240,240,240)",
          borderBottom: "5px solid darkgray",
          padding: "10px",
          marginBottom: "18px",
        }}
      >
        <p><strong>Plik „COPLUS SRK SZPITAL/PRZYCHODNIA.xlsx”</strong></p>  
      <p>
      Plik importujący dane do aplikacji CO+ składa się z 11 arkuszy. </p>
<ul>
 <li>
 <strong> Arkusze 1,2,3,5,6,7,8 są obowiązkowe </strong>przy wdrożeniu podstawowej wersji standardu rachunku kosztów (<strong>bez kolumn dotyczących pacjenta</strong>).
  </li> 
  <li>
 <strong> Arkusz 4 jest nieobowiązkowy </strong>i wykorzystują go tylko te podmioty, w których poradnie czy oddziały realizują, poza działalnością finansowaną przez NFZ, działalność komercyjną.
  </li> 
  <li>
 <strong> Arkusze 9, 10 są nieobowiązkowe </strong>przy wdrożeniu podstawowej wersji standardu rachunku kosztów.
  </li> 
  <li>  Arkusz 11 oraz kolumny dotyczące pacjenta są dla podmiotów wdrażających rozszerzoną wersję standardu rachunku kosztów.
  </li> </ul>	

  

     
      </div>

   
      <p>
        <strong>Spis arkuszy:</strong>
      </p>
      
      <ul>
    <li>
        <strong>1. SŁOWNIK 4</strong>
        – uzupełniony danymi arkusz zawiera listę dodatkowych kont 4, które zostały dodane ponad wymogi rozporządzenia. 
    </li>
    <li>
        <strong>2. SŁOWNIK 5</strong>
        – uzupełniony danymi arkusz zawiera numer i nazwę konta OPK podmiotu
        oraz przypisaną syntetykę i kody resortowe zgodnie z wymogami
        rozporządzenia.
    </li>
    <li>
        <strong>3. Koszty</strong>
         – uzupełniony danymi arkusz zawiera koszty w układzie konto OPK à konto
        4 w podziale na miesiące danego roku.
    </li>
    <li>
        <strong>4. Etap 0</strong>
         – uzupełniony danymi arkusz zawiera informację o sposobie podziału kosztów bezpośrednich poradni / oddziału na OPK związany z udzielaniem świadczeń w ramach NFZ i na OPK związany z udzielaniem świadczeń komercyjnych. 
    </li>
    <li>
        <strong>5. KLUCZE OPK Pomocnicze </strong>
        – uzupełniony danymi arkusz zawiera dane służące do alokacji kosztów
        OPK pomocniczych z grup 530 i 535.
    </li>
    <li>
        <strong>6. KLUCZE OPK Proceduralne </strong>
        – uzupełniony danymi arkusz zawiera dane alokacji OPK z grupy 507.
    </li>
    <li>
        <strong>7. Procedury</strong>
        – uzupełniony danymi arkusz zawiera listę procedur medycznych, kwoty
        kosztów bezpośrednich i standardowe czasy trwania.
    </li>
    <li>
        <strong>8. Przychody</strong>
        - uzupełniony danymi arkusz zawiera kwoty przychodów przypisanych do
        odpowiednich kont OPK.
    </li>
    <li>
        <strong>9. Osobodni oddziały </strong>
        – uzupełniony danymi arkusz zawiera numer konta OPK i liczbę osobodni.
    </li>
    <li>
        <strong>10. Rejestracja SOR</strong>
        -           uzupełniony danymi arkusz zawiera sposób podziału kosztów konta OPK
            SOR Rejestracja na konto OPK pacjentów w ramach ryczałtu dobowego
            oraz konta OPK pacjentów przyjętych na inne oddziały.
    </li>
 
  
    <li>
        <strong>11. Pacjent - leki i materiały</strong>
        - uzupełniony danymi arkusz zawiera kwoty leków i materiałów
        przypisanych bezpośrednio do pacjentów.
    </li>
</ul>

<p>
    <strong>Uzupełnienie danymi pliku importującego dane pozwoli na:</strong>
</p>
<ol>
  <li>
    rozliczenie kosztów OPK pomocniczych (530 i 535),
    </li>
    <li>
    rozliczenie kosztów OPK proceduralnych (507),
    </li>
    <li>
    rozliczenie kosztów zarządu (550),
    </li>
    <li>
    wykonanie analizy rentowności OPK.
    </li>
<li>
    wykonanie analizy rentowności pacjenta i kosztu jednostkowego np.
    osobodnia czy porady/usługi (w wersji CO+ Zaawansowany).
</li>
</ol>

<div className="stage">Przygotowanie i import danych</div>
     
<h1>
    <strong>Przygotowanie i import danych</strong>
</h1>
<p>
    Zasilenie danymi aplikacji CO+ odbywa się w dwóch etapach:
</p>
<ol>
<li>
    Pierwszy etap to przygotowanie danych we wzorcowym pliku Excel do
    importu (opis postępowania poniżej).
</li>
<li>
    Drugi etap do import danych do aplikacji CO+ oraz ewentualnie poprawa
    błędów w danych.
</li>
</ol>
<p>
    Pierwszy etap, czyli uzupełnienie danymi wzorcowego pliku Excel do importu,
    opisany został w poniższych punktach. Do opisów dodane są stosowne filmy
    instruktażowe. Drugi etap przedstawiony zostanie w tej części instrukcji.
</p>
<br/>
<p>
    <strong>Okno importu danych</strong>
</p>
<p>
Import dostępny jest z poziomu menu <strong>Rachunek kosztów - Import danych SRK</strong> (<a href="https://coplus.pl/import">https://coplus.pl/import</a>). Ma do
    niego dostęp tylko Administrator.
</p>

<ol>
  <li>
    Obszar importu danych – służy do wskazania wypełnionego danymi pliku
    Excel oraz weryfikacji poprawności importowanych danych.
</li>
<li>
    Obszar przeglądu i zarządzania danymi – służy do weryfikacji
    zaimportowanych danych (zaprezentowane są sumaryczne kwoty przychodów oraz
    kosztów) i zarządzania dodanymi okresami danych.
</li>
</ol>
<p>
    Zrzut: Okno importu danych do aplikacji CO+
</p>
<p>
    <img style={{maxWidth: '700px'}}
        
        src="/home/images/wzorxls/0. Okno importu danych.png"
    />
</p>
<br/>
<p>
    <strong>Import danych z pliku Excel</strong>
</p>
<ol>
<li>
W celu importu danych należy menu <strong>Rachunek kosztów - Import danych SRK</strong> wybrać    <strong>Import danych</strong>. Ikona postępu pokazuje procent wykonanego
    importu.
</li>
<li>
    Aplikacja CO+ najpierw weryfikuje poprawność struktury pliku Excel, tzn.
    sprawdzana jest poprawność obowiązkowych arkuszy, ich nazwy oraz nazwy
    kolumn. Następnie weryfikacji podlega poprawność danych.
</li>
<li>
    W przypadku błędu CO+ wyświetla odpowiedni komunikat. Import należy
    powtarzać do momentu pojawiania się komunikatu o poprawności danych tzn.
    komunikatu kończącego import (zrzut poniżej)
</li>

<p>
    Zrzut: Komunikat o poprawności danych kończący import
</p>
<p>
    <img
        src="/home/images/wzorxls/0. Komunikat poprawnosci.png"
    />
</p>
<li>
    W celu szybkiej weryfikacji zaimportowanych okresów oraz kwot przychodów
    i kosztów można przejść do obszaru przeglądu i zarządzania danymi.
</li>
<li>
    Po poprawnie zakończonym imporcie, od razu dostępne są raporty.
</li>
</ol>
<br/>
<p>
    <strong>Rodzaje alertów</strong>
</p>
<p>
    W trakcie importu danych z pliku Excel, aplikacja zwraca trzy rodzaje
    komunikatów.
</p>
<p>
    <img
        src="/home/images/wzorxls/0. Alert blad.png"
    />
    Wykrzyknik – oznacza błąd w danych, który należy naprawić i ponownie
    zaimportować plik Excel.
</p>
<p>
    <img
        src="/home/images/wzorxls/0. Alert ostrzezenie.png"
    />
    Ostrzeżenie – oznacza błąd w danych, który nie wpływa na wykonywane
    obliczenia. Zazwyczaj pojawia się on przy braku danych w arkuszach, które
    są nieobowiązkowe.
</p>
<p>
    <img
       src="/home/images/wzorxls/0. Alert poprawny.png"
    />
    Znak poprawności - oznacza poprawnie zaimportowane dane. Po tym
    komunikacie, można przejść do analizy danych w raportach.
</p>
<br/>
<p>
    <strong>Okresy importowanych danych</strong>
</p>
<p>
    Użytkownik ma możliwość zaimportowania w jednym pliku Excel wielu okresów.
    Zaleca się jednak, przygotowywanie danych dla każdego okresu w osobnym
    pliku Excel. Pamiętać należy, że we wszystkich arkuszach jednego pliku musi
    być informacja o tych samych okresach czasu.
</p>
<br/>
<p>
    <strong>Dane budżetowe</strong>
</p>
<p>
    Chcąc zaimportować dane budżetowe trzeba w kolumnie miesiąc wpisać małą
    literę b. Aplikacja CO+ automatycznie doda do raportów wartości budżetowe
    oraz wyliczy odchylenie od planu. Budżet musi mieć poziom szczegółowości
    zbliżony do wykonania. Najłatwiej jest posłużyć się jednym, poprawnie
    zweryfikowanym plikiem Excel i zmienić jego wartości, we wszystkich
    arkuszach, na dane budżetowe.
</p>
<br/>
<p>
    <strong>Najczęstsze błędy:</strong>
</p>
<ul>
    <li>
        Inne nazwy arkuszy i kolumn niż we wzorcowym pliku Excel.
    </li>
    <li>
        W pliku Excel dodano komórki z komunikatami o błędach: #DZIEL/0!,
        #N/D!, #ARG!, #ADR!, #NAZWA?.
    </li>
    <li>
        Niespójność w indeksach kont 4, OPK i procedur w różnych arkuszach.
    </li>
    <li>
        Pusty arkusz, który jest obowiązkowy.
    </li>
    <li>
        W przypadku usuwania danych w arkuszach, pamiętać trzeba o usuwaniu
        całych wierszy.
    </li>
    <li>
        Puste wiersze pomiędzy danymi.
    </li>
</ul>
<br/>
<p>
    <strong>Podsumowanie arkuszy</strong>
</p>
<p>
    Arkusze 1-6
</p>
<p>
    <img style={{maxWidth: '700px'}}
         src="/home/images/wzorxls/0. Arkusz 1-5.png"
    />
</p>
<p>
    Arkusze 7-11
</p>
<p>
    <img style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/0. Arkusz 6-10.png"
    />
</p>







              

      <div className="stage">1. Słownik 4</div>
      <p>
      Uzupełniony danymi arkusz zawiera listę dodatkowych kont 4, które zostały dodane ponad wymogi rozporządzenia. 
</p>
<p>
    Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<p>
    <img  style={{maxWidth: '700px'}}
  
        src="/home/images/wzorxls/1 slownik 4.png"
    />
</p>
<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>


<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/1WZORSłownik4.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/1. WZOR Slownik 4.mp4"
        />
      </p>


    
     <div className="stage">2. Słownik 5</div>
    

     <div>
    <p>
        Uzupełniony danymi arkusz zawiera numer i nazwę konta OPK podmiotu oraz
        przypisaną syntetykę i kody resortowe zgodnie z wymogami
        rozporządzenia.
    </p>
    <p>
        Dwie pierwsze kolumny tabeli to obecnie stosowane konta OPK. Trzecia
        kolumna to syntetyka zgodna z rozporządzeniem. Czwarta i piąta kolumna
        to kod resortowy zgodny z układem kodów charakteryzujących specjalność
        komórki organizacyjnej, o których mowa w załączniku nr 2 do
        <em>
            rozporządzenia Ministra Zdrowia z dnia 17 maja 2012 r. w sprawie
            systemu resortowych kodów identyfikacyjnych oraz szczegółowego
            sposobu ich nadawania
        </em>
        .
    </p>
    <p>
        Dwie dodatkowe kolumny to nazwa klucza podziałowego oraz oznaczenie czy
        dany klucz wymaga comiesięcznej aktualizacji. Kolumny te uzupełniane są
        dla kont z grup: 507, 530 oraz 535.
    </p>
    <p>
        Poniższa tabela zawiera przykład uzupełnienia arkusza.
    </p>
    <p>
        <img
            style={{maxWidth: '700px'}}
            src="/home/images/wzorxls/2 slownik opk.png"
        />
    </p>
    <p>
        <img
            width="203"
            height="80"
            src="/home/images/wzorxls/2 slownik opk klucz.png"
        />
    </p>
    <p>
        <strong> </strong>
    </p>
    <p>
        <strong>UWAGA</strong>
    </p>
    <ul>
      <li>
        Wypełniając tabelę należy sprawdzić czy obecnie posiadana struktura
        OPK spełnia wytyczne rozporządzenia w zakresie wyodrębniania OPK.
        Rozporządzenie nie narzuca zmian OPK. Podmiot powinien jednak zapoznać
        się z wytycznymi, których wykorzystanie ma na celu lepsze odwzorowanie
        ponoszonych kosztów, a w konsekwencji lepszą podstawę do taryfikacji
        świadczeń.
        </li>
        <li>
        Numer z kolumny „Numer konta OPK” będzie wykorzystywany w większości
        arkuszy omawianego pliku do importu, dlatego należy zwrócić uwagę, żeby
        zawsze był podawany w takiej samej formie.
        </li>
        <li>
        Kolumna „Numer konta OPK” musi zawierać unikalne numery. Oznacza to,
        że w przypadku OPK funkcjonujących w ramach różnych działalności, każdy
        musi mieć unikalny numer. (poniżej dwa przykłady)
        </li>
        </ul>
      <p>
        Numer konta dla Pracowni tomografii komputerowej, realizującej badania
        dla oddziałów (OPK w grupie 507), w ramach odrębnej umowy z NFZ (OPK w
        grupie 508) oraz badania odpłatne tzw. komercyjne (OPK w grupie 540).
    </p>
    <br/>
    <p>
        <img
            style={{maxWidth: '700px'}}
            src="/home/images/wzorxls/2 slownik opk przyklad tk.png"
        />
    </p>
    <br/>
    <p>
        Numer konta dla Kuchni, przygotowującej posiłki dla oddziałów (OPK w
        grupie 535) oraz realizującej działalność komercyjną (OPK w grupie
        545).
    </p>
    <br/>
    <p>
        <img
           style={{maxWidth: '700px'}}
            src="/home/images/wzorxls/2 slownik opk przyklad kuchnia.png"
        />
    </p>
    <br/>
</div>




<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>


<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/2WZORSłownik5.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/2. WZOR Slownik 5.mp4"
        />

</p>

<div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
            <a
              href="/articles/zasady_wyodrebniania_opk"
              target="_blank"
            >
              <strong>Załącznik 2 – zasady wyodrębniania OPK</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_sor_i_izby_przyjec"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla SOR i Izby przyjęć</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_bloku_operacyjnego"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla centralnego bloku operacyjnego</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dzialanosci_pomocniczej"
              target="_blank"
            >
              <strong>Zasady dotyczące wyodrębniania OPK działalności pomocniczej</strong>
            </a><br/>
            <a
              href="/articles/zasady_wydzielania_opk_dzialalnosci_podstawowej"
              target="_blank"
            >
              <strong>Zasada wydzielania z OPK działalności podstawowej (500-506) OPK
        proceduralnych (507)</strong>
            </a><br/>
            <a
              href="/articles/jakie_opk_wydzielic_dla_kliniki"
              target="_blank"
            >
              <strong>Jakie wydzielić OPK w przypadku komórki będącej kliniką?</strong>
            </a><br/>
            <a
              href="/articles/jakie_opk_wydzielic_dla_wielu_lokalizacji"
              target="_blank"
            >
              <strong>Jakie wydzielić OPK w przypadku realizacji świadczeń w różnych
        lokalizacjach?</strong>
            </a><br/>
            <a
              href="/articles/jakie_opk_wydzielic_dla_oddzialow_wieloprofilowych"
              target="_blank"
            >
              <strong>Jakie OPK wydzielić dla oddziałów łączonych i wieloprofilowych?</strong>
            </a><br/>
            <a
              href="/articles/rozliczanie_opk_proceduralnego_pracownia_tomografii_komputerowej"
              target="_blank"
            >
              <strong>Rozliczanie kosztów OPK proceduralnego – przykład OPK Pracownia
        tomografii komputerowej</strong>
            </a><br/>
            <a
              href="/articles/rozliczanie_opk_dzialalnosci_pomocniczej_opk_kuchnia_i_pralnia"
              target="_blank"
            >
              <strong>  Rozliczanie kosztów OPK działalności pomocniczej – przykłady OPK Kuchnia
        i OPK Pralnia</strong>
            </a><br/>
          </div>
        </div>
      </div>


      <div className="stage">3. Koszty</div>
      
      <p>
    Uzupełniony danymi arkusz zawiera koszty w układzie konto OPK i konto 4 w
    podziale na miesiące danego roku.
</p>
<p>
    Dwie pierwsze kolumny to rok i miesiąc. Trzecia i czwarta kolumna to numer
    i nazwa konta OPK. Piąta i szósta to numer i nazwa konta 4. Ostatnia
    kolumna to wartość kosztu. Poniższa tabela zawiera przykład uzupełnienia
    arkusza.
</p>
<br/>
<p>
    <img
       style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/3 koszty.png"
    />
</p>
<br/>
<p>
    <strong>Uwaga</strong>
    :
</p>
<ul>
  <li>
    Wypełniając tabelę należy upewnić się, że suma podanych kosztów jest
    równa z kwotami z systemu FK dla danego okresu.
    </li>
<li>
    Kolumny „Numer konta OPK” oraz „Numer konta 4” zawierać muszą numery
    znajdujące się w odpowiednich słownikach w arkuszach „Słownik 4” i „Słownik
    5”.
</li>
</ul>
<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>


<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/3WZORkoszty2.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/3. WZOR- koszty2.mp4"
        />
      </p>



      <div className="stage">4. Etap 0</div>
      <p>
      Uzupełniony danymi arkusz zawiera informację o sposobie podziału kosztów bezpośrednich poradni / oddziału na OPK związany z udzielaniem świadczeń w ramach NFZ i na OPK związany z udzielaniem świadczeń komercyjnych. 
</p>
<p>
    Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<p>
    <img style={{maxWidth: '700px'}}
  
        src="/home/images/wzorxls/4 Etap 0.png"
    />
</p>
<p>
<strong>Jak zostaną podzielone koszty na podstawie powyższego przykładu?</strong> 70% wszystkich kosztów z OPK 501-51002 zostanie na tym OPK, a 30% wszystkich kosztów zostanie zaalokowane na konto działalności komercyjnej 540-51002. Użytkownik może użyć innych kluczy jak na przykład liczba pacjentów. Program sam rozdzieli koszty na podstawie wartości kluczy. 
</p>


 

      <div className="stage">5. KLUCZE OPK Pomocnicze</div>
      

      <p>
    Uzupełniony danymi arkusz zawiera dane służące do alokacji kosztów OPK
    pomocniczych z grup 530 i 535.
</p>
<p>
    Dwie pierwsze kolumny tabeli to rok i miesiąc. Trzecia i czwarta kolumna
    zawierają informacje o numerze konta OPK pomocniczego i klucza podziałowego
    rozliczającego jego koszty. Piąta kolumna to numer konta OPK docelowego, do
    którego zostaną doliczone koszty OPK pomocniczego. Ostatnia kolumna to
    wartość klucza podziałowego. Poniższa tabela zawiera przykład uzupełnienia
    arkusza.
</p>
<br/>

<p>
    <img
            style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/4 klucz opk pomocniczy.png"
    />
</p>
<p>
    <strong>Uwaga</strong>
    :
</p>
<ul>
<li>
    alokując koszty komórek działalności pomocniczej medycznej i niemedycznej
    należy stosować się do zaleceń z załącznika nr 7 STANDARDOWE KLUCZE
    PODZIAŁOWE SŁUŻĄCE ROZLICZANIU KOSZTÓW DZIAŁALNOŚCI POMOCNICZEJ do
    rozporządzenia. Rozporządzenie daje również możliwość wykorzystania
    dotychczas stosowanych kluczy.
</li>
<li>
    koszty OPK pomocniczych w całości muszą zostać rozliczone na inne konta
    OPK, dlatego wszystkie OPK z grupy 530 i 535 muszą zostać dodane do tego
    arkusza.
</li>
<li>
    nie należy tworzyć alokacji konta OPK pomocniczego na to samo konto OPK
    pomocnicze np. OPK Dział IT na OPK Dział IT.
</li>
<li>
    Jak uzupełnić arkusz, w przypadku gdy jeden OPK np. OPK Pralnia świadczy
    usługi na rzecz podmiotu oraz realizuje usługi komercyjne? (poniższa tabela
    zawiera przykład uzupełnienia arkusza).
</li>
</ul>
<br/>
<p>
    <img
         style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/4 klucz opk pomocniczy przyklad pralnia.png"
    />
</p>
<br/>

<ul>
<li>
    Jak uzupełnić arkusz w przypadku OPK SOR (koszty rejestracji i
    segregacji) (530). (poniższa tabela zawiera przykład uzupełnienia arkusza).
</li>
</ul>
<br/>
<p>
    <img
      style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/4 klucz opk pomocniczy sor.png"
    />
   
</p>
<br/>




<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>


<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/4Wzorpomocnicze.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/4. Wzor- pomocnicze.mp4"
        />
      </p>

      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
            
            <a
              href="/articles/zasady_wyodrebniania_opk_dzialanosci_pomocniczej"
              target="_blank"
            >
              <strong>Zasady dotyczące wyodrębniania OPK działalności pomocniczej</strong>
            </a><br/>
            
            <a
              href="/articles/rozliczanie_opk_dzialalnosci_pomocniczej_opk_kuchnia_i_pralnia"
              target="_blank"
            >
              <strong>  Rozliczanie kosztów OPK działalności pomocniczej – przykłady OPK Kuchnia
        i OPK Pralnia</strong>
            </a><br/>

            <a
              href="/articles/zasady_wyodrebniania_opk_dla_sor_i_izby_przyjec"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla SOR i Izby przyjęć</strong>
            </a><br/>

          </div>
        </div>
      </div>



      <div className="stage">6. KLUCZE OPK Proceduralne</div>
     
     
      <p>
    Uzupełniony danymi arkusz zawiera dane alokacji kont OPK z grupy 507.
</p>
<p>
Dwie pierwsze kolumny tabeli to rok i miesiąc. Trzy kolejne zawierają informacje o OPK proceduralnym i zrealizowanych procedurach wraz z wykonaną ilością. Ostatnia kolumna to OPK, który zlecił wykonanie procedury. Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<br/>
<p>
    <img
          style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/5 klucz opk proceduralny.png"
    />
</p>
<br/>
<p>
    W arkuszu znajdują się dwie dodatkowe kolumny, do wypełnienia przez te
    podmioty, które planują dodać dane przychodowe, i mieć możliwość analizy
    rentowności pacjenta.
</p>
<p>
    <img
        width="269"
        height="58"
        src="/home/images/wzorxls/kg i ko pacjenta.png"
    />
</p>
<p>
    <strong>Uwaga</strong>
    :
</p>
<ul>
<li>
    koszty komórek wykonujących procedury należy rozliczać stosując wytyczne
    z załącznika nr 8 STANDARDOWE KLUCZE PODZIAŁOWE SŁUŻĄCE ROZLICZANIU KOSZTÓW
    DZIAŁALNOŚCI PODSTAWOWEJ, W TYM OPK PROCEDURALNYCH.
</li>
<li>
    koszty OPK proceduralnych w całości muszą zostać rozliczone na inne konta
    OPK, dlatego wszystkie OPK z grupy 507 muszą zostać dodane do tego arkusza.
</li>
<li>
    nie należy tworzyć alokacji konta OPK proceduralnego na to samo konto OPK
    proceduralne np. OPK Laboratorium na OPK Laboratorium.
</li>
<li>
    Jak uzupełnić dane w przypadku SOR i świadczonych na rzecz pacjentów
    procedur medycznych?
</li>
</ul>
<p>
    Poniższa tabela prezentuje zasadę przygotowania danych dla OPK SOR
    proceduralnego (507). W większych podmiotach OPK SOR proceduralny powinien
    być zostać podzielony na:
</p>
<ul>
<li>

    507-4902-1 - Szpitalny oddział ratunkowy (procedury - gabinety lekarskie.
    Koszty infrastruktury plus personelu medycznego)
</li>
<li>
    507-4902-2 - Szpitalny oddział ratunkowy (procedury - sale intensywnego
    nadzoru, sale zabiegowe,... Koszty infrastruktury plus personelu
    medycznego)
</li>
</ul>

<p>
    Informacja o liczbie i rodzaju wykonanych procedur w SOR na rzecz pacjentów
    powinna być dostępna w systemie informatycznym podmiotu. Pacjenci, którzy
    byli zaopatrzeni tylko w SOR, w ścieżce pobytu mają wpisany tylko oddział
    SOR. Natomiast pacjenci, którzy po zaopatrzeniu w SOR zostali przyjęci na
    inny oddział, w ścieżce pobytu mają najpierw SOR a następnie nazwę lub
    nazwy oddziałów, na których przebywali, w ramach jednego pobytu w szpitalu
    (mieli ten sam numer księgi głównej).
</p>
<br/>
<p>
    <img
           style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/5 klucz opk proceduralny przyklad sor.png"
    />
</p>
<br/>

<ul>
<li>
    · Jak uzupełnić dane w przypadku Pracowni TK realizującej świadczenia:
</li>
<ul>
<li>
    dla komórek podmiotu,
</li>
<li>
    w ramach odrębnej umowy w NFZ (tzn. realizuje świadczenia kosztochłonne)
    oraz
</li>
<li>
    odpłatne (tzw. usługi komercyjne).
</li>
</ul></ul>

<p>
    Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<br/>
<p>
    <img
       style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/5 klucz opk proceduralny sor.png"
    />
</p>
<br/>



<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>


<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/5WZORproceduralne.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/5. WZOR- proceduralne.mp4"
        />
      </p>



      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
            <a
              href="/articles/zasady_wyodrebniania_opk"
              target="_blank"
            >
              <strong>Załącznik 2 – zasady wyodrębniania OPK</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_sor_i_izby_przyjec"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla SOR i Izby przyjęć</strong>
            </a><br/>
           
          </div>
        </div>
      </div>



      <div className="stage">7. Procedury</div>
   
   
      <p>
 
        Uzupełniony danymi arkusz zawiera listę procedur medycznych, wartości
        kosztów bezpośrednich i standardowe czasy trwania procedur.
</p>
<p>
    Trzy pierwsze kolumny to numer OPK wykonującego procedury, numer i nazwa
    procedury. Czwarta i piąta kolumna to koszt bezpośredni procedury oraz jej
    standardowy czas trwania. Poniższa tabela zawiera przykład uzupełnienia
    arkusza.
</p>
<br/>
<p>
    <img
     style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/6 procedury.png"
    />
</p>
<br/>

<p>
    <strong>Uwaga</strong>:
</p>
<ul><li>
    arkusz musi zawierać co najmniej te procedury, które dodane zostały w
    arkuszu nr 6.
</li>
</ul>




<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>

<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/6WZORprocedury.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/6. WZOR- procedury.mp4"
        />
      </p>



     


      <div className="stage">8. Przychody</div>
    
      <p>
    Uzupełniony danymi arkusz zawiera kwoty przychodów przypisanych do
    odpowiednich kont OPK.
</p>
<p>
    Dwie pierwsze kolumny to rok i miesiąc. Trzecia kolumna to numer konta OPK,
    dla którego ewidencjonowane są przychody. Czwarta to liczba jednostek, za
    które otrzymano przychód np. liczba pacjentów, liczba badań, liczba
    kilogramów, liczba pakietów. Ostatnia kolumna to przychód danego OPK.
</p>
<p>
    W arkuszu znajdują się trzy dodatkowe kolumny, do wypełnienia przez te
    podmioty, które planują dodać dane przychodowe i mieć możliwość analizy
    rentowności pacjenta.
</p>
<p>
    Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<br/>
<p>
    <img
    style={{maxWidth: '700px'}}

        src="/home/images/wzorxls/8 przychody.png"
    />
</p>
<br/>

<p>
    <img
        width="372"
        height="71"
        src="/home/images/wzorxls/8 przychody pacjent.png"
    />
</p>
<p>
    <strong>Uwaga</strong>
    :
</p>
<ul>
<li>
    w arkuszu nie mogą zostać dodane konta z grup: 507, 530, 535.
</li>
<li>
    · W arkuszu poza OPK działalności podstawowej posiadającymi przychód,
    powinny dodatkowo znaleźć się konta z grup: 508, 540, 545.
</li>
</ul>

<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>

<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/8WZORprzychody.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/8. WZOR - przychody.mp4"
        />
      </p>



      <div className="stage">9. Osobodni oddziały (arkusz dodatkowy)</div>
 

      <p>
    Uzupełniony danymi arkusz zawiera numer konta OPK i liczbę osobodni.
</p>
<p>
    Arkusz zawiera dane potrzebne do wyliczenia kosztów osobodni. Dwie pierwsze
    kolumny to rok i miesiąc. Trzecia i czwarta to numer konta OPK, dla którego
    ewidencjonowana jest liczba osobodni.
</p>
<p>
    W arkuszu znajdują się dwie dodatkowe kolumny, do wypełnienia przez te
    podmioty, które planują dodać dane przychodowe i mieć możliwość analizy
    rentowności pacjenta.
</p>
<p>
    Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<br/>
<p>
    <img
          style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/9 osobodni.png"
    />
</p>
<br/>
<p>
    <img
        width="269"
        height="58"
        src="/home/images/wzorxls/kg i ko pacjenta.png"
    />
</p>


<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>

<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/9WZORosobodni.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/9. WZOR - osobodni.mp4"
        />
      </p>

      <div className="stage">10. Rejestracja SOR</div>
   

   <p>
 Uzupełniony danymi arkusz zawiera sposób podziału kosztów konta OPK SOR
 Rejestracja na konto OPK SOR pacjentów w ramach ryczałtu dobowego oraz
 konta OPK pacjentów przyjętych na inne oddziały.
</p>
<p>
 Dwie pierwsze kolumny to rok i miesiąc. Trzecia kolumna to numer OPK SOR
 Rejestracja. Czwarta to numer konta OPK SOR z grupy 503 lub numer konta OPK
 oddziału. Ostatnia kolumna to liczba pacjentów.
</p>
<p>
 W arkuszu znajdują się dwie dodatkowe kolumny, do wypełnienia przez te
 podmioty, które planują dodać dane przychodowe i mieć możliwość analizy
 rentowności pacjenta.
</p>
<p>
 Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<br/>
<p>
 <img
         style={{maxWidth: '700px'}}
     src="/home/images/wzorxls/7 sor rejestracja.png"
 />
</p>
<br/>
<p>
 <img
     width="269"
     height="58"
     src="/home/images/wzorxls/kg i ko pacjenta.png"
 />
</p>
<p>
 Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>



<p align="center">
     <ReactPlayer
       width={!matchesWidth ? "300px" : "600px"}
       height={!matchesWidth ? "170px" : "340px"}
       light="/video/7WZORRejSOR.png"
       controls
       playsinline
       playing
       config={{ file: { attributes: { controlsList: "nodownload" } } }}
       onContextMenu={(e) => e.preventDefault()}
       url="/video/7. WZOR- Rej SOR.mp4"
     />
   </p>


      <div className="stage">11. Pacjent - leki i materiały (arkusz dodatkowy)</div>
  

      <p>
    Uzupełniony danymi arkusz zawiera kwoty leków i materiałów przypisanych
    bezpośrednio do pacjentów.
</p>
<p>
    Dwie pierwsze kolumny to rok i miesiąc. Trzecia to numer konta OPK
    pacjenta. Czwarta to numer konta rodzajowego, na którym ewidencjonowane są
    leki, materiały, wyroby medyczne przypisane do pacjenta. Ostatnia kolumna
    to kwota kosztu przypisana do pacjenta.
</p>
<p>
    W arkuszu znajdują się dwie dodatkowe kolumny, do wypełnienia przez te
    podmioty, które planują dodać dane przychodowe i mieć możliwość analizy
    rentowności pacjenta.
</p>
<p>
    Poniższa tabela zawiera przykład uzupełnienia arkusza.
</p>
<br/>
<p>
    <img
    style={{maxWidth: '700px'}}
        src="/home/images/wzorxls/10 leki.png"
    />
</p>
<br/>
<p>
    <img
        width="269"
        height="58"
        src="/home/images/wzorxls/kg i ko pacjenta.png"
    />
</p>
<p>
    <strong>Uwaga</strong>
    :
</p>
<ul>
<li>
    W arkuszu każde konto 4 wpisane musi zostać w osobnym wierszu.
</li>
</ul>

<p>
    Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz.
</p>

<p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/10WZOR_leki.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/10. WZOR - leki.mp4"
        />
      </p>











      <div id="slownik" className="stage">
        Baza wiedzy
      </div>
  

    

    
 <br/>
   
  
    
      <br />
     
      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
           
            <a
              href="/articles"
              target="_blank"
            >
              <strong>Publikacje dotyczące standardu rachunku kosztów</strong>
            </a><br/>
          
                    
          </div>
        </div>
      </div>



            <br />
   

 

     
      </div>
    //</Suspense>
  );
}
