import React from "react";
import MTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import {
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const RankingChart = (props) => {

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      maxWidth: 600,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "100",
      //border: '1px solid #FE8E3C',
      boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)"
    },
  }))(MTooltip);

  return (
    <Paper
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        overflow: "hidden",
        padding: "0px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        border: "0px dotted orange",
        minWidth: "220px",
        minHeight: "320px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >

      <HtmlTooltip arrow placement="bottom-start"
        title={
          <React.Fragment >
            <Typography >{props.tipTitle}</Typography>
            {props.tipBody}
          </React.Fragment>
        }
      >
        <Typography
          style={{
            color: "#0b6471",
            paddingLeft: "5px",
            fontSize: "16px",
            textDecoration: "none",
            fontWeight: "100",
            paddingBottom: "5px",
          }}
        >
          {props.title}
        </Typography>
      </HtmlTooltip>

      <ResponsiveContainer>
        {<BarChart
          style={{ fontFamily: "Roboto", fontSize: "12px" }}
          layout="vertical"
          //width={190}
          //height={310}
          data={props.data}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis hide={true} type="number" />
          <YAxis
            hide={true}
            dataKey="opk_name"
            stroke=""
            type="category"
            width={0}
            /*ticks={data.map((item, index, all) => {
          return index < 3 || index > all.length - 4 ? item.YARD : "";
        })}*/
            tick={false}
          />
          <Tooltip formatter={(value) => ''} />
          <Bar
            name="Wynik"
            dataKey="profit"
            barSize={300 / (1.5 * props.data.length)}
            fill={props.color}
          >
            {props.data.map((entry, index) => (
              <Cell
                cursor="pointer"
                stroke={entry.profit < 0 ? "#FE8E3C" : props.color}
                fill={
                  entry.opk_name == props.opk_name
                    ? "white"
                    : entry.profit < 0
                      ? "#FE8E3C"
                      : props.color
                }
              />
            ))}
          </Bar>
        </BarChart>}
      </ResponsiveContainer>
    </Paper>
  )
};

export default RankingChart;