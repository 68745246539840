import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { apiurl } from '../app/prefs.js';

const yardsList = [
  { yardName: "Blok operacyjny", yardID: 1 },
  { yardName: "Pracownia tomografii komputerowej", yardID: '5077220' },
  { yardName: "Oddział anestezjologii", yardID: 3 },
];

const procedureActivities = [
  { id: 1, caption: "Rejestracja" },
  { id: 2, caption: "Przygotowanie" },
  { id: 3, caption: "Wykonanie" },
  { id: 4, caption: "Czynności po wykonaniu" },
  { id: 5, caption: "Inne" }
]

const procheaders = [
  {
    name: "Nr procedury (nr ICD-9)",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Numer konta OPK Proceduralny",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa konta OPK Proceduralny",
    type: "text",
    edit: "false",
    format: "",
  },
];

const hrheadersFull = [
  {
    name: "Id grupy",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Grupa zaszeregowania",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nr OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Wykonywana czynność",
    type: "combobox",
    edit: "true",
    disabledon: { field: 'Jednostka miary', value: 'procedura' },
  },
  {
    name: "Czas wykonania procedury (minuty)",
    type: "number",
    edit: "true",
    format: "",
    disabledon: { field: 'Jednostka miary', value: 'procedura' }
  },
  {
    name: "Liczba pracowników",
    type: "number",
    edit: "true",
    format: ""
  },
  {
    name: "Koszt jednostkowy",
    type: "number",
    edit: "false",
    format: "currency",
  },
  {
    name: "Koszt całkowity",
    type: "number",
    edit: "false",
    format: "currency"
  }
];

const hrheaders = [
  {
    name: "Id grupy",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Grupa zaszeregowania",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nr OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Wykonywana czynność",
    type: "combobox",
    edit: "true",
    disabledon: { field: 'Jednostka miary', value: 'procedura' },
  },
  {
    name: "Czas wykonania procedury (minuty)",
    type: "number",
    edit: "true",
    format: "",
    disabledon: { field: 'Jednostka miary', value: 'procedura' }
  },
  {
    name: "Liczba pracowników",
    type: "number",
    edit: "true",
    format: ""
  }
];

const matheaders = [
  {
    name: "Kod",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Typ",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Pojemność / rozmiar",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Ilość zużyta na 1 procedurę",
    type: "number",
    edit: "true",
  },
  {
    name: "Uwzględniaj przy rozliczaniu OPK",
    type: "checkbox",
    edit: "true",
  },
  {
    name: "Koszt jednostkowy",
    type: "number",
    edit: "false",
    format: "currency",
  },
  {
    name: "Koszt całkowity",
    type: "number",
    edit: "false",
    format: "currency"
  }
];

const ProceduresList = (params, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  if (params) {
    //getAccessTokenSilently().then((token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/procedureslist`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //get list of wards from procedures
          let templist = [];
          data.procedures.map(
            procedure => {
              if (!templist.some(item => item.wardID === procedure['Numer konta OPK Proceduralny']))
                templist.push({ wardID: procedure['Numer konta OPK Proceduralny'], wardName: procedure['Nazwa konta OPK Proceduralny'] })
            }
          );
          params(
            {
              procedures: data.procedures.sort((a, b) => a['Nazwa procedury'].localeCompare(b['Nazwa procedury'])),
              wardsList: templist.sort((a, b) => a.wardName.localeCompare(b.wardName))
            });
          //console.log(templist)
          //console.log(data);
        });
    }
  };
};

const Hrres = (params, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  if (params) {
    //getAccessTokenSilently().then((token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      fetch(`${apiurl}/hrlist`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          params(data.hr);
          //console.log(data);
        });
    }
  };
};

const Materials = (params, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  if (params) {
    //getAccessTokenSilently().then((token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      fetch(`${apiurl}/materialslist`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          params(data.materials);
          //console.log(data);
        });
    }
  }
};

const procedureinfo = {
  length: "2020-02-02 00:00",
  date: "2020-10-15",
  described_by: "-",
  consulted_with: "-",
};

export {
  Hrres,
  hrheaders,
  hrheadersFull,
  Materials,
  matheaders,
  yardsList,
  ProceduresList,
  procheaders,
  procedureinfo,
  procedureActivities,
};
