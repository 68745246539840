import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { ImCalculator } from "react-icons/im";
import { GiHealthPotion } from "react-icons/gi";
import { RiHealthBookFill } from "react-icons/ri";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import "animate.css";
import "./articles.css";
import { apiurl } from '../app/prefs.js';

const CatMenuData = [
  {
    CAT: "Rachunek kosztów",
    DESC: "Controlling kosztów i przychodów świadczeniodawcy",
  },
  {
    CAT: "Rozporządzenie",
    DESC:
      "Rozporządzenie MZ dotyczące rachunku kosztów",
  },
  {
    CAT: "Wycena procedur medycznych",
    DESC:
      "Metody kalkulacji kosztów procedur",
  },
];

const Cat2ICO = (props) => {
  let icon = <RiHealthBookFill style={{ width: "36px", height: "36px" }} />;
  switch (props.cat) {
    case "Rachunek kosztów":
      icon = <ImCalculator style={{ width: "32px", height: "32px" }} />;
      break;
    case "Rozporządzenie":
      icon = (
        <div
          style={{
            marginTop: "-10px",
            paddingLeft: "7px",
            width: "28px",
            height: "28px",
            fontSize: "28px",
            fontFamily: "roboto",
            fontWeight: "bold",
          }}
        >
          §
        </div>
      );
      break;
    case "Wycena procedur medycznych":
      icon = <GiHealthPotion style={{ width: "36px", height: "36px" }} />;
      break;
  }
  return icon;
};

let articles = [];
let categories = [];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",

    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "300px",
    //minHeight: "700px",
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
  gridListTile: {
    position: "relative",
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#d7e5e7",
    backgroundColor: "#95B0B6",
    color: "white",
    width: "200px",
    maxWidth: "200px",
    height: "300px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  pageSubtitle: {
    display: "flex",
    justifyContent: "flexStart",
    height: "25px",
    marginBottom: "20px",
    marginTop: "-10px",
    width: "100%",
    backgroundColor: "white",
    color: "#9099A2",
    borderRadius: "5px",
    paddingTop: "0px",
    paddingLeft: "0px",
    alignItems: "center",
    flexShrink: 1,

  },
}));

export default function Articles() {
  const classes = useStyles();
  const history = useHistory();

  const [appState, setAppState] = React.useState({
    loading: false,
  });

  const [filter, setFilter] = React.useState("default");

  let baza =
    filter === "default"
      ? articles.sort((a, b) => a.KOLEJNOSC - b.KOLEJNOSC).slice(0, 3)
      : articles.sort((a, b) => a.KOLEJNOSC - b.KOLEJNOSC).filter((element) => element.CAT === filter).slice(0, 3);

  baza.push({ "ID": -1, "CAT": "", "TITLE": "Baza wiedzy", "DESC": "...", "REF2": "/" })

  React.useEffect(() => {
    setAppState({ loading: true });
    let apiUrl = `${apiurl}/categories`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((fetchedData) => {
        categories = fetchedData;
        let apiUrl = `${apiurl}/articles`;
        fetch(apiUrl)
          .then((res) => res.json())
          .then((fetchedData) => {
            articles = fetchedData;
            setAppState({ loading: false });
          });
      });
  }, []);

  const MenuBox = (props) => {
    return (
      <div
        style={{
          height: "90px",
          width: "300px",
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          color: "black",
        }}
      >
        <div
          style={{
            color: "#FE8E3C",
            margin: "10px",
            maxWidth: "30px",
            minWidth: "30px",
          }}
        >
          <Cat2ICO cat={props.title} />
        </div>
        <div
          style={{ display: "block" }}
          onClick={() => {
            props.title && history.push('/articles/' + props.title)
          }}
        >
          <Link
            variant="body1"
            color="primary"
            style={{ fontWeight: "bold", cursor: "pointer" }}
          >
            {props.title}
          </Link>
          <Typography variant="body2" style={{ fontWeight: "lighter" }}>
            {props.desc}
          </Typography>
        </div>
      </div>
    );
  };

  return !appState.loading ? (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "left",
          //height: "100px",
          //maxHeight: "150px",
          width: "100%",
          color: "white",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
          overflow: "auto",
          flexShrink: 1,
        }}
      >
        {CatMenuData.map((category) => (
          <MenuBox
            key={category.DESC}
            title={category.CAT}
            desc={category.DESC}
            icon={category.ICON}
          />
        ))}
      </div>

      {false && <div>
        <div className={classes.pageSubtitle}>
          <Typography variant="h6">
            {filter === "default" ? "Najnowsze publikacje" : filter}
          </Typography>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "", flexShrink: 1, overflow: "hidden" }}>
          {baza.map((tile) => (
            <Paper
              key={tile.ID}
              id="article"
              className={
                classes.gridListTile + " " + "animate__animated animate__fadeIn"
              }
              onClick={() => {
                tile.REF2 && history.push('/articles' + tile.REF2)
              }}
            >
              <div
                style={{
                  borderTop: "0px",
                  backgroundColor: "none",
                  color: "#FE8E3C",
                }}
              >
                <Cat2ICO cat={tile.CAT} />{" "}
              </div>
              <Typography
                variant="subtitle1"
                style={{ lineHeight: "1.5", marginTop: "20px" }}
              >
                {tile.TITLE}
              </Typography>

              {!tile.REF2 && (
                <div
                  className={classes.gridListTile + " " + "readAvatar"}
                  style={{
                    position: "absolute",
                    left: "-10px",
                    top: "-10px",
                    backgroundColor: "rgba(200,200,200,0.8",
                  }}
                ></div>
              )}
              {!tile.REF2 && (
                <Avatar
                  className="readAvatar"
                  style={{ position: "absolute", right: "10px", bottom: "10px" }}
                >
                  <EnhancedEncryptionIcon />
                </Avatar>
              )}
            </Paper>
          ))}
        </div>
      </div>}
    </div>
  ) : (
    <div style={{ position: "relative" }}>
      <div
        style={{
          postition: "absolute",
          transform: "translate(50%, 250%)",
          color: "darkgray",
        }}
      >
        Wczytywanie...
      </div>
    </div>
  );
}
