import React from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./style.css";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <tr onClick={() => setOpen(!open)} className="Topic">
        <td style={{ width: "30px" }}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </td>
        <td>{row.topic}</td>
      </tr>
      <tr>
        <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ marginLeft: "40px" }}>{row.info}</Box>
          </Collapse>
        </td>
      </tr>
    </React.Fragment>
  );
}

const rows = [
  {
    topic: "Procedury chirurgiczne (leczenie zabiegowe)",
    info: (
      <div className="Hintlist">
        <p className="Header">
          <span className="material-symbols-outlined icon">vaccines</span>
          <span>Zasoby materiałowe (jednorazowe)</span>
        </p>
        <p className='HintSummary'>
          Zasoby materiałowe podaje się na podstawie typowego zużycia materiałów, leków, środków spożywczych
          specjalnego przeznaczenia żywieniowego oraz wyrobów medycznych bezpośrednio zużywanych do wykonania procedury medycznej.
        </p>
        <ul>
          <li>
            Dezynfekcja personelu, pacjenta, powierzchni (płyny, mydło,
            szczotka)
          </li>
          <li>„Ubiór” personelu medycznego: rękawice, maski, fartuchy, …</li>
          <li>
            Obłożenie: podkłady, serwety operacyjne, ręczniki, prześcieradło, …
          </li>
          <li>
            Lek do znieczulenia miejscowego (w przypadku procedur zabiegowych w
            poradniach)
          </li>
          <li>Igły, strzykawki</li>
          <li>Nici, ostrza</li>
          <li>Opatrunek, kompres, plaster, opaska</li>
          <li>Inne materiały jednorazowe zużywane w trakcie zabiegu</li>
          <li>
            NIE uwzględniaj kosztu materiałów wszczepialnych (Koszty wyrobów medycznych wszczepialnych m.in. endoprotezy,
            implanty, zastawki serca, stenty, protezy naczyniowe, defibrylatory, soczewki wewnątrzgałkowe itp.
            należy przypisywać indywidualnie do pacjenta i do oddziału, czyli tam gdzie zaewidencjonowany
            jest przychód za tego pacjenta. Dlatego też ww materiały nie stanowią kosztu bezpośredniego procedury medycznej)
          </li>
        </ul>
        <p className="Header">
          <span className="material-symbols-outlined icon">group</span>
          <span>Zasoby osobowe</span>
        </p>
        <p>
          Przedstawiciele poszczególnych grupy pracowniczych biorących udział w
          wykonywaniu procedury np. lekarz, pielęgniarka, technik,…
        </p>
      </div>
    )
  },
  {
    topic: "Procedury diagnostyczne (diagnostyka laboratoryjna i obrazowa)",
    info: (
      <div className="Hintlist">
        <p className="Header">
          <span className="material-symbols-outlined icon">vaccines</span>
          <span>Zasoby materiałowe (jednorazowe)</span>
        </p>
        <p className='HintSummary'>
        Zasoby materiałowe podaje się na podstawie typowego zużycia materiałów, leków, środków spożywczych 
        specjalnego przeznaczenia żywieniowego oraz wyrobów medycznych bezpośrednio zużywanych do wykonania procedury medycznej.
        </p>
        <ul>
          <li>
            Dezynfekcja personelu, pacjenta, powierzchni (płyny, mydło,
            szczotka)
          </li>
          <li>„Ubiór” personelu medycznego: rękawice, maski, fartuchy, …</li>
          <li>
            Obłożenie: podkłady, serwety operacyjne, ręczniki, prześcieradło, …
          </li>
          <li>Igły, strzykawki, zestaw do wstrzykiwacza kontrastu</li>
          <li>Opatrunek, kompres, plaster, opaska</li>
          <li>Inne materiały jednorazowe zużywane w trakcie zabiegu </li>

        </ul>
        <p className="Header">
          <span className="material-symbols-outlined icon">group</span>
          <span>Zasoby osobowe</span>
        </p>
        <p>
          Przedstawiciele poszczególnych grupy pracowniczych biorących udział w
          wykonywaniu procedury np. lekarz, pielęgniarka, technik,…
        </p>
      </div>
    )
  },
  {
    topic: "Procedury anestezjologiczne",
    info: (
      <div className="Hintlist">
        <p className="Header">
          <span className="material-symbols-outlined icon">vaccines</span>
          <span>Zasoby materiałowe (jednorazowe)</span>
        </p>
        <p className='HintSummary'>
        Zasoby materiałowe podaje się na podstawie typowego zużycia materiałów, leków, 
        środków spożywczych specjalnego przeznaczenia żywieniowego oraz wyrobów 
        medycznych bezpośrednio zużywanych do wykonania procedury medycznej.
        </p>
        <ul>
          <li>
            Dezynfekcja personelu, pacjenta, powierzchni (płyny, mydło,
            szczotka)
          </li>
          <li>„Ubiór” personelu medycznego: rękawice, maski, fartuchy, …</li>
          <li>Cewniki, kaniule, rurki, wkłady do ssaka</li>
          <li>
            Łyżka do laryngoskopu jednorazowa, maska anestetyczna, obwód
            oddechowy, aparat do przetoczeń
          </li>
          <li>Elektrody do EKG</li>
          <li>Roztwór do infuzji</li>
          <li>Igły, strzykawki</li>
          <li>Opatrunek, kompres, plaster, opaska</li>
          <li>Inne materiały jednorazowe zużywane w trakcie zabiegu </li>

        </ul>
        <p className="Header">
          <span className="material-symbols-outlined icon">group</span>
          <span>Zasoby osobowe</span>
        </p>
        <p>
          Przedstawiciele poszczególnych grupy pracowniczych biorących udział w
          wykonywaniu procedury np. lekarz, pielęgniarka, technik,…
        </p>
      </div>
    )
  },
  {
    topic:
      "Procedury terapeutyczne (radioterapia, fizykoterapia, rehabilitacja, ...)",
    info: (
      <div className="Hintlist">
        <p className="Header">
          <span className="material-symbols-outlined icon">vaccines</span>
          <span>Zasoby materiałowe (jednorazowe)</span>
        </p>
        <p className='HintSummary'>
        Zasoby materiałowe podaje się na podstawie typowego zużycia materiałów, leków, 
        środków spożywczych specjalnego przeznaczenia żywieniowego oraz 
        wyrobów medycznych bezpośrednio zużywanych do wykonania procedury medycznej.
        </p>
        <ul>
          <li>
            Dezynfekcja personelu, pacjenta, powierzchni (płyny, mydło,
            szczotka)
          </li>
          <li>„Ubiór” personelu medycznego: rękawice, maski, fartuchy, …</li>
          <li>
            Obłożenie: podkłady, serwety operacyjne, ręczniki, prześcieradło, …
          </li>
          <li>Oliwka, talk</li>
          <li>Igły, strzykawki</li>
          <li>Opatrunek, kompres, plaster, opaska</li>
          <li>Inne materiały jednorazowe zużywane w trakcie zabiegu</li>
        </ul>
        <p className="Header">
          <span className="material-symbols-outlined icon">group</span>
          <span>Zasoby osobowe</span>
        </p>
        <p>
          Przedstawiciele poszczególnych grupy pracowniczych biorących udział w
          wykonywaniu procedury np. lekarz, pielęgniarka, technik,…
        </p>
      </div>
    )
  }
];

export default function TipsTable() {
  return (
    <div style={{ marginLeft: '10px', marginTop: '10px' }}>
      <h3>Wskazówki</h3>
      <p>
        W Bibliotece nie ma opisu szukanej procedury? Sprawdź, o czym należy
        pamiętać opisując:
      </p>
      <table>
        <tbody>
          {rows.map((row) => (
            <Row key={row.topic} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
}