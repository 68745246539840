import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { RiHealthBookFill } from "react-icons/ri";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import CheckIcon from "@material-ui/icons/Check";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import EditTable from "../procedures/edittable";
import { GiHealthPotion } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { FaSyringe } from "react-icons/fa";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Tooltip } from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";

import plLocale from "date-fns/locale/pl";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';

import "animate.css";
import "./style.css";

import {
  Hrres,
  Materials,
  matheaders,
  hrheaders,
  hrheadersFull,
  procedureinfo,
  ProceduresList,
  procheaders,
} from "./data";

import { apiurl } from '../app/prefs.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

function StatusTreeView(props) {
  const renderTree = (nodes) =>
    Array.isArray(nodes) ? (
      nodes.map((node) => (
        <TreeItem
          key={node.id}
          nodeId={node.id}
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                lineHeight: "30px",
              }}
            >
              {node.status === "ok" ? (
                <CheckIcon style={{ fontSize: "22px", marginRight: "10px", color: "#0b6471" }} />
              ) : (node.status === 'warn' ?
                <NotListedLocationIcon
                  style={{ fontSize: "22px", marginRight: "10px", color: "#EAC424" }}
                /> :
                <PriorityHighIcon
                  style={{ fontSize: "22px", marginRight: "10px", color: "#FE8E3C" }}
                />
              )}
              {node.name}
            </div>
          }
        >
          {Array.isArray(node.children)
            ? node.children.map((subnode) => renderTree(subnode))
            : null}
        </TreeItem>
      ))
    ) : (
      <TreeItem
        style={{
          color: "rgb(100,100,100)",
          marginLeft: "15px",
        }}
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.name}
      />
    );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={["m1", "m2", "m3"]}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(props.data)}
    </TreeView>
  );
}

const tutorData = [
  {
    id: 0,
    name: 'Wprowadzenie',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/00. Jak zaimportować.jpg', video: '/manual/procedures/00. Jak zaimportować.mp4' },
      { id: 1, title: '', image: '/manual/procedures/0. Procedury Import danych - wstęp.jpg', video: '/manual/procedures/0. Procedury Import danych - wstęp.mp4' }
    ]
  },
  {
    id: 1,
    name: 'Arkusz 1 - Procedury',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/1. Arkusz Procedury.jpg', video: '/manual/procedures/1. Arkusz Procedury.mp4' },
    ]
  },
  {
    id: 2,
    name: 'Arkusz 2 - Materiały',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/2. Arkusz Materiały.jpg', video: '/manual/procedures/2. Arkusz Materiały.mp4' },
      { id: 1, title: '', image: '/manual/procedures/5. Zmiana kosztu materiału.jpg', video: '/manual/procedures/5. Zmiana kosztu materiału.mp4' }
    ]
  },
  {
    id: 3,
    name: 'Arkusz 3 - Personel',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/3. Arkusz Personel.jpg', video: '/manual/procedures/3. Arkusz Personel.mp4' },
      { id: 1, title: '', image: '/manual/procedures/4. Zmiana stawki wynagrodzenia.jpg', video: '/manual/procedures/4. Zmiana stawki wynagrodzenia.mp4' }
    ]
  }
];


const TutorMenu = (props) => {

  const [selectedTopic, setSelectedTopic] = React.useState(0);
  const { content } = props;

  const TutorContent = (props) => {
    const { content } = props;
    const stopOtherVideos = (currentVideoId) => {
      tutorData[selectedTopic].videos.forEach(
        (_, id) => {
          if (currentVideoId !== id) {
            const div = document.getElementById('videoControl_' + id);
            if (div !== undefined) {
              div.pause();
              //div.currentTime = 0;
            }
          }
        }
      )
    };
    return (
      <div className='tutorial_contentPanel'>
        {content.map
          ((video, id) => (
            <div key={id} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 0px 3px 0px', width: '60vw' }}>
                <button
                  onClick={() => {
                    const div = document.getElementById('videoControl_' + id);
                    if (div.requestFullscreen)
                      div.requestFullscreen();
                    else if (div.webkitRequestFullscreen)
                      div.webkitRequestFullscreen();
                    else if (div.msRequestFullScreen)
                      div.msRequestFullScreen();
                  }
                  }
                  style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
                >
                  Pełen ekran
                </button>
              </div>
              <video id={'videoControl_' + id} controls poster={video.image} style={{ width: '60vw' }} onPlay={() => stopOtherVideos(id)} >
                <source src={video.video} type="video/mp4" />
                Video control not supported by browser
              </video>
            </div>
          ))}
      </div>
    )
  };

  const Row = (props) => {
    const { data } = props;
    return (
      <div
        className={
          selectedTopic === data.id ? "tutorial_header_selected" : "tutorial_header"
        }
        onClick={() => setSelectedTopic(data.id)}
      >
        {data.name}
      </div>
    );
  };

  return (
    <div className="tutorial">
      <div className="tutorial_tocPanel">
        <div className="tutorial_toc_header">Spis treści</div>
        {content.map((row, id) => (
          <Row key={id} data={row} />
        ))}
      </div>
      <TutorContent content={tutorData[selectedTopic].videos} />
    </div>
  );
};

export default function ImportDictionaries() {
  const mounted = React.useRef(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [procedures, setProcedures] = React.useState([]);
  const [hrList, setHRList] = React.useState([]);
  const [materialsList, setMaterialsList] = React.useState([]);
  const [importStatus, setImportStatus] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [userData, setUserData] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const fetch_retry = (url, options, n) => fetch(url, options).catch(function (error) {
    if (n === 1) throw error;
    return fetch_retry(url, options, n - 1);
  });

  const getUserData = (token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch_retry(`${apiurl}/getuserdata`, requestOptions, 10)
        .then((response) => response.json(), reason => console.log(reason))
        .then((data) => {
          setUserData(data);
        }, reason => console.log(reason))
        .catch(error => { });
    }
  };

  const setAppData = (setting, value) => {
    getAccessTokenSilently().then((token) => {

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          setting: setting,
          value: value
        })
      };
      fetch(`${apiurl}/setappdata`, requestOptions, 10)
        .then((response) => { getUserData(token) }, reason => console.log(reason))
        .catch(error => { console.log(error) });

    })
  };

  const getData = () => {
    getAccessTokenSilently().then((token) => {
      ProceduresList(setProcedures, token);
      Hrres(setHRList, token);
      Materials(setMaterialsList, token);
      getUserData(token);
    }, reason => alert(reason));
  }

  React.useEffect(() => {
    getData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const ImportFile = (props) => {
    const [progress, setProgress] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleFileImport = (file) => {
      getAccessTokenSilently().then((token) => {
        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setProgress((event.loaded / event.total) * 100);
          }
        });

        req.onload = () => {
          setImportStatus(JSON.parse(req.response));
          getData();
          setIsLoading(false);
        };

        req.upload.addEventListener("error", (event) => {
          alert('Wystąpił błąd podczas przesyłania pliku, proszę spróbować ponownie. Jeżeli problem będzie się powtarzał prosimy o kontakt z serwisem COplus.')
          setIsLoading(false);
        });

        const formData = new FormData();

        formData.append("file", file, file.name);
        formData.append("option", JSON.stringify({ dict: props.dict, dictdate: props.dictdate }));

        req.open(
          "POST",
          `${apiurl}/procedureupload?` + new Date().getTime()
        );
        req.setRequestHeader("Authorization", "Bearer " + token);
        req.send(formData);
        setIsLoading(true);
        setImportStatus([]);
      });
    };

    function CircularProgressWithLabel(props) {
      return (
        <Box position="relative" display="inline-flex">
          <CircularProgress
            color={props.value === 100 ? "primary" : "secondary"}
            variant="static"
            {...props}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Paper
        style={{
          width: "450px",
          height: "75px",
          backgroundColor: "rgb(250,250,250)",
          borderRadius: "5px",
          display: "flex",
          flex: "auto",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "auto",
            alignItems: "center",
          }}
        >
          <input
            disabled={isLoading || props.disabled}
            style={{ display: "none" }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            className={classes.input}
            id={"contained-button-file" + props.title}
            type="file"
            onChange={(event) => {
              handleFileImport(event.currentTarget.files[0]);
              event.currentTarget.files = null;
            }}
          />
          <label htmlFor={"contained-button-file" + props.title}>
            <Button
              disabled={isLoading || props.disabled}
              startIcon={props.icon}
              variant="contained"
              color="primary"
              component="span"
              style={{
                marginRight: "20px",
                width: "350px",
                justifyContent: "left",
              }}
            >
              {props.title}
            </Button>
          </label>
          <CircularProgressWithLabel value={progress} />
        </div>
        {false && <Link disabled={isLoading || props.disabled} href="" variant="body2">
          {"Pobierz plik wzorcowy"}
        </Link>}
      </Paper>
    );
  };

  const DatePicker = () => {
    return (
      <Paper
        style={{
          width: "450px",
          height: "75px",
          //backgroundColor: "#edf4f6",
          border: "0px solid",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "flex",
          flex: "auto",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "10px",
          paddingTop: "5px",
          marginTop: "10px",
          boxShadow: "none"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "auto",
            alignItems: "flex-start",
          }}
        >
          <Typography color={"primary"} style={{ fontWeight: '800' }}>
            Data obowiązywania słownika:
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
              style={{
                marginRight: "20px",
                width: "350px",
                justifyContent: "left",
              }}
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              views={["date"]}
              margin="dense"
              id="date-picker-inline"
              placeholder='Wybierz datę'
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Paper>
    );
  };

  return (
    (materialsList && hrList && procedures && userData) && <div style={{ minHeight: "calc(100vh - 145px)" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <RiHealthBookFill
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            IMPORT SŁOWNIKÓW
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <Paper
        className={classes.root}
        style={{ display: "flex", flexDirection: "column", width: "100%", minHeight: 'calc(100vh - 150px)' }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{ width: "100%" }}
            aria-label="full width tabs"
          >
            <Tab
              key="1"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Import danych
                  </div>
                </div>
              }
            />
            <Tab
              key="2"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Słownik zasobów osobowych
                  </div>
                </div>
              }
            />
            <Tab
              key="3"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Słownik zasobów materiałowych
                  </div>
                </div>
              }
            />
            <Tab
              key="4"
              disabled={procedures === [] ? true : false}
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: procedures !== [] ? "#4E97A5" : "gray",
                    }}
                  >
                    Słownik procedur
                  </div>
                </div>
              }
            />
            <Tab
              key="5"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#FE8E3C",
                      width: '130px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <SchoolIcon />
                    Instrukcje
                  </div>
                </div>
              }
            />

          </Tabs>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel key="1" value={value} index={0} dir={theme.direction}>
              <div style={{ padding: "10px" }}>

                <DatePicker />

                <ImportFile
                  disabled={!selectedDate || (user && user["https://coplus.pl/md"].permissions.preview === 'yes')}
                  dict="Materiały"
                  dictdate={selectedDate}
                  title="Import słownika zasobów materiałowych"
                  icon={<FaSyringe />}
                />
                <ImportFile
                  disabled={!selectedDate || (user && user["https://coplus.pl/md"].permissions.preview === 'yes')}
                  dict="Personel"
                  dictdate={selectedDate}
                  title="Import słownika zasobów osobowych"
                  icon={<HiUserGroup />}
                />
                <ImportFile
                  disabled={user && user["https://coplus.pl/md"].permissions.preview === 'yes'}
                  dict="Procedury"
                  dictdate={selectedDate}
                  title="Import słownika procedur"
                  icon={<GiHealthPotion />}
                />
              </div>
              <div style={{ padding: "10px" }}>
                <Tooltip placement="right"
                  title="Włączenie opcji powoduje ukrycie informacji o stawkach wynagrodzeń pracowników w module opisu procedur">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={userData && userData.app.showHRCosts === 'false'}
                        onChange={() => setAppData('showHRCosts', (userData && userData.app.showHRCosts === 'false'))}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Ukryj stawki wynagrodzeń pracowników"
                  />
                </Tooltip>
              </div>
              <Paper
                className={classes.root}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  //minHeight: "200px",
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "#0b6471", marginBottom: "5px" }}
                >
                  {" "}
                  Status importu{" "}
                </Typography>
                <StatusTreeView data={importStatus} />
              </Paper>
            </TabPanel>
            <TabPanel key="2" value={value} index={1} dir={theme.direction}>
              <Paper style={{ padding: "10px" }}>
                {hrList && <EditTable
                  filterColumn="Grupa zaszeregowania"
                  callback={() => null}
                  resources={hrList}
                  headers={(userData && userData.app.showHRCosts === 'true') ? hrheadersFull : hrheaders}
                  assignments={[]}
                  returnData={() => null}
                  datakey="Indeks materiału"
                  dictkey="Lp"
                  formula2={`0`}
                  mode="preview"
                />}
              </Paper>
            </TabPanel>
            <TabPanel key="3" value={value} index={2} dir={theme.direction}>
              <Paper style={{ padding: "10px" }}>
                {materialsList && <EditTable
                  filterColumn="Nazwa"
                  callback={() => null}
                  resources={materialsList}
                  headers={matheaders}
                  assignments={[]}
                  returnData={() => null}
                  datakey="Indeks materiału"
                  dictkey="Indeks"
                  formula2={`0`}
                  mode="preview"
                />}
              </Paper>
            </TabPanel>
            <TabPanel key="4" value={value} index={3} dir={theme.direction}>
              <Paper style={{ padding: "10px" }}>
                {procedures && <EditTable
                  filterColumn="Nazwa procedury"
                  callback={() => null}
                  resources={procedures}
                  headers={procheaders}
                  assignments={[]}
                  returnData={() => null}
                  datakey="Indeks materiału"
                  dictkey="Indeks"
                  formula2={`0`}
                  mode="preview"
                />}
              </Paper>
            </TabPanel>
            <TabPanel key="5" value={value} index={4} dir={theme.direction}>
              <Paper style={{ padding: "0px" }}>
                <TutorMenu content={tutorData} />
              </Paper>
            </TabPanel>
          </SwipeableViews>
        </div>
      </Paper>
    </div>
  );
}
