import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactDOM from "react-dom";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./app/Main.js";
import { authConfig } from "./app/prefs";

import * as serviceWorker from "./serviceWorker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// pick a date util library
import MomentUtils from "@date-io/moment";
import "moment/locale/pl";
import { BrowserRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

require('es6-promise').polyfill()
require('isomorphic-fetch')

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();
  const domain = authConfig.domain;
  const clientId = authConfig.clientId;
  const audience = authConfig.audience;

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope="read:current_user "
    >
      {children}
    </Auth0Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline>
            <App />
          </CssBaseline>
        </MuiPickersUtilsProvider>

      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();