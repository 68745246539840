import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import CostDetailsTable from "./CostDetailsTable";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MonthSelect from "./MonthSelection";
import ToolTip from "@material-ui/core/Tooltip";


import { apiurl } from '../app/prefs.js';


export default function Report024() {
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataLoaded, setTotalDataLoaded] = React.useState(false);
  const [totalDataComplete, setTotalDataComplete] = React.useState(false);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);
  const [opkList, setOpkList] = React.useState([]);
  const [groupsList, setGroupsList] = React.useState([]);
  const [contractsList, setContractsList] = React.useState([]);
  const [selectedOPK, setSelectedOPK] = React.useState({ opk_id: 'ALL', opk_name: 'Wszystkie' });
  const [selectedGroup, setSelectedGroup] = React.useState({ hr_group_id: 'ALL', hr_group_name: 'Wszystkie' });
  const [selectedContract, setSelectedContract] = React.useState({ contract_id: 'ALL', contract_name: 'Wszystkie' });
  const [selectedAccounts, setSelectedAccounts] = React.useState('ALL');


  const accountsList = [{ id: 'ALL', name: 'Wszystkie' }, { id: 'SELECTED', name: 'Bez 404-01-13 i 404-01-14' }];


  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={dateList}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value.year.toLocaleString();
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            loadData(selectedOPK, selectedGroup, selectedAccounts, selectedContract.contract_id, value.year, selectedMonths);
          }
        }}
      />
    );
  }

  function OpkBox() {
    return (
      <Autocomplete
        id="opk-combo-box"
        options={opkList}
        value={selectedOPK}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "380px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="OPK" />}
        onChange={(event, value) => {
          if (value) {
            setSelectedOPK(value);
            loadData(value.opk_id, selectedGroup.hr_group_id, selectedAccounts, selectedContract.contract_id, startDate.year, selectedMonths);
          }
        }}
      />
    );
  }

  function GroupBox() {
    return (
      <Autocomplete
        id="group-combo-box"
        options={groupsList}
        value={selectedGroup}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.hr_group_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.hr_group_name === value.hr_group_name;
        }}
        style={{ width: "280px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Grupa pracownicza" />}
        onChange={(event, value) => {
          if (value) {
            setSelectedGroup(value);
            loadData(selectedOPK.opk_id, value.hr_group_id, selectedAccounts, selectedContract.contract_id, startDate.year, selectedMonths);
          }
        }}
      />
    );
  }

  function ContractBox() {
    return (
      <Autocomplete
        id="contract-combo-box"
        options={contractsList}
        value={selectedContract}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.contract_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.contract_name === value.contract_name;
        }}
        style={{ width: "250px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Forma zatrudnienia" />}
        onChange={(event, value) => {
          if (value) {
            setSelectedContract(value);
            loadData(selectedOPK.opk_id, selectedGroup.hr_group_id, selectedAccounts, value.contract_id, startDate.year, selectedMonths);
          }
        }}
      />
    );
  }

  function AccountsBox() {
    return (
      <Autocomplete
        id="accounts-combo-box"
        options={accountsList}
        value={accountsList.filter(item => item.id === selectedAccounts)[0]}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.id === value.id;
        }}
        style={{ width: "200px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Konta rodzajowe" />}
        onChange={(event, value) => {
          if (value) {
            setSelectedAccounts(value.id);
            loadData(selectedOPK.opk_id, selectedGroup.hr_group_id, value.id, selectedContract.contract_id, startDate.year, selectedMonths);
          }
        }}
      />
    );
  }

  function loadDataBasic(year, months) {
    loadData(selectedOPK.opk_id, selectedGroup.hr_group_id, selectedAccounts, selectedContract.contract_id, year, months)
  }

  const loadData = (opk, groups, accounts, contracts, year, months) => {
    setTotalDataLoaded(false);
    setTotalDataComplete(false);
    const apiUrl = `${apiurl}/salariesreport/${opk}/${groups}/${accounts}/${contracts}/${year}/${JSON.stringify(months)}`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setTotalData(fetchedData);
          if (
            fetchedData.data_by_contract && fetchedData.data_by_group && fetchedData.data_by_opk && Array.isArray(fetchedData.data_by_opk)
          ) setTotalDataComplete(true);
          setTotalDataLoaded(true);
        });
    });
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/salariesreport`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          console.log(fetchedData);
          setDateList(fetchedData.YEARS);
          if (fetchedData.YEARS && fetchedData.YEARS.length !== 0) {
            setStartDate(fetchedData.YEARS[fetchedData.YEARS.length - 1]);
            setOpkList([
              { opk_id: 'ALL', opk_name: 'Wszystkie' },
              ...fetchedData.OPK_LIST
            ]);
            setGroupsList([
              { hr_group_id: 'ALL', hr_group_name: 'Wszystkie' },
              ...fetchedData.GROUPS_LIST
            ]);
            setContractsList([
              { contract_id: 'ALL', contract_name: 'Wszystkie' },
              ...fetchedData.CONTRACT_TYPE_LIST
            ]);
            loadData(selectedOPK.opk_id, selectedGroup.hr_group_id, selectedAccounts, selectedContract.contract_id, fetchedData.YEARS[fetchedData.YEARS.length - 1].year, selectedMonths);
            setFiltersLoaded(true);
          }
        });
    });
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    if (mounted) loadFilters();
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Ranking kosztów wynagrodzeń wg grup pracowniczych
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {filtersLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <OpkBox />
          <GroupBox />
          <AccountsBox />
          <ContractBox />
          <DateBox data={dateList} />
          <MonthSelect setSelectedMonthsFunction={setSelectedMonths} loadDataFunction={loadDataBasic} selectedMonthsProperty={selectedMonths} startDateProperty={startDate} />
          <ToolTip title='Opis raportu'>
            <a edge='end' style={{ marginTop: '14px', marginLeft: '7px', marginRight: '7px', color: '#0b6471' }} target='_blank' href='/usermanual/opis_raportu_008'>
              <ImportContactsIcon />
            </a>
          </ToolTip>
        </div>
      ) : (
        <div>Wczytywanie danych</div>
      )}
      <p style={{ height: "5px" }} />

      {totalDataComplete ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: totalDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="left chart area"
              style={{
                display: "flex",
                flexWrap: "wrap",
                //maxWidth: "840px",
                width: "100%",
                flexDirection: "column",
                flexShrink: 1,
              }}
            >

              <div
                id="top 3 bottom 3 tables area"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  alignItems: "stretch",
                  flexWrap: "wrap",
                  width: "100%",
                  flexDirection: "row",
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <CostDetailsTable data={totalData.data_by_opk} title='Koszty wynagrodzeń wg OPK' />
                <CostDetailsTable data={totalData.data_by_contract} title='Koszty wynagrodzeń wg formy zatrudnienia' />
                <CostDetailsTable data={totalData.data_by_group} title='Koszty wynagrodzeń wg grup pracowniczych' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        !totalData.data_by_opk && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left"
            }}
            variant="h5"
          >
            Wczytywanie danych
          </Typography>
        )
      )}
      {!totalDataLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: totalData[0] ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
