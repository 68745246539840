import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const ProfitTable = (props) => {
  const [tableState, setTableState] = React.useState({
    expanded: false,
  });

  const tableChange = () => {
    setTableState({ expanded: !tableState.expanded });
  };

  const posorneg = (name, type, value) => {
    let color = "black";
    if (name === "Δ %" || name === "Δ zł") {
      let posorneg = value < 0 ? false : true;
      if (type === "cost") posorneg = !posorneg;
      if (!posorneg) color = "#FE8E3C";
    }
    return color;
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      display: 'none',
      backgroundColor: "white",
      color: "black",
      maxWidth: 600,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "100",
      //border: '1px solid #FE8E3C',
      boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
    },
  }))(Tooltip);

  return (
    <Paper style={{ padding: "5px", width: "100%", marginBottom: "10px" }}>
      <HtmlTooltip
        arrow
        placement="bottom-start"
        title={
          <React.Fragment>
            <Typography>{props.tipTitle}</Typography>
            {props.tipBody}
          </React.Fragment>
        }
      >
        <Typography
          style={{
            color: "#0b6471",
            paddingLeft: "5px",
            fontSize: "16px",
            textDecoration: "none",
            fontWeight: "100",
            paddingBottom: "5px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          {props.title}
        </Typography>
      </HtmlTooltip>

      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.columns.map((col) => (
                <TableCell align={col.align} style={col.style} key={col.name}> 
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data &&
              props.data.slice(0, tableState.expanded ? 100 : 3).map((row, uid) => (
                <TableRow
                  key={uid}
                  //class="animate__animated animate__fadeIn"
                  //class="MuiTableRow.root"
                  hover={true}
                >
                  {props.columns.map((col) => (
                    <TableCell
                      align={col.align}
                      key={col.name}
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.datalen === ""
                            ? "black"
                            : posorneg(
                              col.name,
                              props.dataType,
                              row[col.datadef]
                            ),
                      }}
                    >
                      { row[col.datadef] ? (row.datalen === ""
                        ? (row[col.datadef] != 0 && row[col.datadef])
                        : (row[col.datadef] != 0) ? (row[col.datadef]).toLocaleString("pl-PL", {
                          maximumFractionDigits: col.datalen,
                        }) : '') : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {props.data && props.data.length > 3 && (
              <TableRow>
                <TableCell align="left">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={tableChange}
                  >
                    {tableState.expanded ? `Zwiń` : `Pokaż wszystkie >>>`}
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProfitTable;
