import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import CostDetailsTable from "./CostDetailsTable";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import MUITooltip from "@material-ui/core/Tooltip";
import { apiurl } from '../app/prefs.js';

export default function Report005() {
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataLoaded, setTotalDataLoaded] = React.useState(false);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const monthNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const [selectedMonths, setSelectedMonths] = React.useState([]);

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            loadData(value.year, selectedMonths);
          }
        }}
      />
    );
  }

  function MonthSelect() {
    const [selectedMonth, setSelectedMonth] = React.useState(selectedMonths);
    const [monthListOpen, setMonthListOpen] = React.useState(false);
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value.filter((item) => item !== undefined));
    };
    const handleMonthClose = () => {
      //console.log(selectedMonth);
      setSelectedMonths(selectedMonth);
      setMonthListOpen(false);
      loadData(startDate.year, selectedMonth);
    };
    const handleMonthClear = () => {
      setSelectedMonth([]);
      setSelectedMonths([]);
      loadData(startDate.year, []);
      //console.log([]);
    };

    return (
      <div>
        <InputLabel style={{ fontSize: '12px', marginTop: '1px', marginBottom: '0px' }} id="month-checkbox-label">Miesiąc</InputLabel>
        <Select
          labelId="month-checkbox-label"
          style={{ minWidth: "270px" }}
          multiple
          open={monthListOpen}
          endAdornment={<IconButton onClick={handleMonthClear}></IconButton>}
          displayEmpty
          IconComponent={CloseIcon}
          value={selectedMonth}
          onChange={handleMonthChange}
          input={<Input />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Wszystkie</em>;
            }
            return selected.sort((a, b) => a - b).join(", ");
          }}
          inputProps={{ "aria-label": "Without label" }}
          onClose={handleMonthClose}
          onOpen={() => setMonthListOpen(true)}
        >
          {monthNames.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
          <Button fullWidth onClick={handleMonthClose}>
            Wybierz
          </Button>
        </Select>
      </div>
    );
  }

  const loadData = (year, months) => {
    setTotalDataLoaded(false);
    const apiUrl = `${apiurl}/totalbyaccount/${year}/${JSON.stringify(months)}`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setTotalData(fetchedData);
          setTotalDataLoaded(true);
        });
    });
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/yearslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setDateList(fetchedData.datelist);
          if (fetchedData.datelist && fetchedData.datelist.length !== 0) {
            setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1]);
            loadData(fetchedData.datelist[fetchedData.datelist.length - 1].year, selectedMonths);
            setFiltersLoaded(true);
          }
        });
    });
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    if (mounted) loadFilters();

    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Koszty rodzajowe w czasie
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {filtersLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <DateBox data={dateList} />
          <MonthSelect />
          <MUITooltip title='Opis raportu'>
            <a edge='end' style={{marginTop: '14px', marginLeft: '10px', marginRight: '7px', color: '#0b6471'}} target='_blank' href='/usermanual/opis_raportu_001'>
              <ImportContactsIcon />
            </a>
          </MUITooltip>
        </div>
      ) : (
        <div>Wczytywanie danych</div>
      )}
      <p style={{ height: "5px" }} />

      {totalData[4] ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: totalDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="left chart area"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                //maxWidth: "840px",
                flexDirection: "column",
                flexShrink: 1,
              }}
            >
              <div
                id="tiny charts area"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  flexDirection: "row",
                  flexShrink: 1,
                }}
              >
              </div>
              <div
                id="top 3 bottom 3 tables area"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  alignItems: "stretch",
                  flexWrap: "wrap",
                  width: "100%",
                  flexDirection: "row",
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <CostDetailsTable data={totalData} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        !totalData && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left"

            }}
            variant="h5"
          >
            Wczytywanie danych
          </Typography>
        )
      )}
      {!totalDataLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: totalData[0] ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
