import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "animate.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SideList from "./common";
import NewsLetter from './newsletter';

const ArticleSideBar = () => {
    const matchesWidth = useMediaQuery("(min-width:500px)");
    const matchesWidth1200 = useMediaQuery("(min-width:1400px)");
    return (
      <Paper
        style={{
          padding: "10px",
          marginLeft: matchesWidth && matchesWidth1200 ? "10px" : "0px",
          //backgroundColor: matchesWidth1200 ? "red" : "orange",
          marginBottom: "10px",
          //width: "100%",
          maxWidth: matchesWidth && matchesWidth1200 ? "350px" : "350px",
          //maxWidth: "400px",
          flexShrink: 1,
          flexGrow: 1,
          
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            backgroundColor: "#4E97A5",
            padding: "5px 10px 5px 10px",
            margin: "-10px -10px 30px",
            borderRadius: "5px 5px 0px 0px",
            color: "white",
            fontWeight: "500",
          }}
        >
          Inne publikacje wg kategorii:
        </Typography>
        <SideList/>
        { matchesWidth && matchesWidth1200 && <NewsLetter maxwidth='300px'/>}
      </Paper>
    );
  };

  export default ArticleSideBar;