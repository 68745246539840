import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const SummaryTable = (props) => {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 600,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "100",
      //border: '1px solid #FE8E3C',
      boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
    },
  }))(MTooltip);

  return (
    <Paper
      style={{
        minWidth: "400px",
        flexGrow: 1,
        paddingTop: "5px",
        marginBottom: "10px",
        marginRight: "10px",
      }}
    >
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead style={{ border: "none" }}>
            <TableRow>
              <HtmlTooltip
                arrow
                placement="bottom-start"
                title={
                  <React.Fragment>
                    <Typography>{props.tipTitle}</Typography>
                    {props.tipBody}
                  </React.Fragment>
                }
              >
                <TableCell
                  style={{
                    color: "#0b6471",
                    fontSize: "15px",
                    letterSpacing: "-1px",
                    fontWeight: "100",
                  }}
                >
                  {props.title}
                </TableCell>
              </HtmlTooltip>
              {props.columns &&
                props.columns.map((col) => (
                  <TableCell style={{ color: "#0b6471" }} align="right">
                    {col.header.name}
                  </TableCell>
                ))}
              {!props.columns && (
                <TableCell style={{ color: "#0b6471" }} align="right">
                  Wynik j.
                </TableCell>
              )}
              {!props.columns && (
                <TableCell style={{ color: "#0b6471" }} align="right">
                  Wynik
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data && props.data.map((row) => (
              <TableRow
                key={row.jgp_id}
                className="animate__animated animate__fadeIn"
                //class="MuiTableRow"
                hover={true}
              >
                <TableCell component="th" scope="row">
                  {row.jgp_id}
                </TableCell>
                {props.columns &&
                  props.columns.map((col) => (
                    <TableCell style={{ color: "#0b6471" }} align="right">
                      {row[col.data.name].toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      })}
                    </TableCell>
                  ))}
                {!props.columns && (
                  <TableCell align="right">
                    {row.profit_per_unit.toLocaleString("pl-PL", {
                      maximumFractionDigits: 0,
                    })}
                  </TableCell>
                )}
                {!props.columns && (
                  <TableCell align="right">
                    {row.profit.toLocaleString("pl-PL", {
                      maximumFractionDigits: 0,
                    })}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SummaryTable;
