import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PollIcon from "@material-ui/icons/Poll";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { apiurl } from '../app/prefs.js';
import ProfitTable from "./ProfitTable";
import SummaryTable from "./SummaryTable";

export default function Report008() {
  const [yardList, setYardList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState(null);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [patientsList, setPatientsList] = React.useState(null);
  const [patient, setPatient] = React.useState(null);
  const [patientData, setPatientData] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        disabled={!props.data}
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            if (activeYard) loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  const loadData = (opk, year) => {
    setActiveYard(opk);
    setReportDataLoaded(false);
    setPatient(null);
    setPatientData(null);
    setPatientsList(null);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/jgpdatatotal/${opk.opk_id}/${year}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          setPatientsList(data.patients);
          setReportDataLoaded(true);
        });
    })
  }

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-1"
        disabled = {!props.data || props.data.length === 0}
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Oddział" />}
        onChange={(event, value) => {
          if (value) {
            if (startDate) loadData(value, startDate.year);
          }
        }}
      />
    );
  }

  function JGPComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-2"
        options={props.data}
        value={patient}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.object_id}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.object_id === value;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Pacjent" />}
        onChange={(event, value) => {
          if (value) {
            setPatient(value);
            setPatientData(null);
            getAccessTokenSilently().then((token) => {
              const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              };
              fetch(`${apiurl}/jgpdatadetails/${value.object_id}/${startDate.year}`,requestOptions)
                .then((response) => response.json())
                .then((data) => {
                  //console.log(data);
                  setPatientData(data);
                });
            })
          }
        }}
      />
    );
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    setYardListLoaded(false);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const apiUrl = `${apiurl}/yardslist`;
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (mounted) {
            setYardList(fetchedData.DATA);
            setDateList(fetchedData.datelist);
            setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1]);
            setYardListLoaded(true);
          }
        });
      return function cleanup() {
        //console.log("Main unmounted");
        mounted = false;
      };
    })
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #0b6471)",
          display: "flex",
          height: "45px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "60px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "10px",
              marginRight: "0px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            WYNIKI JGP / PACJENTÓW
          </Typography>

        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <div style={{ position: "absolute", right: "15px" }}>
          <IconButton edge='end'>
              <ImportContactsIcon/>
            </IconButton>
          </div>
        </div>
      ) : (
          <div>List not yet loaded</div>
        )}
      <p style={{ height: "5px" }} />

      {activeYard && reportData ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              id="top 3 bottom 3 tables area"
              style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap",
                width: "100%",
                flexDirection: "row",
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              <SummaryTable
                title="NAJBARDZIEJ RENTOWNE JGP"
                data={reportData.top_jgpdata && reportData.top_jgpdata}
              />
              <SummaryTable
                title="NAJMNIEJ RENTOWNE JGP"
                data={reportData.bottom_jgpdata && reportData.bottom_jgpdata}
              />
            </div>
            <div
              id="top 3 bottom 3 tables area"
              style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap",
                width: "100%",
                flexDirection: "row",
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              <SummaryTable
                title={<div>NAJBARDZIEJ RENTOWNI PACJENCI</div>}
                columns={[
                  {
                    header: {
                      name: "Przychód",
                    },
                    data: {
                      name: "income",
                    },
                  },
                  {
                    header: {
                      name: "Koszt",
                    },
                    data: {
                      name: "cost",
                    },
                  },
                  {
                    header: {
                      name: "Wynik",
                    },
                    data: {
                      name: "profit",
                    },
                  },
                ]}
                data={reportData.top_patients}
              />
              <SummaryTable
                title="NAJMNIEJ RENTOWNI PACJENCI"
                columns={[
                  {
                    header: {
                      name: "Przychód",
                    },
                    data: {
                      name: "income",
                    },
                  },
                  {
                    header: {
                      name: "Koszt",
                    },
                    data: {
                      name: "cost",
                    },
                  },
                  {
                    header: {
                      name: "Wynik",
                    },
                    data: {
                      name: "profit",
                    },
                  },
                ]}
                data={reportData.bottom_patients}
              />
            </div>
          </div>
          {patientsList && (
            <div>
              <JGPComboBox data={patientsList} />
            </div>
          )}
          {patientData && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <ProfitTable
                title="DANE PACJENTA"
                data={patientData.patientsummary}
                dataType="cost"
                columns={[
                  {
                    name: "Oddział",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "yardname",
                    datalen: "",
                  },
                  {
                    name: "JGP",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "JGP",
                    datalen: "",
                  },
                  {
                    name: "Przychód",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "2px solid" },
                    datadef: "income",
                    datalen: 0,
                  },
                  {
                    name: "Koszt",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "4px solid" },
                    datadef: "cost",
                    datalen: 0,
                  },
                  {
                    name: "Wynik",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "5px double" },
                    datadef: "profit",
                    datalen: 0,
                  },
                ]}
              />
              {patientData.procedures && <ProfitTable
                title={<div>KOSZTY PROCEDUR: <strong>{Math.round(patientData.cost_levels.procedure_cost)} zł</strong></div>}
                data={patientData.procedures}
                dataType="cost"
                columns={[
                  {
                    name: "Komórka org.",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "ORG_UNIT",
                    datalen: "",
                  },
                  {
                    name: "Kod procedury",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "procedure_id",
                    datalen: "",
                  },
                  {
                    name: "Nazwa procedury",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "procedure_name",
                    datalen: "",
                  },
                  {
                    name: "Liczba procedur",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "2px solid" },
                    datadef: "qty",
                    datalen: 0,
                  },
                  {
                    name: "Koszt",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "4px solid" },
                    datadef: "cost",
                    datalen: 0,
                  },
                ]}
              />}
              {patientData.hospital_days && <ProfitTable
                title={<div>KOSZTY OSOBODNI: <strong>{Math.round(patientData.cost_levels.hospital_days_cost)} zł</strong></div>}
                data={patientData.hospital_days}
                dataType="cost"
                columns={[
                  {
                    name: "Komórka org.",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "ORG_UNIT",
                    datalen: "",
                  },
                  {
                    name: "Liczba osobodni",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "2px solid" },
                    datadef: "qty",
                    datalen: 0,
                  },
                  {
                    name: "Koszt",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "4px solid" },
                    datadef: "cost",
                    datalen: 0,
                  },
                ]}
              />}
              <ProfitTable
                title={<div>KOSZTY LEKÓW I MATERIAŁÓW: <strong>{Math.round(patientData.cost_levels.overhead_cost)} zł</strong></div>}
                data={patientData.overhead}
                dataType="cost"
                columns={[
                  {
                    name: "Komórka org.",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "ORG_UNIT",
                    datalen: "",
                  },
                  {
                    name: "Koszt",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "4px solid" },
                    datadef: "cost",
                    datalen: 0,
                  },
                ]}
              />
            </div>
          )}
        </div>
      ) : (
          !activeYard && (
            <Typography
              style={{
                position: "absolute",
                top: "120%",
                left: "40%",
                color: "#0b6471",
                textJustify: "left"
              }}
              variant="h5"
            >
              Wybierz komórkę
            </Typography>
          )
        )}
      {!reportDataLoaded && activeYard && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
