import React from "react";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  BarChart,
  Cell,
  Bar,
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
} from "recharts";
import IconButton from "@material-ui/core/IconButton";

const posorneg = (type, value) => {
  let color = "black";

  let posorneg = value < 0 ? false : true;
  if (type === "cost") posorneg = !posorneg;
  if (!posorneg) color = "#FE8E3C";
  return color;
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 600,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "100",
    //border: '1px solid #FE8E3C',
    boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
  },
}))(MTooltip);

function RowKB(props) {
  const [open, setOpen] = React.useState(false);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow
          key={row.opk_name}
          hover
          className={"animate__animated animate__fadeIn"}
        >
          <TableCell
            style={{
              paddingLeft: 20 + 15 * props.lvl,
              borderBottom: "0px solid",
            }}
          >
            {row.children && row.children.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                style={{ color: "lightgray" }}
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography
              variant="body2"
              style={{ paddingLeft: 20 + 15 * props.lvl, color: "gray" }}
            >
              {row.opk_name}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            {row.cost_direct_BU && row.cost_direct_BU.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            })}
          </TableCell>
          <TableCell
            align="right"
            style={{ color: posorneg("cost", row.cost_direct_delta) }}
          >
            {row.cost_direct_delta && row.cost_direct_delta.toLocaleString("pl-PL", {
              maximumFractionDigits: 2,
            })}
          </TableCell>
          <TableCell align="right">
            {row.cost_direct && row.cost_direct.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            })}
          </TableCell>
          <TableCell style={{ padding: "0px" }}></TableCell>
        </TableRow>

        {open &&
          row.children.map((child) => (
            <Row key={child.name} row={child} lvl={props.lvl + 1} />
          ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        key="I KOSZTY BEZPOŚREDNIE"
        className="animate__animated animate__fadeIn"
        //class="MuiTableRow"
        hover={true}
      >
        <TableCell style={{ borderBottom: "0px solid" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ textIndent: "10px" }}>
          I KOSZTY BEZPOŚREDNIE
        </TableCell>
        <TableCell 
          align="right"
        >
          {props.data.costlevels.cost_direct_BU ? props.data.costlevels.cost_direct_BU.toLocaleString("pl-PL", {
            maximumFractionDigits: 0,
          }) : ''}
        </TableCell>
        <TableCell align="right"
        style={{color: posorneg("cost", (props.data.costlevels.cost_direct -
          props.data.costlevels.cost_direct_BU))}}
        >
          {props.data.costlevels.cost_direct_BU ? (
            (100 *
              (props.data.costlevels.cost_direct -
                props.data.costlevels.cost_direct_BU)) /
            Math.abs(props.data.costlevels.cost_direct_BU)
          ).toLocaleString("pl-PL", {
            maximumFractionDigits: 2,
          }) : ''}
        </TableCell>
        <TableCell align="right">
          {props.data.costlevels.cost_direct ? props.data.costlevels.cost_direct.toLocaleString("pl-PL", {
            maximumFractionDigits: 0,
          }) : ''}
        </TableCell>
        <TableCell style={{ padding: "0px" }}></TableCell>
      </TableRow>

      {open &&
        props.data.direct_cost_details.map((row) => (
          <Row key={row.opk_name} row={row} lvl={0} />
        ))}
    </React.Fragment>
  );
}

function RowKP(props) {
  const [open, setOpen] = React.useState(false);

  function Row(props) {
    const { row } = props;
    const [detailsOpen, setDetailsOpen] = React.useState(false);

    const tablerows = [
      { name: "Nazwa klucza", value: row.driver },
      {
        name: "WYK Liczba jednostek klucza",
        value: row.wqty.toLocaleString("pl-PL", { maximumFractionDigits: 0 }),
      },
      {
        name: "WYK Stawka kosztów na jednostkę (zł)",
        value: (row.cost_indirect / row.wqty).toLocaleString("pl-PL", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
      },
    ];

    const data01 = [
      { name: "OPK", value: row.wqty },
      { name: "Pozostałe", value: row.other },
    ];
    const COLORS = ["#FE8E3C", "#0b6471"];

    return (
      <React.Fragment>
        <TableRow
          key={row.opk_name}
          className="animate__animated animate__fadeIn"
          //style={open ? { display: "" } : { display: "none" }}
          //class="MuiTableRow"
          hover={true}
        >
          <TableCell style={{ paddingLeft: "20px", borderBottom: "0px solid" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ color: "lightgray" }}
              onClick={() => setDetailsOpen(!detailsOpen)}
            >
              {detailsOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                  <KeyboardArrowRightIcon />
                )}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography
              variant="body2"
              style={{ paddingLeft: "20px", color: "gray" }}
            >
              {row.opk_name}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {row.cost_indirect_BU && row.cost_indirect_BU.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            })}
          </TableCell>
          <TableCell
            align="right"
            style={{ color: posorneg("cost", row.cost_indirect_delta) }}
          >
            {row.cost_indirect_delta && row.cost_indirect_delta.toLocaleString("pl-PL", {
              maximumFractionDigits: 2,
            })}
          </TableCell>
          <TableCell align="right">
            {row.cost_indirect.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            })}
          </TableCell>
          <TableCell style={{ padding: "0px" }}></TableCell>
        </TableRow>

        {detailsOpen && (
          <TableRow key={row.opk_name}>
            <TableCell
              style={{ paddingLeft: "20px", borderBottom: "0px solid" }}
            ></TableCell>
            <TableCell colSpan="5" style={{ borderBottom: "0px solid" }}>
              <div
                style={{
                  width: "100%",
                  minHeight: "160px",
                  border: "0px dotted blue",
                  paddingLeft: "30px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ border: "0px dotted green" }}>
                  <TableContainer>
                    <Table aria-label="simple table" style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              color: "#0b6471",
                              fontSize: "16px",
                              fontWeight: "100",
                            }}
                          >
                            Alokacja kosztów OPK pomocniczego
                          </TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tablerows.map((trow, tindex) => (
                          <TableRow key={trow.name}>
                            <TableCell component="th" scope="row">
                              {trow.name}
                            </TableCell>
                            <TableCell align="right">{trow.value}</TableCell>

                            <TableCell style={{ padding: "0px" }}>
                              <div
                                style={{
                                  height: "40px",
                                  width: "130px",
                                  padding: "0px",
                                }}
                              >
                                {tindex !== 0 && (
                                  <ResponsiveContainer>
                                    <BarChart
                                      style={{ padding: "0px", margin: "0px" }}
                                      width={250}
                                      height={60}
                                      data={row.monthly}
                                    >
                                      <Bar
                                        dataKey={tindex === 1 ? "wqty" : "rate"}
                                        fill="#4E97A5"
                                        barSize={250 / (1.5 * 12)}
                                      ></Bar>
                                    </BarChart>
                                  </ResponsiveContainer>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div
                  style={{
                    width: "160px",
                    height: "100%",
                    border: "0px dotted orange",
                    marginLeft: "20px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#0b6471",
                      fontSize: "12px",
                      fontWeight: "100",
                    }}
                  >
                    Udział w kosztach OPK pomocniczego
                  </Typography>
                  <PieChart width={200} height={150}>
                    <Pie
                      isAnimationActive={true}
                      data={data01}
                      cx={75}
                      cy={75}
                      outerRadius={65}
                      fill="#8884d8"
                    >
                      {data01.map((entry, index) => (
                        <Cell
                          fill={index === 0 ? COLORS[0] : "white"}
                          stroke={index === 0 ? COLORS[0] : COLORS[1]}
                          strokeWidth="2"
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        key="II DZIAŁALNOŚĆ POMOCNICZA"
        className="animate__animated animate__fadeIn"
        //class="MuiTableRow"
        hover={true}
      >
        <TableCell style={{ borderBottom: "0px solid" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ textIndent: "10px" }}>
          II KOSZTY OPK POMOCNICZYCH
        </TableCell>
        <TableCell align="right">
          {props.data.costlevels.cost_indirect_BU ? props.data.costlevels.cost_indirect_BU.toLocaleString("pl-PL", {
            maximumFractionDigits: 0,
          }) : ''}
        </TableCell>
        <TableCell align="right"
        style={{ color: posorneg("cost", (props.data.costlevels.cost_indirect -
          props.data.costlevels.cost_indirect_BU)) }}
        >
          {props.data.costlevels.cost_indirect_BU ? (
            (100 *
              (props.data.costlevels.cost_indirect -
                props.data.costlevels.cost_indirect_BU)) /
            props.data.costlevels.cost_indirect_BU
          ).toLocaleString("pl-PL", {
            maximumFractionDigits: 2,
          }) : ''}
        </TableCell>
        <TableCell align="right">
          {props.data.costlevels.cost_indirect ? props.data.costlevels.cost_indirect.toLocaleString("pl-PL", {
            maximumFractionDigits: 0,
          }) : ''}
        </TableCell>
        <TableCell style={{ padding: "0px" }}></TableCell>
      </TableRow>

      {open && props.data.indirect_cost_details &&
        props.data.indirect_cost_details.map((row) => (
          <Row key={row.opk_name} row={row} lvl={0} />
        ))}
    </React.Fragment>
  );
}

function RowOPKProc(props) {
  const [open, setOpen] = React.useState(false);

  function Row(props) {
    const { row } = props;
    const [detailsOpen, setDetailsOpen] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
      <React.Fragment>
        <TableRow
          key={row.opk_name}
          className="animate__animated animate__fadeIn"
          //style={open ? { display: "" } : { display: "none" }}
          //class="MuiTableRow"
          hover={true}
        >
          <TableCell style={{ paddingLeft: "20px", borderBottom: "0px solid" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ color: "lightgray" }}
              onClick={() => setDetailsOpen(!detailsOpen)}
            >
              {detailsOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                  <KeyboardArrowRightIcon />
                )}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography
              variant="body2"
              style={{ paddingLeft: "20px", color: "gray" }}
            >
              {row.opk_name}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {row.cost_procedures_BU ? row.cost_procedures_BU.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            }) : ''}
          </TableCell>
          <TableCell
            align="right"
            style={{ color: posorneg("cost", row.cost_procedures_delta) }}
          >
            {row.cost_procedures_delta ? row.cost_procedures_delta.toLocaleString("pl-PL", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) : ''}
          </TableCell>
          <TableCell align="right">
            {row.cost_procedures ? row.cost_procedures.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            }) : ''}
          </TableCell>
          <TableCell style={{ padding: "0px" }}></TableCell>
        </TableRow>

        {detailsOpen && (
          <TableRow key={row.opk_name}>
            <TableCell
              style={{ paddingLeft: "20px", borderBottom: "0px solid" }}
            ></TableCell>
            <TableCell colSpan="5" style={{ borderBottom: "0px solid" }}>
              <div
                style={{
                  width: "100%",
                  minHeight: "60px",
                  border: "0px dashed gray",
                  paddingLeft: "30px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <TableContainer>
                  <Table size="small" style={{ width: "100%" }}>
                  <TableHead>
                      <TableRow>
                        <TableCell
                          rowSpan="1"
                          style={{ color: "#0b6471", fontSize: "16px", fontWeight: "100", }}
                        >
                          Nazwa procedury
                        </TableCell>
                        <TableCell align="right" rowSpan="1" style={{ color: "#0b6471", fontSize: "16px", fontWeight: "100", }}>Liczba procedur</TableCell>
                        <TableCell align="right" colSpan="1" style={{ color: "#0b6471", fontSize: "16px", fontWeight: "100", }}>Koszt jedn. bezpośredni</TableCell>
                        <TableCell align="right" colSpan="1" style={{ color: "#0b6471", fontSize: "16px", fontWeight: "100", }}>Koszty jedn. bezpośredni + pośredni</TableCell>
                        <TableCell align="right" colSpan="1" style={{ color: "#0b6471", fontSize: "16px", fontWeight: "100", }}>Koszt całkowity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row &&
                        row.children &&
                        row.children
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((child) => (
                            <TableRow key={child.procedure_name}>
                              <TableCell component="th" scope="row">
                                {child.procedure_name}
                              </TableCell>
                              <TableCell align="right">
                                {child.qty.toLocaleString("pl-PL", {
                                  maximumFractionDigits: 0,
                                })}
                              </TableCell>
                              <TableCell align="right">
                                {child.cost_given_per_unit.toLocaleString(
                                  "pl-PL",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {child.cost_calculated_per_unit.toLocaleString(
                                  "pl-PL",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {child.cost_calculated.toLocaleString("pl-PL", {
                                  maximumFractionDigits: 0,
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={row && row.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        key="III KOSZTY OPK PROCEDURALNYCH"
        className="animate__animated animate__fadeIn"
        //class="MuiTableRow"
        hover={true}
      >
        <TableCell style={{ borderBottom: "0px solid" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ textIndent: "10px" }}>
          III KOSZTY OPK PROCEDURALNYCH
        </TableCell>
        <TableCell align="right">
          {props.data.costlevels.cost_procedures_BU ? props.data.costlevels.cost_procedures_BU.toLocaleString("pl-PL", {
            maximumFractionDigits: 0,
          }) : ''}
        </TableCell>
        <TableCell align="right"
         style={{ color: posorneg("cost", (props.data.costlevels.cost_procedures -
          props.data.costlevels.cost_procedures_BU)) }}
        >
          {(props.data.costlevels.cost_procedures_BU &&
            props.data.costlevels.cost_procedures_BU > 0) ?
            (
              (100 *
                (props.data.costlevels.cost_procedures -
                  props.data.costlevels.cost_procedures_BU)) /
              props.data.costlevels.cost_procedures_BU
            ).toLocaleString("pl-PL", {
              maximumFractionDigits: 2,
            }) : ''}
        </TableCell>
        <TableCell align="right">
          {props.data.costlevels.cost_procedures ? props.data.costlevels.cost_procedures.toLocaleString("pl-PL", {
            maximumFractionDigits: 0,
          }) : ''}
        </TableCell>
        <TableCell style={{ padding: "0px" }}></TableCell>
      </TableRow>

      {open &&
        props.data.procedure_cost_details.map((row) => (
          <Row key={row.opk_name} row={row} lvl={0} />
        ))}
    </React.Fragment>
  );
}

const OPKTable = (props) => {
  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <HtmlTooltip
        arrow
        placement="bottom-start"
        title={
          <React.Fragment>
            <Typography>{props.tipTitle}</Typography>
            {props.tipBody}
          </React.Fragment>
        }
      >
        <Typography
          style={{
            color: "#0b6471",
            fontSize: "16px",
            fontWeight: "100",
            borderBottom: "0px solid",
            paddingLeft: "10px",
            paddingTop: "5px",
          }}
        >
          ANALIZA KOSZTÓW OPK
        </Typography>
      </HtmlTooltip>

      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead style={{ border: "none" }}>
            <TableRow>
              <TableCell
                style={{
                  borderBottom: "0px solid",
                  width: "2px",
                }}
              />
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                  borderBottom: "0px solid",
                }}
              ></TableCell>
              <TableCell
                style={{ color: "#0b6471", borderBottom: "5px double" }}
                align="right"
              >
                BU
              </TableCell>
              <TableCell
                style={{ color: "#0b6471", borderBottom: "1px solid" }}
                align="right"
              >
                Δ %
              </TableCell>
              <TableCell
                style={{ color: "#0b6471", borderBottom: "4px solid" }}
                align="right"
              >
                WYK
              </TableCell>
              <TableCell
                style={{
                  color: "#0b6471",
                  borderBottom: "4px solid",
                  width: "150px",
                }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key="KOSZTY"
              className="animate__animated animate__fadeIn"
              //class="MuiTableRow"
              hover={true}
            >
              <TableCell
                style={{
                  borderBottom: "0px solid",
                }}
              />
              <TableCell component="th" scope="row">
                KOSZTY
              </TableCell>
              <TableCell align="right">
                {props.data.budget.cost_BU ? props.data.budget.cost_BU.toLocaleString("pl-PL", {
                  maximumFractionDigits: 0,
                }) : ''}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: posorneg("cost", props.data.budgetdata.cost_BU),
                }}
              >
                {props.data.budgetdata.cost_BU ? props.data.budgetdata.cost_BU.toLocaleString("pl-PL", {
                  maximumFractionDigits: 2,
                }) : ''}
              </TableCell>
              <TableCell align="right">
                {(props.data.yearlydata && props.data.yearlydata.cost) ? props.data.yearlydata.cost.toLocaleString("pl-PL", {
                  maximumFractionDigits: 0,
                }) : ''}
              </TableCell>
              <TableCell style={{ padding: "0px" }}>
                <div
                  style={{
                    borderColor: "red",
                    height: "40px",
                    width: "130px",
                    padding: "0px",
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      style={{ padding: "0px", margin: "0px" }}
                      width={250}
                      height={60}
                      data={props.data.monthlydata}
                    >
                      <Bar
                        dataKey="cost"
                        fill="#0B6471"
                        barSize={250 / (1.5 * 12)}
                      >
                        {props.data.monthlydata.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={entry.cost < 0 ? "#FE8E3C" : "#0B6471"}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </TableCell>
            </TableRow>

            <RowKB data={props.data} />
            <RowKP data={props.data} />
            <RowOPKProc data={props.data} />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OPKTable;
