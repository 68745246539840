import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import "./AccountFilter.css";
import { BiFontColor } from "react-icons/bi";

const AccountFilter = (props) => {
  const { titleCaption, menuData, startSelection, updateFunction } = props;

  const underLine = props.underLine || false;
  const fontSize = props.fontSize || '16px';
  const fontStyle = props.fontStyle || 'normal';
  const fontColor = props.fontColor || 'black';
  const marginTop = props.marginTop || '0px';

  const [selectedAccounts, setSelectedAccounts] = React.useState(
    startSelection
  );
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [displayFilter, setDisplayFilter] = React.useState('');
  const [filterQuery, setFilterQuery] = React.useState("");
  const [selectAllState, setSelectAllState] = React.useState('All'); // All / None / Selected as available options. Also One in new version

  React.useEffect(
    () => {
      updateSelectAllStatus(startSelection);
    }, [filterOpen]
  )

  React.useEffect(() => {
    const timeOutId = setTimeout(() => setFilterQuery(displayFilter), 500);
    return () => clearTimeout(timeOutId);
  }, [displayFilter]);

  React.useEffect(() => {
    const timeOutId = setTimeout(() => setDisplayFilter(""), 500);
    return () => clearTimeout(timeOutId);
  }, [filterOpen]);

  const getInfoCaption = () => {
    if (selectedAccounts.length === 0) {
      return "Żadne";
    }
    let accountCount = 0;
    for (let c = 0; c < menuData.length; c++) {
      if (menuData[c].children && menuData[c].children.length !== 0) {
        accountCount = accountCount + menuData[c].children.length;
      } else {
        accountCount = accountCount + 1;
      }
    }
    if (selectedAccounts.length === 1) {
      let accountData = undefined;
      for (let c = 0; c < menuData.length; c++) {
        if (menuData[c].children && menuData[c].children.length !== 0) {
          if (menuData[c].children.find(child => child.id == selectedAccounts[0])) accountData = menuData[c].children.find(child => child.id == selectedAccounts[0])
        } else {
          if (menuData[c].id == selectedAccounts[0]) accountData = menuData[c]
        }
      };
      if (accountData) return accountData.name;
    };
    if (accountCount === selectedAccounts.length) {
      return "Wszystkie";
    } else {
      return "Wybrane";
    }
  };

  const getColorInfo = (id) => {
    if (id.children && id.children.length !== 0) {
      const childrenCount = id.children.length;
      let selectedChildrenCount = 0;
      for (let i = 0; i < childrenCount; i++) {
        if (selectedAccounts.includes(id.children[i].id))
          selectedChildrenCount++;
      }
      if (selectedChildrenCount === 0) return "none";
      if (selectedChildrenCount === childrenCount) {
        return "#0B6471";
      } else {
        return "lightgray";
      }
    } else {
      if (selectedAccounts.includes(id.id)) return "#0B6471";
      else return "none";
    }
  };

  const updateAccount = (id) => {
    const accountId = id.id;
    let temp = [];
    if (id.children && id.children.length !== 0) {
      const childrenCount = id.children.length;
      let selectedChildrenCount = 0;
      for (let i = 0; i < childrenCount; i++) {
        if (selectedAccounts.includes(id.children[i].id))
          selectedChildrenCount++;
      }
      if (selectedChildrenCount === childrenCount) {
        temp = Array.from(selectedAccounts);

        for (let i = 0; i < childrenCount; i++) {
          temp = temp.filter((a) => a !== id.children[i].id);
        }
        setSelectedAccounts(temp);
      } else {
        temp = Array.from(selectedAccounts);
        for (let i = 0; i < childrenCount; i++) {
          if (!temp.includes(id.children[i].id)) temp.push(id.children[i].id);
        }
        setSelectedAccounts(temp);
      }
    } else {
      if (selectedAccounts.includes(accountId)) {
        temp = Array.from(selectedAccounts);
        temp = temp.filter((a) => a !== accountId);
        setSelectedAccounts(temp);
      } else {
        temp = Array.from(selectedAccounts);
        temp.push(accountId);
        setSelectedAccounts(temp);
      }
    };
    updateSelectAllStatus(temp);
  };

  const MyCon = (props) => (
    <svg
      style={{
        border: "1px solid lightgray",
        borderRadius: "5px",
        marginRight: "10px"
      }}
      width="20px"
      height="20px"
      viewBox="-2 -2 28 24"
      fill="none"
    >
      <g id="SVGIcon">
        <path
          id="Vector"
          d="M4 12L8.94975 16.9497L19.5572 6.34326"
          stroke={props.color}
          stroke-width="3.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );

  const selectAllItems = () => {
    let all = [];
    if (displayFilter === "") {
      menuData.forEach((item) => {
        item.children && item.children.length !== 0
          ? item.children.forEach((child) => all.push(child.id))
          : all.push(item.id);
      });
    } else {
      menuData.forEach((item) => {
        item.children && item.children.length !== 0
          ? item.children.forEach(
            (child) =>
              child.name
                .toLowerCase()
                .includes(displayFilter.toLowerCase()) &&
              all.push(child.id)
          )
          : item.name
            .toLowerCase()
            .includes(displayFilter.toLowerCase()) &&
          all.push(item.id);
      });
    }
    setSelectedAccounts(all);
    setSelectAllState('All');
    return (all);
  };

  const getAllItems = () => {
    let all = [];
    if (displayFilter === "") {
      menuData.forEach((item) => {
        item.children && item.children.length !== 0
          ? item.children.forEach((child) => all.push(child.id))
          : all.push(item.id);
      });
    } else {
      menuData.forEach((item) => {
        item.children && item.children.length !== 0
          ? item.children.forEach(
            (child) =>
              child.name
                .toLowerCase()
                .includes(displayFilter.toLowerCase()) &&
              all.push(child.id)
          )
          : item.name
            .toLowerCase()
            .includes(displayFilter.toLowerCase()) &&
          all.push(item.id);
      });
    }
    return (all);
  };

  const unSelectAllItems = () => {
    let all = [];
    setSelectedAccounts(all);
    setSelectAllState('None');
  };

  const ToolbarItem = (props) => {
    return (
      <div>
        <div
          style={{
            marginTop: "6px",
            padding: "2px 10px 3px 0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
          onClick={(event) => {
            if (selectAllState === 'All') {
              unSelectAllItems()
            } else {
              selectAllItems()
            };
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <MyCon color={(selectAllState === 'All') ? "#0B6471" : (selectAllState === 'None') ? 'none' : 'lightgray'} />
            {displayFilter === "" ? "Zaznacz wszystkie" : "Zaznacz widoczne"}
          </div>
        </div>
      </div>
    );
  };

  const filteredOut = (item) => {
    if (displayFilter === "") return true;
    const searchPattern = filterQuery.toLowerCase();

    const visibleChildren = (item) => {
      if (
        item.children &&
        item.children.length !== 0 &&
        item.children.some((child) =>
          child.name.toLowerCase().includes(searchPattern)
        )
      ) {
        return true;
      } else {
        return false;
      }
    };

    if (item.children && item.children.length !== 0) {
      if (visibleChildren(item)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item.name.toLowerCase().includes(searchPattern)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const CustomItem = (props) => {
    const data = props.data;
    return filteredOut(data) ? (
      <div
        onClick={() => updateAccount(data)}
        style={{
          padding: "2px 0px 2px 10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          cursor: "pointer"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <MyCon color={getColorInfo(data)} />
          {data.name}
        </div>
        {data.children &&
          data.children.map((el) =>
            filteredOut(el) ?
              (
                <div
                  style={{ padding: "5px 0px 2px 10px" }}
                  onClick={(event) => {
                    updateAccount(el);
                    event.stopPropagation();
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MyCon color={getColorInfo(el)} />
                    {el.name}
                  </div>
                </div>
              ) : (<div></div>))}
      </div>)
      :
      (<div></div>)
      ;
  };

  const updateSelectAllStatus = (parameter1) => {
    const selectedAccounts = parameter1 || selectedAccounts;
    if (selectedAccounts.length === 0) {
      setSelectAllState('None')
    } else {
      let childrenCount = 0;
      const searchPattern = filterQuery.toLowerCase();
      for (let item = 0; item < menuData.length; item++) {
        if (menuData[item].children && menuData[item].children.length !== 0) {
          menuData[item].children.forEach(child => { if (child.name.toLowerCase().includes(searchPattern)) childrenCount = childrenCount + 1 })
        } else {
          if (menuData[item].name.toLowerCase().includes(searchPattern)) childrenCount = childrenCount + 1;
        }
      };
      if (childrenCount === selectedAccounts.length) {
        setSelectAllState('All')
      } else {
        setSelectAllState('Selected')
      }
    }
  };

  const MenuItems = () => {
    return (
      <div>
        {menuData.map((el, x) => (
          <CustomItem key={x} data={el} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <InputLabel
        style={{ fontSize: '12px', marginBottom: '0px', marginTop: '1px' }}
        id="filter-select-label"
        onClick={() => {

        }}
      >
        {titleCaption}
      </InputLabel>
      <Select
        id="filter-select"
        style={{ width: "250px", fontSize: fontSize, color: fontColor, fontStyle: fontStyle, marginTop: marginTop }}
        value={"1"}
        defaultValue="1"
        disableUnderline={underLine}
        renderValue={(value) => getInfoCaption()}
        onClick={() => {
          setFilterOpen(true);
        }}
        open={filterOpen}
      >
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <TextField
            value={displayFilter}
            onChange={(event) => setDisplayFilter(event.target.value)}
            style={{ marginBottom: '10px', width: '100%' }}
            inputProps={{ style: { fontSize: '14px', padding: '7px 5px 7px 5px' } }}
            id="custom-filter-input"
            variant="outlined"
            size="small"
            placeholder="Wyszukaj"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    style={{ color: "gray", cursor: "pointer" }}
                    onClick={() => setDisplayFilter("")}
                    edge="end"
                  >
                    X
                  </div>
                </InputAdornment>
              )
            }}
          />
          <ToolbarItem />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "70vh",
              overflow: "scroll",
            }}
          >
            <MenuItems />
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              flex: "row",
              justifyContent: "space-between",
              padding: "10px 10px 0px 10px"
            }}
          >
            <Button
              onClick={(event) => {
                setSelectedAccounts(startSelection);
                setFilterOpen(false);
                event.stopPropagation();
              }}
            >
              Anuluj
            </Button>
            <Button
              onClick={(event) => {
                let selected = selectedAccounts;
                if (displayFilter !== '') {
                  const checked = getAllItems();
                  selected = checked.filter(cos => selected.includes(cos));
                  setSelectedAccounts(selected);
                  if (selected.length === 0) {
                    setSelectedAccounts(startSelection);
                    selected = startSelection
                  };
                };
                updateFunction(selected);
                setFilterOpen(false);
                event.stopPropagation();
              }}
            >
              Zatwierdź
            </Button>
          </div>
          <MenuItem value="1" style={{ display: "none" }}></MenuItem>
        </div>
      </Select>
    </div>
  );
};

export default AccountFilter;
