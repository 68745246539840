import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Parser from "html-react-parser";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./article.css";
import { ImInfo } from "react-icons/im";
import { useAuth0 } from "@auth0/auth0-react";
import { apiurl } from '../../app/prefs.js';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function ArticleEditor() {
  const classes = useStyles();
  const [article, setArticle] = React.useState("");
  const [selectedArticle, setSelectedArticle] = React.useState({});
  const [articles, setArticles] = React.useState([]);
  const [isSaved, setIsSaved] = React.useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const handleChange = (event) => {
    setArticle(event.target.value);
    setIsSaved(false);
  };

  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
    };
    fetch(`${apiurl}/articles`, requestOptions)
      .then((res) => res.json())
      .then((data) => setArticles(data));
  }, []);

  const handleSave = () => {
    let dataToSave = {};
    Object.assign(dataToSave, { articlecontent: article });
    Object.assign(dataToSave, { articleID: selectedArticle.ID });
    //console.log(dataToSave);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, },
        body: JSON.stringify(dataToSave),
      };
      fetch(`${apiurl}/article`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
        });

      setIsSaved(true);
    });
  };

  const handleArticleChange = (newValue) => {
    fetch(`${apiurl}/article/${newValue.ID}`)
      .then((res) => res.json())
      .then((data) => {
        setArticle(data[0].ARTICLE);
        setSelectedArticle(newValue);
      });
  };

  return (
    <div style={{ background: "white", padding: "5px", margin: "-5px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: "auto",
          alignItems: "flex-end",
          alignContent: "",
        }}
      >
        <Autocomplete
          style={{ maxWidth: "700px", flexGrow: 1, flexShrink: 1 }}
          options={articles}
          getOptionLabel={(option) => option.TITLE}
          id="disable-clearable"
          disableClearable
          onChange={(event, newValue) => handleArticleChange(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Wybierz artykuł" margin="normal" />
          )}
        />
        <Button
          disabled={isSaved}
          onClick={() => handleSave()}
          style={{ height: "30px", marginBottom: "10px" }}
        >
          Zapisz
        </Button>
      </div>
      <div>
        <form
          style={{ display: "flex", flexDirection: "row" }}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <div style={{ width: "50%", height: "100%" }}>
            <TextField
              style={{ width: "95%", height: "100%" }}
              id="outlined-multiline-flexible"
              label="HTML"
              multiline
              rows={34}
              //rowsMax={4}
              value={article}
              onChange={handleChange}
              variant="outlined"
            />
          </div>
          <div style={{ width: "50%", height: "100%", marginTop: "8px" }}>
            <Paper
              style={{
                width: "95%",
                height: "680px",
                overflow: "auto",
                fontFamily: "roboto",
                padding: "0px 10px 0px 10px",
              }}
              id="outlined-multiline-flexible"
              label="Podgląd"
              variant="outlined"
            >
              {Parser(article, {
                replace: (domNode) => {
                  if (domNode.attribs && domNode.attribs.id === "infoicon") {
                    return <ImInfo style={{ fontSize: "36px", marginRight: "10px" }} />;
                  }
                  if (domNode.attribs && domNode.attribs.id === "video") {
                    return (
                      <div
                        style={{ fontSize: "36px", marginRight: "10px" }}
                      >
                        {domNode.attribs.video}
                        {domNode.attribs.image}
                      </div>
                    );
                  };
                },
              })}
            </Paper>
          </div>
        </form>
      </div>
    </div>
  );
}
