import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import TinyChart from "./TinyChart"
import RankingChart from "./RankingChart"
import OPKTable from "./OPKTable"
import ProfitTable from "./ProfitTable"
import { apiurl } from '../app/prefs.js';
import MonthSelect from "./MonthSelection";
import ToolTip from "@material-ui/core/Tooltip";
import CostByStagesTable from "./CostByStagesTable";

export default function Report023() {
  const [yardList, setYardList] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date("2020-01-01"));
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            activeYard && loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  const loadData = (opk, year, months) => {
    setReportDataLoaded(false);
    const currentMonths = months ? months : selectedMonths;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/otherwardsdata/${opk.opk_id}/${year}/${JSON.stringify(currentMonths)}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data && setReportData(data);
          //console.log(data);
          setReportDataLoaded(true);
        });
    });
  }

  function loadDataWithMonths(year, months) {
    activeYard && loadData(activeYard, year, months)
  }

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => (
          <TextField {...params} label="OPK" />
        )}
        onChange={(event, value) => {
          if (value) {
            setActiveYard(value);
            loadData(value, startDate.year)
          }
        }}
      />
    );
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    setYardListLoaded(false);
    const apiUrl = `${apiurl}/otherunitlist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (mounted) {
            fetchedData.DATA && setYardList(fetchedData.DATA);
            fetchedData.datelist && setDateList(fetchedData.datelist);
            (fetchedData.datelist && fetchedData.datelist.length !== 0) && setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1])
            setYardListLoaded(true);
          }
        });
    });
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            WYNIKI POZOSTAŁYCH OPK
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "300", color: "white" }}
          >
            (BU, WYK) w zł.
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div style={{ display: "flex", flexWrap: "wrap", position: "relative", width: "100%" }}>
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <MonthSelect setSelectedMonthsFunction={setSelectedMonths} loadDataFunction={loadDataWithMonths} selectedMonthsProperty={selectedMonths} startDateProperty={startDate} />
          <ToolTip title='Opis raportu'>
            <a edge='end' style={{ marginTop: '14px', marginLeft: '7px', marginRight: '7px', color: '#0b6471' }} target='_blank' href='/usermanual/opis_raportu_005'>
              <ImportContactsIcon />
            </a>
          </ToolTip>
        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {activeYard && reportData.yearlydata && reportData.yearlydata.profit ?
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >

          <div id="top chart area" style={{ display: "flex", flexWrap: "wrap", width: "100%", flexDirection: "row" }}>
            <div id="left chart area" style={{ display: "flex", flexWrap: "wrap", maxWidth: "840px", flexDirection: "column", flexShrink: 1 }}>
              <div id="tiny charts area" style={{ display: "flex", flexWrap: "wrap", width: "100%", flexDirection: "row", flexShrink: 1 }}>
                <TinyChart
                  title="WYNIK" data={reportData} datatype="profit"
                  actual={reportData.yearlydata.profit.toLocaleString("pl-PL", { maximumFractionDigits: 0, }) + " zł"}
                  budget={reportData.budgetdata.profit_BU && reportData.budgetdata.profit_BU.toLocaleString("pl-PL", { maximumFractionDigits: 0, }) + "%"}
                  color="#4E97A5"
                />
                <TinyChart
                  title="PRZYCHODY" data={reportData} datatype="income"
                  actual={reportData.yearlydata.income.toLocaleString("pl-PL", { maximumFractionDigits: 0, }) + " zł"}
                  budget={reportData.budgetdata.income_BU && reportData.budgetdata.income_BU.toLocaleString("pl-PL", { maximumFractionDigits: 0, }) + "%"}
                  color="#BFFBFF"
                />
                <TinyChart
                  title="KOSZTY" data={reportData} datatype="cost"
                  actual={reportData.yearlydata.cost.toLocaleString("pl-PL", { maximumFractionDigits: 0, }) + " zł"}
                  budget={reportData.budgetdata.cost_BU && reportData.budgetdata.cost_BU.toLocaleString("pl-PL", { maximumFractionDigits: 0, }) + "%"}
                  color="#0B6471"
                />
              </div>
              <div id="top 3 bottom 3 tables area" style={{ display: "flex", paddingRight: "10px", alignItems: "stretch", flexWrap: "wrap", width: "100%", flexDirection: "row", flexGrow: 1, flexShrink: 1 }}>
                <ProfitTable
                  title="RANKING KOSZTÓW WYNAGRODZEŃ WG GRUP PRACOWNICZYCH"
                  data={reportData.salaries_by_group}
                  dataType="cost"
                  columns={[
                    {
                      name: "Grupa pracownicza",
                      align: "left",
                      style: { color: "#0b6471", borderBottom: "1px solid" },
                      datadef: "group_name",
                      datalen: "",
                    },
                    {
                      name: "BU",
                      align: "right",
                      style: { color: "#0b6471", borderBottom: "5px double" },
                      datadef: "cost_bu",
                      datalen: 0,
                    },
                    {
                      name: "Δ %",
                      align: "right",
                      style: { color: "#0b6471", borderBottom: "1px solid" },
                      datadef: "cost_delta",
                      datalen: 2,
                    },
                    {
                      name: "Koszt wynagrodzeń",
                      align: "right",
                      style: { color: "#0b6471", borderBottom: "4px solid" },
                      datadef: "cost",
                      datalen: 0,
                    },
                  ]}
                />
              </div>
            </div>
            <div id="right chart area" style={{ borderColor: "2px red solid", display: "flex", flexGrow: 1, flexWrap: "wrap", width: "220px", flexDirection: "row" }}>
              <RankingChart title="RANKING OPK WG WYNIKU" color="#4E97A5" data={reportData.opkranking} opk_name={activeYard.opk_name} />
            </div>
          </div>
          <OPKTable data={reportData} />
          <CostByStagesTable
            title='DANE STATYSTYCZNE'
            dimUnexpanded='true'
            data={reportData.statistics || []}
            maximumFractionDigits = '0'
            columns={
              [
                { name: 'Wskaźnik', value: 'name', align: 'left', type: 'text', underline: '1px solid' },
                { name: <div><div style={{ fontWeight: "400" }}>Wartość</div></div>, value: 'value', align: 'right', type: 'text', underline: '5px double' },
                { name: <div><div style={{ fontWeight: "400" }}></div></div>, value: 'monthly', align: 'right', type: 'tinychart', underline: '5px double' },
              ]
            }
          />

        </div>
        : !activeYard && <Typography style={{ position: "absolute", top: "120%", left: "40%", color: "#0b6471", textJustify: "left" }} variant="h5">Wybierz komórkę</Typography>}
      {!reportDataLoaded && activeYard && <CircularProgress style={{ position: "absolute", height: "70px", width: "70px", top: reportData.yearlydata ? "50%" : "120%", left: "50%", color: "#0b6471", opacity: "0.6" }} />}
    </div>
  );
}