import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

import { ReactComponent as XlsxIcon } from './xlsx.svg';
import MUITooltip from "@material-ui/core/Tooltip";
import MonthSelect from "./MonthSelection";
import CostByStagesTable from "./CostByStagesTable";

import { apiurl } from '../app/prefs.js';


export default function Report036() {
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataLoaded, setTotalDataLoaded] = React.useState(false);
  const [totalDataComplete, setTotalDataComplete] = React.useState(false);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);


  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={dateList}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value.year.toLocaleString();
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            loadData(value.year, selectedMonths);
          }
        }}
      />
    );
  }


  function loadDataBasic(year, months) {
    loadData(year, months)
  }

  const loadData = (year, months) => {
    setTotalDataLoaded(false);
    setTotalDataComplete(false);
    const apiUrl = `${apiurl}/getopkstatistics/${year}/${JSON.stringify(months)}/json`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setTotalData(fetchedData);
          if (
            fetchedData.length !== 0
          ) setTotalDataComplete(true);
          setTotalDataLoaded(true);
        });
    });
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/salariesreport`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setDateList(fetchedData.YEARS);
          if (fetchedData.YEARS && fetchedData.YEARS.length !== 0) {
            setStartDate(fetchedData.YEARS[fetchedData.YEARS.length - 1]);

            loadData(fetchedData.YEARS[fetchedData.YEARS.length - 1].year, selectedMonths);
            setFiltersLoaded(true);
          }
        });
    });
  }

  const downloadFile = (downloadURL) => {

    getAccessTokenSilently().then((token) => {
      document.body.style.cursor = 'wait';
      const apiUrl = downloadURL
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Przychody i koszty.xlsx`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          document.body.style.cursor = 'auto';
        });
    });

  };

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    if (mounted) loadFilters();
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #0b6471)",
          display: "flex",
          height: "45px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Dane statystyczne
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {filtersLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <DateBox data={dateList} />
          <div style={{ marginRight: '5px' }}>
            <MonthSelect
              setSelectedMonthsFunction={setSelectedMonths}
              loadDataFunction={loadDataBasic}
              selectedMonthsProperty={selectedMonths}
              startDateProperty={startDate}
            />
          </div>

          {false && <MUITooltip title='Pobierz raport jako plik MS Excel'>
            <XlsxIcon
              style={{ marginTop: '15px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471' }}
              onClick={
                () => downloadFile(`${apiurl}/getopkstatistics/${startDate.year}/${JSON.stringify(selectedMonths)}/xlsx`)
              }
            />
          </MUITooltip>}
        </div>
      ) : (
        <div>Wczytywanie danych</div>
      )}
      <p style={{ height: "5px" }} />

      {totalDataComplete ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: totalDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="left chart area"
              style={{
                display: "flex",
                flexWrap: "wrap",
                //maxWidth: "840px",
                width: "100%",
                flexDirection: "column",
                flexShrink: 1,
              }}
            >

              <div
                id="top 3 bottom 3 tables area"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  alignItems: "stretch",
                  flexWrap: "wrap",
                  width: "100%",
                  flexDirection: "row",
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >

                <CostByStagesTable
                  title=' '
                  dimUnexpanded='true'
                  data={totalData}
                  columns={
                    [
                      { name: 'Indeks OPK', value: 'id', align: 'left', type: 'text', underline: '1px solid' },
                      { name: 'Nazwa OPK', value: 'name', align: 'left', type: 'text', underline: '1px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba godzin pracy</div>, value: 'hoursWorked', chartValue: 'hoursWorkedMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba porad</div>, value: 'numberofConsultations', chartValue: 'numberofConsultationsMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba hospitalizowanych</div>, value: 'numberofHospitalizations', chartValue: 'numberofHospitalizationsMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba wypisanych</div>, value: 'numberofDischarges', chartValue: 'numberofDischargesMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba zgonów</div>, value: 'numberofDeaths', chartValue: 'numberofDeathsMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba osobodni</div>, value: 'numberofPersonDays', chartValue: 'numberofPersonDaysMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Liczba łóżek</div>, value: 'numberofBeds', chartValue: 'numberofBedsMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Wykorzystanie łóżek w %</div>, value: 'bedUtilizationPercentage', chartValue: 'bedUtilizationPercentageMonthly', align: 'right', type: 'mixed', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Średni okres pobytu w dniach</div>, value: 'averageLengthofStayInDays', chartValue: 'averageLengthofStayInDaysMonthly', align: 'right', type: 'mixed', underline: '2px solid' },

                    ]
                  }
                />

              </div>
            </div>
          </div>
        </div>
      ) : (
        !totalData.data_by_opk && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left"
            }}
            variant="h5"
          >
            Wczytywanie danych
          </Typography>
        )
      )}
      {!totalDataLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: totalData[0] ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
