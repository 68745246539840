import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import SchoolIcon from "@material-ui/icons/School";
import { ImCalculator } from "react-icons/im";
import { GiHealthPotion } from "react-icons/gi";
import { RiHealthBookFill } from "react-icons/ri";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import { useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import "animate.css";
import "./articles.css";
import { apiurl } from '../app/prefs.js';

const CatMenuData = [
  {
    CAT: "Rachunek kosztów",
    DESC: "Controlling kosztów i przychodów świadczeniodawcy",
  },
  {
    CAT: "Rozporządzenie",
    DESC:
      "Rozporządzenie MZ w sprawie standardu rachunku kosztów u świadczeniodawców",
  },
  {
    CAT: "Wycena procedur medycznych",
    DESC:
      "Metody kalkulacji kosztów bezpośrednich i pośrednich procedur medycznych",
  },
];

const Cat2ICO = (props) => {
  let icon = <RiHealthBookFill style={{ width: "36px", height: "36px" }} />;
  switch (props.cat) {
    case "Rachunek kosztów":
      icon = <ImCalculator style={{ width: "32px", height: "32px" }} />;
      break;
    case "Rozporządzenie":
      icon = (
        <div style={{margin: "0",paddingLeft: "7px", width: "28px", height: "28px", fontSize: "28px", fontFamily: "roboto", fontWeight: "bold"}}>§</div>
      );
      break;
    case "Wycena procedur medycznych":
      icon = <GiHealthPotion style={{ width: "36px", height: "36px" }} />;
      break;
  }
  return icon;
};

let articles = [];
let categories = [];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    minHeight: "700px",
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
  gridListTile: {
    position: "relative",
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#d7e5e7",
    backgroundColor: "#95B0B6",
    color: "white",
    width: "200px",
    maxWidth: "200px",
    height: "300px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  pageSubtitle: {
    display: "flex",
    justifyContent: "flexStart",
    height: "75px",
    width: "100%",
    backgroundColor: "white",
    color: "#9099A2",
    borderRadius: "5px",
    paddingTop: "0px",
    paddingLeft: "0px",
    alignItems: "center",
  },
}));

export default function Articles(props) {
  const classes = useStyles();
  const history = useHistory();


  //if (props.target) console.log(props.target)

  const [appState, setAppState] = React.useState({
    loading: false,
  });

  const [filter, setFilter] = React.useState(props.target ? props.target : "default");

  const baza =
    filter === "default"
      ? articles.sort( (a,b) => a.KOLEJNOSC - b.KOLEJNOSC ).slice(0, 6)
      : articles.filter((element) => element.CAT === filter);

  React.useEffect(() => {
    setAppState({ loading: true });
    let apiUrl = `${apiurl}/categories`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((fetchedData) => {
        categories = fetchedData;
        let apiUrl = `${apiurl}/articles`;
        fetch(apiUrl)
          .then((res) => res.json())
          .then((fetchedData) => {
            articles = fetchedData;
            setAppState({ loading: false });
          });
      });
  }, []);

  const MenuBox = (props) => {
    return (
      <div
        style={{
          height: "90px",
          width: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        <div
          style={{
            color: "#FE8E3C",
            margin: "10px",
            maxWidth: "30px",
            minWidth: "30px",
          }}
        >
          <Cat2ICO cat={props.title} />
        </div>
        <div
          style={{ display: "block" }}
          onClick={() => {
            //console.log(props.title);
            history.push('/articles')
            setFilter(props.title);
          }}
        >
          <Link
            variant="body1"
            color="primary"
            style={{ fontWeight: "bold", cursor: "pointer" }}
          >
            {props.title}
          </Link>
          <Typography variant="body2" style={{ fontWeight: "lighter" }}>
            {props.desc}
          </Typography>
        </div>
      </div>
    );
  };

  return !appState.loading ? (
    <div className={classes.root}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          //background: "linear-gradient(to right, #6d7993, #c4c9d3)",

          display: "flex",
          height: "75px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <SchoolIcon
          fontSize="large"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
        <Typography variant="h4">Baza wiedzy</Typography>
      </div>

      <div className={classes.pageSubtitle}>
        <Typography variant="h6">Dostępne tematy</Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          //height: "100px",
          //maxHeight: "150px",
          width: "100%",
          color: "white",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        {CatMenuData.map((category) => (
          <MenuBox
            key={category.DESC}
            title={category.CAT}
            desc={category.DESC}
            icon={category.ICON}
          />
        ))}
      </div>

      <div className={classes.pageSubtitle}>
        <Typography variant="h6">
          {filter === "default" ? "Najnowsze publikacje" : filter}
        </Typography>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "" }}>
        {baza.map((tile) => (
          <Paper key={tile.ID} id="article" className={classes.gridListTile + " " + "animate__animated animate__fadeIn"}
          onClick={() => {
              tile.REF2 && history.push('/articles'+tile.REF2)
              }} >
            <div
              style={{
                borderTop: "0px",
                backgroundColor: "none",
                color: "#FE8E3C",
              }}
            >
              <Cat2ICO cat={tile.CAT} />{" "}
            </div>
            <Typography
              variant="subtitle1"
              style={{ lineHeight: "1.5", marginTop: "20px" }}
            >
              {tile.TITLE}
            </Typography>

            {!tile.REF2 && <div
              className={classes.gridListTile + " " + "readAvatar"}
              style={{
                position: "absolute",
                left: "-10px",
                top: "-10px",
                backgroundColor: "rgba(200,200,200,0.8",
              }}
            ></div>}
            {!tile.REF2 && <Avatar
              className="readAvatar"
              style={{ position: "absolute", right: "10px", bottom: "10px" }}
            >
              <EnhancedEncryptionIcon />
            </Avatar>}
          </Paper>
        ))}
      </div>
    </div>
  ) : (
    <div style={{ position: "relative" }}>
      <div
        style={{
          postition: "absolute",
          transform: "translate(50%, 250%)",
          color: "darkgray",
        }}
      >
        Wczytywanie...
      </div>
    </div>
  );
}
