import React from "react";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  BarChart,
  Cell,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
} from "recharts";


import IconButton from "@material-ui/core/IconButton";

const CustomTooltip = () => ({
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(100,100,100,0.8)",
            color: "white",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <p className="label">{`${label} : ${payload && payload[0] && payload[0].value.toLocaleString(
            "pl-PL",
            { maximumFractionDigits: 0 }
          )}`}</p>
        </div>
      );
    }
    return null;
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 600,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "100",
    //border: '1px solid #FE8E3C',
    boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
  },
}))(MTooltip);

const posorneg = (type, value) => {
  let color = "black";

  let posorneg = value < 0 ? false : true;
  if (type === "cost") posorneg = !posorneg;
  if (!posorneg) color = "#FE8E3C";
  if (type === 'text') color = 'black';
  return color;
};

const CostByStagesTable = (props) => {

  const maximumFractionDigits = props.maximumFractionDigits || 0;
  const minimumFractionDigits = props.minimumFractionDigits || 0;

  const tinyChartWidth = props.tinyChartWidth || 150;

  const CostByStagesMain = (props) => {

    const { dimUnexpanded } = props;

    const [openNodes, setOpenNodes] = React.useState([]);

    const handleOpenNodes = (id) => {
      const temp = [...openNodes];
      const idx = temp.indexOf(id);
      if (idx === -1) {
        if (dimUnexpanded === 'true') temp.length = 0;
        temp.push(id);
      } else {
        temp.splice(idx);
      }
      setOpenNodes(temp);
    };

    function RowKB(props) {
      const { dimUnexpanded } = props;

      function Row(props) {
        const { row } = props;

        return (
          <React.Fragment>
            {row && <TableRow
              key={props.rowkey}
              hover
              className={"animate__animated animate__fadeIn"}
            >
              <TableCell component="th" scope="row"
                style={{
                  paddingLeft: 30 + 15 * props.lvl,
                  borderBottom: "0px solid",
                }}
              >
                {row.children && row.children.length > 0 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{ color: "lightgray" }}
                    onClick={() => handleOpenNodes(props.rowkey)}
                  >
                    {openNodes.includes(props.rowkey) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                  </IconButton>
                )}
              </TableCell>
              {
                props.columns.map(
                  column =>
                    <TableCell component="th" scope="row" align={column.align}>
                      {(column.type === 'graph') ?
                        <div>
                          <svg
                            className="rankBar"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox={`${row.graphMin} 0 200 5`}
                            preserveAspectRatio="none"
                            version="1.1"
                          >
                            <rect
                              x={Math.min(0, row[column.value])}
                              width={Math.abs(row[column.value])}
                              height="5"
                              stroke="none"
                              stroke-width="1"
                              fill={row[column.value] > 0 ? "#0B6471" : "orange"}
                            />
                          </svg>
                        </div>
                        :
                        column.type === 'tinychart' ?
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={tinyChartWidth}
                            height={40}
                            data={row[column.value]}
                            isAnimationActive={false}
                          //data={Array.from({length: 12},(v,i) => {return {month: i+1, cost:100*i}})}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="m" hide={true} />
                            <Bar
                              isAnimationActive={false}
                              dataKey="cost"
                              fill="#0B6471"
                              barSize={tinyChartWidth / (1.5 * 12)}
                            >
                              {row[column.value].map((entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={entry.cost < 0 ? "#FE8E3C" : "#cee0e3"}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                          :
                          <Typography
                            variant="body2"
                            style={{ paddingLeft: 0 + 0 * props.lvl, color: posorneg(column.type, row[column.value]), fontSize: column.fontSize && column.fontSize }}
                          >
                            {(row[column.value] && row[column.value] != 0) ? row[column.value].toLocaleString("pl-PL", { maximumFractionDigits: maximumFractionDigits, }) : '-'}
                          </Typography>
                      }
                    </TableCell>
                )
              }
            </TableRow>
            }
            {openNodes.includes(props.rowkey) &&
              row.children
                .map((child, uid) => (
                  <Row rowkey={props.lvl + 1 + '_' + uid} row={child} lvl={props.lvl + 1} />
                ))}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <TableRow
            key={props.rowkey}
            //className="animate__animated animate__fadeIn"
            hover={true}
            style={{
              backgroundColor: openNodes.includes(props.rowkey) && '#f5f9f9',
              borderRadius: "5px",
              outline: "none",
              opacity: openNodes.length == 0 ? 1 : openNodes.includes(props.rowkey) ? 1 : .2
            }}
          >
            <TableCell component="th" scope="row" style={{ borderBottom: "0px solid", borderRadius: "5px 0px 0px 5px" }}>
              {props.data.children && props.data.children.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleOpenNodes(props.rowkey)}
                >
                  {openNodes.includes(props.rowkey) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
              )}
            </TableCell>

            {
              props.columns.map(
                column =>
                  <TableCell align={column.align}
                    style={{ fontWeight: (openNodes.includes(props.rowkey) || props.data.opk_id === 'Ogółem') && 500, color: posorneg(column.type, props.data[column.value]) }}>
                    {
                      column.type === 'graph' ?
                        <div>
                          <svg
                            className="rankBar"
                            style={{ opacity: openNodes.length === 0 ? 1 : 0 }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox={`${props.data.graphMin} 0 200 5`}
                            preserveAspectRatio="none"
                            version="1.1"
                          >
                            <rect
                              x={Math.min(0, props.data[column.value])}
                              width={Math.abs(props.data[column.value])}
                              height="5"
                              stroke="none"
                              stroke-width="1"
                              fill={props.data[column.value] > 0 ? "#0B6471" : "orange"}
                            />
                          </svg>
                        </div>
                        :
                        column.type === 'tinychart' ?
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={tinyChartWidth}
                            height={40}
                            data={props.data[column.value]}
                            isAnimationActive={false}
                          //data={Array.from({length: 12},(v,i) => {return {month: i+1, cost:100*i}})}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="m" hide={true} />
                            <Bar
                              isAnimationActive={false}
                              dataKey="cost"
                              fill="#0B6471"
                              barSize={tinyChartWidth / (1.5 * 12)}
                            >
                              {props.data[column.value].map((entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={entry.cost < 0 ? "#FE8E3C" : "#cee0e3"}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                          :
                          column.type === 'mixed' ?
                            <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '3px', border: '1px solid #ececec', backgroundColor: '#f9f9f9', padding: '5px'}}>

                              {(props.data[column.value] && props.data[column.value] != 0) ?
                                props.data[column.value].toLocaleString("pl-PL", { maximumFractionDigits: maximumFractionDigits }) : '-'}

                              <div>
                                <BarChart
                                  style={{ padding: "0px", margin: "0px" }}
                                  width={100}
                                  height={30}
                                  data={props.data[column.chartValue]}
                                  isAnimationActive={false}
                                //data={Array.from({length: 12},(v,i) => {return {month: i+1, cost:100*i}})}
                                >
                                  <Tooltip content={<CustomTooltip />} />
                                  <XAxis dataKey="m" hide={true} />
                                  <Bar
                                    isAnimationActive={false}
                                    dataKey="cost"
                                    fill="#0B6471"
                                    barSize={100 / (1.5 * 12)}
                                  >
                                    {props.data[column.chartValue] && props.data[column.chartValue].map((entry, index) => (
                                      <Cell
                                        cursor="pointer"
                                        fill={entry.cost < 0 ? "#FE8E3C" : "#cee0e3"}
                                      />
                                    ))}
                                  </Bar>
                                </BarChart>
                              </div>
                            </div>
                            :
                            (props.data[column.value] && props.data[column.value] != 0) ?
                              props.data[column.value].toLocaleString("pl-PL", {
                                maximumFractionDigits: maximumFractionDigits,
                              }) : '-'
                    }
                  </TableCell>
              )
            }
          </TableRow>

          {openNodes.includes(props.rowkey) &&
            props.data.children
              .map((row, uid) => <Row rowkey={'0_' + uid} row={row} columns={props.columns} lvl={0} />)}
        </React.Fragment>
      );
    }

    return (
      <Paper
        style={{
          minWidth: "410px",
          width: "100%",
          padding: "5px",
          marginBottom: "10px",
          marginRight: "0px",
        }}
      >

        <Typography
          style={{
            color: "#0b6471",
            fontSize: "16px",
            fontWeight: "100",
            borderBottom: "0px solid",
            paddingLeft: "10px",
            paddingTop: "5px",
          }}
        >
          {props.title || 'ETAPY ALOKACJI'}
        </Typography>

        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead style={{ border: "none" }}>
              <TableRow>
                <TableCell
                  style={{
                    borderBottom: "0px solid",
                    width: "2px",
                  }}
                />

                {props.columns.map(
                  column =>
                    <TableCell
                      style={{
                        color: "#0b6471",
                        fontSize: "16px",
                        fontWeight: "100",
                        borderBottom: column.underline,
                      }}
                      align={column.align}
                    >
                      {column.name}
                    </TableCell>
                )}

              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((account, uid) => (
                <RowKB data={account} rowkey={uid} columns={props.columns} dimUnexpanded={dimUnexpanded} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  return <CostByStagesMain data={props.data} title={props.title} columns={props.columns} dimUnexpanded={props.dimUnexpanded} />

};

export default CostByStagesTable;
