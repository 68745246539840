import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { BsPeopleFill } from "react-icons/bs";
import { usePopupState } from "material-ui-popup-state/hooks";
import "animate.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FcInvite } from "react-icons/fc";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { useConfirm } from "material-ui-confirm";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiurl } from '../app/prefs.js';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const WelcomeInfo = (props) => {
  const { userEmail, resetLink } = props;

  return (
    <Dialog style={{ zIndex: 1000 }} open={props.active} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Zmiana hasła użytkownika</DialogTitle>
      <DialogContent>
        <Typography>
          Prześlij poniższy link na adres email użytkownika
        </Typography>
        <TextField
          defaultValue={userEmail}
          inputProps={{
            readOnly: true,
            disabled: false,
          }}
          autoFocus
          margin="dense"
          id="name"
          label="Email"
          type="text"
          fullWidth
        />
        <TextField
          defaultValue={resetLink}
          inputProps={{
            readOnly: true,
            disabled: false,
          }}
          onChange={() => { }}
          multiline
          autoFocus
          margin="dense"
          id="name"
          label="Link"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => navigator.clipboard.writeText(resetLink)}>
          Kopiuj link
        </Button>
        <Button onClick={() => props.setShowResetToken(false)} color="primary">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UserEdit = (props) => {
  const { user, setUser, saveUser } = props;
  const [emailError, setEmailError] = React.useState(false);

  const handleChange = (property, newValue) => {
    //console.log(newValue);
    setUser((prevState) => ({
      ...prevState,
      [property]: newValue,
    }));

    property === "email" &&
      ValidateEmail(newValue, props.users) &&
      setEmailError(false);
    property === "email" &&
      !ValidateEmail(newValue, props.users) &&
      setEmailError(true);

    if (property === "role")
      setUser((prevState) => ({ ...prevState, priviledges: [] }));
  };
  const handlePriviledgeChange = (event) => {
    setUser((prevState) => ({
      ...prevState,
      priviledges: event.target.value,
    }));
  };

  const error =
    [
      user.email,
      user.name,
      user.role,
      user.orgunit,
      (user.role === 30 && user.priviledges.length > 0) || (user.role !== 30)
    ].filter((v) => v).length !== 5;

  function ValidateEmail(mail, users) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)
      && !users.find(user => user.email === mail)
    ) {
      return true;
    }
    return false;
  }

  return (
    <Dialog style={{ zIndex: 1000 }} open={props.active} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Dane użytkownika</DialogTitle>
      <DialogContent>
        <TextField
          value={user.name}
          autoComplete="off"
          onChange={(e) => handleChange("name", e.currentTarget.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Imię i nazwisko"
          type="text"
          fullWidth
        />
        <TextField
          value={user.email}
          error={emailError}
          disabled={props.newOrExisting === 'existing'}
          autoComplete="off"
          onChange={(e) => handleChange("email", e.currentTarget.value)}
          margin="dense"
          id="email"
          label="E-mail"
          type="email"
          fullWidth
        />
        <TextField
          value={user.orgunit}
          autoComplete="off"
          onChange={(e) => handleChange("orgunit", e.currentTarget.value)}
          margin="dense"
          id="orgunit"
          label="Komórka organizacyjna"
          type="text"
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="role-select-label">Rola</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={user.role}
            onChange={(e) => handleChange("role", e.target.value)}
          >
            <MenuItem value={10}>Administrator Procedur</MenuItem>
            <MenuItem value={20}>Dyrektor</MenuItem>
            <MenuItem value={30}>Kierownik</MenuItem>
            <MenuItem value={15}>Controlling i Księgowość</MenuItem>
            <MenuItem value={40}>Opis Procedur</MenuItem>
          </Select>
        </FormControl>

        <FormControl disabled={user.role !== 30} fullWidth>
          <InputLabel id="wards-mutiple-checkbox-label">OPK</InputLabel>
          <Select
            labelId="wards-mutiple-checkbox-label"
            id="wards-mutiple-checkbox"
            multiple
            dense
            value={user.priviledges}
            onChange={handlePriviledgeChange}
            input={<Input />}
            //renderValue={ prv => {console.log(prv + ' - ' + props.wards.find(ward => (ward.opk_id == prv))); return prv}}
            renderValue={prv =>
              prv.map((i, id) =>
                props.wards.find(ward => ward.opk_id === i).opk_name + (prv.length !== (id + 1) ? '; ' : ''))}
          /*
          renderValue={selected => 
            selected && props.wards.find(ward => (ward.opk_id == selected)).opk_name
          
          }
          */
          //renderValue = {selected => selected}
          //renderValue={(selected) => {props.wards.find( ward => ward.id === selected).join(", ")}}
          //renderValue={(selected) => {props.wards.find( ward => ward.id === selected).join(", ")}}
          >
            {props.wards.map((name) => (
              <MenuItem dense key={name.opk_id} value={name.opk_id}>
                <Checkbox
                  size="small"
                  checked={
                    user.priviledges && user.priviledges.indexOf(name.opk_id) > -1
                  }
                />
                <ListItemText primary={name.opk_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setActive(false)} color="primary">
          Anuluj
        </Button>
        <Button disabled={error || emailError} onClick={() => saveUser()} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserTable = (props) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nr</TableCell>
          <TableCell>Imię i nazwisko</TableCell>
          <TableCell>Adres e-mail</TableCell>
          <TableCell>Komórka org</TableCell>
          <TableCell>Rola</TableCell>
          <TableCell>Uprawnienia</TableCell>
          <TableCell>Opcje</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data && Array.isArray(props.data) && props.data.sort((a, b) => (a.role) - (b.role)).map((row, id) => (
          <TableRow key={id} hover>
            <TableCell>{id + 1}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.orgunit}</TableCell>
            <TableCell>{row.role === 0 ? 'Administrator' :
              row.role === 10 ? 'Administrator procedur' :
                row.role === 20 ? 'Dyrektor' :
                  row.role === 30 ? 'Kierownik' :
                    row.role === 15 ? 'Controlling i Księgowość' :
                      row.role === 40 ? 'Opis Procedur' : ''
            }</TableCell>
            <TableCell>
              {row.priviledges &&
                row.priviledges.map((i, id) =>
                  props.wards.find(ward => ward.opk_id === i)
                    ? (props.wards.find(ward => ward.opk_id === i).opk_name + (row.priviledges.length !== (id + 1) ? ', ' : '')
                    ) : '')}

            </TableCell>
            <TableCell>
              <div style={{ display: row.role === 0 ? "none" : "flex", flexDirection: "row" }}>
                <ToolTip title="Edytuj" placement="top-start">
                  <IconButton
                    disabled={row.role === 0}
                    onClick={() => {
                      props.setActiveUser(props.data[id]);
                      props.setNewOrExisting('existing');
                      props.setEditActive(true);
                    }}
                    size="small"
                    style={{ marginRight: "0px", color: row.role === 0 ? 'lightgray' : "#0b6471" }}
                  >
                    <MdEdit />
                  </IconButton>
                </ToolTip>
                <ToolTip title="Zaproś" placement="top-start">
                  <IconButton
                    onClick={
                      () => {
                        props.setActiveUser(row);
                        props.getTicket(row.email);
                      }
                    }
                    size="small"
                    style={{ color: row.role === 0 ? 'lightgray' : "#FE8E3C" }}
                    disabled={props.data.length === 1 || row.role === 0}
                  >
                    <FcInvite />
                  </IconButton>
                </ToolTip>
                <ToolTip title="Usuń" placement="top-start">
                  <IconButton
                    onClick={() => {
                      //props.setActiveUser(row);
                      props.deleteUser(row.email);
                    }}
                    size="small"
                    style={{ color: row.role === 0 ? 'lightgray' : "#FE8E3C" }}
                    disabled={props.data.length === 1 || row.role === 0}
                  >
                    <MdDelete />
                  </IconButton>
                </ToolTip>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

function AppUserTable() {
  const [users, setUsers] = React.useState(null);
  const [wards, setWards] = React.useState(null);
  const [serviceStatus, setServiceStatus] = React.useState(null);
  const [userLoading, setUserLoading] = React.useState(false);
  const [editActive, setEditActive] = React.useState(false);
  const [newOrExisting, setNewOrExisting] = React.useState('');
  const [showResetToken, setShowResetToken] = React.useState(false);
  const [userTicket, setUserTicket] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const confirm = useConfirm();
  const newUser = {
    name: "",
    email: "",
    orgunit: "",
    role: "",
    priviledges: [],
  };
  const [activeUser, setActiveUser] = React.useState(newUser);

  const deleteUser = (useremail) => {

    confirm({
      description:
        "Usunięcie konta użytkownika jest nieodwracalne. Kontynuować?",
      title: "Wymagane potwierdzenie",
      cancellationText: "Anuluj",
      confirmationText: "Tak, usuń",
      dialogProps: { style: { zIndex: 10000 } },
    })
      .then(() => {
        postDataToDb('delete', useremail);
        /*
        setUsers((prevstate) => [
          ...prevstate.filter((item) => item.email !== useremail),
        ]);
      */

      })
      .catch(reason => console.log(reason));
  }

  const saveUser = () => {
    /*     let changedUser = users.find((elem) => elem.email === activeUser.email);
        if (changedUser)
          setUsers(users.filter((item) => item.email !== changedUser.email));
        setUsers((prevstate) => [...prevstate, activeUser]); */

    let command = '';
    if (newOrExisting === 'new') command = 'create';
    if (newOrExisting === 'existing') command = 'update';
    postDataToDb(command);

    setEditActive(false);
  };

  const postDataToDb = (command, useremail) => {
    setUserLoading(true);
    //console.log(command);
    let dataToSend = activeUser;

    const userData = users.find(user => user.email === useremail);
    if (userData) dataToSend = userData;
    if (useremail) dataToSend.email = useremail;

    Object.assign(dataToSend, { command: command });

    const apiUrl = `${apiurl}/useredit`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dataToSend),
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          //console.log(fetchedData);
          //setUsers(fetchedData);
          getUsersFromDb();
          setUserLoading(false);
        });
    });
  };

  const getTicket = (useremail) => {
    setUserLoading(true);
    //console.log(command);
    //let dataToSend = activeUser;
    let dataToSend = { email: '' }

    if (useremail) dataToSend.email = useremail;

    const apiUrl = `${apiurl}/userticket`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dataToSend),
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          //console.log(fetchedData);
          //setUsers(fetchedData);
          fetchedData.ticket ? setUserTicket(fetchedData.ticket) : setUserTicket('Błąd pobierania linku, spróbuj ponownie lub skontaktuj się ze wsparciem COplus');
          setShowResetToken(true);
          setUserLoading(false);
        });
    });
  };


  const getUsersFromDb = () => {
    setUserLoading(true);
    const apiUrl = `${apiurl}/userlist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setUsers(fetchedData);
          setUserLoading(false);
        });
    });
  };

  const getServiceStatus = () => {
    setUserLoading(true);
    const apiUrl = `${apiurl}/getservicestatus`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setServiceStatus(fetchedData);
          setUserLoading(false);
        });
    });
  };

  const getWardsFromDb = () => {
    setUserLoading(true);
    const apiUrl = `${apiurl}/opklist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setWards(fetchedData);
          //console.log(fetchedData)
          setUserLoading(false);
        });
    });
  }

  React.useEffect(() => {
    getUsersFromDb();
    getWardsFromDb();
    getServiceStatus();
    return function cleanup() {
    };
  }, []);

  return (
    (users) && (wards) ?
      <div style={{
        padding: "10px", transition: "opacity 0.5s", opacity: !userLoading ? "1" : "0.2",
      }}>
        {users && Array.isArray(users) && <Box style={{ padding: "5px", minHeight: "800px" }}>
          <UserTable
            data={users}
            deleteUser={deleteUser}
            setActiveUser={setActiveUser}
            setEditActive={setEditActive}
            setNewOrExisting={setNewOrExisting}
            setShowResetToken={setShowResetToken}
            getTicket={getTicket}
            wards={wards}
          />
          <UserEdit
            active={editActive}
            setActive={setEditActive}
            user={activeUser}
            setUser={setActiveUser}
            users={users}
            deleteUser={deleteUser}
            saveUser={saveUser}
            wards={wards}
            newOrExisting={newOrExisting}
          />
          <WelcomeInfo
            active={showResetToken}
            userEmail={activeUser.email}
            resetLink={userTicket}
            setShowResetToken={setShowResetToken}
          />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography>
              Dostępna liczba użytkowników: <strong>{(serviceStatus && serviceStatus.userCount && serviceStatus.userCount > 0) ? serviceStatus.userCount - users.length : 0}</strong>
            </Typography>
            <Button
              size="small"
              color="primary"
              disabled={(serviceStatus && serviceStatus.userCount && serviceStatus.userCount > 0) ? (serviceStatus.userCount <= users.length) : true}
              variant="contained"
              onClick={() => {
                setActiveUser(newUser);
                setNewOrExisting('new');
                setEditActive(true);
              }}
              style={{
                marginRight: "10px",
                marginBottom: "10px",
                marginTop: "10px",
                justifySelf: "flex-end",
              }}
            >
              Dodaj użytkownika
            </Button>
          </div>
        </Box>}
      </div>
      :
      <CircularProgress
        style={{
          position: "absolute",
          height: "70px",
          width: "70px",
          top: users ? "50%" : "120%",
          left: "50%",
          color: "#0b6471",
          opacity: "0.6",
        }}
      />
  );
}

export default function Users() {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <BsPeopleFill
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Użytkownicy
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <Paper
        className={classes.root}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <AppUserTable />
      </Paper>
    </div>
  );
}
