import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Collapse, Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import Tooltip from "@material-ui/core/Tooltip";


import "animate.css";

import {
  procedureActivities
} from "./data";

import "./style.css";

const getActivityById = (id) => procedureActivities.find(activity => activity.id === id);



export default function EditTable(props) {
  const isWideEnough = useMediaQuery("(min-width:900px)");
  const [data, setData] = React.useState(props.resources);
  const headers = props.headers;
  const [filter, setFilter] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [assignments, setAssignments] = React.useState(props.assignments);
  const callback = props.callback;
  const returnData = props.returnData;

  const filterColumns = Array.isArray(props.filterColumn) ? props.filterColumn : props.filterColumn.split();

  const calc = (a) => {
    let res = 0;
    try {
      res = eval(a.replace(',', '.'));
    } catch (err) {
      res = 0;
    }
    return res;
  };

  //console.log(props.assignments)

  let columns = [];
  headers.map((column) => {
    let align = column.type === "number" ? "right" : column.type === 'checkbox' ? 'center' : "left";
    let edit = column.edit;
    let name = column.name;
    let format = column.format;
    let type = column.type;
    let boundColumns = column.boundColumns;
    let disabledon = column.disabledon;
    let col = { name, align, edit, format, boundColumns, disabledon, type };
    columns.push(col);
    return null;
  });

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    const lookedUpValues = event.target.value.split(' ');
    if (lookedUpValues)
      setData(
        props.resources.filter(
          (row) => {
            const aggrValue = filterColumns.reduce((total, value) => total + ' ' + ((row && row[value]) ? row[value] : ''), '');
            return (row &&
              aggrValue &&
              lookedUpValues.every(
                valueToFind =>
                  aggrValue
                    .toLowerCase()
                    .includes(valueToFind.toLowerCase())
              )
            ) ||
              filterColumns.some(column => row[column] === props.filterExcludedValue)
          }
        )
      );
    else setData(props.resources);
  };

  const handleChangeExpanded = () => {
    setExpanded(!expanded);
  };

  const hrChange = (event, res, col, entryNo) => {

    let data1 = [...assignments];
    const coldef = columns.find(colmn => colmn.name === col);
    let dat = data1.find((element) => (entryNo != null) ? (element.entryNo == entryNo && element[props.datakey] === res) : element[props.datakey] === res);

    /*
        if (coldef.type && coldef.type === 'checkbox') {
          dat[col] = !dat[col]
        } else {
          const onlyNums = event.target.value.replace(/[^0-9.,/*x+-]/g, "");
          dat[col] = onlyNums;
          if (coldef.boundColumns) { dat[coldef.boundColumns.name] = 1 / onlyNums };
        }
    */

    switch (coldef.type) {
      case 'checkbox':
        dat[col] = !dat[col]
        break;
      case 'combobox':
        dat[col] = event.id;
        break;
      default:
        const onlyNums = event.target.value.replace(/[^0-9.,/*x+-]/g, "");
        dat[col] = onlyNums;
        if (coldef.boundColumns) { dat[coldef.boundColumns.name] = 1 / onlyNums };
    }

    setAssignments(data1);
  };

  const handleDelete = (event, id, entryNo) => {
    const selectedIndex = assignments.findIndex(
      (element) => (entryNo != null) ? (element[props.datakey] === id && element.entryNo == entryNo) : element[props.datakey] === id
    );
    let newSelected = [];

    if (selectedIndex !== -1) {
      newSelected = assignments.filter((item) => (entryNo != null) ? (item[props.datakey] !== id || item.entryNo != entryNo) : item[props.datakey] !== id);
    }

    setAssignments(newSelected);
  };

  const relatedData = (id, entryNo) =>
    assignments.find((element) => (entryNo != null) ? (element[props.datakey] === id && element.entryNo == entryNo) : (element[props.datakey] === id));

  const isSelected = (id) =>
    (assignments.length !== 0) && (assignments.findIndex((element) => element[props.datakey] === id) !== -1);

  const handleCheck = (event, id) => {
    const selectedIndex = assignments.findIndex(
      (element) => element[props.datakey] === id
    );
    let newSelected = [];

    let editablecols = {};
    columns.map(
      (col) =>
        col.edit === "true" &&
        Object.assign(editablecols, {
          [col.name]: col.type === 'checkbox' ? true
            : col.type === 'combobox' ? 3
              : 0
        })
    );
    Object.assign(editablecols, { [props.datakey]: id });
    Object.assign(editablecols, { entryNo: 0 });

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(assignments, editablecols);
    }

    if (selectedIndex !== -1) {
      newSelected = assignments.filter((item) => item[props.datakey] !== id);
    }

    setAssignments(newSelected);
  };

  const handleAdditionalAssignment = (event, id) => {
    console.log(id);
    let lastEntryNo = 0;
    assignments.map(
      element => { if (element[props.datakey] === id && element.entryNo && (element.entryNo > lastEntryNo)) lastEntryNo = element.entryNo }
    );
    lastEntryNo = lastEntryNo + 1;
    let newSelected = [];

    let editablecols = {};
    columns.map(
      (col) =>
        col.edit === "true" &&
        Object.assign(editablecols, {
          [col.name]: col.type === 'checkbox' ? true
            : col.type === 'combobox' ? 1
              : 0
        })
    );
    Object.assign(editablecols, { [props.datakey]: id });
    Object.assign(editablecols, { entryNo: lastEntryNo });

    console.log(lastEntryNo);
    console.log(editablecols);

    newSelected = newSelected.concat(assignments, editablecols);

    setAssignments(newSelected);
  };

  const handleClose = () => {
    returnData(assignments);
    callback();
  };

  return (
    <div>
      <Paper
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          padding: "5px",
          paddingLeft: "10px",
        }}
      >
        {props.mode !== "preview" && (
          <Button
            onClick={(e) => handleClose()}
            startIcon={<ExitToAppIcon />}
            size="small"
          >
            Zamknij
          </Button>
        )}
        <Input
          disabled={!data}
          value={filter}
          type="search"
          autoComplete="off"
          onChange={handleFilterChange}
          style={{
            marginLeft: "10px",
            fontSize: "14px",
            verticalAlign: "middle",
            width: '400px',
          }}
          size="small"
          placeholder="Czego szukasz?"
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start" size="small">
              <SearchIcon style={{ fontSize: "16px" }} />
            </InputAdornment>
          }
          endAdornment={
            <IconButton position="start" size="small">
              <Tooltip title='Wpisz szukany tekst. Możesz wpisać kilka szukanych fraz, oddzielając je spacją.' >
                <HelpTwoToneIcon style={{ fontSize: "16px" }} />
              </Tooltip>
            </IconButton>
          }
        />
      </Paper>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow key="1">
              {props.mode !== "preview" && (
                <TableCell
                  padding="checkbox"
                  style={{ padding: "0 0 0 4" }}
                ></TableCell>
              )}
              {columns.filter(clmn => props.multiRowEdit ? (clmn.edit !== 'true' || clmn.type === 'checkbox') : true).map(
                (column) =>
                  (isWideEnough ||
                    column.name === props.filterColumn ||
                    column.edit === "true") && (
                    <TableCell
                      align={column.align}
                      style={{
                        color: column.edit === "true" && "#0b6471",
                        paddingRight: "5px",
                      }}
                    >
                      {column.name}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.slice(0, expanded ? 200 : 20).map((res, id) => (
              <>
                <TableRow
                  hover
                  key={id}
                  className="tabledatarow"
                  role="checkbox"
                  style={{
                    backgroundColor: isSelected(res[props.dictkey]) && "#edf5f6",
                  }}
                >

                  {props.mode !== "preview" && <TableCell padding="checkbox" style={{ padding: "0 0 0 4" }}>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={isSelected(res[props.dictkey])}
                      onClick={(event) => handleCheck(event, res[props.dictkey])}
                    />
                  </TableCell>}
                  {columns.filter(clmn => props.multiRowEdit ? (clmn.edit !== 'true' || clmn.type === 'checkbox') : true).map(
                    (col) =>
                      (isWideEnough ||
                        col.name === props.filterColumn ||
                        col.edit === "true") && (
                        <TableCell
                          align={col.align}
                          padding="none"
                          style={{ paddingRight: "5px", paddingLeft: "15px" }}
                        >
                          {col.edit === "true" &&
                            !(col.disabledon &&
                              res[col.disabledon.field] === col.disabledon.value) ? (
                            col.type === 'checkbox' ?
                              isSelected(res[props.dictkey]) && <Checkbox
                                style={{ color: 'black' }}
                                checkedIcon={<CheckIcon />}
                                icon={<RemoveIcon style={{ color: 'lightgray' }} />}
                                checked={isSelected(res[props.dictkey]) && relatedData(res[props.dictkey])[col.name]}
                                disabled={!isSelected(res[props.dictkey])}
                                onClick={(event) => hrChange(event, res[props.dictkey], col.name)}
                              />
                              :
                              col.type === 'combobox' ?
                                isSelected(res[props.dictkey]) &&
                                <Autocomplete
                                  value={getActivityById(relatedData(res[props.dictkey])[col.name])}
                                  onChange={(event, value) =>
                                    hrChange(value, res[props.dictkey], col.name)
                                  }
                                  options={procedureActivities}
                                  getOptionLabel={(option) => option.caption}
                                  getOptionSelected={(option) => option.id == 3}
                                  id="combobox"
                                  disableClearable
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      multiline
                                      InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontSize: '11px' } }}
                                      margin="dense"
                                    />
                                  )}
                                />

                                /*
                                  <div>
                            {isSelected(res[props.dictkey])
                              ? relatedData(res[props.dictkey])[col.name]
                              : ""}
                          </div>*/
                                :
                                <Input
                                  disableUnderline
                                  onFocus={(event) => {
                                    event.target.select();
                                  }}
                                  readOnly={!isSelected(res[props.dictkey])}

                                  onChange={(e) =>
                                    hrChange(e, res[props.dictkey], col.name)
                                  }
                                  value={
                                    isSelected(res[props.dictkey])
                                      ? relatedData(res[props.dictkey])[col.name]
                                      : ""
                                  }
                                  inputProps={{
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      fontSize: "14px",
                                      fontWeight: "1000",
                                      color:
                                        isSelected(res[props.dictkey]) &&
                                          relatedData(res[props.dictkey])[col.name] == 0
                                          ? "#fe8e3c"
                                          : "#0b6471",
                                    },
                                  }}
                                ></Input>
                          ) : (

                            <div>
                              {col.name === "Koszt całkowity"
                                ? isSelected(res[props.dictkey]) &&
                                "".concat(
                                  eval(props.formula2).toFixed(2),
                                  " zł"
                                )
                                : "".concat(
                                  res[col.name] ? col.format === 'currency' ? res[col.name].toFixed(2) : res[col.name] : '',
                                  col.format === "currency" ? " zł" : ""
                                )}
                            </div>
                          )}
                        </TableCell>
                      )
                  )}
                </TableRow>
                {props.multiRowEdit &&
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                      <Collapse in={isSelected(res[props.dictkey])} timeout="auto" unmountOnExit>
                        <Box marginBottom={1} marginLeft={5}>
                          <TableContainer component={Paper}>
                            <Table size="small" aria-label="collapsible table">
                              <TableHead>
                                <TableRow key="1">
                                  {columns.filter(clmn => clmn.edit === 'true' && clmn.type !== 'checkbox').map(
                                    (column) =>
                                      (isWideEnough ||
                                        column.name === props.filterColumn ||
                                        column.edit === "true") && (
                                        <TableCell
                                          align={column.align}
                                          style={{
                                            color: '#909090',
                                            paddingRight: "5px",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            borderBottom: 'none',
                                            paddingTop: '0px',
                                            paddingBottom: '0px'
                                          }}
                                        >
                                          {column.name}
                                        </TableCell>
                                      )
                                  )}
                                  {props.readonly !== true && <TableCell style={{ maxWidth: "20px" }}></TableCell>}
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {assignments && assignments.filter(activity => activity[props.datakey] === res[props.dictkey]).map((res_activity, id) => (
                                  <>
                                    <TableRow
                                      hover
                                      key={id}
                                      className="tabledatarow"
                                      role="checkbox"
                                      style={{
                                        backgroundColor: isSelected(res_activity[props.datakey]) && "white",
                                      }}
                                    >

                                      {columns.filter(clmn => clmn.edit === 'true' && clmn.type !== 'checkbox').map(
                                        (col) =>
                                          (isWideEnough ||
                                            col.name === props.filterColumn ||
                                            col.edit === "true") && (
                                            <TableCell
                                              align={col.align}
                                              padding="none"
                                              style={{ paddingRight: "5px", paddingLeft: "15px", paddingTop: '0px', paddingBottom: '0px' }}
                                            >
                                              {col.edit === "true" &&
                                                !(col.disabledon &&
                                                  res[col.disabledon.field] === col.disabledon.value) ? (
                                                col.type === 'checkbox' ?
                                                  isSelected(res_activity[props.datakey]) && <Checkbox
                                                    style={{ color: 'black' }}
                                                    checkedIcon={<CheckIcon />}
                                                    icon={<RemoveIcon style={{ color: 'lightgray' }} />}
                                                    checked={isSelected(res_activity[props.datakey]) && relatedData(res_activity[props.datakey], res_activity.entryNo)[col.name]}
                                                    disabled={!isSelected(res_activity[props.datakey])}
                                                    onClick={(event) => hrChange(event, res_activity[props.datakey], col.name, res_activity.entryNo)}
                                                  />
                                                  :

                                                  col.type === 'combobox' ?
                                                    isSelected(res_activity[props.datakey]) &&
                                                    <Autocomplete
                                                      value={getActivityById(relatedData(res_activity[props.datakey], res_activity.entryNo)[col.name])}

                                                      onChange={(event, value) =>
                                                        hrChange(value, res_activity[props.datakey], col.name, res_activity.entryNo)
                                                      }
                                                      options={procedureActivities}
                                                      getOptionLabel={(option) => option.caption}
                                                      getOptionSelected={(option) => option.id == 3}
                                                      id="combobox"
                                                      disableClearable
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          multiline
                                                          InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontSize: '13px', marginTop: '0px', marginBottom: '0px' } }}
                                                          margin="dense"
                                                        />
                                                      )}
                                                    />

                                                    :
                                                    <Input
                                                      disableUnderline
                                                      onFocus={(event) => {
                                                        event.target.select();
                                                      }}
                                                      readOnly={!isSelected(res_activity[props.datakey])}

                                                      onChange={(e) =>
                                                        hrChange(e, res_activity[props.datakey], col.name, res_activity.entryNo)
                                                      }
                                                      value={
                                                        isSelected(res_activity[props.datakey])
                                                          ? relatedData(res_activity[props.datakey], res_activity.entryNo)[col.name]
                                                          : ""
                                                      }
                                                      inputProps={{
                                                        min: 0,
                                                        style: {
                                                          textAlign: "right",
                                                          fontSize: "14px",
                                                          fontWeight: "1000",
                                                          color:
                                                            isSelected(res_activity[props.datakey]) &&
                                                              relatedData(res_activity[props.datakey], res_activity.entryNo)[col.name] == 0
                                                              ? "#fe8e3c"
                                                              : "#0b6471",
                                                        },
                                                      }}
                                                    ></Input>
                                              ) : (

                                                <div>
                                                  {col.name === "Koszt całkowity"
                                                    ? isSelected(res_activity[props.datakey]) &&
                                                    "".concat(
                                                      eval(props.formula2).toFixed(2),
                                                      " zł"
                                                    )
                                                    : col.type !== 'combobox' && "".concat(
                                                      res_activity[col.name] ? col.format === 'currency' ? res_activity[col.name].toFixed(2) : res_activity[col.name] : '',
                                                      col.format === "currency" ? " zł" : ""
                                                    )}
                                                </div>
                                              )}
                                            </TableCell>
                                          )
                                      )}
                                      {props.readonly !== true &&
                                        <TableCell style={{ maxWidth: "30px" }}>
                                          <div className="tabledataoptions">
                                            <IconButton
                                              size="small"
                                              style={{ width: "21px", height: "21px" }}
                                            >
                                              <DeleteIcon
                                                size="small"
                                                style={{ color: "#fe8e3c" }}
                                                onClick={(event) =>
                                                  handleDelete(event, res_activity[props.datakey], res_activity.entryNo)
                                                }
                                              />
                                            </IconButton>
                                          </div>
                                        </TableCell>
                                      }
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>

                              {(res['Jednostka miary'] && res['Jednostka miary'] === 'godzina') &&
                                <Button style={{
                                  width: '100%',
                                  height: '26px',
                                  borderRadius: '5px 5px 5px 5px',
                                  backgroundColor: 'rgb(235, 245, 245)',
                                  margin: '5px',
                                }}
                                  onClick={event => handleAdditionalAssignment(event, res['Id grupy'])}>
                                  Dodaj czynność
                                </Button>
                              }

                            </Table>
                          </TableContainer>

                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                }
              </>
            ))}
            {data && data.length > 20 && (
              <TableRow hover>
                <TableCell colSpan="7">
                  <Link onClick={handleChangeExpanded} className="MUILink">
                    {expanded ? "Zwiń" : "Rozwiń>>>"}
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
