import React, { Suspense } from "react";
import ReactPlayer from "react-player/file";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { StarRounded } from "@material-ui/icons";
//const ReactPlayer = React.lazy(() => import("react-player/file"));

export default function Article007() {
  let a = 1;
  const matchesWidth = useMediaQuery("(min-width:600px)");
  return (
    //<Suspense fallback={<div>Wczytywanie...</div>}>
    <div class="article">
      <br />
      <br />
      <p>
        <strong>Cel pilotażu</strong>
      </p>

      <p>
        Celem pilotażu jest przygotowanie danych do implementacji nowego
        standardu rachunku kosztów oraz poznanie możliwości raportowych jakie on
        daje.
      </p>
      <p>
        <strong>Korzyści dla podmiotu:</strong>
      </p>
      <ol>
        {" "}
        <li>
          Poznanie wytycznych rozporządzenia MZ w sprawie standardu rachunku
          kosztów.
        </li>
        <li>
          Weryfikacja dostępności danych potrzebnych do wdrożenia standardu
          rachunku kosztów.
        </li>
        <li>
          Rozpoczęcie prac nad pozyskaniem brakujących, a wymaganych przez
          rozporządzenie danych.
        </li>
        <li>
          Dostęp do aplikacji CO+, w tym do modułu raportowego pozwalającego na
          poznanie możliwości raportowych oferowanych przez nowy rachunek
          kosztów – podmioty biorące udział w pilotażu otrzymują do końca
          kwietnia 2021 roku dostęp do raportów CO+, bazujących na
          przygotowanych danych.
        </li>
      </ol>

      <p>
        <strong>Dostęp do aplikacji CO+ moduł controllingowy</strong>
      </p>
      <p>
        Uczestnicy pilotażu w trakcie jego trwania otrzymają dostęp do aplikacji
        CO+, która składa się z modułu kosztów OPK, modułu wyceny procedur,
        modułu wyceny pacjenta (wykracza on poza wytyczne rozporządzenia) oraz
        modułu raportowego.
      </p>

      <div
        style={{
          borderRadius: "5px",
          backgroundColor: "rgb(240,240,240)",
          borderBottom: "5px solid darkgray",
          padding: "10px",
          marginBottom: "18px",
        }}
      >
        <p>
          <strong>Koszt udziału w pilotażu</strong>
        </p>
        <p>
          Udział w pilotażu jest bezpłatny i nie wiąże się z żadnym
          zobowiązaniem zakupu dostępu do aplikacji CO+.
        </p>
      </div>

      <div className="stage">Etapy pilotażu</div>
      <p>
        Pilotaż podzielono na etapy tak, aby świadczeniodawca miał czas na
        przygotowanie danych. W trakcie realizacji etapów żadne dane nie muszą
        być importowane do aplikacji, może to nastąpić dopiero w ostatnim
        etapie. Importowane dane nie noszą znamion danych wrażliwych, ponieważ
        ich zakres uniemożliwia identyfikację pacjenta - jego imienia i nazwiska
        lub innych danych osobowych.
      </p>
      <p>
        Ważne! CO+ nie przetwarza danych w formie umożliwiającej identyfikację
        pacjenta. Posługiwanie się numerem księgi głównej i księgi oddziałowej
        spełnia zasadę pseudonimizacji i uniemożliwia CO+ powiązanie danych z
        konkretną osobą. (Zgodnie z treścią wystąpienia pokontrolnego NIK
        (P/19/063 – Wdrożenie przez podmioty lecznicze regulacji dotyczących
        ochrony danych osobowych, delegatura w Lublinie, LLU.410.003.01.2019)).
      </p>
      <p>
        Informacja o księdze głównej i oddziałowej przygotowywana jest tylko
        przez te podmioty, które chcą mieć dostęp do analizy rentowności.
        Analizowane rozporządzenie prezentuje wytyczne dotyczące ewidencji i
        alokacji kosztów, nie mniej jednak dodanie informacji o przychodach
        zwiększa liczbę możliwych do wykonania analiz i raportów. Podmiot
        uczestniczący w pilotażu sam decyduje czy przygotowywany przez niego
        zakres danych zawierał będzie dane o przychodach.
      </p>

      <p>
        W trakcie pilotażu podmiot uzupełnia dane we wzorcowym pliku xls co
        pozwoli na:
        <ol>
          <li>rozliczenie kosztów OPK pomocniczych (530 i 535),</li>
          <li>rozliczenie kosztów OPK proceduralnych (507),</li>
          <li>rozliczenie kosztów zarządu (550),</li>
          <li>wykonanie analizy rentowności OPK.</li>
        </ol>
      </p>

      <div className="stage">ETAP 1 Słownik kont 4</div>
      <p>
        Etap polega na wypełnieniu arkusza 1 zawierającego słownik
        przekształcający obecne konta układu 4 na zestaw kont wymagany przez
        rozporządzenie. Ćwiczenie to można potraktować jako przygotowanie do
        wdrożenia nowego planu kont 4 od stycznia 2021r.
      </p>
      <p>
        Dwie pierwsze kolumny to obecnie stosowany zespół kont 4. Trzecia i
        czwarta kolumna to konta 4 zgodne z rozporządzeniem. Poniższa tabela
        zawiera przykład uzupełnienia arkusza.
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">NR obecne konto 4</p>
            </td>
            <td>
              <p align="center">Nazwa obecne konto 4</p>
            </td>
            <td>
              <p align="center">NR konto 4 ROZP</p>
            </td>
            <td>
              <p align="center">Nazwa konto 4 ROZP</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p>401-01</p>
            </td>
            <td valign="bottom">
              <p>Energia elektryczna</p>
            </td>
            <td valign="bottom">
              <p>401-03-01-01</p>
            </td>
            <td valign="bottom">
              <p>Energia elektryczna</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        Obejrzyj film jak uzupełnić dane, skorzystaj ze{" "}
        <strong>słownika zespołu kont 4</strong> dostępnego w bazie wiedzy na
        stronie coplus.pl (Baza wiedzy &gt; Słowniki &gt; Zespół kont 4) a
        następnie wypełnij arkusz 1.
      </p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/1_pilotaz_konta_4.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/1_pilotaz_konta_4.mp4"
        />
      </p>
      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginRight: "10px" }}
          width="35"
          height="35"
          id="Obraz 9"
          src="/home/images/a11/lupa.png"
        />
        <p style={{ paddingBottom: "0px", color: "#0b6471" }}>
          <a
            style={{ color: "#0b6471" }}
            href="#slownik"
          >{`Masz więcej pytań dotyczących arkusza? Lista pytań i odpowiedzi >>`}</a>
        </p>
      </div>
      <br />
      <div className="stage">ETAP 2 Słownik kont 5</div>
      <p>
        Rozporządzenie nie nakłada na podmiot obowiązku zmiany zespołu kont 5.
        Zdobyte w tym etapie doświadczenie będzie można potem wykorzystać w
        trakcie wypełniania załącznik nr 4 do rozporządzenia (każdy podmiot
        będzie miał taki obowiązek).
      </p>
      <p>
        Dwie pierwsze kolumny to obecnie stosowane konta zespołu 5. Trzecia
        kolumna to numer syntetyczny konta 5 zgodny z rozporządzeniem (ROZP).
        Czwarta i piąta kolumna to kod resortowy zgodny z układem kodów
        charakteryzujących specjalność komórki organizacyjnej, o których mowa w
        załączniku nr 2 do rozporządzenia Ministra Zdrowia z dnia 17 maja 2012
        r. w sprawie systemu resortowych kodów identyfikacyjnych oraz
        szczegółowego sposobu ich nadawania. Poniższa tabela zawiera przykład
        uzupełnienia arkusza.
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">NR obecne konto 5</p>
            </td>
            <td>
              <p align="center">Nazwa obecne konto 5</p>
            </td>
            <td>
              <p align="center">Syntetyka ROZP (500, 501, 503, 504,…)</p>
            </td>
            <td>
              <p align="center">NR konto 5 ROZP</p>
            </td>
            <td>
              <p align="center">Nazwa konto 5 ROZP</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">50002</p>
            </td>
            <td valign="bottom">
              <p>Oddział chorób wewnętrznych</p>
            </td>
            <td valign="bottom">
              <p align="right">504</p>
            </td>
            <td valign="bottom">
              <p align="right">4000</p>
            </td>
            <td valign="bottom">
              <p>Oddział chorób wewnętrznych</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">51002</p>
            </td>
            <td valign="bottom">
              <p>Poradnia chirurgiczna</p>
            </td>
            <td valign="bottom">
              <p align="right">501</p>
            </td>
            <td valign="bottom">
              <p align="right">1500</p>
            </td>
            <td valign="bottom">
              <p>Poradnia chirurgii ogólnej</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        Obejrzyj film jak uzupełnić dane, skorzystaj ze{" "}
        <strong>słownika zespołu kont 5</strong> dostępnego w bazie wiedzy na
        stronie coplus.pl (Baza wiedzy &gt; Słowniki &gt; Zespół kont 5) a
        następnie wypełnij arkusz 2.
      </p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/2_pilotaz_konta_5.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/2_pilotaz_konta_5.mp4"
        />
      </p>
      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginRight: "10px" }}
          width="35"
          height="35"
          id="Obraz 9"
          src="/home/images/a11/lupa.png"
        />
        <p style={{ paddingBottom: "0px", color: "#0b6471" }}>
          <a
            style={{ color: "#0b6471" }}
            href="#slownik"
          >{`Masz więcej pytań dotyczących arkusza? Lista pytań i odpowiedzi >>`}</a>
        </p>
      </div>
      <br />
      <div className="stage">ETAP 3 Koszty</div>
      <p>
        Wypełniony arkusz zawierać będzie{" "}
        <strong>
          {" "}
          dane kosztowe przed rozliczeniem działalności pomocniczej,
          proceduralnej i zarządu (!)
        </strong>{" "}
        w podziale na miesiące. Dwie pierwsze kolumny to rok i miesiąc. Trzecia
        i czwarta kolumna zawierają informacje o numerze i nazwie OPK. Piąta i
        szósta to numer i nazwa konta 4. Ostatnia kolumna to kwota kosztu.
        Poniższa tabela zawiera przykład uzupełnienia arkusza.
      </p>
      <p>
        Uwaga: należy upewnić się, że suma podanych kosztów jest równa z kwotami
        z systemu FK w danym miesiącu.
      </p>
      <p>
        Dane kosztowe powinny być w podziale na miesiące (nie narastająco).
        Można podać je za dowolną liczbę miesięcy większą od 1.
      </p>

      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">Rok</p>
            </td>
            <td>
              <p align="center">Miesiąc</p>
            </td>
            <td>
              <p align="center">Nr OPK (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nazwa OPK</p>
            </td>
            <td>
              <p align="center">Nr konta 4</p>
            </td>
            <td>
              <p align="center">Nazwa konta 4</p>
            </td>
            <td>
              <p align="center">Kwota kosztu</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">2020</p>
            </td>
            <td valign="bottom">
              <p align="right">1</p>
            </td>
            <td valign="bottom">
              <p align="right">504-4000</p>
            </td>
            <td valign="bottom">
              <p>Oddział chorób wewnętrznych</p>
            </td>
            <td valign="bottom">
              <p>401-01-01-14</p>
            </td>
            <td valign="bottom">
              <p>Artykuły żywnościowe</p>
            </td>
            <td valign="bottom">
              <p align="right">9801</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 3.</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/3_pilotaz_koszty.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/3_pilotaz_koszty.mp4"
        />
      </p>
      <br />
      <div className="stage">
        ETAP 4 Alokowanie kosztów komórek pomocniczych medycznych i
        niemedycznych (OPK Pomocnicze)
      </div>
      <p>
        Wypełniony arkusz zawierać będzie dane pomocne przy alokacji kosztów
        komórek pomocniczych medycznych i niemedycznych. Dwie pierwsze kolumny
        to rok i miesiąc. Trzecia i czwarta kolumna zawierają informacje o
        numerze OPK pomocniczego i klucza podziałowego rozliczającego jego
        koszty. Piąta kolumna to numer OPK (docelowego), do którego zostaną
        doliczone koszty OPK pomocniczego. Ostatnia kolumna to wartość klucza.
        Poniższa tabela zawiera przykład uzupełnienia arkusza.
      </p>
      <p>
        <strong>Uwaga</strong>: alokując koszty komórek działalności pomocniczej
        medycznej i niemedycznej zależy stosować się do zaleceń z załącznika nr
        7 STANDARDOWE KLUCZE PODZIAŁOWE SŁUŻĄCE ROZLICZANIU KOSZTÓW DZIAŁALNOŚCI
        POMOCNICZEJ do rozporządzenia. Rozporządzenie daje również możliwość
        wykorzystania dotychczas stosowanych kluczy.
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">Rok</p>
            </td>
            <td>
              <p align="center">Miesiąc</p>
            </td>
            <td>
              <p align="center">NR OPK pomocniczego (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nazwa klucza</p>
            </td>
            <td>
              <p align="center">NR OPK (docelowego)(5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Wartość klucza</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">2020</p>
            </td>
            <td valign="bottom">
              <p align="right">1</p>
            </td>
            <td valign="bottom">
              <p align="right">535-9809</p>
            </td>
            <td valign="bottom">
              <p>osobodzień żywienia</p>
            </td>
            <td valign="bottom">
              <p align="right">504-4000</p>
            </td>
            <td valign="bottom">
              <p align="right">978</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 4.</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/4_pilotaz_pomocnicze.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/4_pilotaz_pomocnicze.mp4"
        />
      </p>
      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginRight: "10px" }}
          width="35"
          height="35"
          id="Obraz 9"
          src="/home/images/a11/lupa.png"
        />
        <p style={{ paddingBottom: "0px", color: "#0b6471" }}>
          <a
            style={{ color: "#0b6471" }}
            href="#slownik"
          >{`Masz więcej pytań dotyczących arkusza? Lista pytań i odpowiedzi >>`}</a>
        </p>
      </div>
      <br />
      <div className="stage">
        ETAP 5 Alokowanie kosztów komórek proceduralnych (OPK proceduralne)
      </div>
      <p>
        Wypełniony arkusz zawierać będzie dane pomocne przy alokacji kosztów
        komórek proceduralnych. Koszty tych OPK należy rozliczać zgodnie z
        wartością kosztów wytworzenia procedur medycznych (zgodnie z
        załącznikiem 8 STANDARDOWE KLUCZE PODZIAŁOWE SŁUŻĄCE ROZLICZANIU KOSZTÓW
        DZIAŁALNOŚCI PODSTAWOWEJ, W TYM OPK PROCEDURALNYCH do rozporządzenia).
      </p>
      <p>
        Dwie pierwsze kolumny to rok i miesiąc. Cztery kolejne zawierają
        informacje o OPK proceduralnym i zrealizowanych procedurach wraz z
        wykonaną ilością. Ostatnia kolumna to OPK, który zlecił wykonanie
        procedury. Poniższa tabela zawiera przykład uzupełnienia arkusza.
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">rok</p>
            </td>
            <td>
              <p align="center">miesiąc</p>
            </td>
            <td>
              <p align="center">OPK Wykonujące (OPK Proceduralne) (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nr procedury (nr ICD-9)</p>
            </td>
            <td>
              <p align="center">Nazwa procedury</p>
            </td>
            <td>
              <p align="center">Liczba procedur</p>
            </td>
            <td>
              <p align="center">OPK Zlecające (5XX-XXXX)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">2020</p>
            </td>
            <td>
              <p align="right">1</p>
            </td>
            <td>
              <p align="right">507-7220</p>
            </td>
            <td>
              <p>87.030</p>
            </td>
            <td>
              <p>TK głowy bez wzmocnienia kontrastowego</p>
            </td>
            <td>
              <p align="right">20</p>
            </td>
            <td>
              <p align="right">504-4000</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        W arkuszu znajdują się dwie dodatkowe kolumny. Są do wypełnienia przez
        te podmioty, które planują dodać dane przychodowe i mieć możliwość
        analizy rentowności komórek oraz JGP.
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">Nr KG</p>
            </td>
            <td>
              <p align="center">Nr KS Oddziałowej</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom"></td>
            <td valign="bottom"></td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 5.</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/5_pilotaz_proceduralne.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/5_pilotaz_proceduralne.mp4"
        />
      </p>
      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginRight: "10px" }}
          width="35"
          height="35"
          id="Obraz 9"
          src="/home/images/a11/lupa.png"
        />
        <p style={{ paddingBottom: "0px", color: "#0b6471" }}>
          <a
            style={{ color: "#0b6471" }}
            href="#slownik"
          >{`Masz więcej pytań dotyczących arkusza? Lista pytań i odpowiedzi >>`}</a>
        </p>
      </div>
      <br />
      <div className="stage">
        ETAP 6 Koszt standardowy i czas trwania procedury
      </div>
      <p>
        Arkusz zawiera dane potrzebne do rozliczenia kosztów OPK proceduralnych.
        Trzy pierwsze kolumny to numer OPK wykonującego procedury, numer i nazwa
        procedury. Czwarta i piąta kolumna to koszt wytworzenia procedur oraz
        jej standardowy czas trwania. Poniższa tabela zawiera przykład
        uzupełnienia arkusza.
      </p>
      <p>
        Uwaga: <strong> Koszt wytworzenia</strong>, zgodnie z rozporządzeniem,
        jest sumą kosztów zasobów materiałowych oraz osobowych realizujących
        procedurę medyczną.
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">OPK Wykonujące (OPK Proceduralne)(5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nr procedury (nr ICD-9)</p>
            </td>
            <td>
              <p align="center">Nazwa procedury</p>
            </td>
            <td>
              <p align="center">Koszt wytworzenia</p>
            </td>
            <td>
              <p align="center">Standardowy czas trwania (w h)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">507-7220</p>
            </td>
            <td>
              <p>87.030</p>
            </td>
            <td>
              <p>TK głowy bez wzmocnienia kontrastowego</p>
            </td>
            <td>
              <p align="right">60</p>
            </td>
            <td>
              <p align="right">0,5</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 6.</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/6_pilotaz_procedury.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/6_pilotaz_procedury.mp4"
        />
      </p>
      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginRight: "10px" }}
          width="35"
          height="35"
          id="Obraz 9"
          src="/home/images/a11/lupa.png"
        />
        <p style={{ paddingBottom: "0px", color: "#0b6471" }}>
          <a
            style={{ color: "#0b6471" }}
            href="#slownik"
          >{`Masz więcej pytań dotyczących arkusza? Lista pytań i odpowiedzi >>`}</a>
        </p>
      </div>
      <br />
      <div className="stage">ETAP 7 Liczba osobodni</div>
      <p>
        Arkusz zawiera dwie tabele. Tabela druga jest dla tych podmiotów, które
        chcą mieć dostęp do informacji o rentowności JGP.
      </p>
      <p>
        <strong>TABELA 1 </strong>
      </p>
      <p>
        Arkusz zawiera dane potrzebne do wyliczenia kosztów osobodni. Dwie
        pierwsze kolumny to rok i miesiąc. Trzecia i czwarta to numer OPK dla
        którego mierzone są osobodni oraz liczba osobodni.
      </p>
      <p>
        Dodatkowa kolumna oznaczona kolorem zielonym jest dla podmiotów, które
        planują dodać dane przychodowe i mieć możliwość analizy rentowności
        komórek.
      </p>
      <p>Poniższa tabela zawiera przykład uzupełnienia arkusza.</p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">rok</p>
            </td>
            <td>
              <p align="center">miesiąc</p>
            </td>
            <td>
              <p align="center">NR OPK (pacjenta)(5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Liczba osobodni</p>
            </td>
            <td>
              <p align="center">Przychód OPK</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">2020</p>
            </td>
            <td valign="bottom">
              <p align="right">1</p>
            </td>
            <td valign="bottom">
              <p align="right">504-4220</p>
            </td>
            <td valign="bottom">
              <p align="right">1152</p>
            </td>
            <td valign="bottom"></td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 7.</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/7_pilotaz_osobodni.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/7_pilotaz_osobodni.mp4"
        />
      </p>
      <br />
      <p>
        <strong>TABELA 2</strong>
      </p>
      <p>
        {" "}
        W arkuszu pliku xls dodano tabelę z danymi i możliwymi wariantami wraz z
        komentarzem. W arkuszu dodano konkretne przykłady pobytów wraz z
        wariantami. Tworząc analogiczny raport w podmiocie należy zapoznać się z
        tabelą 2 i jej danymi.
      </p>
      <p>Tabela zawiera następujące dane</p>
      <ul>
        <li>Rok i miesiąc – okres, dla których są dane.</li>
        <li>Nr KG – numer księgi głównej pacjenta.</li>

        <li>
          Data przyjęcia do szpitala oraz Data wypisu ze szpitala - daty
          rozpoczęcia i zakończenia pobytu w szpitalu. Daty te wymagają
          uszczegółowienia w przypadku, kiedy pacjent był hospitalizowany na
          więcej niż jednym oddziale.
        </li>
        <li>
          Nr KS Oddziałowej i NR OPK pobytu pacjenta – numer księgi oddziałowej
          oraz nazwa oddziału hospitalizacji pacjenta.
        </li>
        <li>
          Data przyjęcia na oddział i Data wypisu z oddziału – daty rozpoczęcia
          i zakończenia hospitalizacji pacjenta. Daty te są takie same jak „Data
          przyjęcia do szpitala oraz Data wypisu ze szpitala” tylko w przypadku
          kiedy pacjent przebywał na jednym oddziale.
        </li>

        <li>JGP – numer JGP z jakim został wypisany pacjent.</li>
        <li>Przychód – przychód za pacjenta.</li>
        <li>
          KOMENTARZ – komentarz zawiera opisy 5 wariantów wypełnienia tabeli, w
          zależności czy pacjent został wypisany i czy był hospitalizowany na
          jednym lub większej liczbie oddziałów.
        </li>
      </ul>
      <div className="stage">ETAP 8 Pacjenci SOR</div>
      <p>
        Arkusz zawiera dane dotyczące liczby pacjentów, którym udzielono
        świadczeń w SOR. W załączniku nr 2 do rozporządzenia czytamy „
        <em>
          W przypadku świadczeniodawcy, który zawarł umowę o udzielanie
          świadczeń opieki zdrowotnej ze środków publicznych w szpitalnym
          oddziale ratunkowym (SOR) lub w izbie przyjęć w ramach ryczałtu
          dobowego, wydziela się OPK, które mają na celu oddzielenie kosztów
          zaopatrzenia świadczeniobiorców w SOR lub w izbie przyjęć rozliczanych
          w ramach ryczałtu dobowego od kosztów zaopatrzenia świadczeniobiorców
          w SOR lub izbie przyjęć rozliczanych (po przekazaniu na oddziały
          szpitalne) w ramach Jednorodnych Grup Pacjentów (JGP), osobodni,
          punktów w ramach Terapeutycznej Skali Interwencji Medycznych (TISS)
          lub innych świadczeń
        </em>
        .”
      </p>
      <p>
        Dwie pierwsze kolumny to rok i miesiąc. Trzecia kolumna to numer OPK
        SOR. Dwie kolejne kolumny to liczby pacjentów SOR w podziale na
        pacjentów, którym udzielono świadczeń tylko w SOR oraz pacjentów, którzy
        po zaopatrzeni w SOR zostali przyjęci na inny oddział.
      </p>
      <p>
        Dodatkowa kolumna oznaczona kolorem zielonym jest dla podmiotów, które
        planują dodać dane przychodowe i mieć możliwość analizy rentowności
        komórek.
      </p>
      <p>Poniższa tabela zawiera przykład uzupełnienia arkusza.</p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">rok</p>
            </td>
            <td>
              <p align="center">miesiąc</p>
            </td>
            <td>
              <p align="center">Nr OPK SOR (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Liczba pacjentów TYLKO SOR</p>
            </td>
            <td>
              <p align="center">
                Liczba pacjentów przyjętych na oddziały po poradzie w SOR
              </p>
            </td>
            <td>
              <p align="center">Przychód (dobowy ryczałt)</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">2020</p>
            </td>
            <td valign="bottom">
              <p align="right">1</p>
            </td>
            <td valign="bottom">
              <p align="right">503-4902</p>
            </td>
            <td valign="bottom">
              <p align="right">700</p>
            </td>
            <td valign="bottom">
              <p align="right">230</p>
            </td>
            <td valign="bottom">
              <p align="right">600000</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 8</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/8_pilotaz_pacjenci_sor.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/8_pilotaz_pacjenci_sor.mp4"
        />
      </p>
      <br />
      <div className="stage">ETAP 9 Pacjenci poradnie</div>
      <p>
        Arkusz zawiera dane dotyczące liczby pacjentów wg poradni. Dwie pierwsze
        kolumny to rok i miesiąc. Trzecia kolumna to numer OPK poradni. Czwarta
        kolumna to liczba pacjentów.
      </p>
      <p>
        Dodatkowa kolumna oznaczona kolorem zielonym jest dla podmiotów, które
        planują dodać dane przychodowe i mieć możliwość analizy rentowności
        komórek.
      </p>
      <p>Poniższa tabela zawiera przykład uzupełnienia arkusza.</p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">rok</p>
            </td>
            <td>
              <p align="center">miesiąc</p>
            </td>
            <td>
              <p align="center">Nr OPK poradni (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Liczba pacjentów</p>
            </td>
            <td>
              <p align="center">Przychód</p>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <p align="right">2020</p>
            </td>
            <td valign="bottom">
              <p align="right">1</p>
            </td>
            <td valign="bottom">
              <p align="right">501-1100</p>
            </td>
            <td valign="bottom">
              <p align="right">160</p>
            </td>
            <td valign="bottom">
              <p align="right">50000</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>Obejrzyj film jak uzupełnić dane, a następnie wypełnij arkusz 9.</p>
      <p align="center">
        <ReactPlayer
          width={!matchesWidth ? "300px" : "600px"}
          height={!matchesWidth ? "170px" : "340px"}
          light="/video/9_pilotaz_pacjenci_poradni.png"
          controls
          playsinline
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          url="/video/9_pilotaz_pacjenci_poradni.mp4"
        />
      </p>
      <br />

      <div id="slownik" className="stage">
        Lista pytań i odpowiedzi
      </div>
      <div>
        <p>
          <strong>Arkusz „2 Słownik kont 5”</strong>
        </p>

        <ul>
          <li>
            Wypełniając tabelę należy zweryfikować czy obecnie posiadana
            struktura OPK spełnia wytyczne rozporządzenia w zakresie
            wyodrębniania OPK. Rozporządzenie nie narzuca zmian całego planu
            kont 5. Podmiot powinien jednak zapoznać się z wytycznymi, których
            wykorzystanie ma na celu lepsze odwzorowanie ponoszonych kosztów, a
            w konsekwencji lepszą podstawę do taryfikacji świadczeń.
          </li>
        </ul>
      </div>

      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
            <a
              href="/articles/zasady_wyodrebniania_opk"
              target="_blank"
            >
              <strong>Załącznik 2 – zasady wyodrębniania OPK</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_sor_i_izby_przyjec"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla SOR i Izby przyjęć</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_bloku_operacyjnego"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla centralnego bloku operacyjnego</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dzialanosci_pomocniczej"
              target="_blank"
            >
              <strong>Zasady dotyczące wyodrębniania OPK działalności pomocniczej</strong>
            </a><br/>
            <a
              href="/articles/zasady_wydzielania_opk_dzialalnosci_podstawowej"
              target="_blank"
            >
              <strong>Zasada wydzielania z OPK działalności podstawowej (500-506) OPK
        proceduralnych (507)</strong>
            </a><br/>
            <a
              href="/articles/jakie_opk_wydzielic_dla_kliniki"
              target="_blank"
            >
              <strong>Jakie wydzielić OPK w przypadku komórki będącej kliniką?</strong>
            </a><br/>
            <a
              href="/articles/jakie_opk_wydzielic_dla_wielu_lokalizacji"
              target="_blank"
            >
              <strong>Jakie wydzielić OPK w przypadku realizacji świadczeń w różnych
        lokalizacjach?</strong>
            </a><br/>
            <a
              href="/articles/jakie_opk_wydzielic_dla_oddzialow_wieloprofilowych"
              target="_blank"
            >
              <strong>Jakie OPK wydzielić dla oddziałów łączonych i wieloprofilowych?</strong>
            </a><br/>
            <a
              href="/articles/rozliczanie_opk_proceduralnego_pracownia_tomografii_komputerowej"
              target="_blank"
            >
              <strong>Rozliczanie kosztów OPK proceduralnego – przykład OPK Pracownia
        tomografii komputerowej</strong>
            </a><br/>
            <a
              href="/articles/rozliczanie_opk_dzialalnosci_pomocniczej_opk_kuchnia_i_pralnia"
              target="_blank"
            >
              <strong>  Rozliczanie kosztów OPK działalności pomocniczej – przykłady OPK Kuchnia
        i OPK Pralnia</strong>
            </a><br/>
          </div>
        </div>
      </div>

    
 <br/>
      <p>
        {" "}
        <strong>Arkusz „4. Rozl OPK Pomocniczych”</strong>
      </p>

      <ul>
        <li>
          Jak uzupełnić arkusz, w przypadku gdy jeden OPK np. OPK Pralnia
          świadczy usługi na rzecz podmiotu oraz realizuje usługi komercyjne?
        </li>
      </ul>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td nowrap="">
              <p align="center">Rok</p>
            </td>
            <td>
              <p align="center">Miesiąc</p>
            </td>
            <td>
              <p align="center">NR OPK pomocniczego (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nazwa klucza</p>
            </td>
            <td>
              <p align="center">NR OPK (docelowego) (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Wartość klucza</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>nr OPK Pralnia</p>
            </td>
            <td>
              <p>liczba kg</p>
            </td>
            <td>
              <p>nr OPK Oddziału X</p>
            </td>
            <td>
              <p align="right">450</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>nr OPK Pralnia</p>
            </td>
            <td>
              <p>liczba kg</p>
            </td>
            <td>
              <p>nr OPK Poradni X</p>
            </td>
            <td>
              <p align="right">70</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>nr OPK Pralnia</p>
            </td>
            <td>
              <p>liczba kg</p>
            </td>
            <td>
              <p>nr OPK Pralnia - usługi komercyjne (na koncie 545)</p>
            </td>
            <td>
              <p align="right">100</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong> </strong>
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">Rok</p>
            </td>
            <td>
              <p align="center">Miesiąc</p>
            </td>
            <td>
              <p align="center">NR OPK pomocniczego (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nazwa klucza</p>
            </td>
            <td>
              <p align="center">NR OPK (docelowego) (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Wartość klucza</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>535-9858</p>
            </td>
            <td>
              <p>liczba kg</p>
            </td>
            <td>
              <p>504-4100</p>
            </td>
            <td>
              <p align="right">450</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>535-9858</p>
            </td>
            <td>
              <p>liczba kg</p>
            </td>
            <td>
              <p>501-1100</p>
            </td>
            <td>
              <p align="right">70</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>535-9858</p>
            </td>
            <td>
              <p>liczba kg</p>
            </td>
            <td>
              <p>545-9858</p>
            </td>
            <td>
              <p align="right">100</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong> </strong>
      </p>
      <br />

      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
            
            <a
              href="/articles/zasady_wyodrebniania_opk_dzialanosci_pomocniczej"
              target="_blank"
            >
              <strong>Zasady dotyczące wyodrębniania OPK działalności pomocniczej</strong>
            </a><br/>
            
            <a
              href="/articles/rozliczanie_opk_dzialalnosci_pomocniczej_opk_kuchnia_i_pralnia"
              target="_blank"
            >
              <strong>  Rozliczanie kosztów OPK działalności pomocniczej – przykłady OPK Kuchnia
        i OPK Pralnia</strong>
            </a><br/>
          </div>
        </div>
      </div>

    
      <br />
      <ul>
        <li>
          Jak uzupełnić arkusz w przypadku OPK SOR (koszty rejestracji i
          segregacji) (530)
        </li>
      </ul>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">Rok</p>
            </td>
            <td>
              <p align="center">Miesiąc</p>
            </td>
            <td>
              <p align="center">NR OPK pomocniczego (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nazwa klucza</p>
            </td>
            <td>
              <p align="center">NR OPK (docelowego) (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Wartość klucza</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>
                <strong>530-4902</strong>- Szpitalny oddział ratunkowy
                (rejestracja i segregacja)
              </p>
            </td>
            <td>
              <p>liczba pacjentów</p>
            </td>
            <td>
              <p>
                <strong>503-4902</strong>
                Szpitalny oddział ratunkowy (koszty tylko pacjentów w ramach
                ryczałtu dobowego)
              </p>
            </td>
            <td>
              <p align="right">100</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2020</p>
            </td>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>
                <strong>530-4902</strong>- Szpitalny oddział ratunkowy
                (rejestracja i segregacja)
              </p>
            </td>
            <td>
              <p>liczba pacjentów</p>
            </td>
            <td>
              <p>nr OPK Oddziału X</p>
            </td>
            <td>
              <p align="right">30</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <br />

      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
           
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_sor_i_izby_przyjec"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla SOR i Izby przyjęć</strong>
            </a><br/>
          
            <a
              href="/articles/zasady_wyodrebniania_opk_dzialanosci_pomocniczej"
              target="_blank"
            >
              <strong>Zasady dotyczące wyodrębniania OPK działalności pomocniczej</strong>
            </a><br/>
           
          </div>
        </div>
      </div>



            <br />
      <p>
        <strong>Arkusz „5. Rozl OPK Proceduralnych”</strong>
      </p>

      <ul>
        <li>
          Jak uzupełnić dane w przypadku SOR i świadczonych na rzecz pacjentów
          procedur medycznych?
        </li>
      </ul>
      <p>
        Poniższa tabela prezentuje zasadę przygotowania danych dla OPK SOR
        proceduralnego (507). W większych podmiotach OPK SOR proceduralny
        powinien być zostać podzielony na:
      </p>
      <ul>
        <ul>
          <li>
            507-4902-1 - Szpitalny oddział ratunkowy (procedury - gabinety
            lekarskie. Koszty infrastruktury plus personelu medycznego)
          </li>
          <li>
            507-4902-2 - Szpitalny oddział ratunkowy (procedury - sale
            intensywnego nadzoru, sale zabiegowe,... Koszty infrastruktury plus
            personelu medycznego)
          </li>
        </ul>
      </ul>
      <p>
        Informacja o liczbie i rodzaju wykonanych procedur w SOR na rzecz
        pacjentów powinna być dostępna w systemie informatycznym podmiotu.
        Pacjenci, którzy byli zaopatrzeni tylko w SOR, w ścieżce pobytu mają
        wpisany tylko oddział SOR. Natomiast pacjenci, którzy po zaopatrzeniu w
        SOR zostali przyjęci na inny oddział, w ścieżce pobytu mają najpierw SOR
        a następnie nazwę lub nazwy oddziałów, na których przebywali, w ramach
        jednego pobytu w szpitalu (mieli ten sam numer księgi głównej).
      </p>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">rok</p>
            </td>
            <td>
              <p align="center">miesiąc</p>
            </td>
            <td>
              <p align="center">OPK Wykonujące (OPK Proceduralne) (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nr procedury (nr ICD-9)</p>
            </td>
            <td>
              <p align="center">Nazwa procedury</p>
            </td>
            <td>
              <p align="center">Liczba procedur</p>
            </td>
            <td>
              <p align="center">OPK Zlecające (5XX-XXXX)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">2020</p>
            </td>
            <td>
              <p align="right">1</p>
            </td>
            <td>
              <p>
                <strong>507-4902</strong>
                Szpitalny oddział ratunkowy (procedury)
              </p>
            </td>
            <td>
              <p>89.00</p>
            </td>
            <td>
              <p>Porada lekarska, konsultacja, asysta</p>
            </td>
            <td>
              <p align="right">100</p>
            </td>
            <td>
              <p>
                <strong>503-4902</strong>
                Szpitalny oddział ratunkowy (koszty tylko pacjentów w ramach
                ryczałtu dobowego)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">2020</p>
            </td>
            <td>
              <p align="right">1</p>
            </td>
            <td>
              <p>
                <strong>507-4902</strong>
                Szpitalny oddział ratunkowy (procedury)
              </p>
            </td>
            <td>
              <p>89.00</p>
            </td>
            <td>
              <p>Porada lekarska, konsultacja, asysta</p>
            </td>
            <td>
              <p align="right">30</p>
            </td>
            <td>
              <p>OPK Oddziału np.. 504-4500</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />


      <div
        style={{
          background: "rgb(240,240,240)",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <img
            width="35"
            height="35"
            id="Obraz 9"
            src="/home/images/a11/lupa.png"
          />
        </p>
        <div>
          <p>Więcej w artykułach:</p>
          <div>
            <a
              href="/articles/zasady_wyodrebniania_opk"
              target="_blank"
            >
              <strong>Załącznik 2 – zasady wyodrębniania OPK</strong>
            </a><br/>
            <a
              href="/articles/zasady_wyodrebniania_opk_dla_sor_i_izby_przyjec"
              target="_blank"
            >
              <strong>Zasady wyodrębniania OPK dla SOR i Izby przyjęć</strong>
            </a><br/>
           
          </div>
        </div>
      </div>

     
      <br />
      <ul>
        <li>
          Jak uzupełnić dane w przypadku Pracowni TK realizującej świadczenia:
        </li>
        <ul>
          <li>dla komórek podmiotu,</li>
          <li>
            w ramach odrębnej umowy w NFZ (tzn. realizuje świadczenia
            kosztochłonne) oraz
          </li>
          <li>odpłatne (tzw. usługi komercyjne).</li>
        </ul>
      </ul>

      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td>
              <p align="center">rok</p>
            </td>
            <td>
              <p align="center">miesiąc</p>
            </td>
            <td>
              <p align="center">OPK Wykonujące (OPK Proceduralne) (5XX-XXXX)</p>
            </td>
            <td>
              <p align="center">Nr procedury (nr ICD-9)</p>
            </td>
            <td>
              <p align="center">Nazwa procedury</p>
            </td>
            <td>
              <p align="center">Liczba procedur</p>
            </td>
            <td>
              <p align="center">OPK Zlecające (5XX-XXXX)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">2020</p>
            </td>
            <td>
              <p align="right">1</p>
            </td>
            <td>
              <p>
                <strong>507-7220 </strong>
                Pracownia tomografii komputerowej
              </p>
            </td>
            <td>
              <p>87.049</p>
            </td>
            <td>
              <p>TK głowy ze wzmocnieniem kontrastowym</p>
            </td>
            <td>
              <p align="right">14</p>
            </td>
            <td>
              <p>OPK Poradni lub OPK Oddziału</p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">2020</p>
            </td>
            <td>
              <p align="right">1</p>
            </td>
            <td>
              <p>
                <strong>507-7220 </strong>
                Pracownia tomografii komputerowej
              </p>
            </td>
            <td>
              <p>87.049</p>
            </td>
            <td>
              <p>TK głowy ze wzmocnieniem kontrastowym</p>
            </td>
            <td>
              <p align="right">34</p>
            </td>
            <td>
              <p>
                <strong>508-7220</strong>
                Pracownia tomografii komputerowej (NFZ- kosztochłonne)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p align="right">2020</p>
            </td>
            <td>
              <p align="right">1</p>
            </td>
            <td>
              <p>
                <strong>507-7220 </strong>
                Pracownia tomografii komputerowej
              </p>
            </td>
            <td>
              <p>87.049</p>
            </td>
            <td>
              <p>TK głowy ze wzmocnieniem kontrastowym</p>
            </td>
            <td>
              <p align="right">10</p>
            </td>
            <td>
              <p>
                <strong>540-7220 </strong>
                Pracownia tomografii komputerowej (działalność komercyjna)
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    //</Suspense>
  );
}
