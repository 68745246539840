import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as XlsxIcon } from './xlsx.svg';
import MUITooltip from "@material-ui/core/Tooltip";

import { apiurl } from '../app/prefs.js';

//Procedures Report

function format(n) {
  var parts = Number(n) || 0;
  parts = parts.toString().split(".");
  const numberPart = parts[0];
  const decimalPart =
    parts[1] && Math.round(parts[1] / Math.pow(10, parts[1].length - 2));
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, "\xa0") +
    (decimalPart ? "," + decimalPart : "")
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-procedures">
        <p className="label-procedures">{`${label}:`}</p>
        <p className="value-procedures">{`${format(payload[0].value)}`}</p>
      </div>
    );
  }
  return null;
};

function OpkSummary(props) {
  const { data } = props;
  const monthNameTable = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień'
  ];

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >

      {
        data.map(
          (opk, idx) => {
            return (
              <div key={idx} className="opksik">
                <div className="opksheader">
                  {opk.id + ' - ' + opk.opk_name}
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td className='opksth'>Wyszczególnienie</td>
                      {monthNameTable.map((m, idx) => (<td key={idx} className='opksth'>{m}</td>))}
                      <td className='opksth'>Narastająco</td>
                    </tr>
                    <tr>
                      <td className='opksts'>Przychody</td>
                      {opk.monthly.map((m, idx) => (<td key={idx} className='opkstd'>{m.income.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>))}
                      <td className='opksts'>{opk.income.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>
                    </tr>
                    <tr>
                      <td className='opksts'>Koszty bezpośrednie</td>
                      {opk.monthly.map((m, idx) => (<td key={idx} className='opkstd'>{m.cost_direct.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>))}
                      <td className='opksts'>{opk.cost_direct.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>
                    </tr>
                    <tr>
                      <td className='opksts extramargin'>Wynik (k. bezp.)</td>
                      {opk.monthly.map((m, idx) => (<td key={idx} className={m.income - m.cost_direct >= 0 ? 'opksts extramargin' : 'opksts extramargin negative'}>{(m.income - m.cost_direct).toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>))}
                      <td className={opk.income - opk.cost_direct >= 0 ? 'opksts extramargin' : 'opksts extramargin negative'}>{(opk.income - opk.cost_direct).toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>
                    </tr>
                    <tr>
                      <td className='opksts'>Koszty pośrednie</td>
                      {opk.monthly.map((m, idx) => (<td key={idx} className='opkstd'>{m.cost_indirect.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>))}
                      <td className='opksts'>{opk.cost_indirect.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>
                    </tr>
                    <tr>
                      <td className='opksts'>Wynik</td>
                      {opk.monthly.map((m, idx) => (<td key={idx} className={m.profit >= 0 ? 'opksts' : 'opksts negative'}>{m.profit.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>))}
                      <td className={opk.profit >= 0 ? 'opksts' : 'opksts negative'}>{opk.profit.toLocaleString("pl-pl", { maximumFractionDigits: 0 })}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
        )
      }

    </Paper>
  );
}

export default function Report026() {
  const [yardList, setYardList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState(null);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();

  const [reportInfoOpen, setReportInfoOpen] = React.useState(false);

  const loadData = (opk, year) => {
    setActiveYard(opk);
    setReportDataLoaded(false);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/opksummary/${opk.opk_id}/${year}/json`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          setReportDataLoaded(true);
        });
    });
  }


  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Grupa OPK" />}
        onChange={(event, value) => {
          if (value) {
            if (startDate) loadData(value, startDate.year)
          }
        }}
      />
    );
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        disabled={!props.data}
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            if (activeYard) loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    setYardListLoaded(false);
    const apiUrl = `${apiurl}/rdyr2/list/2020`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setDateList(fetchedData[0].datelist);
          if (fetchedData[0].datelist && fetchedData[0].datelist.length !== 0) {
            setStartDate(fetchedData[0].datelist[fetchedData[0].datelist.length - 1]);
            if (fetchedData[0].opklist) {
              setYardList(fetchedData[0].opklist);
              setYardListLoaded(true);
            }
            setFiltersLoaded(true);
          }
        });
    });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadFilters();
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const downloadFile = (downloadURL) => {

    getAccessTokenSilently().then((token) => {
      document.body.style.cursor = 'wait';
      const apiUrl = downloadURL
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Podsumowanie OPK.xlsx`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          document.body.style.cursor = 'auto';
        });
    });

  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #0b6471)",
          display: "flex",
          height: "50px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "5px",
              marginRight: "5px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Podsumowanie OPK
          </Typography>

        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <MUITooltip title='Pobierz raport jako plik MS Excel'>
            <XlsxIcon
              style={{ marginTop: '15px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471' }}
              onClick={
                () => downloadFile(`${apiurl}/opksummary/${activeYard.opk_id}/${startDate.year}/xlsx`)
              }
            />
          </MUITooltip>

        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {activeYard && reportData ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >

          <OpkSummary data={reportData} />

        </div>
      ) : (
        !activeYard && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left",
            }}
            variant="h5"
          >
            Wybierz grupę OPK
          </Typography>
        )
      )}
      {!reportDataLoaded && activeYard && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}

    </div>
  );
}
