import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";

import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { ReactComponent as XlsxIcon } from './xlsx.svg';
import MUITooltip from "@material-ui/core/Tooltip";
import LibraryIcon from '@material-ui/icons/LocalLibraryOutlined';
import Button from "@material-ui/core/Button";
import { apiurl } from '../app/prefs.js';

import { Popover, Paper, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import MonthSelect from "./MonthSelection";
import Snackbar from "@material-ui/core/Snackbar";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";

const VideoIcon = (props) => (
  <svg
    style={{ ...props.style }}
    class="svg-icon"
    width='26px'
    height='26px'
    color='#0b6471'
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M725.333333 574.506667l170.666667 113.770666V335.722667l-170.666667 113.770666v125.013334z m0-227.562667l189.653334-126.442667C943.36 201.6 981.333333 221.930667 981.333333 256v512c0 34.069333-37.973333 54.4-66.346666 35.498667L725.333333 677.056v48.106667C725.333333 784.085333 677.504 832 618.752 832H149.248A106.666667 106.666667 0 0 1 42.666667 725.162667V298.837333C42.666667 239.914667 90.496 192 149.248 192h469.504A106.666667 106.666667 0 0 1 725.333333 298.837333v48.106667zM128 298.837333v426.325334A21.333333 21.333333 0 0 0 149.248 746.666667h469.504c11.584 0 21.248-9.685333 21.248-21.504V298.837333A21.333333 21.333333 0 0 0 618.752 277.333333H149.248C137.664 277.333333 128 287.018667 128 298.837333z" fill="#0b6471" /></svg>
);

const PlayIcon = () => (
  <svg width="40px" height="40px" viewBox="-1 0 14 14" id="meteor-icon-kit__solid-play-s" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.00154V13.0015C0 13.7788 0.84797 14.259 1.5145 13.859L11.5145 7.859C12.1618 7.4706 12.1618 6.5325 11.5145 6.1441L1.5145 0.14405C0.84797 -0.25586 0 0.22425 0 1.00154z" fill="#758CA3"></path></g></svg>
);

//Procedures Report

function format(n) {
  var parts = Number(n) || 0;
  parts = parts.toString().split(".");
  const numberPart = parts[0];
  const decimalPart =
    parts[1] && Math.round(parts[1] / Math.pow(10, parts[1].length - 2));
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, "\xa0") +
    (decimalPart ? "," + decimalPart : "")
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-procedures">
        <p className="label-procedures">{`${label}:`}</p>
        <p className="value-procedures">{`${format(payload[0].value)}`}</p>
      </div>
    );
  }
  return null;
};


function ProceduresSummary(props) {
  const { row } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  React.useEffect(
    () => {
      setPage(0)
    }, [row]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        CENNIK PROCEDUR
      </Typography>

      <TableContainer>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Numer OPK
              </TableCell>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Rok
              </TableCell>
              <TableCell
                align="left"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Kod Procedury
              </TableCell>

              <TableCell
                align="left"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Nazwa Procedury
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Średni Koszt Jednostkowy
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Ilość Wykonań
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {row &&
              row
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((child) => (
                  <TableRow key={child.id} hover="true">
                    <TableCell>
                      {child.opk}
                    </TableCell>
                    <TableCell>
                      {child.year}
                    </TableCell>
                    <TableCell align="left">
                      <div>
                        {child.id}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div>
                        {child.name}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ color: child.cc > 0 ? 'black' : '#FE8E3C' }}>
                        {child.cc.toLocaleString("pl-PL", {
                          maximumFractionDigits: 2, minimumFractionDigits: 2,
                        })}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        {child.qty.toLocaleString("pl-PL", {
                          maximumFractionDigits: 0, minimumFractionDigits: 0,
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={row && row.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

export default function Report034() {
  const [yardList, setYardList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState({ opk_id: 'ALL', opk_name: 'Wszystkie' });
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState(null);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [selectedMonths, setSelectedMonths] = React.useState([]);
  const [copyInfoVisible, setCopyInfoVisible] = React.useState(false);
  const { getAccessTokenSilently, user } = useAuth0();

  const [reportInfoOpen, setReportInfoOpen] = React.useState(false);

  const loadData = (opk, year, months) => {
    setActiveYard(opk);
    setReportDataLoaded(false);
    if (months === undefined) months = selectedMonths;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/getprocedurepricelist/${year}/${JSON.stringify(months)}/${opk.opk_id}/json`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          setReportDataLoaded(true);
        });
    });
  }

  function loadDataBasic(year, months) {
    if (activeYard) loadData(activeYard, year, months)
  };

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-2"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        //defaultValue={'ALL'}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="OPK Proceduralny" />}
        onChange={(event, value) => {
          if (value) {
            if (startDate) loadData(value, startDate.year)
          }
        }}
      />
    );
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        disabled={!props.data}
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            if (activeYard) loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  const loadFilters = async () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/yearslist`;
    const token = await getAccessTokenSilently();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const res = await fetch(apiUrl, requestOptions);
    const fetchedData = await res.json();

    setDateList(fetchedData.datelist);
    if (fetchedData.datelist && fetchedData.datelist.length !== 0) {
      setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1]);
      //loadData(fetchedData.datelist[fetchedData.datelist.length - 1].year);
      setFiltersLoaded(true);
      return fetchedData.datelist[fetchedData.datelist.length - 1];
    } else {
      return 'N/D'
    }

  }

  React.useEffect(() => {
    let mounted = true;

    const getData = async () => {
      setYardListLoaded(false);
      const apiUrl = `${apiurl}/proceduralunitlist`;
      const token = await getAccessTokenSilently();
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(apiUrl, requestOptions);
      const fetchedData = await res.json();

      if (mounted) {
        setYardList([{ opk_id: 'ALL', opk_name: 'Wszystkie' }].concat(fetchedData));
        const sD = await loadFilters();
        loadDataBasic(sD.year, []);
        setYardListLoaded(true);
      }
    };

    getData();

    return function cleanup() {

      mounted = false;
    };
  }, []);

  const downloadFile = (downloadURL) => {
    setReportDataLoaded(false);
    getAccessTokenSilently().then((token) => {
      document.body.style.cursor = 'wait';
      const apiUrl = downloadURL
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Cennik procedur.xlsx`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          document.body.style.cursor = 'auto';
          setReportDataLoaded(true);
        });
    });

  };

  const copyDataToClipBoard = async () => {
    setCopyInfoVisible(true);
    if (Array.isArray(reportData) && reportData.length !== 0) {
      let clipboard = '';
      for (let row = 0; row < reportData.length; row++) {
        const data = reportData[row];
        clipboard = clipboard.concat(
          data.opk + '\t' +
          data.year + '\t' +
          "'" + data.id + '\t' +
          data.name + '\t' +
          data.cc.toFixed(2).replace('.', ',') + '\t' +
          data.qty.toFixed(0) + '\n'
        )
      };
      try { navigator.clipboard.writeText(clipboard) }
      catch {
        console.log('Error copying to clipboard - check user priviledges')
      } finally {
        
      }
    };

  };

  const [videoListOpen, setVideoListOpen] = React.useState(false);
  const [videoPlayDialogOpen, setVideoPlayDialogOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(undefined);

  const handleVideoListClose = () => {
    setVideoListOpen(false)
  };
  const videoData = [
    {
      image: '/manual/reports/4. Raport - procedury.jpg',
      video: '/manual/reports/4. Raport - procedury.mp4'
    }
  ];

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setVideoListOpen(false);
    setVideoPlayDialogOpen(true);
  };

  const VideoList = () => {
    return <div>
      {
        videoData.map(
          (v, i) => {
            return <div className="videoListItem" onClick={() => handleVideoSelect(v)}>
              <div style={{ position: 'relative' }}>
                <img src={v.image} alt={'Video nr ' + i} />
                <div style={{
                  position: 'absolute',
                  top: '90px',
                  left: '190px',
                  opacity: '.5',
                  color: 'gray'
                }}>
                  <PlayIcon />
                </div>
              </div>
            </div>
          }
        )
      }
    </div>
  }

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #0b6471)",
          display: "flex",
          height: "45px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Wewnętrzny cennik procedur
          </Typography>

        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <div style={{ marginRight: '5px' }}>
            <MonthSelect
              setSelectedMonthsFunction={setSelectedMonths}
              loadDataFunction={loadDataBasic}
              selectedMonthsProperty={selectedMonths}
              startDateProperty={startDate}
            />
          </div>

          {
            /*        
              <MUITooltip title='Opis raportu'>
                <LibraryIcon
                  id='reportInfoIcon'
                  onClick={() => setReportInfoOpen(true)}
                  style={{ marginTop: '15px', marginRight: '10px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471' }}
                />
              </MUITooltip>
              <MUITooltip title='Instrukcje video'>
                <div style={{ height: '36px', marginRight: '10px' }}
                  onClick={
                    () => {
                      setSelectedVideo(videoData[0]);
                      setVideoPlayDialogOpen(true);
                    }
                  }>
                  <VideoIcon style={{ marginTop: '14px', cursor: 'pointer' }}></VideoIcon>
                </div>
              </MUITooltip>
            */
          }

          <MUITooltip title='Pobierz raport jako plik MS Excel'>
            <XlsxIcon
              style={{ marginTop: '15px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471', marginRight: '10px', }}
              onClick={
                () => downloadFile(`${apiurl}/getprocedurepricelist/${startDate.year}/${JSON.stringify(selectedMonths)}/${activeYard.opk_id}/xlsx`)
              }
            />
          </MUITooltip>

          <MUITooltip title='Kopiuj dane do schowka'>
            <FileCopyOutlinedIcon
              id='CopyButton'
              style={{ marginTop: '15px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471' }}
              onClick={
                () => copyDataToClipBoard()
              }
            />
          </MUITooltip>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={copyInfoVisible}
            autoHideDuration={1000}
            onClose={() => setCopyInfoVisible(false)}
            message="Skopiowano"
          />

        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {activeYard && reportData ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >

          <ProceduresSummary row={reportData} />


        </div>
      ) : (
        !activeYard && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left",
            }}
            variant="h5"
          >
            Wybierz OPK Proceduralny
          </Typography>
        )
      )}
      {!reportDataLoaded && activeYard && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
      <Popover
        id='hintPopOver'
        open={reportInfoOpen}
        anchorEl={document.getElementById('reportInfoIcon')}
        onClose={() => setReportInfoOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Paper
          style={{
            padding: "10px",
            width: "600px",
            fontFamily: "roboto",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              top: "0px",
              display: "flex",
              width: "100%",
              justifyContent: "right"
            }}
          >
            <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => setReportInfoOpen(false)} />
          </div>
          <div style={{ fontSize: '16px', padding: '5px', marginBottom: '5px' }}>
            <p>
              <b>Raport zawiera koszty całkowite procedur medycznych</b> (suma kosztów bezpośrednich i pośrednich)
              wykonanych w OPK Proceduralnych (zabiegowych, diagnostycznych) w podziale na miesiące.
            </p>
            <p>
              <b>Koszt bezpośredni</b> (inaczej koszt normatywny, koszt standardowy) to suma kosztów
              zasobów materiałowych oraz kosztów zasobów osobowych podanych przez Administratora.
            </p>
            <p>
              <b>Koszt pośredni</b> (narzut kosztów infrastruktury) doliczony jest przez CO+ zgodnie z wymogami
              rozporządzenia MZ (w zależności od wybranej przez Administratora opcji: zgodnie z jednostką kalkulacyjną albo czasem trwania procedury).
            </p>
            <p>
              <b>Średnie wartości</b> kosztów uwzględniają liczbę wykonanych procedur.
            </p>
          </div>
          <div
            style={{
              top: "60px",
              display: "flex",
              justifyContent: "flex-end",
              borderTop: '1px #0B6471 solid'
            }}
          >
            <Button onClick={() => setReportInfoOpen(false)}>
              Zakończ
            </Button>
          </div>
        </Paper>
      </Popover>
      <Popover
        id='videoSelectPopOver'
        open={videoListOpen}
        anchorEl={document.getElementById('video')}
        onClose={handleVideoListClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Paper
          style={{
            padding: "10px",
            width: "450px",
            fontFamily: "roboto",
            display: "flex",
            flexDirection: "column",
            height: 'calc(100vh - 234px)',
          }}
        >

          <div
            style={{
              top: "0px",
              display: "flex",
              width: "100%",
              justifyContent: "right"
            }}
          >

            <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleVideoListClose()} />

          </div>

          <VideoList />
        </Paper>
      </Popover>

      <Dialog

        maxWidth='90vw'
        open={videoPlayDialogOpen}
        onClose={() => setVideoPlayDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"

      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', margin: '0px', padding: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 10px 3px 0px' }}>
            <button
              onClick={() => {
                const div = document.getElementById('videoControl');
                if (div.requestFullscreen)
                  div.requestFullscreen();
                else if (div.webkitRequestFullscreen)
                  div.webkitRequestFullscreen();
                else if (div.msRequestFullScreen)
                  div.msRequestFullScreen();
              }
              }
              style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
            >
              Pełen ekran
            </button>
            <button
              onClick={() => setVideoPlayDialogOpen(false)}
              style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
            >
              Zamknij
            </button>
          </div>
          <video id='videoControl' controls autoPlay style={{ width: '80vw' }}>
            <source src={selectedVideo && selectedVideo.video} type="video/mp4" />
            Video control not supported by browser
          </video>

        </DialogContent>
      </Dialog>
    </div>
  );
}
