import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import Looks5RoundedIcon from "@material-ui/icons/Looks5Rounded";
import Typography from "@material-ui/core/Typography";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import useDebounce from "./use-debounce";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { apiurl } from '../app/prefs.js';

const theme = createMuiTheme({

  transitions: {
    // So we have `transition: none;` everywhere
    //create: () => "none",
  },
});

var data2 = [];

const useStyles = makeStyles({
  root: {
    //height: 264,
    flexGrow: 1,
    //maxWidth: 1024,
  },
});

const getTreeItemsFromData = (treeItems) => {
  return treeItems.map((treeItemData) => {
    let children = undefined;
    if (treeItemData.children && treeItemData.children.length > 0) {
      children = getTreeItemsFromData(treeItemData.children);
    }
    return (
      <TreeItem
        key={treeItemData.id}
        nodeId={treeItemData.id}
        label={treeItemData.name}
        icon={
          treeItemData.leaf ? (
            <Looks5RoundedIcon style={{ color: "#FE8E3C" }} />
          ) : null
        }
        children={children}
      />
    );
  });
};

function dfs(node, term, foundIDS) {
  // Implement your search functionality
  let isMatching =
    node.name && node.name.toLowerCase().indexOf(term.toLowerCase()) > -1;

  if (Array.isArray(node.children)) {
    node.children.forEach((child) => {
      const hasMatchingChild = dfs(child, term, foundIDS);
      isMatching = isMatching || hasMatchingChild;
    });
  }

  // We will add any item if it matches our search term or if it has a children that matches our term
  if (isMatching && node.id) {
    foundIDS.push(node.id);
  }

  return isMatching;
}

function filter(data, matchedIDS) {
  return data
    .filter((item) => matchedIDS.indexOf(item.id) > -1)
    .map((item) => ({
      ...item,
      children: item.children ? filter(item.children, matchedIDS) : [],
    }));
}

function search(term) {
  // We wrap data in an object to match the node shape
  const dataNode = {
    children: data2,
  };

  const matchedIDS = [];
  // find all items IDs that matches our search (or their children does)
  dfs(dataNode, term, matchedIDS);

  // filter the original data so that only matching items (and their fathers if they have) are returned
  //return filter(data2, matchedIDS);

  return matchedIDS;
}

export default function OPKTreeView() {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  React.useEffect(
    () => {
      if (debouncedSearchTerm !== "") {
        let res = search(debouncedSearchTerm);
        setResults(res);
        setExpanded(res);
        //if (name==='') {matched = []}
      } else {
        //setResults([]);
        setExpanded([]);
      }
      //setSearching(false);
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  //setResults(search(searchTerm));
  //const treeItems = search(name);
  var treeItems = debouncedSearchTerm !== "" ? filter(data2, results) : data2;
  //const treeItems = data2
  //if (name==='') {matched = []}

  const [appState, setAppState] = React.useState({
    loading: false,
  });

  React.useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `${apiurl}/opk`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((fetchedData) => {
        data2 = fetchedData;
        setAppState({ loading: false });
      });
  }, [setAppState]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  return !appState.loading ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "60px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <ListAltOutlinedIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5">Zespół kont 5</Typography>
          <Typography variant="subtitle1" style={{ fontWeight: "300" }}>
            Układ i nazwy kont 5 zgodne z następującymi rozporządzeniami MZ:
            Dz.U. z 2020 r., poz. 2045 oraz Dz. U. z 2019 r. poz. 173.
          </Typography>
        </div>
      </div>

      <div
        style={{ borderBottom: "1px solid", width: "250px", marginTop: "20px" }}
      >
        <InputBase
          startAdornment={
            <InputAdornment position="start" size="small">
              <SearchIcon style={{ fontSize: "16px" }} />
            </InputAdornment>
          }
          style={{ marginTop: "0px", paddingBottom: "0px" }}
          autoComplete="off"
          id="standard-search"
          placeholder="Czego szukasz?"
          type="search"
          size="small"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <p />
      <div
        style={{
          width: "100%",
          minHeight: "570px",
          //maxheight: "350px",
          overflow: "auto",
          padding: "5px",
        }}
      >
        <ThemeProvider theme={theme}>
          <TreeView
            className={classes.root}
            expanded={expanded}
            //defaultExpanded={["403"]}
            onNodeToggle={handleToggle}
            defaultCollapseIcon={
              <FolderOpenRoundedIcon style={{ color: "#0b6471" }} />
            }
            defaultExpandIcon={
              <FolderRoundedIcon
                style={{ color: "#0b6471", verticalAlign: "text-top" }}
              />
            }
            defaultEndIcon={<FolderRoundedIcon style={{ color: "#0b6471" }} />}
          >
            {getTreeItemsFromData(treeItems)}
          </TreeView>
        </ThemeProvider>
      </div>
      <Typography variant="body2">
        * Indeksacja nadawana indywidualnie przez świadczeniodawcę (przykład)
      </Typography>
    </div>
  ) : (
      <div style={{ position: "relative" }}>
        <div
          style={{
            postition: "absolute",
            transform: "translate(50%, 250%)",
            color: "darkgray",
          }}
        >
          Wczytywanie...
      </div>
      </div>
    );
}
