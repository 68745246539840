import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditTable from "./edittable.js";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import "animate.css";
import "./style.css";
import { Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import Tooltip from "@material-ui/core/Tooltip";

import {
  procedureActivities
} from "./data";
const getActivityById = (id) => procedureActivities.find(activity => activity.id === id);

export default function ResTable(props) {
  const isWideEnough = useMediaQuery("(min-width:900px)");
  const [data, setData] = React.useState(props.resources);
  const header = props.headers;
  const [filter, setFilter] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  //const [assignments, setAssignments] = React.useState(props.assignments);
  const assignments = props.assignments;
  const setAssignments = props.setAssignments;
  const formula = props.formula;
  const setProcedureCosts = props.setProcedureCosts;
  const setRootAssignments = props.setAssignments;
  const readonly = props.readonly;
  const optionsData = props.optionsData;
  const dropdownFilterOptions = props.dropdownFilterOptions;
  const [dropdownFilterValue, setDropdownFilterValue] = React.useState(null);

  const filterColumns = Array.isArray(props.filterColumn) ? props.filterColumn : props.filterColumn.split();

  let columns = [];
  header.map((column) => {
    let align = column.type === "number" ? "right" : column.type === 'checkbox' ? 'center' : "left";
    let edit = column.edit;
    let name = column.name;
    let format = column.format;
    let boundColumns = column.boundColumns;
    let disabledon = column.disabledon;
    let type = column.type;
    let col = { name, align, edit, format, boundColumns, disabledon, type };
    columns.push(col);
    return null;
  });

  const calc = (a) => {
    let res = 0;
    try {
      res = eval(a.replace(',', '.'));
    } catch (err) {
      res = 0;
    }
    return res;
  };

  const [toggleFilter, setToggleFilter] = React.useState("all");
  const changeToggleFilter = (event, newOption) => {
    setToggleFilter(newOption);
    if (props.callbackFn !== undefined) props.callbackFn(newOption);
  };
  const ToggleFilterButton = () => {
    return (
      optionsData ? <ToggleButtonGroup
        value={toggleFilter}
        exclusive
        size="small"
        onChange={changeToggleFilter}
        aria-label="text alignment"
      >
        {optionsData.map((option) => (
          <ToggleButton
            key={option.value}
            style={{ width: "100px", border: 'none', height: '30px' }}
            value={option.value}
            aria-label="left aligned"
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
        :
        null
    )
  }

  const DropDownFilter = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={dropdownFilterOptions}
        value={dropdownFilterValue}
        size="small"
        autoHighlight
        getOptionLabel={(option) => option && option.label}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option === value;
        }}
        style={{ width: "350px", marginBottom: "0px", marginRight: '10px' }}
        renderInput={(params) => <TextField  {...params} label="OPK" />}
        onChange={(event, value) => {
          if (value) {
            setDropdownFilterValue(value)
          } else {
            setDropdownFilterValue(null)
          }
        }}
      />
    )
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    const lookedUpValues = event.target.value.split(' ');
    if (lookedUpValues)
      setData(
        props.resources.filter(
          (row) => {
            const aggrValue = filterColumns.reduce((total, value) => total + ' ' + ((row && row[value]) ? row[value] : ''), '');
            return (row &&
              aggrValue &&
              lookedUpValues.every(
                valueToFind =>
                  aggrValue
                    .toLowerCase()
                    .includes(valueToFind.toLowerCase())
              )
            ) ||
              filterColumns.some(column => row[column] === props.filterExcludedValue)
          }
        )
      );
    else setData(props.resources);
  };

  const handleChangeExpanded = () => {
    setExpanded(!expanded);
  };

  const hrChange = (event, res, col, entryNo) => {
    let data1 = [...assignments];
    const coldef = columns.find(colmn => colmn.name === col);
    let dat = data1.find((element) => (entryNo != null) ? (element[props.datakey] === res && element.entryNo == entryNo) : element[props.datakey] === res);
    //console.log(data1);
    //console.log(props.datakey);

    /*     if (coldef.type && coldef.type === 'checkbox') {
          dat[col] = !dat[col]
        } else
          dat[col] = event.target.value.replace(/[^0-9.,/*x+-]/g, ''); */

    switch (coldef.type) {
      case 'checkbox':
        dat[col] = !dat[col]
        break;
      case 'combobox':
        dat[col] = event.id;
        break;
      default:
        const onlyNums = event.target.value.replace(/[^0-9.,/*x+-]/g, "");
        dat[col] = onlyNums;
        if (coldef.boundColumns) { dat[coldef.boundColumns.name] = 1 / onlyNums };
    }

    dat["Koszt całkowity"] = 1;
    setAssignments(data1);
  };

  React.useEffect(() => {
    let costs = 0;
    assignments.map(
      (res) => {
        relatedData(res[props.datakey]) && (costs = costs + eval(formula));
      }
    );
    setProcedureCosts && setProcedureCosts(costs);
    setRootAssignments && setRootAssignments(assignments);
  }, [assignments]);

  const relatedData = (id) =>
    data && data.find((element) => element[props.dictkey] === id);

  const handleDelete = (event, id, entryNo) => {
    const selectedIndex = assignments.findIndex(
      (element) => (entryNo != null) ? (element[props.datakey] === id && element.entryNo == entryNo) : element[props.datakey] === id
    );
    let newSelected = [];

    if (selectedIndex !== -1) {
      newSelected = assignments.filter((item) => (entryNo != null) ? (item[props.datakey] !== id || item.entryNo != entryNo) : item[props.datakey] !== id);
    }

    setAssignments(newSelected);
  };

  return (
    <div style={{ width: 'inherit' }}>
      <Paper
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          padding: "5px",
          paddingLeft: "10px",
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
          {readonly !== true && <Button
            onClick={handleDialogOpen}
            startIcon={<AddBoxIcon />}
            size="small"
          >
            Dodaj
          </Button>
          }

          <Input
            type="search"
            autoComplete="off"
            onFocus={(event) => {
              event.target.select();
            }}
            value={filter}
            onChange={handleFilterChange}
            style={{
              marginLeft: "10px",
              marginRight: '10px',
              width: '400px',
              fontSize: "14px",
              verticalAlign: "middle",
            }}
            size="small"
            placeholder="Czego szukasz?"
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start" size="small">
                <SearchIcon style={{ fontSize: "16px" }} />
              </InputAdornment>
            }
            endAdornment={
              <IconButton position="start" size="small">
                <Tooltip title='Wpisz szukany tekst. Możesz wpisać kilka szukanych fraz, oddzielając je spacją.' >
                  <HelpTwoToneIcon style={{ fontSize: "16px" }} />
                </Tooltip>
              </IconButton>
            }
          />
          {dropdownFilterOptions && <DropDownFilter />}
          <ToggleFilterButton />
        </div>
      </Paper>
      <TableContainer component={Paper} style={{ boxSizing: "content-box" }}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow key="1">
              {columns.map(
                (column) =>
                  (isWideEnough ||
                    column.name === props.filterColumn ||
                    column.edit === "true") && (
                    <TableCell
                      key={column.name}
                      align={column.align}
                      padding="none"
                      style={{
                        paddingLeft: "5px",
                        color: column.edit === "true" && "#0b6471"
                      }}
                    >
                      {column.name}
                    </TableCell>
                  )
              )}
              {readonly !== true && <TableCell style={{ maxWidth: "20px" }}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(assignments
              .filter(item => toggleFilter === 'all' ? true : toggleFilter === 'ready' ? (item['Data zapisu'] && item['Czas trwania']) : !item['Data zapisu'] || !item['Czas trwania'])
              .filter(item => dropdownFilterValue ? item.opk_id === dropdownFilterValue.id : true))
              .slice(0, ((filter && filter.length > 2) || (dropdownFilterValue !== null)) ? Infinity : (expanded ? 200 : 20)).map(
                (res, id) =>
                  relatedData(res[props.datakey]) && (
                    <TableRow hover key={id} className="tabledatarow">
                      {columns.map(
                        (col, colid) =>
                          (isWideEnough ||
                            col.name === props.filterColumn ||
                            col.edit === "true") && (
                            <TableCell
                              key={colid}
                              padding="none"
                              align={col.align}
                              style={{ paddingLeft: "5px" }}

                            >
                              {col.edit === "true" &&
                                !(col.disabledon && relatedData(res[props.datakey]) &&
                                  relatedData(res[props.datakey])[col.disabledon.field] === col.disabledon.value) ? (
                                col.type === 'checkbox' ?
                                  <Checkbox
                                    checkedIcon={<CheckIcon />}
                                    icon={<RemoveIcon style={{ color: 'lightgray' }} />}
                                    style={{ color: 'black' }}
                                    checked={res[col.name]}
                                    onClick={(event) => hrChange(event, res[props.datakey], col.name, res.entryNo)}
                                  />
                                  :
                                  col.type === 'combobox' ?
                                    <Autocomplete
                                      value={getActivityById(res[col.name])}
                                      onChange={(event, value) =>
                                        hrChange(value, res[props.datakey], col.name, res.entryNo)
                                      }
                                      options={procedureActivities}
                                      getOptionSelected={(option, value) => option && value && option.id === value.id}
                                      getOptionLabel={(option) => option.caption}
                                      id="combobox"
                                      disableClearable
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}

                                          multiline
                                          InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontSize: '14px' } }}
                                          margin="dense"
                                        />
                                      )}
                                    />
                                    :
                                    <Input
                                      disableUnderline
                                      onFocus={(event) => {
                                        event.target.select();
                                      }}

                                      onChange={(e) =>
                                        hrChange(e, res[props.datakey], col.name, res.entryNo)
                                      }
                                      value={res[col.name]}
                                      inputProps={{
                                        disabled: false,
                                        min: 0,
                                        style: {
                                          textAlign: "right",
                                          fontSize: "14px",
                                          paddingRight: '10px',
                                          fontWeight: "1000",
                                          backgroundColor: "#e7f0f1",
                                          borderRadius: "5px",
                                          color:
                                            res[col.name] == 0 ? "#fe8e3c" : "black"
                                        }
                                      }}
                                    />
                              ) : (
                                <div>
                                  {col.name !== "Koszt całkowity" &&
                                    relatedData(res[props.datakey]) &&
                                    relatedData(res[props.datakey])[col.name] &&
                                    (col.format === 'currency' ?
                                      (relatedData(res[props.datakey])[col.name] / 1).toLocaleString('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                      :
                                      relatedData(res[props.datakey])[col.name]
                                    )
                                  }
                                  {col.name === "Koszt całkowity" &&
                                    relatedData(res[props.datakey]) &&
                                    eval(props.formula).toFixed(2)}
                                  {col.format === "currency" && relatedData(res[props.datakey]) && " zł"}
                                </div>
                              )}
                            </TableCell>
                          )
                      )}
                      {readonly !== true &&
                        <TableCell style={{ maxWidth: "30px" }}>
                          <div className="tabledataoptions">
                            <IconButton
                              size="small"
                              style={{ width: "21px", height: "21px" }}
                            >
                              <DeleteIcon
                                size="small"
                                style={{ color: "#fe8e3c" }}
                                onClick={(event) =>
                                  handleDelete(event, res[props.datakey], res.entryNo)
                                }
                              />
                            </IconButton>
                          </div>
                        </TableCell>
                      }
                    </TableRow>
                  )
              )}
            {assignments.length > 20 && (
              <TableRow hover>
                <TableCell>
                  <Link onClick={handleChangeExpanded} className="MUILink">
                    {expanded ? "Zwiń" : "Rozwiń>>>"}
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        //style={{ zIndex: 10000, height: "100%" }}
        style={{ height: "100%" }}
      >
        <Typography
          variant="h6"
          style={{ color: "#0b6471", marginLeft: "30px", marginTop: "10px" }}
        >
          {props.procedureName && `Przypisz zasoby do procedury ${props.procedureName['Nr procedury (nr ICD-9)']} : ${props.procedureName['Nazwa procedury']}`}
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <EditTable
            filterColumn={props.filterColumn}
            callback={handleDialogClose}
            resources={props.resources}
            headers={props.headers}
            assignments={assignments}
            returnData={setAssignments}
            datakey={props.datakey}
            dictkey={props.dictkey}
            formula2={props.formula2}
            multiRowEdit={props.multiRowEdit}
            readonly={readonly}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
