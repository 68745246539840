import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";



export default function MonthSelect(props) {
  const { setSelectedMonthsFunction, loadDataFunction, selectedMonthsProperty, startDateProperty } = props;
  const [selectedMonth, setSelectedMonth] = React.useState(selectedMonthsProperty);
  const [monthListOpen, setMonthListOpen] = React.useState(false);
  const monthNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value.filter((item) => item !== undefined));
  };
  const handleMonthClose = () => {
    //console.log(selectedMonth);
    setSelectedMonthsFunction(selectedMonth);
    setMonthListOpen(false);
    loadDataFunction(startDateProperty.year, selectedMonth);
  };
  const handleMonthClear = () => {
    setSelectedMonth([]);
    setSelectedMonthsFunction([]);
    loadDataFunction(startDateProperty.year, []);
    //console.log([]);
  };
  const MyIcon = () => (
    <div onClick={handleMonthClear} style={{ padding: '0px', margin: '0px', cursor: 'pointer' }}><CloseIcon /></div>
  )

  return (
    <div>
      <InputLabel style={{ fontSize: '12px', marginTop: '1px', marginBottom: '0px' }} id="month-checkbox-label">Miesiąc</InputLabel>
      <Select
        labelId="month-checkbox-label"
        style={(props.version === 2) ? { minWidth: "130px", color: '#0B6471', fontSize: '14px', height: '24px' } : { minWidth: "130px" }}
        multiple
        open={monthListOpen}
        //endAdornment={<div onClick={handleMonthClear}></div>}
        displayEmpty
        IconComponent={MyIcon}
        value={selectedMonth}
        onChange={handleMonthChange}
        input={<Input />}
        disableUnderline={props.version === 2}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Wszystkie</em>;
          }
          return selected.sort((a, b) => a - b).join(", ");
        }}
        inputProps={{ "aria-label": "Without label" }}
        onClose={handleMonthClose}
        onOpen={() => setMonthListOpen(true)}
      >
        {monthNames.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
        <Button fullWidth onClick={handleMonthClose}>
          Wybierz
        </Button>
      </Select>
    </div>
  );
}