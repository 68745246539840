import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "animate.css";
import PropTypes from "prop-types";
import React from "react";
import { RiHealthBookFill } from "react-icons/ri";
import { apiurl } from '../app/prefs.js';
import "./style.css";
import TipsTable from "./procedureTips.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

export default function Procedureslibrary() {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const classes = useStyles();

  //procedures library variables
  const [libraryList, setLibraryList] = React.useState([]);
  const [libraryProcedure, setLibraryProcedure] = React.useState(undefined);
  const [libraryData, setLibraryData] = React.useState(undefined);

  const fetch_retry = (url, options, n) => fetch(url, options).catch(function (error) {
    if (n === 1) throw error;
    return fetch_retry(url, options, n - 1);
  });

  const getData = () => {
    getAccessTokenSilently().then((token) => {
      getLibraryList(token);
    });
  }

  const getLibraryList = (token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch_retry(`${apiurl}/procedureslibrary`, requestOptions, 10)
        .then((response) => response.json(), reason => console.log(reason))
        .then((data) => {
          setLibraryList(data);
        }, reason => console.log(reason))
        .catch(error => { });
    }
  };

  const TableFromData = (props) => {
    return (
      <TableContainer>
        <Table size="small">
          <TableBody>
            {props.data.map(
              (row) => {
                return <TableRow>
                  {row.map(column =>
                    <TableCell style={{ fontSize: '12px', fontWeight: column.fontWeight, paddingBottom: '0px', borderBottom: '1px solid lightgray' }} align={column.justify} >{column.text}</TableCell>
                  )}
                </TableRow>
              })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  React.useEffect(() => {
    getData();
    return () => {
    };
  }, []);


  return (
    <div>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <RiHealthBookFill
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            BIBLIOTEKA PRZYKŁADOWYCH OPISÓW PROCEDUR
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />

      {libraryList &&
        <Paper
          className={classes.root}
          style={{ display: "flex", flexDirection: "column", width: "100%", minHeight: '800px', padding: '5px' }}
        >
          <Autocomplete
            disabled={libraryList.length !== 0 ? false : true}
            style={{
              minWidth: "200px",
              marginRight: "10px",

              marginBottom: '5px',
            }}
            size="small"
            options={libraryList}
            getOptionLabel={(option) => option && (option.procedureId + ': ' + option.procedureName)}
            renderOption={
              (option, state) =>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {option.procedureId + ': ' + option.procedureName}
                </div>
            }
            value={libraryProcedure}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setLibraryProcedure(newValue);
                getAccessTokenSilently().then((token) => {
                  const requestOptions = {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + token,
                    },
                  };

                  let procedureCode = newValue.procedureId;
                  if (typeof procedureCode === 'string' || procedureCode instanceof String) {
                    procedureCode = procedureCode.replace(/\//g, '%2F')
                  };

                  fetch(
                    `${apiurl}/procedureslibrary/${procedureCode}`,
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      setLibraryData(data);

                    })
                })
              } else {
                setLibraryProcedure(undefined);
                setLibraryData(undefined)
              }
            }}
            id="procedureLibraryList"
            //disableClearable
            clearText="Wyczyść wybór"
            
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                //label="Procedura wzorcowa"
                placeholder="Wpisz fragment nazwy lub kodu szukanej procedury"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,

                  style: {
                    backgroundColor: "#e7f0f1",
                    color: "#0B6471",
                    fontSize: "14px",
                  },
                }}
              />
            )}
          />
          {(libraryData && libraryData.materials && libraryData.personel) ?
            <div>
              <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#0B6471', marginTop: '10px', marginLeft: '15px' }}>
                <table>
                  <tr>
                    <td>
                      Koszt bezp. procedury:
                    </td>
                    <td align="right" style={{ color: 'black' }}>
                      {Number(Number(libraryData.materials.totalCost) + Number(libraryData.personel.totalCost)).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Zasoby materiałowe:
                    </td>
                    <td align="right" style={{ color: 'black' }}>
                      {Number(Number(libraryData.materials.totalCost)).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Zasoby osobowe:
                    </td>
                    <td align="right" style={{ color: 'black' }}>
                      {Number(Number(libraryData.personel.totalCost)).toFixed(2)}
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#0B6471', marginTop: '10px', marginLeft: '15px' }}>
                Zasoby materiałowe
              </div>
              <TableFromData data={libraryData.materials.table} />
              <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#0B6471', marginTop: '30px', marginLeft: '15px' }}>
                Zasoby osobowe
              </div>
              <TableFromData data={libraryData.personel.table} />
              <div style={{ marginTop: '10px' }}>
                Opis na podstawie materiałów dostępnych na stronie AOTMiT
              </div>
            </div>
            : <div><TipsTable/></div>
          }
        </Paper>
      }
    </div>

  );
}
