import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProfitTable from "./ProfitTable.js";
import { Button } from "@material-ui/core";


import { apiurl } from '../app/prefs.js';


export default function RegisteredCodesRep() {
  const [yardList, setYardList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const { getAccessTokenSilently, user } = useAuth0();

  function AutoLookup(props) {
    const [value, setValue] = React.useState(activeYard);

    return (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              name: newValue
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              name: newValue.inputValue
            });
          } else {
            setValue(newValue);
            setActiveYard(newValue);
          }
        }}
        filterOptions={(options, params) => {
          function wildTest(wildcard, str) {
            let w = wildcard.replace(/[.+^${}()|[\]\\]/g, "\\$&"); // regexp escape
            const re = new RegExp(
              `^${w.replace(/\*/g, ".*").replace(/\?/g, ".")}$`,
              "i"
            );
            return re.test(str); // remove last 'i' above to have case sensitive
          }

          //const filtered = filter(options, params);
          let filtered = [];

          // Suggest the creation of a new value
          if (params.inputValue.length >= 3) {
            filtered = options.filter((item) =>
              wildTest(
                "*" + params.inputValue.replace(" ", "*") + "*",
                item.name
              )
            );
          }

          if (params.inputValue === "") {
            filtered.length = 0;
          }

          return filtered;
        }}
        fullWidth
        includeInputInList
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={props.data}
        getOptionLabel={(option) => {
          // Regular option
          return option.name;
        }}
        renderOption={(option) => option.name}
        style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'white' }}

        renderInput={(params) => (
          <TextField
            {...params}
            style={{ minWidth: '800px' }}
            label="Podmiot"
            placeholder="Wybierz podmiot (wpisz na najmniej 3 znaki)"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
          />
        )}
      />
    );
  }

  function ClipboardButton(props) {
    function handleClick() {
      const JSONData = props.data;
      var arrData =
        typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

      var CSV = '';

      //This condition will generate the Label/Header
      if (1 === 1) {
        var row = '';

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + '\t';
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + '\n';
      }

      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
          row += '' + arrData[i][index] + '\t';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + '\n';
      }

      navigator.clipboard.writeText(CSV);
    }

    return <Button color='primary' variant='contained' style={{ marginBottom: '10px' }} onClick={handleClick}>Kopiuj do schowka</Button>;
  }

  React.useEffect(() => {
    let mounted = true;
    setYardListLoaded(false);
    const apiUrl = `${apiurl}/providerslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (mounted) {
            fetchedData.providers_list && setYardList(fetchedData.providers_list);
            setYardListLoaded(true);
          }
        });
    });
    return function cleanup() {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    activeYard && loadData(activeYard.book_id)

  }, [activeYard]);

  const loadData = (opk) => {
    setReportDataLoaded(false);

    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/providerslist/${opk}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data && setReportData(data);
          //data && console.log(data);
          setReportDataLoaded(true);
        });
    });
  }

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{

          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Kody resortowe podmiotów
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "300", color: "white" }}
          >
            Dane aktualne na 1.06.2021
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div style={{ display: "flex", flexWrap: "wrap", position: "relative", width: "100%" }}>
          <AutoLookup data={yardList} />
        </div>
      ) : (
        <div>Wczytywanie danych...</div>
      )}
      <p style={{ height: "5px" }} />

      {activeYard ?
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >

          <ClipboardButton data={reportData.provider_data} />

          {reportData.provider_data && reportData.headers && <ProfitTable
            title="Kody resortowe podmiotu"
            data={reportData.provider_data}
            dataType="cost"
            columns={reportData.headers}
          />}

          {reportData.agreements && reportData.agreementsHeaders &&
          <ProfitTable
            title="Kontrakty z NFZ"
            data={reportData.agreements.sort((a, b) => b.amount - a.amount)}
            dataType="cost"
            columns={reportData.agreementsHeaders}
          />}

        </div>
        : !activeYard && <Typography style={{ position: "absolute", top: "120%", left: "40%", color: "#0b6471" }} variant="h5" style={{ textJustify: "left" }}></Typography>}
      {!reportDataLoaded && activeYard && <CircularProgress style={{ position: "absolute", height: "70px", width: "70px", top: reportData.yearlydata ? "50%" : "120%", left: "50%", color: "#0b6471", opacity: "0.6" }} />}
    </div>
  );
}