import { apiurl } from '../app/prefs.js';

const fetch_retry = (url, options, n) => fetch(url, options).catch(function(error) {
  if (n === 1) throw error;
  return fetch_retry(url, options, n - 1);
});

const yardsList = [
  { yardName: "Blok operacyjny", yardID: 1 },
  { yardName: "Pracownia tomografii komputerowej", yardID: '5077220' },
  { yardName: "Oddział anestezjologii", yardID: 3 },
];

const procheaders = [
  {
    name: "Nr procedury (nr ICD-9)",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Numer konta OPK Proceduralny",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa konta OPK Proceduralny",
    type: "text",
    edit: "false",
    format: "",
  },
];

const hrheaders = [
  {
    name: "Id grupy",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Grupa zaszeregowania",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nr OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Numer grupy pracowniczej",
    type: "text",
    edit: "false",
    format: "",
  },
];

const hrheadersFull = [
  {
    name: "Id grupy",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Grupa zaszeregowania",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa procedury",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Koszt jednostkowy",
    type: "number",
    edit: "false",
    format: "currency",
  },
  {
    name: "Nr OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa OPK",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Numer grupy pracowniczej",
    type: "text",
    edit: "false",
    format: "",
  },
];

const matheaders = [
  {
    name: "Kod",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Typ",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Pojemność / rozmiar",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Jednostka miary",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Koszt jednostkowy",
    type: "number",
    edit: "false",
    format: "currency",
  },
  {
    name: "Nr konta 4",
    type: "text",
    edit: "false",
    format: "",
  },
];

const ProceduresList = (params, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  if (params) {
    //getAccessTokenSilently().then((token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch_retry(`${apiurl}/procedureslist`, requestOptions, 10)
        .then((response) => response.json(), reason => console.log(reason))
        .then((data) => {
          params(data.procedures);
          //console.log(data);
        }, reason => console.log(reason))
        .catch(error => {});
    }
  };
};

const Hrres = (params, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  if (params) {
    //getAccessTokenSilently().then((token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      fetch_retry(`${apiurl}/hrlist`, requestOptions, 10)
        .then((response) => response.json())
        .then((data) => {
          params(data.hr);
          //console.log(data);
        })
        .catch(error => {});
    }
  };
};

const Materials = (params, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  if (params) {
    //getAccessTokenSilently().then((token) => {
    if (token) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      fetch_retry(`${apiurl}/materialslist`, requestOptions, 10)
        .then((response) => response.json())
        .then((data) => {
          params(data.materials);
          //console.log(data);
        })
        .catch(error => {});
    }
  }
};

const procedureinfo = {
  length: "2020-02-02 00:00",
  date: "2020-10-15",
  described_by: "-",
  consulted_with: "-",
};

export {
  Hrres,
  hrheaders,
  hrheadersFull,
  Materials,
  matheaders,
  yardsList,
  ProceduresList,
  procheaders,
  procedureinfo,
};
