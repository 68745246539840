import React from "react";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  BarChart,
  Cell,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
} from "recharts";
import IconButton from "@material-ui/core/IconButton";

const CustomTooltip = () => ({
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(100,100,100,0.8)",
            color: "white",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <p className="label">{`${label} : ${payload[0].value.toLocaleString(
            "pl-PL",
            { maximumFractionDigits: 0 }
          )}`}</p>
        </div>
      );
    }
    return null;
  },
});

const posorneg = (type, value) => {
  let color = "black";

  let posorneg = value < 0 ? false : true;
  if (type === "cost") posorneg = !posorneg;
  if (!posorneg) color = "#FE8E3C";
  return color;
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 600,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "100",
    //border: '1px solid #FE8E3C',
    boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
  },
}))(MTooltip);

function RowKB(props) {
  const [open, setOpen] = React.useState(false);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        {((row.cost_direct && row.cost_direct !== 0) || (row.cost_direct_BU && row.cost_direct_BU !== 0)) && <TableRow
          key={row.account_name}
          hover
          className={"animate__animated animate__fadeIn"}
        >
          <TableCell
            style={{
              paddingLeft: 30 + 15 * props.lvl,
              borderBottom: "0px solid",
            }}
          >
            {row.children && row.children.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                style={{ color: "lightgray" }}
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography
              variant="body2"
              style={{ paddingLeft: 0 + 0 * props.lvl, color: "gray" }}
            >
              {row.opk_id}
            </Typography>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography
              variant="body2"
              style={{ paddingLeft: 0 + 0 * props.lvl, color: "gray" }}
            >
              {row.account_name}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {" "}
            {row.cost_direct_BU &&
              row.cost_direct_BU.toLocaleString("pl-PL", {
                maximumFractionDigits: 0,
              })}
          </TableCell>
          <TableCell
            align="right"
            style={{ color: posorneg("cost", row.cost_direct_delta) }}
          >
            {row.cost_direct_delta &&
              (100 * row.cost_direct_delta).toLocaleString("pl-PL", {
                maximumFractionDigits: 2,
              })}
          </TableCell>
          <TableCell align="right">
            {row.cost_direct.toLocaleString("pl-PL", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </TableCell>
          <TableCell style={{ padding: "0px" }}>
            <div
              style={{
                borderColor: "red",
                height: "40px",
                width: "130px",
                padding: "0px",
              }}
            >
              {row.monthly && (
                <ResponsiveContainer>
                  <BarChart
                    style={{ padding: "0px", margin: "0px" }}
                    width={250}
                    height={60}
                    data={row.monthly}
                  >
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey="MONTH" hide={true} />
                    <Bar
                    
                      dataKey="cost_direct"
                      fill="#0B6471"
                      barSize={250 / (1.5 * 12)}
                    >
                      {row.monthly.map((entry, index) => (
                        <Cell
                          cursor="pointer"
                          fill={entry.cost_direct < 0 ? "#FE8E3C" : "#cee0e3"}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </TableCell>
        </TableRow>
        }
        {open &&
          row.children
            .sort((a, b) => {
              return b.cost_direct - a.cost_direct;
            })
            .map((child) => (
              <Row key={child.name} row={child} lvl={props.lvl + 1} />
            ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        key={props.data.account_name}
        className="animate__animated animate__fadeIn"
        //class="MuiTableRow"
        hover={true}
        style={{ backgroundColor: open && '#f5f9f9', borderRadius: "5px", outline: "none" }}
      >
        <TableCell style={{ borderBottom: "0px solid", borderRadius: "5px 0px 0px 5px" }}>
          {props.data.children && props.data.children.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ fontWeight: (open || props.data.opk_id === 'Ogółem') && 500, textIndent: "0px" }}>
          {props.data.opk_id}
        </TableCell>
        <TableCell component="th" scope="row" style={{ fontWeight: (open || props.data.opk_id === 'Ogółem') && 500, textIndent: "0px" }}>
          {props.data.account_name}
        </TableCell>
        <TableCell align="right"
          style={{ fontWeight: (open || props.data.opk_id === 'Ogółem') && 500 }}>
          {props.data.cost_direct_BU &&
            props.data.cost_direct_BU.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            })}
        </TableCell>
        <TableCell align="right"
          style={{
            fontWeight: (open || props.data.opk_id === 'Ogółem') && 500,
            color: posorneg("cost", (props.data.cost_direct - props.data.cost_direct_BU))
          }}>
          {props.data.cost_direct_BU &&
            (
              100 * (props.data.cost_direct - props.data.cost_direct_BU) /
              Math.abs(props.data.cost_direct_BU)
            ).toLocaleString("pl-PL", {
              maximumFractionDigits: 2, minimumFractionDigits: 2
            })}
        </TableCell>
        <TableCell align="right"
          style={{ fontWeight: (open || props.data.opk_id === 'Ogółem') && 500 }}>
          {props.data.cost_direct.toLocaleString("pl-PL", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </TableCell>
        <TableCell style={{ padding: "0px", borderRadius: "0px 5px 5px 0px" }}>
          <div
            style={{
              height: "40px",
              width: "130px",
              padding: "0px",
            }}
          >
            {props.data.monthly && (
              <ResponsiveContainer>
                <BarChart
                  style={{ padding: "0px", margin: "0px" }}
                  width={250}
                  height={60}
                  data={props.data.monthly}
                >
                  <Tooltip content={<CustomTooltip />} />
                  <XAxis dataKey="MONTH" hide={true} />
                  <Bar
                    dataKey="cost_direct"
                    fill="#0B6471"
                    barSize={250 / (1.5 * 12)}
                  >
                    {props.data.monthly.map((entry, index) => (
                      <Cell
                        cursor="pointer"
                        fill={entry.cost_direct < 0 ? "#FE8E3C" : "#cee0e3"}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </TableCell>
      </TableRow>

      {open &&
        props.data.children
          .sort((a, b) => {
            return b.cost_direct - a.cost_direct;
          })
          .map((row) => <Row key={row.account_name} row={row} lvl={0} />)}
    </React.Fragment>
  );
}

const CostDetailsTable = (props) => {
  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
        <Typography
          style={{
            color: "#0b6471",
            fontSize: "16px",
            fontWeight: "100",
            borderBottom: "0px solid",
            paddingLeft: "10px",
            paddingTop: "5px",
          }}
        >
          {props.title || 'KOSZTY'}
        </Typography>

      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead style={{ border: "none" }}>
            <TableRow>
              <TableCell
                style={{
                  borderBottom: "0px solid",
                  width: "2px",
                }}
              />

              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                  borderBottom: "0px solid",
                  minWidth: "140px",
                }}
              >
                Indeks
              </TableCell>

              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                  borderBottom: "0px solid",
                }}
              >
                Nazwa
              </TableCell>

              <TableCell
                style={{ color: "#0b6471", borderBottom: "5px double" }}
                align="right"
              >
                BU
              </TableCell>
              <TableCell
                style={{ color: "#0b6471", borderBottom: "1px solid" }}
                align="right"
              >
                Δ %
              </TableCell>
              <TableCell
                style={{ color: "#0b6471", borderBottom: "4px solid" }}
                align="right"
              >
                WYK
              </TableCell>
              <TableCell
                style={{
                  color: "#0b6471",
                  borderBottom: "4px solid",
                  width: "150px",
                }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((account) => (
              <RowKB data={account} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CostDetailsTable;
