import React from "react";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { BarChart, Cell, XAxis, Bar, Tooltip } from "recharts";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const CustomTooltip = (props) => {

  const { active } = props;
  if (active) {
    const { payload, label } = props;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "rgba(100,100,100,0.8)",
          color: "white",
          padding: "3px",
          borderRadius: "5px",
        }}
      >
        <p className="label">{`${label} : ${payload[0].value.toLocaleString(
          "pl-PL",
          { maximumFractionDigits: 0 }
        )}`}</p>
      </div>
    );
  } else return null;

};

const TinyChart = (props) => {
  let posorneg =
    props.budget && props.budget.substring(0, 1) === "-" ? false : true;
  if (props.datatype === "cost") posorneg = !posorneg;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      display: 'none',
      backgroundColor: "white",
      color: "black",
      maxWidth: 600,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "100",
      //border: '1px solid #FE8E3C',
      boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
    },
  }))(MTooltip);

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        paddingTop: "5px",
        paddingBottom: "0px",
        paddingLeft: "5px",
        border: "0px dotted orange",
        width: "270px",
        marginBottom: props.marginBottom ? props.marginBottom : '10px',
        marginRight: props.marginRight ? props.marginRight : '10px',
        height: props.height,

      }}
    >
      <HtmlTooltip
        arrow
        placement="bottom-start"
        title={
          <React.Fragment>
            <Typography>{props.tipTitle}</Typography>
            {props.tipBody}
          </React.Fragment>
        }
      >
        <Typography
          style={{
            color: "#0b6471",
            paddingLeft: "5px",
            fontSize: "16px",
            textDecoration: "none",
            fontWeight: "100",
            paddingBottom: "0px",
          }}
        >
          {props.title}
        </Typography>
      </HtmlTooltip>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px",
          paddingTop: "5px",
          border: "0px solid red",
          width: "250px",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography
            style={{
              color: "#0b6471",
              fontSize: "20px",
              fontWeight: "500",
              marginRight: "0px",
            }}
          >
            {props.actual}
          </Typography>
        </div>
        {props.budget && (
          <div style={{ display: "flex" }}>
            <Typography
              style={{
                color: "#0b6471",
                fontSize: "25px",
                marginTop: "-3px",
                marginRight: "5px",
              }}
            >
              {`Δ`}
            </Typography>
            <Typography
              style={{
                color: "#0b6471",
                fontSize: "20px",
              }}
            >
              {`BU`}
            </Typography>
            <Typography
              style={{
                color: posorneg ? "#0b6471" : "#FE8E3C",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: "5px",
              }}
            >
              {props.budget}
            </Typography>
          </div>
        )}
      </div>

      <BarChart
        style={{ paddingTop: "0px" }}
        width={250}
        height={60}
        data={props.data.monthlydata}
      >
        <Tooltip content={<CustomTooltip />} />
        <XAxis dataKey="MONTH" hide={true} />
        <Bar
          name={props.title}
          dataKey={props.datatype}
          fill={props.color}
          barSize={250 / (1.5 * 12)}
        >
          {props.data.monthlydata.map((entry, index) => (
            <Cell
              key={index}
              cursor="pointer"
              fill={entry[props.datatype] < 0 ? "#FE8E3C" : props.color}
            />
          ))}
        </Bar>
      </BarChart>
    </Paper>
  );
};

export default TinyChart;
