import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SettingsIcon from "@material-ui/icons/Settings";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import "./register.css";
import { apiurl } from '../app/prefs.js';

export default function RegisterEvent() {
  const matchesWidth = useMediaQuery("(min-width:600px)");
  const [agreed, setAgreed] = React.useState(false);
  const [agreedOptional, setAgreedOptional] = React.useState(false);
  const handleAgreedChange = () => setAgreed(!agreed);
  const handleAgreedOptionalChange = () => setAgreedOptional(!agreedOptional);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const handleClose = (event, reason) => {
    setInfoOpen(false);
  };
  const [data, setData] = React.useState({
    companyname: "",
    personname: "",
    personposition: "",
    email: "",
  });

  const handleDataChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    event.target.name === "email" &&
      ValidateEmail(event.target.value) &&
      setEmailError(false);
    event.target.name === "email" &&
      !ValidateEmail(event.target.value) &&
      setEmailError(true);
  };

  const {
    companyname,
    personname,
    personposition,
    email,
  } = data;

  const error =
    [
      companyname,
      personname,
      personposition,
      email,
    ].filter((v) => v).length !== 4;

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const subscribe = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(Object.assign(data, { agreedOptional: agreedOptional })),
    };
    if (ValidateEmail(email)) {
      fetch(`${apiurl}/register/event/`, requestOptions)
        .then((response) => response.text)
        .then(() => {
          setInfoOpen(true);
          setData({
            companyname: "",
            personname: "",
            personposition: "",
            email: "",
          });
          setAgreed(false);
          setAgreedOptional(false);
        });
    } else setEmailError(true);
  };

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ backgroundColor: "#0b6471" }}
      />
    );
  }

  return (
    <div>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #0b6471)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <SettingsIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography
            variant="h5"
            style={{
              color: "white",
              fontSize: matchesWidth ? "22px" : "18px",
            }}
          >
            Rejestracja: Standard Rachunku Kosztów - Korzyści dla Dyrekcji
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <div
        style={{
          width: "100%",
          overflow: "auto",
          padding: "0px",
        }}
      >
        <FormControl required error={error} component="fieldset">
          <Paper
            style={{
              display: "flex",
              position: "relative",
              width: "100%",
              flexDirection: "column",
              maxWidth: "900px",
              padding: "5px",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{
                marginTop: "20px",
                paddingLeft: "0px",
                paddingRight: "10px",
                borderRadius: "0px",
                borderBottom: "2px solid darkgray",
              }}
            >
              INFORMACJE O WEBINARIUM DLA CZŁONKÓW PFSz
            </Typography>
            <Typography variant='body1' style={{ marginTop: '10px', marginBottom: '0px' }}>
              <strong>Termin:</strong> 29 kwietnia 2021 (czwartek), godzina 10:00 - 12:00
              <br />
              <strong>Organizator:</strong> COplus Sp. z o.o.
              <br />
              <strong>Prowadzący:</strong> dr Anna Szymańska
              <br />
              <br />
              <u>Udział bezpłatny</u>
              <br />
              <br />
              <strong>Agenda</strong>
              <br />
              <ol>
                <li>Standard rachunku kosztów – podstawowe informacje</li>
                <li>Wymagane etapy alokacji kosztów:
                  <ol type='a'>
                    <li>Alokacje kosztów komórek pomocniczych</li>
                    <li>Alokacje kosztów komórek proceduralnych</li>
                    <li>Alokacja kosztów zarządu</li>
                  </ol>
                </li>
                <li>Wycena procedur medycznych – koszt bezpośredni i pośredni procedury</li>
                <li>CO+ - program controllingowy umożliwiający wdrożenie wytycznych rozporządzenia MZ 
                  w sprawie zaleceń dotyczących standardu rachunku kosztów u świadczeniodawców</li>
                <li>Raporty zarządcze na podstawie standardu rachunku kosztów</li>
                <li>Pytania i odpowiedzi</li>
              </ol>

            </Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              style={{
                marginTop: "20px",
                paddingLeft: "0px",
                paddingRight: "10px",
                borderRadius: "0px",
                borderBottom: "2px solid darkgray",
              }}
            >
              FORMULARZ ZGŁOSZENIOWY
            </Typography>
            <TextField
              name="companyname"
              value={companyname}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Nazwa świadczeniodawcy"
              helperText=""
              margin="none"
              size="small"

            />
            <TextField
              name="personname"
              value={personname}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Imię i nazwisko"
              helperText=""
              margin="none"
              size="small"

            />
            <TextField
              name="personposition"
              value={personposition}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Stanowisko"
              helperText=""
              margin="none"
              size="small"

            />
            <TextField
              name="email"
              value={email}
              error={emailError}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Adres e-mail"
              helperText=""
              margin="none"
              size="small"

            />
            <FormHelperText style={{ marginTop: "15px", marginBottom: "15px" }}>
              Wszystkie powyższe pola są wymagane
            </FormHelperText>
            <FormControlLabel
              style={{ fontSize: "4px" }}
              control={
                <Checkbox
                  color="primary"
                  checked={agreed}
                  onChange={handleAgreedChange}
                  name="agreed"
                />
              }
              label={
                <Typography style={{ lineHeight: "1.5em", fontSize: "14px" }}>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych przez COplus Sp. z o.o.,
                  w celu przeprowadzenia webinarium i jego prawidłowej organizacji (wymagane).
                </Typography>
              }
            />
            <FormControlLabel
              style={{ fontSize: "4px" }}
              control={
                <Checkbox
                  color="primary"
                  checked={agreedOptional}
                  onChange={handleAgreedOptionalChange}
                  name="agreed"
                />
              }
              label={
                <Typography style={{ lineHeight: "1.5em", fontSize: "14px" }}>
                  Wyrażam zgodę na przetwarzanie danych osobowych w celu
                  informowania o przyszłych webinariach, szkoleniach,
                  publikacjach, nowych funkcjonalnościach aplikacji CO+.
                </Typography>
              }
            />
            <Typography variant='body2' style={{ marginTop: '10px', marginBottom: '10px' }}>
              Administratorem Pani/Pana danych osobowych jest COplus Sp. z o.o.
              Przetwarzanie danych osobowych dotyczy danych podanych w formularzu zgłoszeniowym.
              Podane dane osobowe będą przetwarzane zgodnie z obowiązującymi przepisami prawa.
              Powyższe dane osobowe są niezbędne do prawidłowego wykonania usług.
              Przetwarzanie danych osobowych dla celów prawidłowego wykonania usług będzie odbywać się na podstawie wyraźnej i dobrowolnej zgody.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={!agreed || error || emailError}
              onClick={() => subscribe()}
            >
              Zarejestruj się
            </Button>
          </Paper>
        </FormControl>
      </div>
      <Snackbar open={infoOpen} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Dziękujemy za rejestrację! Wkrótce otrzymasz potwierdzenie na wskazany
          adres email.
        </Alert>
      </Snackbar>
    </div>
  );
}
