import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import { DatePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import {
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "animate.css";
import ProfitTable from "./ProfitTable";
import TinyChart from "./TinyChart";
import { CircularProgress } from "@material-ui/core";
import { apiurl } from '../app/prefs.js';
import MonthSelect from "./MonthSelection";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    display: 'none',
    backgroundColor: "white",
    color: "black",
    maxWidth: 600,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "100",
    //border: '1px solid #FE8E3C',
    boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
  },
}))(MTooltip);

const RankingChart = (props) => (
  <Paper
    style={{
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      padding: "0px",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "5px",
      border: "0px dotted orange",
      minWidth: "220px",
      width: "100%",
      //maxWidth: "1024px",
      height: "360px",
      marginBottom: "10px",
      marginRight: "0px",
      flexShrink: "1",
      flexGrow: "1",
    }}
  >
    <HtmlTooltip
      arrow
      placement="bottom-start"
      title={
        <React.Fragment>
          <Typography>{props.tipTitle}</Typography>
          {props.tipBody}
        </React.Fragment>
      }
    >
      <Typography
        style={{
          color: "#0b6471",
          paddingLeft: "5px",
          fontSize: "16px",
          textDecoration: "none",
          fontWeight: "100",
          paddingBottom: "5px",
        }}
      >
        {props.title}
      </Typography>
    </HtmlTooltip>
    <ResponsiveContainer>
      <BarChart
        style={{ fontFamily: "Roboto", fontSize: "12px" }}
        layout="vertical"
        //width={190}
        //height={310}
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <XAxis hide={true} type="number" />
        <YAxis
          hide={true}
          dataKey="opk_name"
          stroke=""
          type="category"
          width={0}
          /*ticks={data.map((item, index, all) => {
        return index < 3 || index > all.length - 4 ? item.YARD : "";
      })}*/
          tick={false}
        />
        <Tooltip
          formatter={(value) =>
            new Intl.NumberFormat("pl", { maximumFractionDigits: 0 }).format(
              value
            )
          }
        />

        <Bar
          name="Wynik"
          dataKey="profit"
          barSize={310 / (1.5 * props.data.length)}
          fill={props.color}
        >
          {props.data.map((entry, index) => (
            <Cell
              cursor="pointer"
              fill={entry.profit < 0 ? "#FE8E3C" : props.color}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </Paper>
);

export default function Report011() {
  const [data, setData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [yardList, setYardList] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  const [activeYard, setActiveYard] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [toolTipsEnabled, setToolTipsEnabled] = React.useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);

  const handleToolTipChange = () => {
    setToolTipsEnabled(!toolTipsEnabled);
  };

  const loadData = (opk, year, months) => {
    setLoaded(false);
    const currentMonths = months ? months : selectedMonths;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/rdyr2/${opk.opk_id}/${year}/${JSON.stringify(currentMonths)}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoaded(true);
        });
    });
  }

  function loadDataWithMonths(year, months) {
    activeYard && loadData(activeYard, year, months)
  }

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Grupa OPK" />}
        onChange={(event, value) => {
          if (value) {
            setActiveYard(value);
            loadData(value, startDate.year);
          }
        }}
      />
    );
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '15px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  React.useEffect(() => {
    let mounted = true;
    setLoaded(false);
    const apiUrl = `${apiurl}/rdyr2/list/2020`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          {
            if (mounted) {
              setYardList(fetchedData[0].opklist);
              setDateList(fetchedData[0].datelist);
              if ((fetchedData[0].opklist && fetchedData[0].opklist.length !== 0) &&
                (fetchedData[0].datelist && fetchedData[0].datelist.length !== 0)) {
                setActiveYard(fetchedData[0].opklist[0]);
                setStartDate(fetchedData[0].datelist[fetchedData[0].datelist.length - 1])
                loadData(fetchedData[0].opklist[0], fetchedData[0].datelist[fetchedData[0].datelist.length - 1].year);
              }
            }
          }
        });
    });
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const SummaryTable = (props) => (
    <Paper
      style={{
        minWidth: "410px",
        paddingTop: "5px",
        marginBottom: "10px",
        marginRight: "10px",
      }}
    >
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead style={{ border: "none" }}>
            <TableRow>
              <HtmlTooltip
                arrow
                placement="bottom-start"
                disableHoverListener="true"
                title={
                  <React.Fragment>
                    <Typography>{props.tipTitle}</Typography>
                    {props.tipBody}
                  </React.Fragment>
                }
              >
                <TableCell
                  style={{
                    color: "#0b6471",
                    fontSize: "15px",
                    letterSpacing: "-1px",
                    fontWeight: "100",
                  }}
                >
                  {props.title}
                </TableCell>
              </HtmlTooltip>

              <TableCell style={{ color: "#0b6471" }} align="right">
                Wynik
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow
                key={row.opk_name}
                className="animate__animated animate__fadeIn"
                //class="MuiTableRow"
                hover={true}
              >
                <TableCell component="th" scope="row">
                  {row.opk_name}
                </TableCell>
                <TableCell align="right">
                  {row.profit.toLocaleString("pl-PL", {
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <div style={{ minHeight: '650px' }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            WYNIKI OPK
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "300", color: "white" }}
          >
            (BU, WYK) w zł.
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <div
        style={{
          width: "100%",
          //height: "650px",
          //maxheight: "350px",
          overflow: "auto",
          padding: "5px",
        }}
      >
        <div style={{ display: "flex", position: "relative", width: "100%" }}>
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <MonthSelect setSelectedMonthsFunction={setSelectedMonths} loadDataFunction={loadDataWithMonths} selectedMonthsProperty={selectedMonths} startDateProperty={startDate} />
          <div style={{ position: "absolute", right: "15px" }}>

            <IconButton edge='end' target='_blank' href='/usermanual/opis_raportu_006'>
              <ImportContactsIcon />
            </IconButton>

          </div>
        </div>
        <p style={{ height: "5px" }} />

        {loaded && data[0].yearlydata.profit ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  maxWidth: "840px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TinyChart
                    title="WYNIK"
                    data={data[0]}
                    datatype="profit"
                    actual={
                      data[0].yearlydata.profit.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + " zł"
                    }
                    budget={
                      data[0].budgetdata.profit_BU.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + "%"
                    }
                    color="#4E97A5"
                  />
                  <TinyChart
                    title="PRZYCHODY"
                    data={data[0]}
                    datatype="income"
                    actual={
                      data[0].yearlydata.income.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + " zł"
                    }
                    budget={
                      data[0].budgetdata.income_BU.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + "%"
                    }
                    color="#BFFBFF"
                  />
                  <TinyChart
                    title="KOSZTY"
                    data={data[0]}
                    datatype="cost"
                    actual={
                      data[0].yearlydata.cost.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + " zł"
                    }
                    budget={
                      data[0].budgetdata.cost_BU.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + "%"
                    }
                    color="#0B6471"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "stretch",
                    border: "0px solid red",
                    width: "830px",
                  }}
                >
                  <SummaryTable
                    title="NAJBARDZIEJ RENTOWNE OPK"
                    data={data[0].opkranking.slice(0, 3)}
                  />
                  <SummaryTable
                    title="NAJMNIEJ RENTOWNE OPK"
                    data={data[0].opkranking.slice(-3).reverse()}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  flexWrap: "wrap",
                  width: "220px",
                  flexDirection: "row",
                }}
              >
                <RankingChart
                  title="RANKING OPK WG WYNIKU"
                  color="#4E97A5"
                  data={data[0].opkranking}
                  opk_name="a"
                />
              </div>
            </div>
            <ProfitTable
              title="RANKING WG WYNIKU"
              tipTitle={null}
              tipBody={
                <div
                  style={{
                    //boxShadow: "10px 10px 16px -5px rgba(94,94,94,1)",
                    fontFamily: "Roboto",
                    fontWeight: "200",
                    lineHeight: "22px",
                    padding: "10px",
                  }}
                >
                  <div style={{ marginBottom: "10px" }}>
                    Tabela prezentuje ranking OPK wg wyniku.
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <b style={{ fontWeight: "400" }}>BU</b> - wartość budżetu
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <b style={{ fontWeight: "400" }}>Δ % - wartość dodatnia</b>{" "}
                    - odchylenie pozytywne - oznacza wykonanie większe niż
                    budżet
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <b style={{ fontWeight: "400" }}>Δ % </b> {` - `}
                    <b style={{ fontWeight: "400", color: "#FE8E3C" }}>
                      wartość ujemna{" "}
                    </b>
                    - odchylenie negatywne - oznacza wykonanie mniejsze niż
                    budżet
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <b style={{ fontWeight: "400" }}>Wynik</b> - wartość
                    wykonana
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <b style={{ fontWeight: "400" }}>
                      Udział % OPK w przychodzie
                    </b>{" "}
                    - udział wybranego OPK w przychodzie grupy, dla której
                    wykonano raport. Im większy % tym większy udział OPK.
                  </div>
                  <div>
                    <b style={{ fontWeight: "400" }}>Udział % OPK w koszcie</b>{" "}
                    - udział wybranego OPK w kosztach grupy, dla której wykonano
                    raport. Im większy % ym większy udział OPK.
                  </div>
                </div>
              }
              data={data[0].ranking_by_profit}
              columns={[
                {
                  name: "OPK",
                  align: "left",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "OPK",
                  datalen: "",
                },
                {
                  name: "BU",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "5px double" },
                  datadef: "profit_BU",
                  datalen: 0,
                },
                {
                  name: "Δ %",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "profit_delta",
                  datalen: 2,
                },
                {
                  name: "Wynik",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "4px solid" },
                  datadef: "profit_ACT",
                  datalen: 0,
                },
                {
                  name: "Udział % OPK w przychodzie",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "income_share",
                  datalen: 2,
                },
                {
                  name: "Udział % OPK w koszcie",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "cost_share",
                  datalen: 2,
                },
              ]}
            />
            <ProfitTable
              title="RANKING WG ODCHYLENIA WYNIKU OD BUDŻETU"
              data={data[0].ranking_by_delta}
              columns={[
                {
                  name: "OPK",
                  align: "left",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "OPK",
                  datalen: "",
                },
                {
                  name: "BU",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "5px double" },
                  datadef: "profit_BU",
                  datalen: 0,
                },
                {
                  name: "Δ zł",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "profit_delta",
                  datalen: 0,
                },
                {
                  name: "Wynik",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "4px solid" },
                  datadef: "profit_ACT",
                  datalen: 0,
                },
              ]}
            />
            <ProfitTable
              title="RANKING WG KOSZTÓW"
              data={data[0].ranking_by_cost}
              dataType="cost"
              columns={[
                {
                  name: "OPK",
                  align: "left",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "OPK",
                  datalen: "",
                },
                {
                  name: "BU",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "5px double" },
                  datadef: "cost_BU",
                  datalen: 0,
                },
                {
                  name: "Δ %",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "cost_delta",
                  datalen: 2,
                },
                {
                  name: "Koszt",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "4px solid" },
                  datadef: "cost_ACT",
                  datalen: 0,
                },
                {
                  name: "Udział % OPK w przychodzie",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "income_share",
                  datalen: 2,
                },
                {
                  name: "Wynik",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "profit_ACT",
                  datalen: 2,
                },
              ]}
            />
            <ProfitTable
              title="RANKING WG PRZYCHODÓW"
              data={data[0].ranking_by_income}
              columns={[
                {
                  name: "OPK",
                  align: "left",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "OPK",
                  datalen: "",
                },
                {
                  name: "BU",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "5px double" },
                  datadef: "income_BU",
                  datalen: 0,
                },
                {
                  name: "Δ %",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "income_delta",
                  datalen: 2,
                },
                {
                  name: "Przychód",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "4px solid" },
                  datadef: "income_ACT",
                  datalen: 0,
                },
                {
                  name: "Udział % OPK w koszcie",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "cost_share",
                  datalen: 2,
                },
                {
                  name: "Wynik",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "profit_ACT",
                  datalen: 2,
                },
              ]}
            />
            {activeYard.opk_id === '504' && <ProfitTable
              title="RANKING WG KOSZTU OSOBODNIA"
              data={data[0].ranking_by_cost_per_hd}
              columns={[
                {
                  name: "OPK",
                  align: "left",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "OPK_name",
                  datalen: "",
                },
                {
                  name: "Liczba osobodni",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "hospital_days",
                  datalen: 0,
                },
                {
                  name: "Koszt osobodnia",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "4px solid" },
                  datadef: "cost_per_hd",
                  datalen: 0,
                },
              ]}
            />}
            <ProfitTable
              title="RANKING KOSZTÓW WYNAGRODZEŃ WG GRUP PRACOWNICZYCH"
              data={data[0].salaries_by_group}
              dataType="cost"
              columns={[
                {
                  name: "Grupa pracownicza",
                  align: "left",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "group_name",
                  datalen: "",
                },
                {
                  name: "BU",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "5px double" },
                  datadef: "cost_bu",
                  datalen: 0,
                },
                {
                  name: "Δ %",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "1px solid" },
                  datadef: "cost_delta",
                  datalen: 2,
                },
                {
                  name: "Koszt wynagrodzeń",
                  align: "right",
                  style: { color: "#0b6471", borderBottom: "4px solid" },
                  datadef: "cost",
                  datalen: 0,
                },
              ]}
            />
          </div>
        ) : (
          <div style={{ overflow: 'hidden' }}>
            {activeYard ?
              (!loaded ? <CircularProgress style={{ display: "block", margin: "auto", overflow: 'hidden' }}>
                Wczytywanie danych raportu
                </CircularProgress> : <Typography variant='h5'>Brak danych</Typography>
              )
              :
              <Typography variant='h5'>Wybierz Grupę OPK</Typography>
            }
          </div>
        )}
      </div>
    </div>
  );
}
