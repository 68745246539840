import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SchoolIcon from "@material-ui/icons/School";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import "animate.css";
import ArticleContent from "../startup/startup001content.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./startup.css";

export default function Startup001() {
  const matchesWidth = useMediaQuery("(min-width:600px)");
  window.scrollTo(0, 0);

  return (
    <div>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "75px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <SchoolIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography
            variant="h5"
            style={{ color: "white", fontSize: matchesWidth ? "22px" : "18px" }}
          >
            Standard rachunku kosztów - pilotaż
          </Typography>

        </div>
      </div>
      <p style={{ height: "5px" }} />
      <div
        style={{
          width: "100%",
          //height: "650px",
          //maxheight: "350px",
          overflow: "auto",
          padding: "0px",
        }}
      >
        <Paper
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            minHeight: "500px",
            maxWidth: "800px",
            padding: "20px",
          }}
        >
          <div style={{ position: "absolute", right: "15px" }}>
            <IconButton edge="end">
              <ShareIcon />
            </IconButton>
            {matchesWidth && (
              <IconButton edge="end">
                <PrintIcon />
              </IconButton>
            )}
          </div>
          <ArticleContent />
        </Paper>
      </div>
    </div>
  );
}
