import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";

import Paper from "@material-ui/core/Paper";
import { BsPeopleFill } from "react-icons/bs";
import { usePopupState } from "material-ui-popup-state/hooks";
import "animate.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import ToolTip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { useConfirm } from "material-ui-confirm";

import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import { apiurl } from '../app/prefs.js';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

let periodsList = [
    {
        year: "2020",
        month: "1",
        income: 1000000,
        cost: 950000,
    },
    {
        year: "2020",
        month: "2",
        income: 1100000,
        cost: 1050000,
    },
];


export default function AppPeriodsTable() {
    const [periods, setPeriods] = React.useState(null);
    const [selectedPeriods, setSelectedPeriods] = React.useState([]);
    const [dataLoading, setDataLoading] = React.useState(false);
    const { getAccessTokenSilently, user } = useAuth0();
    const confirm = useConfirm();

    const getDataFromSource = () => {
        getAccessTokenSilently().then((token) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
            };
            setDataLoading(true);
            fetch(`${apiurl}/periodslist/`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.CONTENT_SUMMARY) { setPeriods(data.CONTENT_SUMMARY) } else { setPeriods(null) };
                    setDataLoading(false);

                });
        })
    }

    const cleanData = () => {
        confirm({
            description:
                "Usunięcie danych wiąże się ze skasowaniem ich z bazy danych. Tej operacji nie można cofnąć. Kontynuować?",
            title: "Wymagane potwierdzenie",
            cancellationText: "Anuluj",
            confirmationText: "Tak, usuń",
            dialogProps: { style: { zIndex: 10000 } },
        }).then(() =>
            getAccessTokenSilently().then((token) => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                    body: JSON.stringify(selectedPeriods),
                };
                setDataLoading(true);
                fetch(`${apiurl}/cleandata/`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        //console.log(data);
                        setSelectedPeriods([]);
                        getDataFromSource();
                        setDataLoading(false);

                    });
            }))
            .catch(() => console.log());
    }

    React.useEffect(() => {
        getDataFromSource();
    }, []);

    const changeSelectionState = (node) => {
        if (selectedPeriods.includes(node.year + ':' + node.month)) {
            setSelectedPeriods((prevState) => prevState.filter(element => element !== node.year + ':' + node.month))
        }
        else {
            setSelectedPeriods((prevState) => ([...prevState, node.year + ':' + node.month]))
        }
    }

    const PeriodsTable = (props) => {
        return (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Nr</TableCell>
                            <TableCell>Rok</TableCell>
                            <TableCell>Miesiąc</TableCell>
                            <TableCell align='right'>Przychód</TableCell>
                            <TableCell align='right'>Koszt bezpośredni</TableCell>
                            <TableCell align='right'>Koszt rozliczony</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.sort((a, b) => (12 * b.year - 12 * a.year) + (b.month - a.month)).map((row, id) => (
                            <TableRow key={id}>
                                <TableCell padding="checkbox" style={{ padding: "0 0 0 4" }}>
                                    <Checkbox
                                        style={{ color: "black" }}
                                        checked={selectedPeriods.includes(row.year + ':' + row.month)}
                                        onClick={(event) => changeSelectionState(row)}
                                    />
                                </TableCell>
                                <TableCell>{id + 1}</TableCell>
                                <TableCell>{row.year}</TableCell>
                                <TableCell>{row.month}</TableCell>
                                <TableCell align='right'>{row.income.toLocaleString('pl-PL', { maximumFractionDigits: 2 })}</TableCell>
                                <TableCell align='right'>{row.cost_direct.toLocaleString('pl-PL', { maximumFractionDigits: 2 })}</TableCell>
                                <TableCell align='right'>{row.cost_processed.toLocaleString('pl-PL', { maximumFractionDigits: 2 })}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    };

    return (
        <div>
            {(periods) ? <Box style={{
                padding: "5px", minHeight: "600px", transition: "opacity 0.5s",
                opacity: !dataLoading ? "1" : "0.2",
            }}>
                <div
                    style={{
                        display: "flex",
                        flex: "auto",
                        alignItems: "flex-start",
                        flexDirection: "column",
                    }}
                ></div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography>
                        Wybrane okresy: <strong>{selectedPeriods.length}</strong>
                    </Typography>
                    <Button
                        size="small"
                        color="primary"
                        disabled={periods.length < 1 || selectedPeriods.length === 0 || ((periods.length === selectedPeriods.length))}
                        variant="contained"
                        onClick={() => {
                            cleanData();
                        }}
                        style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                            marginTop: "10px",
                            justifySelf: "flex-start",
                        }}
                    >
                        Usuń dane dla zaznaczonych okresów
                    </Button>
                </div>
                <PeriodsTable
                    data={periods} selection={selectedPeriods}
                />

            </Box> :
                <CircularProgress
                    style={{
                        position: "absolute",
                        height: "70px",
                        width: "70px",
                        top: periods ? "50%" : "120%",
                        left: "50%",
                        color: "#0b6471",
                        opacity: "0.6",
                    }}
                />
            }

        </div>
    );
}

