import React from "react";
import "./styles.css";
import { Checkbox, Input, Tooltip, Button } from "@material-ui/core";
import { FixedSizeList } from "react-window";

const FilterWindow = (props) => {
  const { closeFn, downloadFn, list, lookupPlaceholder } = props;
  const [fileLoading, setFileLoading] = React.useState(false);
  const [filterMask, setFilterMask] = React.useState("");
  const filterItemList = list.sort((a, b) => a.id > b.id ? 1 : -1);
  const [filteredItems, setFilteredItems] = React.useState(new Set([]));
  const filteredList = filterItemList.filter((i) =>
    i.id.includes(filterMask)
  );
  const [selectionStatus, setSelectionStatus] = React.useState("unchecked");

  const updateSelectionStatus = () => {
    const filteredCount = filteredList.length;
    const selectedCount = filteredList.filter((i) => filteredItems.has(i.id))
      .length;
    if (selectedCount === 0) {
      setSelectionStatus("unchecked");
    } else {
      if (selectedCount === filteredCount) {
        setSelectionStatus("checked");
      } else setSelectionStatus("indeterminate");
    }
  };

  React.useEffect(
    () => {
      updateSelectionStatus()
    }
  );

  const updateFilteredItems = (checked, item) => {
    const selected = new Set(filteredItems);
    if (checked) {
      selected.add(item);
    } else {
      selected.delete(item);
    }
    console.timeLog("check");
    setFilteredItems(selected);
  };

  const addRemoveSelection = (checked) => {
    const list = new Set(filteredItems);
    if (checked) {
      //add
      filterItemList
        .filter((i) => i.id.includes(filterMask))
        .forEach((item) => list.add(item.id));
      setSelectionStatus("checked");
    } else {
      //remove
      filterItemList
        .filter((i) => i.id.includes(filterMask))
        .forEach((item) => list.delete(item.id));
      setSelectionStatus("unchecked");
    }
    setFilteredItems(list);
  };

  function renderRow(props) {
    const { index, style } = props;

    return (
      <Tooltip title={filteredList[index].name}>
        <div
          className="virtualRow"
          style={style}
          key={filteredList[index].id}
        >
          <Checkbox
            size="small"
            style={{ margin: "0px", marginRight: "5px", padding: "0px" }}
            checked={filteredItems.has(filteredList[index].id)}
            onChange={(event, checked) =>
              updateFilteredItems(checked, filteredList[index].id)
            }
          />
          <> {filteredList[index].id} </>
        </div>
      </Tooltip>
    );
  }

  return (
    <div>
      <div style={{ display: fileLoading ? 'flex' : 'none', position: "absolute", width: '0px', height: "200px", textAlign: "center", left: '45%', top: "200px", fontSize: "2px" }}>
        <div id="wrapper">
          <div class="gears" id="two-gears">
            <div class="gears-container">
              <div class="gear-rotate"></div>
              <div class="gear-rotate-left"></div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "5px", opacity: fileLoading ? .2 : 1 }}>
        <Input
          fullWidth
          margin="dense"
          placeholder={lookupPlaceholder}
          style={{ marginBottom: "10px" }}
          onChange={(event) => setFilterMask(event.target.value)}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              height: "360px",
              width: "240px",
              marginRight: "5px"
            }}
          >
            <div style={{ marginBottom: "5px" }}>Dostępne:</div>
            <div
              style={{
                border: "1px solid gray",
                borderRadius: "3px",
                height: "336px",
                padding: "3px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px"
                }}
              >
                <Checkbox
                  size="small"
                  style={{ margin: "0px", marginRight: "5px", padding: "0px" }}
                  indeterminate={selectionStatus === "indeterminate"}
                  checked={selectionStatus === "checked"}
                  onChange={(event, checked) => addRemoveSelection(checked)}
                />
                <div
                  style={{
                    fontStyle: "italic",
                    color: "gray",
                    marginBottom: "0px"
                  }}
                >
                  Wybierz wszystkie
                </div>
              </div>
              <FixedSizeList
                height={300}
                width={220}
                itemSize={24}
                itemCount={filteredList.length}
                itemKey={(index) => filteredList[index].id}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </div>
          <div
            style={{
              height: "360px",
              width: "100%"
            }}
          >
            <div style={{ marginBottom: "5px" }}>Wybrane:</div>
            <div
              style={{
                border: "1px solid gray",
                borderRadius: "3px",
                width: "100%",
                height: "336px",
                overflow: "scroll"
              }}
            >
              <ul
                style={{ margin: "0px", padding: "5px", paddingLeft: "20px" }}
              >
                {filterItemList
                  .filter((i) => filteredItems.has(i.id))
                  .map((item, id) => {
                    return <li key={id}>{item.name}</li>;
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "5px",
            height: '30px',
          }}
        >
          <Button onClick={() => setFilteredItems(new Set([]))}>
            Kasuj wybór
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "120px"
            }}
          >
            <Button onClick={() => closeFn(false)}>Anuluj</Button>
            <Button disabled={filteredItems.size === 0} onClick={() => {
              setFileLoading(true);
              downloadFn(filterItemList
                .filter((i) => filteredItems.has(i.id))
                .map((item, id) => {
                  return item.id;
                }))
            }
            }>Pobierz</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWindow;