import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUITooltip from "@material-ui/core/Tooltip";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import TinyChart from "./TinyChart";

import OPKTable2 from "./OPKTable2";
import ProfitTable from "./ProfitTable";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { apiurl } from '../app/prefs.js';
import MonthSelect from "./MonthSelection";

function DriverTargets(props) {
  const { row } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(
    () => {
      setPage(0)
    }, [row]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomTooltip = (props) => {
    const { active } = props;
    if (active) {
      const { payload, label } = props;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(100,100,100,0.8)",
            color: "white",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <p className="label">{`${label} : ${payload[0].value.toLocaleString(
            "pl-PL",
            { maximumFractionDigits: 0 }
          )}`}</p>
        </div>
      );
    } else return null;
  };

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        ANALIZA ROZLICZENIA KOSZTÓW OPK POMOCNICZEGO
      </Typography>

      <TableContainer>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                OPK Docelowe
              </TableCell>
              <TableCell
                align="center"
                colSpan='2'
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                WYK Liczba jednostek klucza
              </TableCell>

              <TableCell
                align="center"
                colSpan='2'
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                WYK Koszt rozliczony
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {row &&
              row
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((child) => (
                  <TableRow key={child.opk_id} hover={true}>
                    <TableCell component="th" scope="row">
                      {child.opk_name}
                    </TableCell>
                    <TableCell align="right">
                      {child.wqty.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      })}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <div
                        style={{
                          height: "40px",
                          width: "130px",
                          padding: "0px",
                        }}
                      >
                        <ResponsiveContainer>
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={250}
                            height={60}
                            data={child.monthly}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="MONTH" hide={true} />
                            <Bar
                              dataKey="wqty"
                              fill="#0B6471"
                              barSize={250 / (1.5 * 12)}
                            >
                              {child.monthly.map((entry, index) => (
                                <Cell
                                  key={index}
                                  cursor="pointer"
                                  fill={entry.wqty < 0 ? "#FE8E3C" : "#0B6471"}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {child.cost_indirect.toLocaleString("pl-PL", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <div
                        style={{
                          height: "40px",
                          width: "130px",
                          padding: "0px",
                        }}
                      >
                        <ResponsiveContainer>
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={250}
                            height={60}
                            data={child.monthly}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="MONTH" hide={true} />
                            <Bar
                              dataKey="cost_indirect"
                              fill="#4E97A5"
                              barSize={250 / (1.5 * 12)}
                            >
                              {child.monthly.map((entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={entry.cost_indirect < 0 ? "#FE8E3C" : "#4E97A5  "}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={row && row.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

function DriverSummary(props) {
  const { row } = props;

  const CustomTooltip = (props) => {
    const { active } = props;
    if (active) {
      const { payload, label } = props;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(100,100,100,0.8)",
            color: "white",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <p className="label">{`${label} : ${payload[0].value.toLocaleString(
            "pl-PL",
            { maximumFractionDigits: 0 }
          )}`}</p>
        </div>
      );
    } else return null;
  };

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        KLUCZ PODZIAŁOWY OPK POMOCNICZEGO
      </Typography>

      <TableContainer>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Klucz
              </TableCell>
              <TableCell
                align="center"
                colSpan='2'
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                WYK Liczba jednostek klucza
              </TableCell>

              <TableCell
                align="center"
                colSpan='2'
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                WYK Stawka kosztów na jednostkę
              </TableCell>
              <TableCell
                align="center"
                colSpan='2'
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                WYK Koszt rozliczony
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row &&
              row
                .map((child) => (
                  <TableRow key={child.opk_id}>
                    <TableCell component="th" scope="row">
                      {child.driver}
                    </TableCell>
                    <TableCell align="right">
                      {child.wqty.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      })}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <div
                        style={{
                          height: "40px",
                          width: "130px",
                          padding: "0px",
                        }}
                      >
                        <ResponsiveContainer>
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={250}
                            height={60}
                            data={child.monthly}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="MONTH" hide={true} />
                            <Bar
                              dataKey="wqty"
                              fill="#0B6471"
                              barSize={250 / (1.5 * 12)}
                            >
                              {child.monthly.map((entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={entry.wqty < 0 ? "#FE8E3C" : "#0B6471"}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {child.rate.toLocaleString("pl-PL", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <div
                        style={{
                          height: "40px",
                          width: "130px",
                          padding: "0px",
                        }}
                      >
                        <ResponsiveContainer>
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={250}
                            height={60}
                            data={child.monthly}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="MONTH" hide={true} />
                            <Bar
                              dataKey="rate"
                              fill="#4E97A5"
                              barSize={250 / (1.5 * 12)}
                            >
                              {child.monthly.map((entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={entry.rate < 0 ? "#FE8E3C" : "#4E97A5  "}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {(child.cost).toLocaleString("pl-PL", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <div
                        style={{
                          height: "40px",
                          width: "130px",
                          padding: "0px",
                        }}
                      >
                        <ResponsiveContainer>
                          <BarChart
                            style={{ padding: "0px", margin: "0px" }}
                            width={250}
                            height={60}
                            data={child.monthly}
                          >
                            <Tooltip content={<CustomTooltip />} />
                            <XAxis dataKey="MONTH" hide={true} />
                            <Bar
                              dataKey='cost'
                              fill="#4E97A5"
                              barSize={250 / (1.5 * 12)}
                            >
                              {child.monthly.map((entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={entry.rate < 0 ? "#FE8E3C" : "#4E97A5  "}
                                />
                              ))}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default function Report006() {
  const [yardList, setYardList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState(null);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);


  const loadData = (opk, year, months) => {
    setActiveYard(opk);
    setReportDataLoaded(false);

    const currentMonths = months ? months : selectedMonths;

    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/supportunitdetails/${opk.opk_id}/${year}/${JSON.stringify(currentMonths)}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          //console.log(`${apiurl}/supportunitdetails/${opk.opk_id}/${year}/${currentMonths}`);
          //console.log("Report data loaded");
          setReportDataLoaded(true);
        });
    });
  }

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Komórka" />}
        onChange={(event, value) => {
          if (value) {
            if (startDate.year) {
              loadData(value, startDate.year);
            }
          }
        }}
      />
    );
  }

  function loadDataWithMonths(year, months) {
    activeYard && loadData(activeYard, year, months)
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        disabled={!props.data}
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            if (activeYard) loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/yearslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setDateList(fetchedData.datelist);
          if (fetchedData.datelist && fetchedData.datelist.length !== 0) {
            setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1]);
            //loadData(fetchedData.datelist[fetchedData.datelist.length - 1].year);
            setFiltersLoaded(true);
          }
        });
    });
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    setYardListLoaded(false);
    const apiUrl = `${apiurl}/supportunitlist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (mounted) {
            setYardList(fetchedData);
            loadFilters();
            setYardListLoaded(true);
          }
        });
    });
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Analiza OPK Pomocniczego
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "300", color: "white" }}
          >
            (BU, WYK) w zł.
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <MonthSelect setSelectedMonthsFunction={setSelectedMonths} loadDataFunction={loadDataWithMonths} selectedMonthsProperty={selectedMonths} startDateProperty={startDate} />
          <MUITooltip title='Opis raportu'>
            <a edge='end' style={{ marginTop: '14px', marginLeft: '10px', marginRight: '7px', color: '#0b6471' }} target='_blank' href='/usermanual/opis_raportu_003'>
              <ImportContactsIcon />
            </a>
          </MUITooltip>
        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {activeYard && reportData && reportData.yearlydata.cost ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="tiny charts area"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                flexDirection: "row",
                flexShrink: 1,
              }}
            >
              <Paper style={{ marginRight: '10px', marginBottom: '10px' }}>
                <TinyChart
                  title="KOSZTY"
                  marginBottom='0px'
                  marginRight='0px'
                  height='100%'
                  data={reportData}
                  datatype="cost"
                  actual={
                    reportData.yearlydata && reportData.yearlydata.cost && reportData.yearlydata.cost.toLocaleString("pl-PL", {
                      maximumFractionDigits: 0,
                    }) + " zł"
                  }
                  budget={
                    reportData.budgetData && reportData.budgetData.cost_BU && reportData.budgetdata.cost_BU.toLocaleString("pl-PL", {
                      maximumFractionDigits: 0,
                    }) + "%"
                  }
                  color="#0B6471"
                />
              </Paper>
              <ProfitTable
                title="RANKING KOSZTÓW WYNAGRODZEŃ WG GRUP PRACOWNICZYCH"
                data={reportData.salaries_by_group}
                dataType="cost"
                columns={[
                  {
                    name: "Grupa pracownicza",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "group_name",
                    datalen: "",
                  },
                  {
                    name: "BU",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "5px double" },
                    datadef: "cost_bu",
                    datalen: 0,
                  },
                  {
                    name: "Δ %",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "cost_delta",
                    datalen: 2,
                  },
                  {
                    name: "Koszt wynagrodzeń",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "4px solid" },
                    datadef: "cost",
                    datalen: 0,
                  },
                ]}
              />
            </div>
          </div>
          <OPKTable2 data={reportData} />
          {reportData.driver_summary && <DriverSummary row={reportData.driver_summary} />}
          {reportData.driver_summary_1 && <DriverSummary row={reportData.driver_summary_1} />}
          {reportData.driver_by_destination && <DriverTargets row={reportData.driver_by_destination} />}
          {reportData.driver_by_destination_1 && <DriverTargets row={reportData.driver_by_destination_1} />}
        </div>
      ) : (
        !activeYard && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left"
            }}
            variant="h5"
          >
            Wybierz komórkę
          </Typography>
        )
      )}
      {!reportDataLoaded && activeYard && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
