import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { apiurl } from '../app/prefs.js';
import "./register.css";

export default function Register(props) {
  let version = null
  if (props.match.params.id) {
    if (props.match.params.id === 'rozporzadzenie') version = 'Rozporządzenie';
    if (props.match.params.id === 'eprocedury') version = 'e-procedury';
    if (props.match.params.id === 'zaawansowany') version = 'Zaawansowany';
  };

  const matchesWidth = useMediaQuery("(min-width:600px)");
  const [agreed, setAgreed] = React.useState(false);
  const handleAgreedChange = () => setAgreed(!agreed);
  const [accepted, setAccepted] = React.useState(false);
  const handleAcceptedChange = () => setAccepted(!accepted);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const handleClose = (event, reason) => {
    setInfoOpen(false);
  };
  const [data, setData] = React.useState({
    regon: "",
    nip: "",
    nfzcode: "",
    companyname: "",
    city: "",
    postalcode: "",
    street: "",
    streetnumber: "",
    personname: "",
    personposition: "",
    email: "",
    phone: "",
    selectedversion: version
  });

  const handleDataChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    event.target.name === "email" &&
      ValidateEmail(event.target.value) &&
      setEmailError(false);
    event.target.name === "email" &&
      !ValidateEmail(event.target.value) &&
      setEmailError(true);
  };

  const {
    regon,
    nip,
    nfzcode,
    companyname,
    city,
    postalcode,
    street,
    streetnumber,
    personname,
    personposition,
    email,
    phone,
  } = data;

  const error =
    [
      regon,
      nip,
      nfzcode,
      companyname,
      city,
      postalcode,
      street,
      streetnumber,
      personname,
      personposition,
      email,
      phone,
    ].filter((v) => v).length !== 12;

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const subscribe = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    if (ValidateEmail(email)) {
      fetch(`${apiurl}/register/start/`, requestOptions)
        .then((response) => response.text)
        .then(() => {
          setInfoOpen(true);
          setData({
            regon: "",
            nip: "",
            nfzcode: "",
            companyname: "",
            city: "",
            postalcode: "",
            street: "",
            streetnumber: "",
            personname: "",
            personposition: "",
            email: "",
            phone: "",
            selectedversion: version
          });
          setAgreed(false);
          setAccepted(false);
        });
    } else setEmailError(true);
  };

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ backgroundColor: "#0b6471" }}
      />
    );
  }

  return version && (
    <div>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <SettingsIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography
            variant="h5"
            style={{
              color: "white",
              fontSize: matchesWidth ? "22px" : "18px",
            }}
          >
            Zamówienie - CO+ {version}
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <div
        style={{
          width: "100%",
          overflow: "auto",
          padding: "0px",
        }}
      >
        <FormControl required error={error} component="fieldset">
          <Paper
            style={{
              display: "flex",
              position: "relative",
              width: "100%",
              flexDirection: "column",
              maxWidth: "800px",
              padding: "5px",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "0px",
                borderBottom: "2px solid darkgray",
              }}
            >
              Dane świadczeniodawcy
            </Typography>

            <TextField
              name="regon"
              value={regon}
              onChange={handleDataChange}
              className="forminput"
              style={{ marginTop: "10px", maxWidth: "200px" }}
              type="number"
              label="REGON"
              placeholder="Nr REGON"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 9);
              }}
              min={0}
              required
            />
            <TextField
              name="nip"
              value={nip}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "200px" }}
              type="number"
              label="NIP"
              placeholder="Nr NIP"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              min={0}
              required
            />
            <TextField
              name="nfzcode"
              value={nfzcode}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "800px" }}
              type="text"
              label="Kod świadczeniodawcy nadawany przez oddział wojewódzki Narodowego Funduszu Zdrowia"
              placeholder="Kod świadczeniodawcy"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <TextField
              name="companyname"
              value={companyname}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Nazwa świadczeniodawcy"
              placeholder="Nazwa świadczeniodawcy"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <TextField
                name="city"
                value={city}
                onChange={handleDataChange}
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "400px",
                }}
                type="text"
                label="Miasto"
                placeholder="Miasto"
                helperText=""
                margin="none"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
              <TextField
                name="postalcode"
                value={postalcode}
                onChange={handleDataChange}
                style={{ marginTop: "10px", maxWidth: "200px" }}
                type="text"
                label="Kod pocztowy"
                placeholder="Kod pocztowy"
                helperText=""
                margin="none"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <TextField
                name="street"
                value={street}
                onChange={handleDataChange}
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "400px",
                }}
                type="text"
                label="Ulica"
                placeholder="Ulica"
                helperText=""
                margin="none"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
              <TextField
                name="streetnumber"
                value={streetnumber}
                onChange={handleDataChange}
                style={{ marginTop: "10px", maxWidth: "200px" }}
                type="text"
                label="Nr budynku i lokalu"
                placeholder="Nr budynku i lokalu"
                helperText=""
                margin="none"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </div>
            <Typography
              variant="subtitle1"
              color="primary"
              style={{
                marginTop: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "0px",
                borderBottom: "2px solid darkgray",
              }}
            >
              Dane osoby administrującej rachunkiem kosztów
            </Typography>
            <TextField
              name="personname"
              value={personname}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Imię i nazwisko"
              placeholder="Imię i nazwisko"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <TextField
              name="personposition"
              value={personposition}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Stanowisko"
              placeholder="Stanowisko"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <TextField
              name="email"
              value={email}
              error={emailError}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "700px" }}
              type="text"
              label="Adres e-mail"
              placeholder="Adres e-mail"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <TextField
              name="phone"
              value={phone}
              onChange={handleDataChange}
              style={{ marginTop: "10px", maxWidth: "300px" }}
              type="text"
              label="Telefon"
              placeholder="Telefon"
              helperText=""
              margin="none"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
            <FormHelperText style={{ marginTop: "15px", marginBottom: "15px" }}>
              Pola oznaczone gwiazdką są wymagane
            </FormHelperText>
            <FormControlLabel
              style={{ fontSize: "4px" }}
              control={
                <Checkbox
                  color="primary"
                  checked={accepted}
                  onChange={handleAcceptedChange}
                  name="accepted"
                />
              }
              label={
                <Typography style={{ lineHeight: "1.5em", fontSize: "14px" }}>
                  Akceptuję <a href='/regulamin' target='blank'>Regulamin korzystania z aplikacji CO+</a>
                </Typography>
              }
            />
            <FormControlLabel
              style={{ fontSize: "4px" }}
              control={
                <Checkbox
                  color="primary"
                  checked={agreed}
                  onChange={handleAgreedChange}
                  name="agreed"
                />
              }
              label={
                <Typography style={{ lineHeight: "1.5em", fontSize: "14px" }}>
                  Wyrażam zgodę na przetwarzanie danych osobowych w celu
                  rejestracji zamówienia oraz informowania o przyszłych szkoleniach,
                  publikacjach, nowych funkcjonalnościach aplikacji.
                </Typography>
              }
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!agreed || !accepted || error || emailError}
              onClick={() => subscribe()}
            >
              Zamów CO+ {version}
            </Button>
          </Paper>
        </FormControl>
      </div>
      <Snackbar open={infoOpen} autoHideDuration={20000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Dziękujemy za złożenie zamówienia! Wkrótce skontaktujemy się z Państwem w celu zawarcia umowy.
        </Alert>
      </Snackbar>
    </div>
  );
}
