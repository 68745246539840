import React from "react";
import Paper from "@material-ui/core/Paper";
import "animate.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { apiurl } from '../app/prefs.js';

const NewsLetter = (props) => {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [rodoInfoOpen, setRodoInfoOpen] = React.useState(false);
  const handleChange = (event) => {
    setEmail(event.target.value);
    if (emailError && ValidateEmail(event.target.value)) setEmailError(false);
  };

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ backgroundColor: "#0b6471" }}
      />
    );
  }

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleClose = (event, reason) => {
    setInfoOpen(false);
  };

  const handleRodoClose = (event, reason) => {
    setRodoInfoOpen(false);
  };

  const subscribe = () => {
    if (ValidateEmail(email)) {
      fetch(`${apiurl}/newsletter/subscribe/${email}`)
        .then((response) => response.text)
        .then(() => {
          setInfoOpen(true);
          setEmail("");
        });
    } else setEmailError(true);
  };

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        minHeight: "100px",
        maxWidth: props.maxwidth ? props.maxwidth : "800px",
        padding: "20px",
      }}
    >
      <strong
        style={{ fontFamily: "times", fontSize: "18px", color: "orange" }}
      >
        ZAPISZ SIĘ NA NEWSLETTER
      </strong>
      <br />
      <p style={{ fontSize: "16px" }}>
        Bądź na bieżąco z informacjami dotyczącymi standardu rachunku kosztów u
        świadczeniodawców.
      </p>
      <form
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
        size="small"
        noValidate
        autoComplete="off"
      >
        <TextField
          value={email}
          onChange={handleChange}
          style={{ minWidth: "250px", flexGrow: 1 }}
          id="standard-basic"
          error={emailError}
          helperText={emailError && "Nieprawidłowy adres email"}
          placeholder="podaj Twój adres e-mail..."
        />
        <Button onClick={subscribe} style={{ justifySelf: "end" }}>
          Zapisz
        </Button>
        <Typography variant="caption" display="block" gutterBottom>
          Zapisując się wyrażam zgodę na przetwarzanie moich danych osobowych w
          celach marketingowych przez COplus Sp. z o.o. zgodnie z Klauzulą
          Informacyjną, znajdującą się{" "}
          <a
            onClick={() => setRodoInfoOpen(true)}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            tutaj
          </a>
          .
        </Typography>
      </form>
      <Snackbar open={infoOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Twój adres został dodany do newslettera. Dziękujemy!
        </Alert>
      </Snackbar>
      <Snackbar
        
        open={rodoInfoOpen}
        onClose={handleRodoClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={{ padding: "15px", boxShadow: "8px 6px 24px 0px rgba(50, 50, 50, 0.64)", overflow: 'auto' }}>
          <div>
          <strong>Klauzula informacyjna</strong>
          <br />
          <br />
          Informacje dla osób fizycznych dotyczące ochrony ich danych osobowych
          na podstawie rozporządzenia Parlamentu Europejskiego o ochronie danych
          osobowych. Dokument ten zawiera podstawowe informacje na temat sposobu
          przetwarzania Państwa danych oraz praw, jakie Państwu zapewniają
          przepisy o ochronie danych osobowych. Treść dokumentu jest zgodna z
          Rozporządzeniem nr 2016/679 w sprawie ochrony danych osobowych (dalej
          „RODO”). Administratorem danych osobowych jest COplus Sp. z o.o. z
          siedzibą przy ul. Dębowej 60, 62-002 Suchy Las, dalej:
          “Administrator”. Kontakt do Administratora:
          <br/>
          <ul style={{padding:'10px'}}>
            <li>telefon: 514 132 420</li>
            <li>e-mail: mampytanie@coplus.pl</li>
          </ul>
          COplus Sp. z o.o. przetwarza Państwa dane osobowe (adres e-mail),
          które zgodnie z prawem otrzymaliśmy od Państwa poprzez formularz
          zapisu na newsletter na stronie www.coplus.pl, w celach
          marketingowych. Dostęp do Państwa danych (adres e-mail), w powyższych
          celach, mają pracownicy zatrudnieni przez COplus sp. z o.o.
          przestrzegając poufnych zobowiązań. Przekazywanie danych do odbiorców
          spoza Spółki, może nastąpić tylko wówczas, gdy jesteśmy do tego
          prawnie zobowiązani. Państwa dane będą przechowywane w celach
          marketingowych do czasu wycofania zgody. Każda osoba, której dane
          osobowe przetwarzamy, ma prawo dostępu do treści swoich danych, ich
          sprostowania (poprawiania, uzupełniania), ograniczenia ich
          przetwarzania lub usunięcia (poprzez wysłanie wiadomości do
          Administratora). Prawo dostępu oraz prawo do ich usunięcia są
          ograniczone na podstawie RODO. Ma Pan/ Pani prawo wniesienia skargi do
          Prezesa Urzędu ds. Ochrony Danych Osobowych, w przypadku gdy uznają
          Państwo, że przetwarzanie danych osobowych narusza przepisy RODO.
          <br/>
          <br/>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button variant='contained' size='small' color='primary' onClick={() => setRodoInfoOpen(false)}>Zamknij</Button>
          </div>
        </Paper>
      </Snackbar>
    </Paper>
  );
};

export default NewsLetter;
