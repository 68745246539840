import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
//import PrintIcon from "@material-ui/icons/Print";
//import ShareIcon from "@material-ui/icons/Share";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ResTable from '../procedures/restable';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownload';
import ToolTip from "@material-ui/core/Tooltip";
import { apiurl } from '../app/prefs.js';

export default function Report011() {
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataLoaded, setTotalDataLoaded] = React.useState(false);
  const [wardsList, setWardsList] = React.useState([]);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const { getAccessTokenSilently, user } = useAuth0();

  const [callbackData, setCallbackData] = React.useState('all');

  const loadData = () => {
    setTotalDataLoaded(false);
    const apiUrl = `${apiurl}/proceduresummary`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          let wards = [];
          fetchedData.procedures_summary.map(
            item => {
              if (wards.length === 0 || !wards.some(ward => ward.id === item.opk_id)) {
                wards.push({ id: item.opk_id, label: item.opk_name })
              }
            }
          )
          setWardsList(wards);
          setTotalData(fetchedData);
          setTotalDataLoaded(true);

        });
    });
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    loadFilters();
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);


  const loadFilters = () => {
    setFiltersLoaded(false);
    loadData();
    setFiltersLoaded(true);
  }

  const procedureheaders = [
    {
      name: "OPK Proceduralny",
      type: "text",
      edit: "false",
      format: "",
    },
    {
      name: "Procedura",
      type: "text",
      edit: "false",
      format: "",
    },
    {
      name: "Data zapisu",
      type: "text",
      edit: "false",
      format: "",
    },
    {
      name: "Czas trwania",
      type: "number",
      edit: "false",
      format: "",
    },
    {
      name: "Koszt bezpośredni",
      type: "number",
      edit: "false",
      format: "currency",
    },
    {
      name: "Koszt materiałów",
      type: "number",
      edit: "false",
      format: "currency",
    },
    {
      name: "Koszt personelu",
      type: "number",
      edit: "false",
      format: "currency",
    },
    {
      name: "Koszt bezpośredni (klucz)",
      type: "number",
      edit: "false",
      format: "currency",
    },
  ];

  const optionsData = [
    { label: "wszystkie", value: "all" },
    { label: "opisane", value: "ready" },
    { label: "nieopisane", value: "empty" }
  ];

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Koszty bezpośrednie procedur
          </Typography>
        </div>
      </div>

      {totalDataLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >

          {false && <div style={{ position: "absolute", right: "15px" }}>
            <IconButton edge='end' target='_blank' href='/usermanual/opis_raportu_007'>
              <ImportContactsIcon />
            </IconButton>
          </div>}

        </div>
      ) : (
        <div>Wczytywanie danych</div>
      )}

      {totalData && totalData.procedures_summary ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: totalDataLoaded ? "1" : "0.2",
            width: "100%"
          }}
        >
          <ResTable
            headers={procedureheaders}
            assignments={totalData.procedures_summary}
            resources={totalData.procedures_summary}
            optionsData={optionsData}
            dropdownFilterOptions={wardsList}
            downloadButton={true}
            dictkey={'row_id'}
            datakey={'row_id'}
            filterColumn={'Procedura'}
            readonly={true}
            callbackFn = {setCallbackData}
          />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <ToolTip title="Pobierz jako plik MS Excel" placement="top-start">
              <IconButton
                onClick={
                  () => {
                    getAccessTokenSilently().then((token) => {
                      fetch(`${apiurl}/procedurestoexcel/${callbackData}`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          Authorization: "Bearer " + token,
                        },
                      })
                        .then((response) => response.blob())
                        .then((blob) => {
                          // Create blob link to download
                          const url = window.URL.createObjectURL(
                            new Blob([blob]),
                          );
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute(
                            'download',
                            `Procedury.xlsx`,
                          );
                          // Append to html link element page
                          document.body.appendChild(link);
                          // Start download
                          link.click();
                          // Clean up and remove the link
                          link.parentNode.removeChild(link);
                        });
                    });
                  }
                }
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            </ToolTip>
          </div>
        </div>
      ) : (
        !totalData && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: 'left'
            }}
            variant="h5"
          >
            Wczytywanie danych
          </Typography>
        )
      )}
      {!totalDataLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: totalData[0] ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
