import React from "react";
import MTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  BarChart,
  Cell,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
} from "recharts";
import IconButton from "@material-ui/core/IconButton";

const CustomTooltip = () => ({
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, label } = this.props;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(100,100,100,0.8)",
            color: "white",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <p className="label">{`${label} : ${payload[0].value.toLocaleString(
            "pl-PL",
            { maximumFractionDigits: 0 }
          )}`}</p>
        </div>
      );
    }
    return null;
  },
});

const posorneg = (type, value) => {
  let color = "black";

  let posorneg = value < 0 ? false : true;
  if (type === "cost") posorneg = !posorneg;
  if (!posorneg) color = "#FE8E3C";
  return color;
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 600,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "100",
    //border: '1px solid #FE8E3C',
    boxShadow: "10px 10px 24px -1px rgba(70,70,70,0.75)",
  },
}))(MTooltip);

function RowKB(props) {
  const [open, setOpen] = React.useState(false);

  function Row(props) {
    const { row, meta } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        {(row) && <TableRow
          key={row.id}
          hover
          className={"animate__animated animate__fadeIn"}

        >
          <TableCell
            style={{
              paddingLeft: 5 * props.lvl,
              marginLeft: '0px',
              borderBottom: "0px solid",
            }}
          >
            {row.children && row.children.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                style={{ color: "lightgray" }}
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            )}
          </TableCell>

          {
            Array(meta.columnsdef.length).fill().map(
              (col, colid) => {
                return <TableCell
                  align={meta.columnsdef[colid].align}
                  colSpan={1}
                  key={colid}
                  component="th"
                  scope="row"
                  style={{ color: "gray", fontWeight: '100', textIndent: "0px", border: 'none' }}>
                  {row[meta.columnsdef[colid].name]}
                </TableCell>
              }
            )
          }

        </TableRow>
        }
        {open &&
          row.children
            .map((child) => (
              <Row key={child.id} row={child} lvl={props.lvl + 1} />
            ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        key={props.id}
        //class="MuiTableRow"
        hover
        className="animate__animated animate__fadeIn"
        style={{ backgroundColor: props.data.color, borderRadius: "5px", outline: "none" }}
      >
        <TableCell style={{ borderBottom: "0px solid", borderRadius: "5px 0px 0px 5px", maxWidth: '16px', paddingLeft: '0px' }}>
          {props.data.children && props.data.children.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        {
          Array(4 - props.data.colspan).fill().map(
            (col, colid) => {
              return <TableCell
                align={props.meta.columnsdef[colid].align}
                colSpan={props.data.colspan}
                key={colid}
                component="th"
                scope="row"
                style={{ color: 'black', fontWeight: '100', textIndent: "0px", border: 'none' }}>
                {props.data[props.meta.columnsdef[colid].name]}
              </TableCell>
            }
          )
        }

      </TableRow>
      {
        open && props.data.children &&
        props.data.children
          .map((row) => <Row key={row.id} row={row} meta={props.meta} lvl={0} />)
      }
    </React.Fragment >
  );
}

const BasicTable = (props) => {
  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        {props.data.meta.title || ''}
      </Typography>

      <TableContainer>
        <Table size="small" aria-label="simple table">

          <TableBody>
            {props.data.data.map((account, id) => (
              <RowKB data={account} key={id} id={id} meta={props.data.meta} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BasicTable;
