import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CostByStagesTable from "./CostByStagesTable";

import { Paper, Popover } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import LibraryIcon from '@material-ui/icons/LocalLibraryOutlined';
import ArrowIcon from '@material-ui/icons/KeyboardBackspace';

import Tooltip from "@material-ui/core/Tooltip";

import { apiurl } from '../app/prefs.js';
import TinyChart from "./TinyChart";
import { ReactComponent as PdfIcon } from './pdf17.svg';

import { Dialog, DialogContent } from '@material-ui/core';

import MonthSelect from "./MonthSelection";

import AccountFilter from './AccountFilter.js';

const VideoIcon = (props) => (
    <svg
        class="svg-icon"
        width='26px'
        height='26px'
        color='#0b6471'
        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M725.333333 574.506667l170.666667 113.770666V335.722667l-170.666667 113.770666v125.013334z m0-227.562667l189.653334-126.442667C943.36 201.6 981.333333 221.930667 981.333333 256v512c0 34.069333-37.973333 54.4-66.346666 35.498667L725.333333 677.056v48.106667C725.333333 784.085333 677.504 832 618.752 832H149.248A106.666667 106.666667 0 0 1 42.666667 725.162667V298.837333C42.666667 239.914667 90.496 192 149.248 192h469.504A106.666667 106.666667 0 0 1 725.333333 298.837333v48.106667zM128 298.837333v426.325334A21.333333 21.333333 0 0 0 149.248 746.666667h469.504c11.584 0 21.248-9.685333 21.248-21.504V298.837333A21.333333 21.333333 0 0 0 618.752 277.333333H149.248C137.664 277.333333 128 287.018667 128 298.837333z" fill="#0b6471" /></svg>
)

const PlayIcon = () => (
    <svg width="40px" height="40px" viewBox="-1 0 14 14" id="meteor-icon-kit__solid-play-s" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.00154V13.0015C0 13.7788 0.84797 14.259 1.5145 13.859L11.5145 7.859C12.1618 7.4706 12.1618 6.5325 11.5145 6.1441L1.5145 0.14405C0.84797 -0.25586 0 0.22425 0 1.00154z" fill="#758CA3"></path></g></svg>
)

export default function Dashboard() {
    const [totalData, setTotalData] = React.useState(undefined);
    const [totalDataLoaded, setTotalDataLoaded] = React.useState(false);
    const [noData, setNoData] = React.useState(false);

    const [selectedYear, setSelectedYear] = React.useState(1);
    const [years, setYears] = React.useState([]);
    const [selectedMonths, setSelectedMonths] = React.useState([]);
    const [reportInfoOpen, setReportInfoOpen] = React.useState(false);

    const { getAccessTokenSilently, user } = useAuth0();

    const [videoListOpen, setVideoListOpen] = React.useState(false);
    const [videoPlayDialogOpen, setVideoPlayDialogOpen] = React.useState(false);
    const [selectedVideo, setSelectedVideo] = React.useState(undefined);

    const [opkFilterItems, setOPKFilterItems] = React.useState([]);
    const [selectedOPK, setSelectedOPK] = React.useState([]);
    const [opkFilterStatus, setOPKFilterStatus] = React.useState('All');

    const applyOPKFilter = async (accounts) => {

        const getFilterStatus = () => {
            if (accounts.length === 0) {
                return "None";
            }
            let accountCount = 0;
            const menuData = opkFilterItems;
            for (let c = 0; c < menuData.length; c++) {
                if (menuData[c].children && menuData[c].children.length !== 0) {
                    accountCount = accountCount + menuData[c].children.length;
                } else {
                    accountCount = accountCount + 1;
                }
            }
            if (accountCount === accounts.length) {
                return "All";
            } else {
                return "Selected";
            }
        };

        setOPKFilterStatus(getFilterStatus());
        setTotalDataLoaded(false);
        loadDataForOPK(accounts);
        setSelectedOPK(accounts);

        //setReportDataLoaded(true);
    };

    const handleVideoListClose = () => {
        setVideoListOpen(false)
    };

    const videoData = [
        {
            image: '/manual/reports/00. Raporty w CO+.jpg',
            video: '/manual/reports/00. Raporty w CO+.mp4'
        },
        {
            image: '/manual/reports/Raport - podsumowanie.jpg',
            video: '/manual/reports/Raport - podsumowanie.mp4'
        },
    ];

    const beginnerVideoData = [
        {
            image: '/manual/tutorial/0. Wzorcowy plik MS Excel - wstęp.jpg',
            video: '/manual/tutorial/0. Wzorcowy plik MS Excel - wstęp.mp4',
        },

        {
            video: '/open/3_pierwsze_arkusze.mp4',
            image: '/open/3_pierwsze_arkusze.png',
        },

        {
            image: '/manual/reports/00. Raporty w CO+.jpg',
            video: '/manual/reports/00. Raporty w CO+.mp4'
        },
        {
            image: '/manual/0_Procedury_wstep.jpg',
            video: '/manual/0_Procedury_wstep.mp4',
        },
        {
            image: '/manual/tutorial/Arkusz 1 - Jak dodawać nowe konta zespołu 4.jpg',
            video: '/manual/tutorial/Arkusz 1 - Jak dodawać nowe konta zespołu 4.mp4'
        },
        {
            image: '/manual/procedures/0. Procedury Import danych - wstęp.jpg',
            video: '/manual/procedures/0. Procedury Import danych - wstęp.mp4'
        }
    ];

    const handleVideoSelect = (video) => {
        setSelectedVideo(video);
        setVideoListOpen(false);
        setVideoPlayDialogOpen(true);
    };

    const VideoList = () => {
        return <div>
            {
                videoData.map(
                    (v, i) => {
                        return <div className="videoListItem" onClick={() => handleVideoSelect(v)}>
                            <div style={{ position: 'relative' }}>
                                <img src={v.image} alt={'Video nr ' + i} />
                                <div style={{
                                    position: 'absolute',
                                    top: '90px',
                                    left: '190px',
                                    opacity: '.5',
                                    color: 'gray'
                                }}>
                                    <PlayIcon />
                                </div>
                            </div>
                        </div>
                    }
                )
            }
        </div>
    }

    const BeginnersVideoList = () => {
        return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {
                beginnerVideoData.map(
                    (v, i) => {
                        return <div className="videoListItem" onClick={() => handleVideoSelect(v)}>
                            <div style={{ position: 'relative' }}>
                                <img src={v.image} alt={'Video nr ' + i} />
                                <div style={{
                                    position: 'absolute',
                                    top: '90px',
                                    left: '190px',
                                    opacity: '.5',
                                    color: 'gray'
                                }}>
                                    <PlayIcon />
                                </div>
                            </div>
                        </div>
                    }
                )
            }
        </div>
    }

    const loadData = (param) => {
        setTotalDataLoaded(false);
        const apiUrl = `${apiurl}/defaultdashboard`;
        getAccessTokenSilently().then((token) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            fetch(apiUrl, requestOptions)
                .then((res) => res.json())
                .then((fetchedData) => {

                    if (fetchedData.error !== undefined) {
                        setNoData(true);
                    } else {
                        //below - code to setup OPK filter during first data load
                        if (param === 'first' && fetchedData.details && Array.isArray(fetchedData.details)) {
                            const dictionary = fetchedData.details.map(
                                parent => {
                                    const children = parent.children.map(
                                        child => ({ id: child.id, name: child.name })
                                    );
                                    return ({
                                        id: parent.parent_id,
                                        name: parent.parent_name,
                                        children: children
                                    })
                                }
                            );
                            const defaultSelected = [];
                            dictionary.forEach(parent => {
                                parent.children.forEach(
                                    child => defaultSelected.push(child.id)
                                )
                            });
                            setOPKFilterItems(dictionary);
                            setSelectedOPK(defaultSelected);
                        };

                        setYears(fetchedData.years);
                        setSelectedYear(fetchedData.selectedYear);
                        setTotalData(fetchedData);
                        setTotalDataLoaded(true);
                    };
                });
        });
    };

    const loadDataForOPK = (opkList) => {
        setTotalDataLoaded(false);
        const apiUrl = `${apiurl}/defaultdashboard/${selectedYear}/${JSON.stringify(selectedMonths)}`;
        getAccessTokenSilently().then((token) => {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                }
            };
            console.log(opkFilterItems.length);
            console.log(opkList.length);
            if ( opkFilterItems.reduce( (c, p) =>  c + p.children.length,0) > opkList.length) {
                Object.assign(
                    requestOptions, {
                    body: JSON.stringify(
                        {
                            "opkList": JSON.stringify(opkList)
                        })
                }
                );
            };
            fetch(apiUrl, requestOptions)
                .then((res) => res.json())
                .then((fetchedData) => {
                    setTotalData(fetchedData);
                    setTotalDataLoaded(true);
                });
        });
    };

    const loadDataForYear = (year) => {
        setTotalDataLoaded(false);
        const apiUrl = `${apiurl}/defaultdashboard/${year}/${JSON.stringify(selectedMonths)}`;
        getAccessTokenSilently().then((token) => {
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    Authorization: "Bearer " + token,
                }
            };
            if (opkFilterStatus === 'Selected') {
                requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(
                        {
                            "opkList": JSON.stringify(selectedOPK)
                        })
                }
            }
            fetch(apiUrl, requestOptions)
                .then((res) => res.json())
                .then((fetchedData) => {
                    setTotalData(fetchedData);
                    setTotalDataLoaded(true);

                });
        });
    };

    const loadDataForMonths = (year, months) => {
        setTotalDataLoaded(false);
        const apiUrl = `${apiurl}/defaultdashboard/${year}/${JSON.stringify(months)}`;
        getAccessTokenSilently().then((token) => {
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    Authorization: "Bearer " + token,
                }
            };
            if (opkFilterStatus === 'Selected') {
                requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(
                        {
                            "opkList": JSON.stringify(selectedOPK)
                        })
                }
            }
            fetch(apiUrl, requestOptions)
                .then((res) => res.json())
                .then((fetchedData) => {
                    setTotalData(fetchedData);
                    setTotalDataLoaded(true);

                });
        });
    };

    const downloadFile = (downloadURL) => {

        getAccessTokenSilently().then((token) => {
            document.body.style.cursor = 'wait';
            const apiUrl = downloadURL;
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    Authorization: "Bearer " + token,
                }
            };
            if (opkFilterStatus === 'Selected') {
                requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(
                        {
                            "opkList": JSON.stringify(selectedOPK)
                        })
                }
            }
            fetch(apiUrl, requestOptions)
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `PodsumowanieSRK.pdf`,
                    );
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    document.body.style.cursor = 'auto';
                });
        });

    };

    React.useEffect(() => {
        let mounted = true;
        loadData('first');
        return function cleanup() {
            mounted = false;
        };
    }, []);

    function YearBox() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                <Autocomplete
                    id="combo-box"
                    disabled={years.length <= 1}
                    options={years}
                    value={selectedYear}
                    size="small"
                    disableClearable
                    autoHighlight
                    getOptionLabel={(option) => option}
                    getOptionSelected={(option, value) => {
                        if (!value) return false;
                        return option === value;
                    }}
                    style={{ width: "120px", marginBottom: "0px", marginLeft: '0px', marginRight: '10px' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Rok"
                            variant="filled"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: {
                                    backgroundColor: "#e7f0f1",
                                    color: "#0B6471",
                                    fontSize: "14px",
                                },
                            }}
                        />
                    )}
                    onChange={(event, value) => {
                        if (value) {
                            setSelectedYear(value);
                            loadDataForYear(value);
                        }
                    }}
                />
            </div>
        );
    }

    const InfoBox = (props) => {
        return <Paper
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px",
                paddingTop: "5px",
                paddingBottom: "0px",
                paddingLeft: "5px",
                paddingRight: '5px',
                border: "0px dotted orange",
                width: "270px",
                marginBottom: props.marginBottom ? props.marginBottom : '10px',
                marginRight: props.marginRight ? props.marginRight : '10px',
                height: props.height,

            }}
        >

            <Typography
                style={{
                    color: "#0b6471",
                    paddingLeft: "5px",
                    fontSize: "16px",
                    textDecoration: "none",
                    fontWeight: "100",
                    paddingBottom: "0px",
                }}
            >
                WIĘCEJ ANALIZ W MENU RAPORTY
            </Typography>
            <div style={{
                width: "100%",
                height: "100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <PollIcon style={{
                    height: '100px',
                    width: '100px',
                    position: 'static',
                    color: '#0B6471',
                    cursor: 'pointer'
                }} />
            </div>
        </Paper>
    }

    return (
        <div className="App" style={{ position: "relative" }}>
            <div
                style={{
                    //background: "linear-gradient(to right, #0b6471, #9BC0C5)",
                    display: "flex",
                    height: "55px",
                    width: "100%",
                    backgroundColor: "#0b6471",
                    color: "white",
                    borderRadius: "5px",
                    paddingTop: "0px",
                    paddingLeft: "0px",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "80px", marginTop: "5px" }}>
                    <PollIcon
                        style={{
                            marginLeft: "20px",
                            marginRight: "20px",
                            fontSize: "42px",
                        }}
                    />
                </div>
                <div>
                    <Typography variant="h5" style={{ color: "white" }}>
                        Standard Rachunku Kosztów - Podsumowanie
                    </Typography>
                </div>
            </div>

            {totalData ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        transition: "opacity 0.5s",
                        opacity: totalDataLoaded ? "1" : "0.2",
                        paddingTop: '5px',
                    }}
                >
                    <div
                        style={{
                            display: "flex", justifyContent: "flex-start", backgroundColor: 'white', padding: '5px', borderRadius: '5px', padding: '5px', marginBottom: '5px', marginRight: '1px'
                        }}
                    >
                        <YearBox />
                        <div style={{ marginRight: '10px', backgroundColor: '#e7f0f1', borderRadius: '5px', padding: '5px 0px 0px 5px' }}>
                            <MonthSelect
                                version={2}
                                setSelectedMonthsFunction={setSelectedMonths}
                                loadDataFunction={loadDataForMonths}
                                selectedMonthsProperty={selectedMonths}
                                startDateProperty={{ year: selectedYear }}
                            />
                        </div>
                        <div style={{ marginRight: '10px', backgroundColor: '#e7f0f1', borderRadius: '5px', padding: '5px 0px 0px 5px' }}>
                            <AccountFilter
                                titleCaption="OPK"
                                underLine={true} //disable underline
                                fontSize='14px'
                                fontStyle='italic'
                                fontColor='rgb(11, 100, 113)'
                                marginTop='-3px'
                                menuData={opkFilterItems}
                                startSelection={selectedOPK}
                                updateFunction={applyOPKFilter}
                            />
                        </div>
                        <Tooltip title='Opis raportu'>
                            <Button
                                id='reportInfoIcon'
                                onClick={() => setReportInfoOpen(true)}
                                style={{
                                    marginTop: "0px",
                                    width: "48px",
                                    minWidth: "48px",
                                    height: "46px",
                                    backgroundColor: "#e7f0f1",
                                    color: "#0B6471",
                                    borderRadius: "5px",
                                    marginRight: '10px',
                                }}
                            >
                                <LibraryIcon
                                    style={{ width: "30px", height: "30px" }}
                                />
                            </Button>
                        </Tooltip>

                        <Tooltip title='Pobierz raport jako PDF'>
                            <Button
                                id='saveSinglePdf'
                                onClick={
                                    () => downloadFile(`${apiurl}/defaultdashboard/${selectedYear}/${JSON.stringify(selectedMonths)}/pdf`)
                                }
                                style={{
                                    marginTop: "0px",
                                    width: "48px",
                                    minWidth: "48px",
                                    height: "46px",
                                    backgroundColor: "#e7f0f1",
                                    color: "#0B6471",
                                    borderRadius: "5px",
                                    marginRight: '10px',
                                }}
                            >
                                <PdfIcon
                                    style={{ marginTop: '2px', cursor: 'pointer' }}
                                />
                            </Button>
                        </Tooltip>

                        <Tooltip title='Instrukcje video'>
                            <Button
                                id='video'
                                onClick={
                                    () => setVideoListOpen(true)
                                }
                                style={{
                                    marginTop: "0px",
                                    width: "48px",
                                    minWidth: "48px",
                                    height: "46px",
                                    backgroundColor: "#e7f0f1",
                                    color: "#0B6471",
                                    borderRadius: "5px",
                                    marginRight: '10px',
                                }}
                            > <sup style={{ position: 'absolute', color: '#FE8E3C', fontWeight: 'bold', top: '0px', left: '4px' }}>N</sup>
                                <VideoIcon
                                    style={{ color: '#0B6471', marginTop: '2px', cursor: 'pointer' }}
                                />
                            </Button>
                        </Tooltip>

                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            overflow: "auto",

                        }}
                    >
                        <div
                            id="upper left"
                            style={{
                                display: "flex",
                                paddingRight: "0px",
                                alignItems: "stretch",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                flexGrow: '1',
                                justifyContent: "flex-start"
                            }}
                        >

                            <TinyChart
                                title="WYNIK"
                                marginRight="10px"
                                data={{ monthlydata: totalData.totals.children }}
                                datatype="profit"
                                actual={
                                    totalData.totals.profit.toLocaleString("pl-PL", {
                                        maximumFractionDigits: 0,
                                    }) + " zł"
                                }
                                budget={
                                    totalData.totals.profit_BU.toLocaleString("pl-PL", {
                                        maximumFractionDigits: 0,
                                    }) + "%"
                                }
                                color="#4E97A5"
                            />
                            <TinyChart
                                title="PRZYCHODY"
                                marginRight="10px"
                                data={{ monthlydata: totalData.totals.children }}
                                datatype="income"
                                actual={
                                    totalData.totals.income.toLocaleString("pl-PL", {
                                        maximumFractionDigits: 0,
                                    }) + " zł"
                                }
                                budget={
                                    totalData.totals.income_BU.toLocaleString("pl-PL", {
                                        maximumFractionDigits: 0,
                                    }) + "%"
                                }
                                color="#BFFBFF"
                            />
                            <TinyChart
                                title="KOSZTY"
                                marginRight="10px"
                                data={{ monthlydata: totalData.totals.children }}
                                datatype="cost"
                                actual={
                                    totalData.totals.cost.toLocaleString("pl-PL", {
                                        maximumFractionDigits: 0,
                                    }) + " zł"
                                }
                                budget={
                                    totalData.totals.cost_BU.toLocaleString("pl-PL", {
                                        maximumFractionDigits: 0,
                                    }) + "%"
                                }
                                color="#0B6471"
                            />
                            <InfoBox />

                        </div>

                        <div
                            id="top 3 bottom 3 tables area"
                            style={{
                                display: "flex",
                                paddingRight: "0px",
                                alignItems: "stretch",
                                flexWrap: "wrap",
                                flexDirection: "column",
                                marginRight: '5px',
                                flexGrow: '200'
                            }}
                        >

                            <CostByStagesTable
                                title='ANALIZA RENTOWNOŚCI'
                                dimUnexpanded='true'
                                data={totalData.details}
                                columns={
                                    [
                                        { name: 'Nazwa OPK', value: 'name', align: 'left', type: 'text', underline: '1px solid' },
                                        { name: <div><div style={{ fontWeight: "400" }}>Koszty całkowite</div></div>, value: 'cost', align: 'right', type: 'text', underline: '5px double' },
                                        { name: <div style={{ fontWeight: "400" }}>Przychód</div>, value: 'income', align: 'right', type: 'text', underline: '2px solid' },
                                        { name: <div style={{ fontWeight: "400" }}>Wynik</div>, value: 'profit', align: 'right', type: 'profit', underline: '4px solid' },
                                        { name: <div style={{ fontWeight: "400" }}></div>, value: 'graphValue', align: 'right', type: 'graph', underline: '4px solid' },
                                    ]
                                }
                            />

                            <CostByStagesTable
                                title='RANKING KOSZTÓW WG GRUP KOSZTÓW RODZAJOWYCH'
                                dimUnexpanded='true'
                                data={totalData.costByGroups}
                                columns={
                                    [
                                        { name: 'Grupa kosztów', value: 'group_name', align: 'left', type: 'text', underline: '1px solid' },
                                        { name: <div><div style={{ fontWeight: "400" }}>Koszt</div></div>, value: 'cost', align: 'right', type: 'text', underline: '5px double' },
                                        { name: <div><div style={{ fontWeight: "400" }}>Udział w koszcie całkowitym</div></div>, value: 'share', align: 'right', type: 'text', underline: '5px double' },
                                        { name: <div><div style={{ fontWeight: "400" }}></div></div>, value: 'monthly', align: 'right', type: 'tinychart', underline: '5px double' },
                                    ]
                                }
                            />

                            <CostByStagesTable
                                title='RANKING KOSZTÓW WYNAGRODZEŃ WG GRUP PRACOWNICZYCH'
                                dimUnexpanded='true'
                                data={totalData.salaries}
                                columns={
                                    [
                                        { name: 'Grupa pracownicza', value: 'group_name', align: 'left', type: 'text', underline: '1px solid' },
                                        { name: <div><div style={{ fontWeight: "400" }}>Koszt wynagrodzeń</div></div>, value: 'cost', align: 'right', type: 'text', underline: '5px double' },
                                        { name: <div><div style={{ fontWeight: "400" }}></div></div>, value: 'monthly', align: 'right', type: 'tinychart', underline: '5px double' },
                                    ]
                                }
                            />
                        </div>


                    </div>

                    <Popover
                        id='hintPopOver'
                        open={reportInfoOpen}
                        anchorEl={document.getElementById('reportInfoIcon')}
                        onClose={() => setReportInfoOpen(false)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                    >
                        <Paper
                            style={{
                                padding: "10px",
                                width: "600px",
                                fontFamily: "roboto",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <div
                                style={{
                                    top: "0px",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "right"
                                }}
                            >
                                <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => setReportInfoOpen(false)} />
                            </div>
                            <div style={{ fontSize: '16px', padding: '5px', marginBottom: '5px' }}>
                                <p>Raport zawiera <b>wyniki, przychody oraz koszty całkowite</b> (suma kosztów bezpośrednich i doliczonych)
                                    dla poszczególnych komórek (Ośrodków Powstawania Kosztów).</p>
                                <p>Raport nie pokazuje kosztów poszczególnych komórek pomocniczych i proceduralnych, ponieważ
                                    zgodnie z wymogami Standardu Rachunku Kosztów, zostały one rozliczone na inne komórki widoczne
                                    w raporcie. Analiza kosztów utrzymania tych komórek dostępna jest w raportach: <b>OPK Pomocnicze</b> oraz <b>OPK Proceduralne</b>.</p>
                            </div>
                            <div
                                style={{
                                    top: "60px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    borderTop: '1px #0B6471 solid'
                                }}
                            >
                                <Button onClick={() => setReportInfoOpen(false)}>
                                    Zakończ
                                </Button>
                            </div>
                        </Paper>
                    </Popover>
                </div>
            ) : (
                (!totalData && !noData) && (
                    <Typography
                        style={{
                            position: "absolute",
                            top: "120%",
                            left: "40%",
                            color: "#0b6471",
                            textJustify: "left",
                        }}
                        variant="h5"
                    >
                        Wczytywanie danych
                    </Typography>
                )
            )}
            {(!totalDataLoaded && !noData) && (
                <CircularProgress
                    style={{
                        position: "absolute",
                        height: "70px",
                        width: "70px",
                        top: totalData ? "50%" : "120%",
                        left: "50%",
                        color: "#0b6471",
                        opacity: "0.6",
                    }}
                />
            )}
            {(noData) && (
                <div>
                    <Typography
                        style={{
                            color: "#0b6471",
                            textJustify: "center",
                            marginTop: '10px',
                            marginBottom: '20px',
                        }}
                        variant="h5"
                    >
                        Do aplikacji nie zaimportowano jeszcze żadnych danych.
                    </Typography>
                    <div>
                        <BeginnersVideoList />
                    </div>
                </div>
            )}

            <Popover
                id='videoSelectPopOver'
                open={totalDataLoaded && videoListOpen}
                anchorEl={document.getElementById('video')}
                onClose={handleVideoListClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            >
                <Paper
                    style={{
                        padding: "10px",
                        width: "450px",
                        fontFamily: "roboto",
                        display: "flex",
                        flexDirection: "column",
                        //height: 'calc(100vh - 270px)'
                    }}
                >

                    <div
                        style={{
                            top: "0px",
                            display: "flex",
                            width: "100%",
                            justifyContent: "right"
                        }}
                    >

                        <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleVideoListClose()} />

                    </div>

                    <VideoList />

                </Paper>
            </Popover>
            <Dialog

                maxWidth='90vw'
                open={videoPlayDialogOpen}
                onClose={() => setVideoPlayDialogOpen(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"

            >
                <DialogContent style={{ display: 'flex', flexDirection: 'column', margin: '0px', padding: '0px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 10px 3px 0px' }}>
                        <button
                            onClick={() => {
                                const div = document.getElementById('videoControl');
                                if (div.requestFullscreen)
                                    div.requestFullscreen();
                                else if (div.webkitRequestFullscreen)
                                    div.webkitRequestFullscreen();
                                else if (div.msRequestFullScreen)
                                    div.msRequestFullScreen();
                            }
                            }
                            style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
                        >
                            Pełen ekran
                        </button>
                        <button
                            onClick={() => setVideoPlayDialogOpen(false)}
                            style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
                        >
                            Zamknij
                        </button>
                    </div>
                    <video id='videoControl' controls autoPlay style={{ width: '80vw' }}>
                        <source src={selectedVideo && selectedVideo.video} type="video/mp4" />
                        Video control not supported by browser
                    </video>

                </DialogContent>
            </Dialog>
        </div>

    );
}
