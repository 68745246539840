import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUITooltip from "@material-ui/core/Tooltip";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import TinyChart from "./TinyChart";

import OPKTable2 from "./OPKTable2";
import ProfitTable from "./ProfitTable";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { apiurl } from '../app/prefs.js';
import MonthSelect from "./MonthSelection";
import CostByStagesTable from "./CostByStagesTable";

function ProceduresSummary(props) {
  const { row } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  React.useEffect(
    () => {
      setPage(0)
    }, [row]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        WYKONANE PROCDEDURY
      </Typography>

      <TableContainer>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Nazwa procedury
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Liczba procedur
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Koszt jedn. bezpośredni
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Koszty jedn. bezpośredni + pośredni
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Koszt całkowity
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row &&
              row
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((child) => (
                  <TableRow key={child.PROCEDURE_NAME} hover="true">
                    <TableCell component="th" scope="row">
                      {child.PROCEDURE_NAME}
                    </TableCell>
                    <TableCell align="right">
                      {child.qty.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {child.cost_given_per_unit.toLocaleString("pl-PL", {
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {child.cost_calculated_per_unit.toLocaleString("pl-PL", {
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {child.cost_calculated.toLocaleString("pl-PL", {
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={row && row.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

function ProceduresPerCustomer(props) {
  const { rows } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  React.useEffect(
    () => {
      setPage(0)
    }, [rows]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = (props) => {
    const [open, setOpen] = React.useState(false);
    const { data } = props;
    return (
      <React.Fragment>
        <TableRow key={data.custom_02} hover="true">
          <TableCell style={{ paddingLeft: "10px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ color: "lightgray" }}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {data.custom_02_name}
          </TableCell>
          <TableCell align="right">
            {data.qty.toLocaleString("pl-PL", {
              maximumFractionDigits: 0,
            })}
          </TableCell>
          <TableCell align="right">
            {data.cost_calculated.toLocaleString("pl-PL", {
              maximumFractionDigits: 2,
            })}
          </TableCell>
        </TableRow>
        {open && (
          <TableRow>
            <TableCell style={{ backgroundColor: "rgb(240,240,240)" }} colSpan="4">
              <ProceduresSummary row={data.procedures} />
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        ALOKACJA KOSZTÓW OPK PROCEDURALNEGO
      </Typography>

      <TableContainer>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  maxWidth: "30px",
                }}
              ></TableCell>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Nazwa OPK zlecającego
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Liczba procedur
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Koszt całkowity
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((child) => <Row key={child.custom_02} data={child} />)}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows && rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

export default function Report007() {
  const [yardList, setYardList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState(null);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);

  const loadData = async (opk, year, months) => {
    setActiveYard(opk);
    setReportDataLoaded(false);
    const currentMonths = months ? months : selectedMonths;

    const token = await getAccessTokenSilently();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    let response = await fetch(`${apiurl}/proceduralunitdetails/${opk.opk_id}/${year}/${JSON.stringify(currentMonths)}`, requestOptions);
    const data = await response.json();

    response = await fetch(`${apiurl}/getinfrastructurecost/${year}/${JSON.stringify(currentMonths)}/json/${opk.opk_id}`, requestOptions);
    const infrastructureCost = await response.json();

    Object.assign(data, { infrastructureCost: infrastructureCost });

    setReportData(data);
    setReportDataLoaded(true);

  }

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Komórka" />}
        onChange={(event, value) => {
          if (value) {
            if (startDate) loadData(value, startDate.year)
          }
        }}
      />
    );
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        disabled={!props.data}
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            if (activeYard) loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/yearslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setDateList(fetchedData.datelist);
          if (fetchedData.datelist && fetchedData.datelist.length !== 0) {
            setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1]);
            //loadData(fetchedData.datelist[fetchedData.datelist.length - 1].year);
            setFiltersLoaded(true);
          }
        });
    });
  }

  function loadDataWithMonths(year, months) {
    activeYard && loadData(activeYard, year, months)
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    setYardListLoaded(false);
    const apiUrl = `${apiurl}/proceduralunitlist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (mounted) {
            setYardList(fetchedData);
            loadFilters();
            setYardListLoaded(true);
          }
        });
    });
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Analiza OPK Proceduralnego
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "300", color: "white" }}
          >
            (BU, WYK) w zł.
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <MonthSelect setSelectedMonthsFunction={setSelectedMonths} loadDataFunction={loadDataWithMonths} selectedMonthsProperty={selectedMonths} startDateProperty={startDate} />
          <MUITooltip title='Opis raportu'>
            <a edge='end' style={{ marginTop: '14px', marginLeft: '10px', marginRight: '7px', color: '#0b6471' }} target='_blank' href='/usermanual/opis_raportu_004'>
              <ImportContactsIcon />
            </a>
          </MUITooltip>
        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {activeYard && reportData && reportData.yearlydata.cost ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="tiny charts area"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                flexDirection: "row",
                flexShrink: 1,
              }}
            >
              <Paper style={{ marginRight: '10px', marginBottom: '10px' }}>
                <TinyChart
                  marginBottom='0px'
                  marginRight='0px'
                  height='100%'
                  title="KOSZTY"
                  data={reportData}
                  datatype="cost"
                  actual={
                    reportData.yearlydata.cost.toLocaleString("pl-PL", {
                      maximumFractionDigits: 0,
                    }) + " zł"
                  }
                  budget={
                    reportData.budgetdata.cost_BU.toLocaleString("pl-PL", {
                      maximumFractionDigits: 0,
                    }) + "%"
                  }
                  color="#0B6471"
                />
              </Paper>
              <ProfitTable
                title="RANKING KOSZTÓW WYNAGRODZEŃ WG GRUP PRACOWNICZYCH"
                data={reportData.salaries_by_group}
                dataType="cost"
                columns={[
                  {
                    name: "Grupa pracownicza",
                    align: "left",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "group_name",
                    datalen: "",
                  },
                  {
                    name: "BU",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "5px double" },
                    datadef: "cost_bu",
                    datalen: 0,
                  },
                  {
                    name: "Δ %",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "1px solid" },
                    datadef: "cost_delta",
                    datalen: 2,
                  },
                  {
                    name: "Koszt wynagrodzeń",
                    align: "right",
                    style: { color: "#0b6471", borderBottom: "4px solid" },
                    datadef: "cost",
                    datalen: 0,
                  },
                ]}
              />
            </div>
          </div>
          <OPKTable2 data={reportData} />
          <ProceduresSummary row={reportData.procedures_summary} />
          <ProceduresPerCustomer rows={reportData.procedures_per_customer} />
          <CostByStagesTable
                  title='KOSZT INFRASTRUKTURY'
                  dimUnexpanded='true'
                  data={reportData.infrastructureCost || []}
                  columns={
                    [
                      { name: <div style={{ fontWeight: "100" }}>Wskaźnik</div>, value: 'name', align: 'left', type: 'text', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}>Wartość</div>, value: 'value', align: 'right', type: 'text', underline: '2px solid' },
                      { name: <div style={{ fontWeight: "100" }}></div>, value: 'monthly', align: 'right', type: 'tinychart', underline: '2px solid' },
                    ]
                  }
                />

        </div>
      ) : (
        !activeYard && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left",
            }}
            variant="h5"
          >
            Wybierz komórkę
          </Typography>
        )
      )}
      {!reportDataLoaded && activeYard && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
