import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import { RiHealthBookFill } from "react-icons/ri";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import CheckIcon from "@material-ui/icons/Check";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";


import SchoolIcon from "@material-ui/icons/School";
import "animate.css";
import "./style.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const tutorDataProcDescription = [
  {
    id: 0,
    name: 'Wycena Procedur',
    videos: [
      {
        id: 0, title: '',
        image: '/manual/0_Procedury_wstep.jpg',
        video: '/manual/0_Procedury_wstep.mp4'
      },
      {
        id: 1, title: '',
        image: '/manual/1_Jak_wyglada_okno.jpg',
        video: '/manual/1_Jak_wyglada_okno.mp4'
      },
      {
        id: 2, title: '',
        image: '/manual/2_Jak_przypisac_materialy.jpg',
        video: '/manual/2_Jak_przypisac_materialy.mp4'
      },
      {
        id: 3, title: '',
        image: '/manual/3_Jak_przypisac_osoby.jpg',
        video: '/manual/3_Jak_przypisac_osoby.mp4'
      },
      {
        id: 4, title: '',
        image: '/manual/4_Jak_wpisac_ilosc.jpg',
        video: '/manual/4_Jak_wpisac_ilosc.mp4'
      },
      {
        id: 5, title: '',
        image: '/manual/5_Jak_kopiowac_opisy.jpg',
        video: '/manual/5_Jak_kopiowac_opisy.mp4'
      },
      {
        id: 6, title: '',
        image: '/manual/6_Jaki_jest_czas.jpg',
        video: '/manual/6_Jaki_jest_czas.mp4'
      },
      {
        id: 7, title: '',
        image: '/manual/7_Biblioteka.jpg',
        video: '/manual/7_Biblioteka.mp4'
      },
    ]
  }
];

const tutorDataProcedures = [
  {
    id: 0,
    name: 'Wprowadzenie',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/00. Jak zaimportować.jpg', video: '/manual/procedures/00. Jak zaimportować.mp4' },
      { id: 1, title: '', image: '/manual/procedures/0. Procedury Import danych - wstęp.jpg', video: '/manual/procedures/0. Procedury Import danych - wstęp.mp4' }
    ]
  },
  {
    id: 1,
    name: 'Arkusz 1 - Procedury',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/1. Arkusz Procedury.jpg', video: '/manual/procedures/1. Arkusz Procedury.mp4' },
    ]
  },
  {
    id: 2,
    name: 'Arkusz 2 - Materiały',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/2. Arkusz Materiały.jpg', video: '/manual/procedures/2. Arkusz Materiały.mp4' },
      { id: 1, title: '', image: '/manual/procedures/5. Zmiana kosztu materiału.jpg', video: '/manual/procedures/5. Zmiana kosztu materiału.mp4' }
    ]
  },
  {
    id: 3,
    name: 'Arkusz 3 - Personel',
    videos: [
      { id: 0, title: '', image: '/manual/procedures/3. Arkusz Personel.jpg', video: '/manual/procedures/3. Arkusz Personel.mp4' },
      { id: 1, title: '', image: '/manual/procedures/4. Zmiana stawki wynagrodzenia.jpg', video: '/manual/procedures/4. Zmiana stawki wynagrodzenia.mp4' }
    ]
  }
];

const tutorDataSRK = [
  {
    id: 0,
    name: 'Wprowadzenie',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/0. Wzorcowy plik MS Excel - wstęp.jpg', video: '/manual/tutorial/0. Wzorcowy plik MS Excel - wstęp.mp4' }
    ]
  },
  {
    id: 1,
    name: 'Arkusz 1 - Słownik 4',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/1. Trzy pierwsze arkusze.png', video: '/manual/tutorial/1. Trzy pierwsze arkusze.mp4' },
      { id: 1, title: '', image: '/manual/tutorial/Arkusz 1 - Jak dodawać nowe konta zespołu 4.jpg', video: '/manual/tutorial/Arkusz 1 - Jak dodawać nowe konta zespołu 4.mp4' }
    ]
  },
  {
    id: 2,
    name: 'Arkusz 2 - Słownik 5',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/1. Trzy pierwsze arkusze.png', video: '/manual/tutorial/1. Trzy pierwsze arkusze.mp4' }
    ]
  },
  {
    id: 3,
    name: 'Arkusz 3 - Koszty',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/1. Trzy pierwsze arkusze.png', video: '/manual/tutorial/1. Trzy pierwsze arkusze.mp4' }
    ]
  },
  {
    id: 4,
    name: 'Arkusz 4 - Etap 0',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/4. Arkusz 4. Etap 0.png', video: '/manual/tutorial/4. Arkusz 4. Etap 0.mp4' }
    ]
  },
  {
    id: 5,
    name: 'Arkusz 5 - Klucze OPK Pomocnicze',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/5. Arkusz 5 KLUCZE OPK Pomocnicze.png', video: '/manual/tutorial/5. Arkusz 5 KLUCZE OPK Pomocnicze.mp4' }
    ]
  },
  {
    id: 6,
    name: 'Arkusz 6 - Klucze OPK Proceduralne',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/6. Arkusz 6 KLUCZE OPK Proceduralne.png', video: '/manual/tutorial/6. Arkusz 6 KLUCZE OPK Proceduralne.mp4' },
      { id: 1, title: '', image: '/manual/tutorial/Arkusz 6 - Jak uzupelnić arkusz dla procedur komercyjnych.jpg', video: '/manual/tutorial/Arkusz 6 - Jak uzupelnić arkusz dla procedur komercyjnych.mp4' },
      { id: 2, title: '', image: '/manual/tutorial/Arkusz 6 - Jak wpisać procedury bezpośrednio finansowanane przez NFZ.jpg', video: '/manual/tutorial/Arkusz 6 - Jak wpisać procedury bezpośrednio finansowanane przez NFZ.mp4' }
    ]
  },
  {
    id: 7,
    name: 'Arkusz 7 - Procedury',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/7. Arkusz 7 Procedury.png', video: '/manual/tutorial/7. Arkusz 7 Procedury.mp4' },
      { id: 1, title: '', image: '/manual/tutorial/7. Arkusz 7 Procedury- uaktualnienie.jpg', video: '/manual/tutorial/7. Arkusz 7 Procedury- uaktualnienie.mp4' },
      { id: 2, title: '', image: '/manual/tutorial/1. Alert merytoryczny.jpg', video: '/manual/tutorial/1. Alert merytoryczny.mp4' },
    ]
  },
  {
    id: 8,
    name: 'Arkusz 8 - Przychody',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/8. Arkusz 8 Przychody.png', video: '/manual/tutorial/8. Arkusz 8 Przychody.mp4' },
      { id: 1, title: '', image: '/manual/tutorial/Arkusz 8 - jak wpisać przychód dla OPK 507, 530 oraz 535.jpg', video: '/manual/tutorial/Arkusz 8 - jak wpisać przychód dla OPK 507, 530 oraz 535.mp4' }
    ]
  },
  {
    id: 9,
    name: 'Arkusz 9 - Osobodni oddziały',
    videos: [
      { id: 0, title: '', image: '/manual/tutorial/9. Arkusz 9 Osobodni.png', video: '/manual/tutorial/9. Arkusz 9 Osobodni.mp4' }
    ]
  },
];

const TutorMenu = (props) => {

  const [selectedTopic, setSelectedTopic] = React.useState(0);
  const { content } = props;

  const TutorContent = (props) => {
    const { content } = props;
    const stopOtherVideos = (currentVideoId) => {
      content.forEach(
        (_, id) => {
          if (currentVideoId !== id) {
            const div = document.getElementById('videoControl_' + id);
            if (div !== undefined) {
              div.pause();
              //div.currentTime = 0;
            }
          }
        }
      )
    };
    return (
      <div className='tutorial_contentPanel'>
        {content.map
          ((video, id) => (
            <div key={id} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 0px 3px 0px', width: '60vw' }}>
                <button
                  onClick={() => {
                    const div = document.getElementById('videoControl_' + id);
                    if (div.requestFullscreen)
                      div.requestFullscreen();
                    else if (div.webkitRequestFullscreen)
                      div.webkitRequestFullscreen();
                    else if (div.msRequestFullScreen)
                      div.msRequestFullScreen();
                  }
                  }
                  style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
                >
                  Pełen ekran
                </button>
              </div>
              <video id={'videoControl_' + id} controls poster={video.image} style={{ width: '60vw' }} onPlay={() => stopOtherVideos(id)} >
                <source src={video.video} type="video/mp4" />
                Video control not supported by browser
              </video>
            </div>
          ))}
      </div>
    )
  };

  const Row = (props) => {
    const { data } = props;
    return (
      <div
        className={
          selectedTopic === data.id ? "tutorial_header_selected" : "tutorial_header"
        }
        onClick={() => setSelectedTopic(data.id)}
      >
        {data.name}
      </div>
    );
  };

  return (
    <div className="tutorial">
      <div className="tutorial_tocPanel">
        <div className="tutorial_toc_header">Spis treści</div>
        {content.map((row, id) => (
          <Row key={id} data={row} />
        ))}
      </div>
      <TutorContent content={content[selectedTopic].videos} />
    </div>
  );
};

export default function VideoManual() {
  const mounted = React.useRef(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [procedures, setProcedures] = React.useState([]);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ minHeight: "calc(100vh - 145px)" }}>
      <div
        style={{
          background: '#0b6471',
          display: "flex",
          height: "40px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <SchoolIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "32px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Filmy instruktażowe
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <Paper
        className={classes.root}
        style={{ display: "flex", flexDirection: "column", width: "100%", minHeight: 'calc(100vh - 150px)' }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{ width: "100%" }}
            aria-label="full width tabs"
          >
            <Tab
              key="1"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Import danych - SRK
                  </div>
                </div>
              }
            />
            <Tab
              key="2"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Import danych - Procedury
                  </div>
                </div>
              }
            />
            <Tab
              key="3"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Wycena procedur
                  </div>
                </div>
              }
            />

          </Tabs>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel key="1" value={value} index={0} dir={theme.direction}>
              <Paper style={{ padding: "0px" }}>
                <TutorMenu content={tutorDataSRK} />
              </Paper>
            </TabPanel>
            <TabPanel key="2" value={value} index={1} dir={theme.direction}>
              <Paper style={{ padding: "0px" }}>
                <TutorMenu content={tutorDataProcedures} />
              </Paper>
            </TabPanel>
            <TabPanel key="3" value={value} index={2} dir={theme.direction}>
              <Paper style={{ padding: "0px" }}>
                <TutorMenu content={tutorDataProcDescription} />
              </Paper>
            </TabPanel>
          </SwipeableViews>
        </div>
      </Paper>
    </div>
  );
}
