import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SchoolIcon from "@material-ui/icons/School";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import "animate.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactPlayer from "react-player/file";
import NewsLetter from "../newsletter";
import ArticleSideBar from "../sidebar";
import Parser from "html-react-parser";
import { ImInfo } from "react-icons/im";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import "./article.css";
import { apiurl } from '../../app/prefs.js';

export default function Article(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setAnchorEl(null);
  };
  const mounted = React.useRef(true);
  const matchesWidth = useMediaQuery("(min-width:600px)");
  //const matchesWidth = false;
  window.scrollTo(0, 0);
  const [articleContent, setArticleContent] = React.useState(null);

  React.useEffect(() => {
    if (mounted.current) {
      //console.log("useEffect - mounted");
      fetch(`${apiurl}/article/${props.id}`)
        .then((res) => res.json())
        .then((data) => {
          //console.log("!!!123123123!!!");
          setArticleContent(data[0].ARTICLE);
        });
    } else {
      mounted.current = true;
      //console.log("just mounted");
    }
    return () => {
      //console.log("unmounted");
      mounted.current = false;
    };
  }, []);

  return (
    articleContent && (
      <div>
        <div
          style={{
            background: "linear-gradient(to right, #0b6471, #9BC0C5)",
            display: "flex",
            height: "75px",
            width: "100%",
            backgroundColor: "#0b6471",
            color: "white",
            borderRadius: "5px",
            paddingTop: "0px",
            paddingLeft: "0px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "80px", marginTop: "5px" }}>
            <SchoolIcon
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "36px",
              }}
            />
          </div>
          <div>
            <Typography
              variant="h5"
              style={{
                color: "white",
                fontSize: matchesWidth ? "24px" : "16px",
              }}
            >
              {props.category}
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: "white",
                fontSize: matchesWidth ? "20px" : "14px",
              }}
            >
              {props.title}
            </Typography>
          </div>
        </div>
        <p style={{ height: "5px" }} />
        <div
          style={{
            width: "100%",
            //height: "650px",
            //maxheight: "350px",
            overflow: "auto",
            padding: "0px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'center' }}>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'start', maxWidth: '1138px' }}>
              <div
                style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'center' }}
              >
                <Paper
                  style={{
                    display: "flex",
                    position: "relative",
                    width: "100%",
                    minHeight: "500px",
                    maxWidth: "800px",
                    padding: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ position: "absolute", right: "15px" }}>
                    {props.category !== 'Regulamin' && <EmailShareButton
                      className="Demo__some-network__share-button"

                      edge="end"
                      url={window.location.href}
                      subject={"Polecam artykuł: " + props.title}
                      body="Polecam przeczytanie poniższego artykułu: "
                      style={{
                        margin: "0px",
                        padding: "0px",
                        border: "0px",
                        outline: "0px",

                      }}
                    >
                      <EmailIcon
                        className="sharebutton"
                        size={32}
                        bgStyle={{ fill: "#FE8E3C" }}
                        borderRadius="5px"
                      />
                    </EmailShareButton>}

                    {matchesWidth && false && (
                      <IconButton edge="end">
                        <PrintIcon />
                      </IconButton>
                    )}
                  </div>
                  {Parser(articleContent, {
                    replace: (domNode) => {
                      if (domNode.attribs && domNode.attribs.id === "infoicon") {
                        return (
                          <ImInfo
                            style={{ fontSize: "36px", marginRight: "10px" }}
                          />
                        );
                      };
                      if (domNode.attribs && domNode.attribs.id === "video") {
                        return (
                          <p align="center">
                            <ReactPlayer
                              width={!matchesWidth ? "300px" : "600px"}
                              height={!matchesWidth ? "170px" : "340px"}
                              light={`"${domNode.attribs.image}"`}
                              controls
                              playsinline
                              playing
                              config={{ file: { attributes: { controlsList: "nodownload" } } }}
                              onContextMenu={(e) => e.preventDefault()}
                              url={`${domNode.attribs.video}`}
                            />
                          </p>
                        );
                      };
                    },
                  })}
                </Paper>
                {props.category !== 'Regulamin' && <ArticleSideBar />}
              </div>
              {props.category !== 'Regulamin' && <NewsLetter />}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
