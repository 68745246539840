import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { BsPeopleFill } from "react-icons/bs";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { GiHealthPotion } from "react-icons/gi";
import { FiEdit3 } from "react-icons/fi";
import { BiMailSend } from "react-icons/bi";
import { BiCheckDouble } from "react-icons/bi";
import { useConfirm } from "material-ui-confirm";
import EditTable from "../procedures/edittable";
import { apiurl } from '../app/prefs.js';
import "animate.css";
import "./style.css";

import { tasks } from "./data";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow key={-row.user_id} className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.user_name}
        </TableCell>
        <TableCell align="left">{row.org_unit}</TableCell>
        <TableCell align="left">{row.user_email}</TableCell>
        <TableCell align="left">
          <Button
            color="primary"
            startIcon={<GiHealthPotion />}
            style={{ marginRight: "10px" }}
            onClick={() => props.handleDialogOpen(true)}
          >
            Przypisz
          </Button>
          <Button
            onClick={() => {
              props.setActiveUser(row);
              props.setEditActive(true);
            }}
            style={{ color: "#4e97a5" }}
            startIcon={<FiEdit3 />}
          >
            Zmień
          </Button>
        </TableCell>
      </TableRow>
      <TableRow key={row.user_id}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              margin={1}
              style={{
                borderRadius: "5px",
                border: "2px solid rgb(220,220,220)",
                backgroundColor: "rgb(250,250,250)",
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                component="div"
              >
                Przypisane procedury
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow key="subhead">
                    <TableCell align="left">Kod procedury</TableCell>
                    <TableCell align="left">Nazwa procedury</TableCell>
                    <TableCell align="left">Data do</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Opcje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task["Kod procedury"]}>
                      <TableCell component="th" scope="row" align="left">
                        {task["Kod procedury"]}
                      </TableCell>
                      <TableCell align="left">
                        {task["Nazwa procedury"]}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: task.statuscode === 3 ? "#FE8E3C" : "black",
                        }}
                      >
                        {task["Data do"]}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: task.statuscode === 3 ? "#FE8E3C" : "black",
                        }}
                      >
                        {task["Status"]}
                      </TableCell>
                      <TableCell align="left">
                        {task.statuscode === 1 && (
                          <Button color="primary" startIcon={<BiMailSend />}>
                            Wyślij
                          </Button>
                        )}
                        {task.statuscode === 4 && (
                          <Button color="primary" startIcon={<BiCheckDouble />}>
                            Weryfikuj
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const UserEdit = (props) => {
  const { user, setUser, saveUser } = props;
  const handleChange = (property, newValue) => {
    setUser((prevState) => ({
      ...prevState,
      [property]: newValue,
    }));
  };

  return (
    <Dialog
      style={{ zIndex: 10000 }}
      open={props.active}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Dane użytkownika</DialogTitle>
      <DialogContent>
        <TextField
          value={user.user_name}
          autoComplete="off"
          onChange={(e) => handleChange("user_name", e.currentTarget.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Imię i nazwisko"
          type="text"
          fullWidth
        />
        <TextField
          value={user.user_email}
          autoComplete="off"
          onChange={(e) => handleChange("user_email", e.currentTarget.value)}
          margin="dense"
          id="email"
          label="E-mail"
          type="email"
          fullWidth
        />
        <TextField
          value={user.org_unit}
          autoComplete="off"
          onChange={(e) => handleChange("org_unit", e.currentTarget.value)}
          margin="dense"
          id="orgunit"
          label="Komórka organizacyjna"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!user.user_id}
          onClick={() => props.deleteUser(user.user_id)}
          color="primary"
        >
          Usuń
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={() => props.setActive(false)} color="primary">
          Anuluj
        </Button>
        <Button onClick={() => saveUser()} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const procheaders = [
  {
    name: "Indeks",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Nazwa",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Komórka org.",
    type: "text",
    edit: "false",
    format: "",
  },
  {
    name: "Data opisu",
    type: "text",
    edit: "false",
    format: "",
  },
];

const proceduresList = [
  {
    Indeks: "21.841",
    Nazwa: "21.841 - Plastyka przegrody nosowej",
    "Komórka org.": "Blok operacyjny",
    "Data opisu": "",
  },
  {
    Indeks: "13.49",
    Nazwa: "13.49 - Rozdrobnienie soczewki i aspiracja zaćmy - inne",
    "Komórka org.": "Blok operacyjny",
    "Data opisu": "",
  },
];

const taskAssignments = [{ IndeksProcedury: "21.841", Termin: "2021-01-15" }];

export default function ProceduresUsers() {
  const confirm = useConfirm();
  const [dialogOpen, handleDialogOpen] = React.useState(false);
  const mounted = React.useRef(false);
  const [editActive, setEditActive] = React.useState(false);
  const [assignments, setAssignments] = React.useState([]);
  const newUser = {
    user_name: "",
    user_email: "",
    org_unit: "",
  };
  const [activeUser, setActiveUser] = React.useState(newUser);
  const [consultants, setConsultants] = React.useState([]);

  const saveUser = () => {
    let dataToSend = activeUser;
    //console.log(dataToSend);
    if (activeUser.user_id) Object.assign(dataToSend, { command: "update" });
    else Object.assign(dataToSend, { command: "create" });
    //console.log(dataToSend);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dataToSend),
      };
      fetch(`${apiurl}/users`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          loadUsers();
          //setConsultants(data.Result)
        });
    });
    setEditActive(false);
  };

  const deleteUser = () => {
    let dataToSend = activeUser;
    Object.assign(dataToSend, { command: "delete" });
    //console.log(dataToSend)
    confirm({
      description:
        "Usunięcie konta użytkownika wiąże się ze skasowaniem przypisanych do niego zadań. Tej operacji nie można cofnąć. Kontynuować?",
      title: "Wymagane potwierdzenie",
      cancellationText: "Anuluj",
      confirmationText: "Tak, usuń",
      dialogProps: { style: { zIndex: 10000 } },
    })
      .then(() =>
        getAccessTokenSilently().then((token) => {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dataToSend),
          };
          fetch(`${apiurl}/users`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              //console.log(data)
              loadUsers();
              //setConsultants(data.Result)
            });
        })
      )
      .catch(() => console.log("Canceled"));
    setEditActive(false);
  };

  const { getAccessTokenSilently, user } = useAuth0();
  const classes = useStyles();

  const loadUsers = () => {
    let dataToSend = {};
    Object.assign(dataToSend, { command: "list" });
    getAccessTokenSilently().then((token) => {
      //console.log(token)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dataToSend),
      };
      fetch("${apiurl}/users", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data)
          if (data.Status === "Success") setConsultants(data.Result);
          else setTimeout(loadUsers, 500);
        });
    });
  };

  React.useEffect(() => {
    if (mounted.current) {
    } else {
      loadUsers();
      mounted.current = true;
    }
  }, []);

  const handleUser = () => {
    setActiveUser(newUser);
    setEditActive(true);
  };

  return (
    <div>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <BsPeopleFill
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Opis procedur - definiowanie uprawnień
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <Paper
        className={classes.root}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <Typography
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            paddingLeft: "10px",
          }}
          variant="h6"
        >
          Konsultanci merytoryczni
        </Typography>
        {consultants && (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow key="head">
                  <TableCell style={{ width: "20px" }} />
                  <TableCell align="left">Imię i nazwisko</TableCell>
                  <TableCell align="left">Stanowisko</TableCell>
                  <TableCell align="left">E-mail</TableCell>
                  <TableCell align="left">Opcje</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consultants.map((row) => (
                  <Row
                    key={row.user_id}
                    row={row}
                    setActiveUser={setActiveUser}
                    setEditActive={setEditActive}
                    handleDialogOpen={handleDialogOpen}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            startIcon={<BsPeopleFill />}
            color="primary"
            variant="contained"
            onClick={() => handleUser()}
            style={{
              marginRight: "10px",
              marginBottom: "10px",
              marginTop: "10px",
              justifySelf: "flex-end",
            }}
          >
            Dodaj
          </Button>
        </div>
      </Paper>
      <UserEdit
        active={editActive}
        setActive={setEditActive}
        user={activeUser}
        setUser={setActiveUser}
        saveUser={saveUser}
        deleteUser={deleteUser}
      />
      <Dialog
        fullScreen={false}
        fullWidth={true}
        maxWidth="xl"
        open={dialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        style={{
          zIndex: 10000,
        }}
      >
        <Typography
          variant="h6"
          style={{ color: "#0b6471", marginLeft: "30px", marginTop: "10px" }}
        >
          Wybierz procedury do opisu
        </Typography>

        <DialogContent>
          <div style={{ minHeight: "500px" }}>
            <EditTable
              filterColumn="Nazwa"
              callback={handleDialogOpen}
              resources={proceduresList}
              headers={procheaders}
              assignments={assignments}   
              datakey={'IndeksProcedury'}
              dictkey={'Indeks'}
              returnData={setAssignments}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
